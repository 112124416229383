import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, Fade, LinearProgress, TextField, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from '../AppContextProvider';
import { MasterService } from '../Services/MasterService';
import moment from 'moment';
import {format} from '../Utils';
import SingleDate from '../components/SingleDate';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    autoCompleteSelectionTextField: {

    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewSchemeDialog(props) {
    const [open, setOpen] = React.useState(false);
    const {showConfirmDialog, showSnackBar, closeConfirmDialog, state} = useContext(AppContext);
    const [loading, setLoading] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [title, setTitle] = React.useState(undefined);
    const [scheme, setScheme] = React.useState({startdate : moment(), enddate: moment()});

    const classes = useStyles();

    React.useEffect(() => {
        if(props.scheme){
            setLoading(true);
            setScheme(props.scheme);
            setEditMode(true);
        }
        setTitle(props.scheme ? ` Modify Scheme` : `Create Scheme`);
        setOpen(props.open);
        setLoading(false);
    }, [props.open]);


    const handleChange = (event) => {
        event.persist();
        setScheme(item => ({...item, [event.target.name]: event.target.value}));
    }


const handleSave = () => {
    if(scheme.name && scheme.amount && scheme.interest_1 
        && scheme.interest_2 && scheme.interest_3 && scheme.startdate){
        showConfirmDialog("Confirm save Scheme?", undefined, () => {
            setLoading(true);
            save();
            closeConfirmDialog();
        });
    } else {
        showSnackBar('All fields are Mandatory', 'error');
    }
}

const save = () => {
    let header = {user_id : state.user.id };
    let body = Object.assign({}, scheme);
    body.startdate = body.startdate ? moment(body.startdate).startOf('d').utc().format(format): null;
    body.enddate = body.startdate ? moment(body.enddate).endOf('d').utc().format(format): null;
    
    MasterService.saveScheme(body, header)
    .then((data) => {
        showSnackBar(`Scheme Saved.`, 'success');
        props.save();
        handleClose();
        setLoading(false);
    })
    .catch((err) => {
        console.log(err);
        showSnackBar(`Failed to Save Scheme. Please check again or contact Admin.`, 'error');
        setLoading(false);
    })
}
 
const handleClose = () => {
    setScheme({startdate : moment(), enddate: moment()});
    setOpen(false);
    setEditMode(false);
    props.close();
};

  return (
      <Dialog disableBackdropClick disableEscapeKeyDown onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                <Grid item xs={12} lg={12}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '10ms' : '0ms'
                        }}
                        unmountOnExit
                        >
                        <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                    </Fade>
                </Grid>
                <Grid item container xs={12} lg={12} key={1} spacing={2} alignItems="flex-start" justifyContent="space-between" alignContent="space-between">
                    <Grid item xs={12} lg={12}>
                        <TextField 
                            id="outlined-basic-name" 
                            label="Name" 
                            name="name"
                            disabled={loading}
                            required
                            variant="outlined" 
                            value={scheme.name || ''} 
                            fullWidth 
                            margin="dense"
                            type="text"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField 
                            id="outlined-basic" 
                            label="Amount" 
                            name="amount"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={scheme.amount ? scheme.amount : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                            type="number"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <TextField 
                            id="outlined-basic" 
                            label="Allowed Deviation" 
                            name="allowed_deviation"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={scheme.allowed_deviation ? scheme.allowed_deviation : ''}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: 100,
                                }
                            }}
                            type="number"
                            onChange={handleChange}/>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <TextField 
                            id="outlined-basic" 
                            label="No of Days" 
                            name="interval_days"
                            variant="outlined"
                            fullWidth
                            disabled={editMode}
                            margin="dense"
                            value={scheme.interval_days ? scheme.interval_days : ''}
                            InputProps={{
                                min: 0
                            }}
                            type="number"
                            onChange={handleChange}/>
                    </Grid> */}
                    <Grid item xs={12} lg={4}>
                        <TextField 
                            id="outlined-basic" 
                            label="Interest Slab 1" 
                            name="interest_1"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={editMode}
                            value={scheme.interest_1 ? scheme.interest_1 : ''}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                            type="number"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField 
                            id="outlined-basic" 
                            label="Interest Slab 2" 
                            name="interest_2"
                            variant="outlined"
                            margin="dense"
                            disabled={editMode}
                            value={scheme.interest_2 ? scheme.interest_2 : ''}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                            fullWidth
                            type="number"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField 
                            id="outlined-basic" 
                            label="Interest Slab 3" 
                            name="interest_3"
                            variant="outlined"
                            margin="dense"
                            disabled={editMode}
                            value={scheme.interest_3 ? scheme.interest_3 : ''}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                            fullWidth
                            type="number"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <div style={{maxWidth: 180}}>
                            <SingleDate 
                                label="Start"
                                error={editMode ? false : undefined}
                                helperText={editMode ? '' : undefined}
                                date={scheme.startdate}
                                minDate={moment()}
                                setDate={(date) => {
                                    setScheme(scheme => ({...scheme, startdate: date}));
                                }}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <div style={{maxWidth: 180}}>
                            <SingleDate 
                                label="End"
                                date={scheme.enddate}
                                minDate={scheme.startdate}
                                setDate={(date) => {
                                    setScheme(scheme => ({...scheme, enddate: date}));
                                }}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControlLabel
                            style={{marginTop: 12}}
                            control={
                            <Checkbox
                                color="primary"
                                checked={scheme.standard}
                                onChange={(event) => {
                                    setScheme(scheme => ({...scheme, standard: event.target.checked ? 1: 0}))
                                }}
                                inputProps={{ 'aria-label': 'standard checkbox' }}
                            />
                            }
                            label="Standard Scheme"
                        />
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} autoFocus onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button disabled={loading} autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
  );
}