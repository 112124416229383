import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	content: {
        textAlign: 'center', 
        marginBottom: 20
    },
    '@global': {
        '.MuiDialog-paperWidthXs': {
            maxWidth: 300
        }
    }
}));

export default function ProgressDialog(props) {
  const { open, message } = props;

  const classes = useStyles();

  return (
    <Dialog
        style={{zIndex: 1500}} 
        fullWidth
        disableEscapeKeyDown
        onClose={(event, reason) => {
          return
        }}
        maxWidth="xs"
        className={classes.root}
        aria-labelledby="simple-dialog-title" 
        open={open}>
      <DialogTitle id="simple-dialog-title">{message ? message : 'Please Wait...'}</DialogTitle>
      <DialogContent className={classes.content}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}

ProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
};
