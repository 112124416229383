import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TableRow, TableBody, Fab, InputAdornment, TextField, Icon, Button } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { FinanceService } from "../Services/FinanceService";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { StyledTableCell, BootstrapTooltip, format_without_time, generateID } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import SingleDate from '../components/SingleDate';
import moment from 'moment';
import ItemSelection from "../components/ItemSelection";
import { ROLES } from "../Roles";
import { TXN_TYPE } from "../Constants";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
let counter = 0;
const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function JournalTransaction(props){

    const {state, showSnackBar, showProgressDialog, closeProgressDialog, closeConfirmDialog, showConfirmDialog} = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const [accounts, setAccounts] = React.useState([]);
    const [entryBy, setEntryBy] = React.useState('');
    const [narration, setNarration] = React.useState('');
    const [branches, setBranches] = React.useState([]);
    const [selectedBranches, setSelectedBranches] = React.useState(null);
    const [startdate, setStartDate] = React.useState(moment().startOf('day'));
    const [minDate, setMinDate] = React.useState(moment().startOf('day'));
    const [currentRows, setCurrentRows] = React.useState([]);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [id, setId] = React.useState(null);
    
    const columns = [
        { id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Ledger Name', sortable: false },
        { id: 'debit', align: 'center', numeric: true, disablePadding: false, label: 'Debit', sortable: false },
        { id: 'credit', align: 'center', numeric: true, disablePadding: false, label: 'Credit', sortable: false },
        // { id: 'narration', align: 'center', numeric: true, disablePadding: false, label: 'Narration', sortable: false },
        { id: 'actions', align: 'center', numeric: false, disablePadding: false, label: '', sortable: false },
    ];

    React.useEffect(() => {
        let bList = state.branches.filter(b => b.id !== '-1');
        setBranches(bList);
        if(bList.length === 1){
            setSelectedBranches(bList[0]);
            fetchMaster(bList[0]);
        }
        if(!state.user){
            history.push('/');
        } else {
            setMinDate(state.user.roles.includes(ROLES.ADMIN) ? moment().startOf('day').set('M', 3).startOf('M') :  moment().startOf('day'));
            if(props.location.search){
                let id = null
                let qString = queryString.parse(props.location.search);
                if(qString.id) {
                    id = qString.id;
                    setId(id);
                    if(qString.startdate)
                        setStartDate(moment(qString.startdate));
                    if(qString.branchId){
                        setSelectedBranches(state.branches.find(b => b.id === qString.branchId));
                        fetchMaster(state.branches.find(b => b.id === qString.branchId));
                    }
                }
            }
        }
    }, [true])

    React.useEffect(() => {
        if(loadedMaster){
            if(id){
                showProgressDialog();
                FinanceService.getJournalTransactionDetails(id)
                .then((data) => {
                    if(data){
                        setNarration(data[0].narration);
                        setEntryBy(data[0].entry_by);
                        let rows = [];
                        data.sort((a, b) => (a.credit_debit < b.credit_debit) ? 1 : -1)
                        data.forEach((row,i) => {
                            rows.push({
                                id: row.id,
                                link_id: row.link_id,
                                rowid : counter++,
                                account: accounts.find(a => a.id === row.account_id),
                                debit: row.credit_debit === TXN_TYPE.D ? row.amount : null,
                                credit: row.credit_debit === TXN_TYPE.C ? row.amount : null
                            })
                        });
                        setCurrentRows(rows);
                    }
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    closeProgressDialog();
                    showConfirmDialog('Failed to load Transaction', undefined, () => {
                        history.push('/');
                        closeConfirmDialog();
                    });
                });
            } else {
                closeProgressDialog();
            }
        }
    }, [loadedMaster, id])

    const handleBranchChange = (branch) => {
        if(null !== branch){
            if(selectedBranches === null){
                setSelectedBranches(branch);
                fetchMaster(branch, true);
            } else if(selectedBranches !== null && branch.id !== selectedBranches.id){
                setSelectedBranches(branch);
                fetchMaster(branch, true);
                
            }
        } else {
            setSelectedBranches(null);
            setLoadedMaster(false);
        }
    }

    const fetchMaster = (branch, clearRows = false) => {
        showProgressDialog();
        FinanceService.getLedgers(null, branch.id, null, 0, 9999)
        .then(data => {
            if(data && data.length > 0) {
                setAccounts(data);
            }
            newEntry(clearRows);
            setLoadedMaster(true);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
        });
    }

    const newEntry = (clearRows = false, debit = null, credit = null) => {
        let row = {
            rowid : counter++,
            account: null,
            debit: debit,
            credit: credit
        }
        if(clearRows){
            setCurrentRows([row]);
        } else {
            setCurrentRows([...currentRows, row]);
        }
    }

    const handleAccountChange = rowid => account => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, account : account} 
                : row 
        ))
    }

    const handleDebitChange = rowid => event => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, debit : event.target.value} 
                : row 
        ))
    }

    const handleCreditChange = rowid => event => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, credit : event.target.value} 
                : row 
        ))
    }
    
    const handleNarrationChange = event => {
        setNarration(event.target.value);
    }

    const handleRemove = rowid => event => {
        setCurrentRows(currentRows.filter(r => r.rowid !== rowid));
    }

    const checkIfEntriesAreMatching = () => {
        let debitTotal = currentRows.reduce((sum, next) => sum + parseFloat(next.debit || 0), 0);
        let creditTotal = currentRows.reduce((sum, next) => sum + parseFloat(next.credit || 0), 0);
        if(debitTotal === creditTotal){
            return true;
        }
        return false;
    }

    const saveTransaction = e => {
        console.log(e);
        e.preventDefault();
        let cdEntry = currentRows.filter(row => row.debit && parseFloat(row.debit) > 0 && row.credit && parseFloat(row.credit) > 0);
        let missingData = currentRows.filter(row => !row.account || (!row.debit && !row.credit) || row.debit === 0 || row.credit  === 0);
        if(missingData.length > 0){
            showSnackBar('Missing data', 'error');
        } else if(cdEntry.length > 0) {
            showSnackBar('Cannot have Debit and Credit amount for the same Particular.', 'error');
        } else {
            let matchingEntries = checkIfEntriesAreMatching();
            if(!matchingEntries){
                showSnackBar('Debit and Credit amounts don\'t match', 'error')
            } else if(currentRows.length > 0 && selectedBranches && startdate){
                setSubmitted(true);
                let link_id = id || generateID(); 
                let txnRows = currentRows.map(row => ({
                    id: row.id || null,
                    link_id: link_id,
                    branch_id : selectedBranches.id,
                    account_id : row.account.id,
                    amount : row.credit > 0 ? row.credit : row.debit,
                    credit_debit: row.credit > 0 ? TXN_TYPE.C: TXN_TYPE.D,
                    narration : narration,
                    txn_date : startdate.format(format_without_time),
                    txn_type : "JOURNAL",
                    entry_by: entryBy,
                }));
                // let p = txnRows.map(row => FinanceService.saveJournalLedgerTransaction(row));
                showProgressDialog('Saving Transactions....');
                FinanceService.saveJournalLedgerTransaction(txnRows)
                .then(data => {
                    if(data){
                        showSnackBar('Saved Transaction', "info");
                        setCurrentRows([]);
                        resetForm();
                    } else {
                        showSnackBar('Something went Wrong. Please try again or contact Admin.', "warning");
                    }
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    setSubmitted(false);
                    closeProgressDialog();
                });
            }
        }
    }

    const resetForm = () => {
        setSelectedBranches(null);
        setStartDate(moment().startOf('day'));
        setSubmitted(false);
        setEntryBy('');
    };

    const handleEnterKey = () => {
        let debitTotal = currentRows.reduce((sum, next) => sum + parseFloat(next.debit || 0), 0);
        let creditTotal = currentRows.reduce((sum, next) => sum + parseFloat(next.credit || 0), 0);
        if(debitTotal !== creditTotal){
            if(debitTotal > creditTotal){
                newEntry(false, null, debitTotal - creditTotal)
            } else {
                newEntry(false, creditTotal - debitTotal, null);
            }
        }
    }

    return (
        <>
            <Grid container direction="row" spacing={1} justifyContent="center">
                {/* <Grid item xl={12} lg={12} xs={12}>
                    <div style={{float: 'left'}}>
                        
                    </div>
                </Grid> */}
                <Grid item xl={12} lg={12} xs={12}>
                    <Paper className={classes.section}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} lg={3}>
                                <ItemSelection 
                                    required 
                                    clearable={true} 
                                    label='Select Entity' 
                                    value={selectedBranches} 
                                    optionLabel='name' 
                                    options={branches} 
                                    selected={handleBranchChange}/>
                            </Grid>
                            <Grid item>
                                <div style={{maxWidth: 170}}>
                                    <SingleDate 
                                        date={startdate}
                                        minDate={minDate}
                                        maxDate={moment()}
                                        setDate={(date) => {
                                            setStartDate(date);
                                        }}/>
                                    </div>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div style={{minHeight: 300, maxHeight: 400, overflowY: 'auto', display: 'block'}}>
                                    <Table className={classes.table} stickyHeader>
                                        <EnhancedTableHead
                                            order={""}
                                            orderBy={""}
                                            onRequestSort={() => {}}
                                            rows={columns}  />
                                        <TableBody>
                                            {currentRows.map((row, i) => 
                                                <TableRow key={i} hover>
                                                    <StyledTableCell scope="row" align="justify" padding='none' style={{minWidth: '20vw'}}>
                                                        <ItemSelection 
                                                            clearable={true} 
                                                            required 
                                                            label='Particular' 
                                                            value={row.account} 
                                                            optionLabel='name' 
                                                            fullWidth
                                                            options={accounts.sort((a, b) => a.group_name.localeCompare(b.group_name))} 
                                                            selected={handleAccountChange(row.rowid)}
                                                            groupBy={(option) => option.group_name}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='none'>
                                                        <TextField 
                                                            id="outlined-basic" 
                                                            label="Debit" 
                                                            name="debit"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={row.debit ? row.debit : ''} 
                                                            onKeyUp={(event) => {
                                                                event.preventDefault();
                                                                if (event.key === 'Enter' || event.key === 'Tab')
                                                                    handleEnterKey();
                                                            }}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                                min: 0
                                                            }}
                                                            style={{marginTop: 8}}
                                                            type="number"
                                                            onChange={handleDebitChange(row.rowid)}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='none'>
                                                        <TextField 
                                                            id="outlined-basic" 
                                                            label="Credit" 
                                                            name="credit"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={row.credit ? row.credit : ''} 
                                                            onKeyUp={(event) => {
                                                                event.preventDefault();
                                                                if (event.key === 'Enter' || event.key === 'Tab')
                                                                    handleEnterKey();
                                                            }}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                                min: 0
                                                            }}
                                                            style={{marginTop: 8}}
                                                            type="number"
                                                            onChange={handleCreditChange(row.rowid)}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='normal'>
                                                        <Icon style={{marginTop: 8}} onClick={handleRemove(row.rowid)}>delete</Icon>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item xs={11} lg={8}>
                            <TextField 
                                id="outlined-basic" 
                                label="Narration" 
                                name="narration"
                                multiline 
                                rows={2}
                                variant="outlined" 
                                value={narration ? narration : ''} 
                                fullWidth 
                                margin="dense"
                                style={{marginLeft: 16}}
                                onChange={handleNarrationChange}/>
                            </Grid>
                            <Grid container item xs={12} lg={12} justifyContent={"space-between"} alignItems="center" style={{marginTop: 8}}>
                                <Grid item xs={12} lg={4}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Entered By" 
                                        name="entry_by"
                                        required 
                                        multiline 
                                        row={2}
                                        rowsMax="2" 
                                        variant="outlined" 
                                        value={entryBy ? entryBy : ''} 
                                        fullWidth 
                                        margin="dense"
                                        style={{marginLeft: 16}}
                                        onChange={(e) => setEntryBy(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button 
                                        disabled={(entryBy.length === 0 || currentRows.length === 0 || !selectedBranches) || submitted} 
                                        variant="contained"
                                        color="primary" 
                                        onClick={saveTransaction}>Save Transaction</Button> 
                                </Grid>
                                {/* <div style={{float: 'right', marginRight: 20, marginTop: 20}}>
                                       
                                </div> */}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <BootstrapTooltip title="Click to add new Entry">
                <Fab onClick={handleEnterKey} color="primary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip>
        </>
    )
}