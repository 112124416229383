import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, Tooltip, IconButton, FormControl, RadioGroup, FormControlLabel, Radio, Button, InputAdornment, TextField, Box } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, formatNumber, getTimeInIST, format_display_fancy_notime, precision, format_display_withtime, HtmlTooltip } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SingleDate from "../components/SingleDate";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import SearchBar from "../components/SearchBar";
import XLSX from 'xlsx';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '40vw',
        [theme.breakpoints.down('md')]: {
           width: '100vw'
        },
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    cellStyle: {
        fontSize: 12,
        lineHeight: '16px'
    }
}));

export default function PledgeOutstandingReport(props){

    const {state, setTitle, openViewLoan, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar} = useContext(AppContext);
    const classes = useStyles();
    const [selectedBranches, setSelectedBranches] = React.useState([]);
    const [selectedSchemeType, setSelectedSchemeType] = React.useState('scheme');
    const [selectedDateRangeType, setSelectedDateRangeType] = React.useState('between');
    const [selectedAmountType, setSelectedAmountType] = React.useState('none');
    const [summary, setSummary] = React.useState({});
    const [filter, setFilter] = React.useState('');
    const [branches, setBranches] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('pledge_no');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [amountFilter, setAmountFilter] = React.useState(0);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl.No.', sortable: false },
        { id: 'pledge_no', align: 'justify', numeric: false, disablePadding: true, label: 'Loan No', sortable: true },
        { id: 'start_date', align: 'center', numeric: false, disablePadding: true, label: 'Date', sortable: true },
        { id: 'customer_name', align: 'center', numeric: false, disablePadding: true, label: 'Customer', sortable: true },
        { id: 'customer_address', align: 'justify', numeric: false, disablePadding: true, label: 'Address', sortable: true },
        { id: 'scheme_name', align: 'center', numeric: false, disablePadding: true, label: 'Scheme', sortable: true },
        { id: 'total_weight', align: 'center', numeric: false, disablePadding: true, label: 'Net Weight', sortable: true },
        { id: 'loan_amount', align: 'right', numeric: true, disablePadding: true, label: 'Loan Amount', sortable: false },
        { id: 'received_amount', align: 'right', numeric: true, disablePadding: true, label: 'Amount Received (P+I)', sortable: false },
        { id: 'loan_amount_due', align: 'right', numeric: true, disablePadding: true, label: 'Capital Due', sortable: false },
        { id: 'interest_due', align: 'right', numeric: true, disablePadding: true, label: 'Interest Due', sortable: false },
        { id: 'total_amount_due', align: 'right', numeric: true, disablePadding: true, label: 'Amount Due', sortable: false },
        { id: 'days', align: 'right', numeric: true, disablePadding: true, label: 'Days', sortable: false },
        { id: 'current_days', align: 'center', numeric: false, disablePadding: true, label: 'Current Due Days', sortable: false },
        { id: 'pledge_rate', align: 'right', numeric: true, disablePadding: true, label: 'Rate', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.report_outstanding_loan_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.report_outstanding_loan_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.report_outstanding_loan_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Pledge Outstanding Report');
        showProgressDialog();
        // console.log(state);
        let bL = state.branches.filter(b => b.id !== '-1');
        setBranches(bL);

        if(bL.length === 1){
            setSelectedBranches(bL);
        }
        setGenerate(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            showProgressDialog();
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            let amountLTE = null;
            let amountGTE = null;
            if(selectedAmountType === 'lte'){
                amountLTE = amountFilter;
            } else if(selectedAmountType === 'gte'){
                amountGTE = amountFilter;
            }
            let fc = filter && filter.length > 0 ? filter : '';
            LoanRequestsService.reportOutstandingLoanCount(selectedDateRangeType === 'between' ? startdate : undefined, enddate, fc, selectedBranchIds, selectedSchemeType, amountLTE, amountGTE)
            .then(data => {
                setTotalItems(toInt(data.total_count));
                setFetchPage(true);
                setSummary(data);
                if(toInt(data.total_count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            let amountLTE = null;
            let amountGTE = null;
            if(selectedAmountType === 'lte'){
                amountLTE = amountFilter;
            } else if(selectedAmountType === 'gte'){
                amountGTE = amountFilter;
            }
            LoanRequestsService.reportOutstandingLoan(selectedDateRangeType === 'between' ? startdate : undefined, enddate, fc, selectedBranchIds, selectedSchemeType, amountLTE, amountGTE, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                if(data){
                    data.forEach((element, index) => {
                        let days = moment().endOf('d').diff(getTimeInIST(element.start_date).startOf('d'), 'days');
                        let currentDueDays = element.interest_last_received ? enddate.endOf('d').diff(getTimeInIST(element.interest_last_received).startOf('d'), 'days') : enddate.endOf('d').diff(getTimeInIST(element.start_date).startOf('d'), 'days');
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover>
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 20}} className={classes.cellStyle}>
                                    {index + 1 + (currentPageNumber * numItemsPerPage)}
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="justify" 
                                    padding='none' 
                                    style={{ color: 'blue', cursor: 'pointer' }} 
                                    className={classes.cellStyle}
                                    onClick={() => {
                                        openViewLoan(element.pledge_no)
                                    }}
                                    >
                                    <HtmlTooltip arrow title="Click to view Loan">
                                        <div>
                                            {element.pledge_no || ''}
                                        </div>
                                    </HtmlTooltip>
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' className={classes.cellStyle}>
                                    {getTimeInIST(element.start_date).format(format_display_fancy_notime)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none' className={classes.cellStyle}>
                                    {element.customer_name}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none' className={classes.cellStyle}>
                                    <span>{element.customer_address1}<br/>
                                    {element.customer_address2 && (<span>{element.customer_address2}<br/></span>)}
                                    {element.customer_address3 && (<span>{element.customer_address3}<br/></span>)}
                                    {element.customer_pincode}
                                    </span>
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' className={classes.cellStyle}>
                                    {element.scheme_name || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' className={classes.cellStyle}>
                                    {`${element.total_weight} gms`}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.loan_amount || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.received_amount || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.loan_amount_due || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.interest_due || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.total_amount_due || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{minWidth: 85}} className={classes.cellStyle}>
                                    {`${days} day${days > 1 ? 's' : ''}`}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{minWidth: 85}} className={classes.cellStyle}>
                                    {`${currentDueDays} day${currentDueDays > 1 ? 's' : ''}`}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle} style={{paddingRight: 16}}>
                                    {formatNumber(element.rate || 0)}
                                </StyledTableCell>                               
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportData = () => {
        showProgressDialog('Generating Report...');
        let rows = [];
        let filename = 'Pledge Outstanding Report';
        rows.push(['Scheme Type' , selectedSchemeType === 'scheme' ? 'Scheme' : 'Sub Scheme'])
        rows.push([`Pledge ${selectedDateRangeType === 'between' ? 'Between' : 'As On'}` , `${selectedDateRangeType === 'between' ? startdate.format(format_display_fancy_notime) : enddate.format(format_display_fancy_notime)}`, selectedDateRangeType === 'between' ? enddate.format(format_display_fancy_notime) : ''])
        rows.push(columns.map(c => c.label));
        let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
        let fc = filter && filter.length > 0 ? filter : '';
        LoanRequestsService.reportOutstandingLoan(selectedDateRangeType === 'between' ? startdate : undefined, enddate, fc, selectedBranchIds, selectedSchemeType, null, null, orderBy, order, 0, totalItems)
        .then((data) => {
            if(data){
                data.forEach((d, index) => {
                    let days = moment().endOf('d').diff(getTimeInIST(d.start_date).startOf('d'), 'days')
                    let currentDueDays = d.interest_last_received ? enddate.endOf('d').diff(getTimeInIST(d.interest_last_received).startOf('d'), 'days') : enddate.endOf('d').diff(getTimeInIST(d.start_date).startOf('d'), 'days')
                    rows.push([index + 1, d.pledge_no, getTimeInIST(d.start_date).format(format_display_withtime), 
                        d.customer_name, d.customer_address1 + ', ' + (d.customer_address2 ? d.customer_address2 : '') + ', ' + (d.customer_address3 ? d.customer_address3 : '') + ', ' + d.customer_pincode, 
                        d.scheme_name, precision(d.total_weight || 0), d.loan_amount , d.received_amount, d.loan_amount_due, 
                        d.interest_due, d.total_amount_due, days, currentDueDays, 
                        d.rate]);
                });
                rows.push([]);
                rows.push([]);
                //Summary
                rows.push(['Total Net Weight', summary.total_weight]);
                rows.push(['Total Loan Amount', summary.total_loan_amount]);
                rows.push(['Total Amount Received', summary.total_received_amount]);
                rows.push(['Total Principal Amount Due', summary.total_loan_amount_due]);
                rows.push(['Total Interest Due', summary.total_interest_due]);
                rows.push(['Total Due', summary.total_loan_amount_due + summary.total_interest_due]);

                const wb = XLSX.utils.book_new();
                const wsAll = XLSX.utils.aoa_to_sheet(rows);
                XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
                XLSX.writeFile(wb, filename + ".xlsx");
                closeProgressDialog();
            }
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to export. Please try again later', 'error');
            closeProgressDialog();
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={12} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                    {
                                        branches.length > 1 && (
                                            <Grid item xs={12} lg={2}>
                                                <ItemSelection 
                                                    multiple 
                                                    clearable={true} 
                                                    label='Entity' 
                                                    value={selectedBranches} 
                                                    optionLabel='name' 
                                                    options={branches} 
                                                    selected={setSelectedBranches}/>
                                            </Grid>  
                                        )
                                    }
                                <Grid item xs={12} lg={1}>
                                    <FormControl component="fieldset" style={{marginLeft: 8}}>
                                        <RadioGroup aria-label="scheme type" name="schemeType" value={selectedSchemeType} onChange={(e) => setSelectedSchemeType(e.target.value)}>
                                            <FormControlLabel value="scheme" control={<Radio />} label={<Box component="div" fontSize={12}>Scheme</Box>} />
                                            <FormControlLabel value="sub_scheme" control={<Radio />} label={<Box component="div" fontSize={12}>Sub Scheme</Box>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    Loan:
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="timeline" name="timeline_type" value={selectedDateRangeType} onChange={(e) => setSelectedDateRangeType(e.target.value)}>
                                            <FormControlLabel value="between" control={<Radio />} label={<Box component="div" fontSize={12}>Between</Box>} />
                                            <FormControlLabel value="ason" control={<Radio />} label={<Box component="div" fontSize={12}>As On</Box>} />
                                            <FormControlLabel value="older" control={<Radio />} label={<Box component="div" fontSize={12}>Older than</Box>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {
                                    selectedDateRangeType === 'between' ? (
                                        <Grid item container xs={12} lg={2}>
                                            <Grid item>
                                                <div style={{maxWidth: 170}}>
                                                    <SingleDate 
                                                        label="From"
                                                        date={startdate}
                                                        maxDate={moment()}
                                                        setDate={(date) => {
                                                            setStartDate(date);
                                                        }}
                                                        onKeyUp={(event) => {
                                                            event.preventDefault();
                                                            if (event.key === 'Enter'){
                                                                !generate && setGenerate(true)
                                                            }
                                                        }}/>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div style={{maxWidth: 170}}>
                                                    <SingleDate 
                                                        label="To"
                                                        date={enddate}
                                                        minDate={startdate}
                                                        maxDate={moment()}
                                                        setDate={(date) => {
                                                            setEndDate(date);
                                                        }}
                                                        onKeyUp={(event) => {
                                                            event.preventDefault();
                                                            if (event.key === 'Enter'){
                                                                !generate && setGenerate(true)
                                                            }
                                                        }}/>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : selectedDateRangeType === 'ason' ? (
                                        <Grid item container xs={12} lg={2}>
                                            <Grid item>
                                                <div style={{maxWidth: 170}}>
                                                    <SingleDate 
                                                        label="As on"
                                                        date={enddate}
                                                        minDate={startdate}
                                                        maxDate={moment()}
                                                        setDate={(date) => {
                                                            setEndDate(date);
                                                        }}
                                                        onKeyUp={(event) => {
                                                            event.preventDefault();
                                                            if (event.key === 'Enter'){
                                                                !generate && setGenerate(true)
                                                            }
                                                        }}/>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item container xs={12} lg={2}>
                                            <Grid item>
                                                <div style={{maxWidth: 170}}>
                                                    <TextField  
                                                        fullWidth
                                                        variant="outlined"
                                                        defaultValue={moment(enddate).diff(moment(), 'days') || 0}
                                                        onChange={(e) => {
                                                            if (!Number.isNaN(e.target.value) && Number.isInteger(+e.target.value)) {
                                                                setEndDate(moment().add(-(e.target.value), 'd'));
                                                            }
                                                        }}
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                                                            'aria-label': 'days',
                                                            min: 0
                                                        }}
                                                        onKeyUp={(event) => {
                                                            event.preventDefault();
                                                            if (event.key === 'Enter'){
                                                                !generate && setGenerate(true)
                                                            }
                                                        }}
                                                        />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} lg={1}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="amount" name="amount_type" value={selectedAmountType} onChange={(e) => setSelectedAmountType(e.target.value)}>
                                            <FormControlLabel value="none" style={{fontSize: 12}} control={<Radio />} label={<Box component="div" fontSize={12}>All Amounts</Box>} />
                                            <FormControlLabel value="lte" control={<Radio />} label={<Box component="div" fontSize={12}>Amount Less than</Box>} />
                                            <FormControlLabel value="gte" control={<Radio />} label={<Box component="div" fontSize={12}>Amount Greater than</Box>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                    <Grid item container xs={12} lg={2}>
                                        {
                                            selectedAmountType !== 'none' && (
                                                <Grid item>
                                                    <div>
                                                        <TextField  
                                                            fullWidth
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={amountFilter || 0}
                                                            onChange={(e) => {
                                                                if (!Number.isNaN(e.target.value) && +(e.target.value) >= 0) {
                                                                    setAmountFilter(e.target.value);
                                                                }
                                                            }}
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                                'aria-label': 'amount',
                                                                min: 0,
                                                            }}
                                                            onKeyUp={(event) => {
                                                                event.preventDefault();
                                                                if (event.key === 'Enter'){
                                                                    !generate && setGenerate(true)
                                                                }
                                                            }}
                                                            />
                                                    </div>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                {/* <Grid item xs={12} lg={2}>
                                    <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                        <SearchBar 
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                            setFilter(e.target.value);
                                            setGenerate(true);
                                        }}/>
                                    </div>
                                </Grid> */}
                                <Grid item xs={12} lg={2}>
                                    <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                        <SearchBar 
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                            setFilter(e.target.value);
                                            setGenerate(true);
                                        }}/>
                                    </div>
                                    <Tooltip title="Generate Report">
                                        <Button size="small" variant="contained" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            Generate
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={totalItems === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Loans</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                    {
                                        summary && (
                                            <div className={classes.summary}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="left">Total Net Weight</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="right">{`${precision(summary.total_weight|| 0)} gms`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="left">Total Loan Amount</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="right">{formatNumber(summary.total_loan_amount|| 0)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="left">Total Amount Received</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="right">{formatNumber(summary.total_received_amount|| 0)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="left">Total Principal Amount Due</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="right">{formatNumber(summary.total_loan_amount_due|| 0)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="left">Total Interest Due</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="inherit" variant="subtitle2" align="right">{formatNumber(summary.total_interest_due|| 0)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}