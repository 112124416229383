import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { linkPreventCaching } from '../Utils';

const useStyles = makeStyles((theme) => ({
    image: {
        margin: 12,
        position: 'relative',
        height: 200,
        width: '90% !important',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover,  &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.7,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    imageBackdrop: {
        borderRadius: 8,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.3,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageButtonBottom: {
        position: 'absolute',
        left: 0,
        right: 0,
        // top: 0,
        // bottom: 0,
        bottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    }
}))

const ImageHolder = ({ title, link }) => {
    let classes = useStyles()

    return (
        <ButtonBase
            focusRipple
            className={classes.image}
            href={link} target="_blank">
            <img
                src={linkPreventCaching(link)}
                height="200" width="200"
                alt={title}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButtonBottom}>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                >
                    {title}
                    <span className={classes.imageMarked} />
                </Typography>
            </span>
        </ButtonBase>
    )
}

export default ImageHolder