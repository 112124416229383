import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, Fade, LinearProgress, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from '../AppContextProvider';
import { MasterService } from '../Services/MasterService';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    autoCompleteSelectionTextField: {

    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewItemDialog(props) {
    const [open, setOpen] = React.useState(false);
    const {showConfirmDialog, showSnackBar, closeConfirmDialog, state} = useContext(AppContext);
    const [loading, setLoading] = React.useState(false);

    const [title, setTitle] = React.useState(undefined);
    const [item, setItem] = React.useState({});

    const classes = useStyles();

    React.useEffect(() => {
        if(props.item){
            setLoading(true);
            setItem(props.item);
        }
        setTitle(item.id ? ` Modify item` : `Create item`);
        setOpen(props.open);
        setLoading(false);
    }, [props.open]);


    const handleChange = (event) => {
        event.persist();
        setItem(item => ({...item, [event.target.name]: event.target.value}));
    }


const handleSave = () => {
    if(item.name){
        showConfirmDialog("Confirm save Item?", undefined, () => {
            setLoading(true);
            save();
            closeConfirmDialog();
        });
    } else {
        showSnackBar('All fields are Mandatory', 'error');
    }
}

const save = () => {
    let header = {user_id : state.user.id };
    let body = item;
    
    MasterService.saveItem(body, header)
    .then((data) => {
        showSnackBar(`Item Saved.`, 'success');
        props.save();
        handleClose();
        setLoading(false);
    })
    .catch((err) => {
        console.log(err);
        showSnackBar(`Failed to Save Item. Please check again or contact Admin.`, 'error');
        setLoading(false);
    })
}
 
const handleClose = () => {
    setItem({});
    setOpen(false);
    props.close();
};

  return (
      <Dialog disableBackdropClick disableEscapeKeyDown onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                <Grid item xs={12} lg={12}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '10ms' : '0ms'
                        }}
                        unmountOnExit
                        >
                        <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                    </Fade>
                </Grid>
                <Grid item container xs={12} lg={12} key={1} spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={12}>
                        <TextField 
                            id="outlined-basic-name" 
                            label="Name" 
                            name="name"
                            disabled={loading}
                            required
                            variant="outlined" 
                            value={item.name || ''} 
                            fullWidth 
                            margin="dense"
                            type="text"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TextField 
                            id="outlined-basic-desc" 
                            label="Description" 
                            disabled={loading}
                            name="description"
                            variant="outlined" 
                            value={item.description || ''} 
                            fullWidth 
                            margin="dense"
                            type="text"
                            onChange={handleChange}/>
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} autoFocus onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button disabled={loading} autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
  );
}