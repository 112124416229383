import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TableRow, TableBody, Divider, Icon, Tooltip, IconButton, Button, Typography, Radio, FormControlLabel, RadioGroup, FormControl, Box } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { StyledTableCell, formatNumber, format_display_fancy_notime, HtmlTooltip } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SingleDate from "../components/SingleDate";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import XLSX from 'xlsx';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop: theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),

    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '40vw',
        [theme.breakpoints.down('md')]: {
            width: '100vw'
        },
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    cellStyle: {
        fontSize: 12,
        lineHeight: '16px'
    }
}));

export default function LoyaltyCostAnalysis(props) {

    const { state, setTitle, setLinearLoading, openViewLoan, showProgressDialog, closeProgressDialog, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    const [selectedBranches, setSelectedBranches] = React.useState([]);
    const [branches, setBranches] = React.useState([]);
    const [selectedCriteria, setSelectedCriteria] = React.useState('customer');
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const order = 'asc';
    const orderBy = 'pledge_no';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [finalColumns, setFinalColumns] = React.useState([]);
    const columns = [
        { id: 'sl_no', align: 'center', numeric: false, disablePadding: true, label: 'Sl.No.', sortable: false },
        { id: 'name', align: 'left', numeric: false, disablePadding: true, label: 'Name', sortable: false },
        { id: 'pledge_no', align: 'left', numeric: false, disablePadding: true, label: 'Loan No', sortable: false },
        { id: 'interest_collected', align: 'right', numeric: true, disablePadding: true, label: 'Total Interest collected as per standard', sortable: false },
        { id: 'interest_subscheme', align: 'right', numeric: true, disablePadding: true, label: 'Interest as per Loyalty Schemes', sortable: false },
    ];

    React.useEffect(() => {
        setTitle('Loyalty Cost Analysis');
        // console.log(state);
        let bL = state.branches.filter(b => b.id !== '-1');
        setBranches(bL);

        if (bL.length === 1) {
            setSelectedBranches(bL);
        }
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        if (generate && selectedBranches.length > 0) {
            setCurrentRows([]);
            setLinearLoading(true);
            showProgressDialog();
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            LoanRequestsService.reportLoyaltyCostAnalysis(startdate, enddate, selectedBranchIds, selectedCriteria)
                .then(data => {
                    let rows = [];
                    setFinalColumns(selectedCriteria === 'customer' ? columns.filter(c => c.id !== 'pledge_no') : columns)
                    if (data) {
                        setData(data);
                        data.forEach((element, index) => {
                            rows.push(
                                <TableRow
                                    key={index}
                                    hover>
                                    <StyledTableCell scope="row" align="center" padding='none' className={classes.cellStyle}>
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left" padding='none' className={classes.cellStyle}>
                                        {element.name}
                                    </StyledTableCell>
                                    {
                                        selectedCriteria === 'loan' && (
                                            <StyledTableCell
                                                scope="row"
                                                align="left"
                                                padding='none'
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                className={classes.cellStyle}
                                                onClick={() => {
                                                    openViewLoan(element.pledge_no)
                                                }}
                                            >
                                                <HtmlTooltip arrow title="Click to view Loan">
                                                    <div>
                                                        {element.pledge_no || ''}
                                                    </div>
                                                </HtmlTooltip>
                                            </StyledTableCell>
                                        )}
                                    <StyledTableCell scope="row" align="right" padding='none' className={classes.cellStyle}>
                                        {formatNumber(element.interest_collected || 0)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' className={classes.cellStyle} style={{ paddingRight: 16 }}>
                                        {formatNumber(element.interest_subscheme || 0)}
                                    </StyledTableCell>
                                </TableRow>
                            );
                        });
                    }
                    setCurrentRows(rows);
                    setLinearLoading(false);
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                    closeProgressDialog();
                });
            setGenerate(false);
        } else if (generate) {
            showSnackBar('Select an Entity First', 'info');
            setGenerate(false);
        }
    }, [generate]);

    const sortBy = (event, property) => {
    }

    const exportData = () => {
        showProgressDialog('Generating Report...');
        let rows = [];
        let filename = 'Loyalty Cost Analysis Report';

        rows.push(['Branches', selectedBranches.map(b => b.name).join(', ')])
        rows.push([startdate.format(format_display_fancy_notime), enddate.format(format_display_fancy_notime)])
        rows.push(finalColumns.map(c => c.label));
        if (data) {
            data.forEach((d, index) => {
                if (selectedCriteria === 'customer') {
                    rows.push([index + 1, d.name, d.interest_collected, d.interest_subscheme]);
                } else {
                    rows.push([index + 1, d.name, d.pledge_no, d.interest_collected, d.interest_subscheme]);
                }
            });
            const wb = XLSX.utils.book_new();
            const wsAll = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
            XLSX.writeFile(wb, filename + ".xlsx");
            closeProgressDialog();
        } else {
            showSnackBar('No data to Export', 'info');
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={8} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                {
                                    branches.length > 1 && (
                                        <Grid item xs={12} lg={3}>
                                            <ItemSelection
                                                multiple
                                                clearable={true}
                                                label='Entity'
                                                value={selectedBranches}
                                                optionLabel='name'
                                                options={branches}
                                                selected={setSelectedBranches} />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} lg={2}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="timeline" name="criteria_type" value={selectedCriteria} onChange={(e) => setSelectedCriteria(e.target.value)}>
                                            <FormControlLabel value="customer" control={<Radio />} label={<Box component="div" fontSize={12}>By Customer</Box>} />
                                            <FormControlLabel value="loan" control={<Radio />} label={<Box component="div" fontSize={12}>By Loan</Box>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={12} lg={4} spacing={1}>
                                    <Grid item>
                                        <div style={{ maxWidth: 170 }}>
                                            <SingleDate
                                                label="From"
                                                date={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setStartDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter') {
                                                        !generate && setGenerate(true)
                                                    }
                                                }} />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ maxWidth: 170 }}>
                                            <SingleDate
                                                label="To"
                                                date={enddate}
                                                minDate={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setEndDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter') {
                                                        !generate && setGenerate(true)
                                                    }
                                                }} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Tooltip title="Generate Report">
                                        <Button size="small" variant="contained" style={{ marginTop: 8 }} aria-label="Refresh" color="primary" onClick={() => { !generate && setGenerate(true) }}>
                                            Generate
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={currentRows.length === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={currentRows.length === 0} style={{ marginTop: 8 }} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={finalColumns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        currentRows.length === 0 && (
                                            <div style={{ marginBottom: 50 }}>
                                                <Typography color="error" variant="subtitle2">No Records Available</Typography>
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}