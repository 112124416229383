import React, { Component } from "react";
import "../css/employee.css";
import { Form } from 'react-bootstrap';
import { UserService } from "../Services/UserService";
import AddIcon from '@material-ui/icons/Add';
import {
    // IconButton,
    Tooltip,
    // Icon,
    Paper,
    withStyles,
    Fab,
    Table,
    TableBody,
    TableRow,
    Typography,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    FormControl,
    Button,
    ButtonBase,
    makeStyles,
} from "@material-ui/core";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import { Constants } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import {AppContext} from '../AppContextProvider';
import { BootstrapTooltip, linkPreventCaching, StyledTableCell } from "../Utils";
import MyDialog from "../components/MyDialog";
import UploadKYCDialog from "../components/UploadKYCDialog";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(0),
        }
    },
  });

class Customer extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_customer: { name: "", mobile_no: "", code: "", email: "", roles: []},
        show: false,
        columns : [
            { id: 'index', numeric: false, align: 'left', disablePadding: true, label: 'Sl.No', sortable: false },
            { id: 'name', numeric: false, align: 'center', disablePadding: true, label: 'Name', sortable: true },
            { id: 'cust_code', numeric: false, align: 'center', disablePadding: true, label: 'Customer Code', sortable: true },
            { id: 'mobile_no', align: 'center', numeric: false, disablePadding: true, label: 'Mobile' , sortable: false },
            { id: 'address', align: 'center', numeric: false, disablePadding: true, label: 'Address', sortable: true },
            { id: 'nominee', align: 'center', numeric: false, disablePadding: true, label: 'Nominee', sortable: true },
            { id: 'occupation', align: 'center', numeric: false, disablePadding: true, label: 'Occupation', sortable: true },
            // { id: 'pincode', numeric: false, disablePadding: true, label: 'Pin Code', sortable: true  },
            // { id: 'location', numeric: false, disablePadding: true, label: 'Geo Location', sortable: false  },
            { id: 'email', align: 'center', numeric: false, disablePadding: true, label: 'Email', sortable: false  },
            { id: 'kyc', align: 'center', numeric: false, disablePadding: true, label: 'KYC Details', sortable: false  },
            { id: 'active_loans', align: 'center', numeric: false, disablePadding: false, label: 'Active Loans', sortable: true  },
            // { id: '', numeric: true, disablePadding: false, label: '', sortable: false  }
        ],
        order: 'asc',
        orderBy: 'name',
        numItemsPerPage: 10,
        currentPageNumber: 0,
        totalItems: 0,
        totalPages: 0,
        type: Constants.CUSTOMER,
        filter: '',
      }

      
      this.createModifyUser = this.createModifyUser.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
 
    componentDidMount(){
        const {setTitle} = this.context;
        setTitle("Manage Customers");
        this.refresh();
    }

    refresh = () => {
		this.fetchTotalPages();
    }
    
    fetchTotalPages(){
        const {setLinearLoading, state} = this.context;
        setLinearLoading(true);
        let {filter} = this.state;
        let branches = state.selectedBranch.id !== '-1' ? state.selectedBranch.id : null;
        UserService.getTotalCustomerCount(filter, branches)
        .then((data) => {
            this.setState({
                totalItems: data.count, 
                totalPages: Math.ceil(data.count / this.state.numItemsPerPage) - 1
            });
            if(data.count > 0) {
                this.fetchCustomers(0, this.state.numItemsPerPage);
            } else {
                setLinearLoading(false);
                this.setState({rows: [], message: "No Customers", showSpinner: false });
            }
        })
        .catch(error => {
            setLinearLoading(false);
            console.log(error);
        });
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_customer: { name: "", mobile_no: "", code: "", email: ""}
        });
    }

    fetchCustomers = (start, numberItems) => {
        const {setLinearLoading, state} = this.context;
		let {order, orderBy, filter} = this.state;
        let rows = [];
		let sort = orderBy ? orderBy : '', direction = order ? order : '';
		let branches = state.selectedBranch.id !== '-1' ? state.selectedBranch.id : null;
		UserService.getCustomersList(branches, start, numberItems, filter, sort, direction)
        .then((data) => {
            if(data){
                data.forEach((user, index) => {
                    rows.push(<CustomerDetails index={index + 1  + start} key={index + 1  + start} user={user} classes={this.props.classes} remove={this.remove} edit={this.edit} changePhoto={this.changePhoto} refresh={this.refresh}/>);
                });
            }
			this.setState({rows, message: data.length > 0 ? "" : "No Customers"});
			setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        });
    }
    
    changePhoto = (customerId, file) => {
        const {showSnackBar, showProgressDialog, closeProgressDialog} = this.context;
        showProgressDialog('Uploading...');
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('document_type', 'PHOTO');
        UserService.changePhoto(customerId, formData)
        .then(data => {
            if(data.success){
                showSnackBar('Photo changed', 'info');
                this.refresh();
            } else {
                showSnackBar('Failed to change Photo. Please try again later', 'error');
            }
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to change Photo. Please try again later', 'error');
            closeProgressDialog();
        })
    }

	handleSelect = (eventKey, e) => {
        if(eventKey === 'prev'){
            if((this.state.currentPageNumber) > 0){
                this.fetchRow(this.state.currentPageNumber - 1);
            }
        } else if(eventKey === 'next'){
            if((this.state.currentPageNumber) < this.state.totalPages){
                this.fetchRow(this.state.currentPageNumber + 1);
            }   
        }
    }

    fetchRow = (newCurrentPage) => {
        this.fetchCustomers(newCurrentPage * this.state.numItemsPerPage, this.state.numItemsPerPage);
        this.setState({ currentPageNumber: newCurrentPage });
    }

	handleChangeRowsPerPage = event => {
        this.setState({ numItemsPerPage : event.target.value, currentPageNumber: 0 }, () => this.fetchCustomers(0, this.state.numItemsPerPage));
    }

    handleChangePage = (event, page) => {
        if(this.state.currentPageNumber > page){
            this.fetchRow(this.state.currentPageNumber - 1);
        } else {
            this.fetchRow(this.state.currentPageNumber + 1);
        }
    };

    sortBy = (event, property) => {
        const orderBy = property;
        let order = 'desc';
    
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }
    
        this.setState({ order, orderBy }, () => this.fetchCustomers(0, this.state.numItemsPerPage));
    }

	filterCriteria = (e) => {
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value || "";
        }
        this.setState({filter : searchText}, () => this.fetchCustomers(0, this.state.numItemsPerPage));
	}

    handleShow() {
        this.setState({ show: true });
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_customer;
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    handleChangeCheckBox(e) {
        let change = this.state.new_customer;
        if(e.target.checked && !change['roles'].includes(e.target.name)){
            change['roles'].push(e.target.name);
        } else {
            var index = change['roles'].indexOf(e.target.name);
            change['roles'].splice(index, 1);
        }
        this.setState({new_customer: change});
    }

    createModifyUser(e){
        const {showConfirmDialog, closeConfirmDialog, showSnackBar} = this.context;
        e.preventDefault();
        if(this.state.new_customer.name && this.state.new_customer.mobile_no && this.state.new_customer.pincode.length >= 6 && this.state.new_customer.address.length > 5){
            showConfirmDialog("Confirm?", undefined, () => {
                this.createAPI(this.state.new_customer);
                closeConfirmDialog();
            });
        } else {
            showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, user){
        const {showConfirmDialog, closeConfirmDialog} = this.context;
        e.preventDefault();
        showConfirmDialog("Confirm removal of Customer?", undefined, () => {
            this.removeAPI(this.dialog, user.id);
            closeConfirmDialog();
        });
    }

    removeAPI(dialog, id){
        const {showConfirmDialog, showSnackBar} = this.context;
        UserService.removeUser(id)
        .then((data) => {
            this.refresh();
            showSnackBar(`Removed Customer`, 'success');
        })
        .catch((error) => {
            showConfirmDialog("Could not remove Customer. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, user){
        e.preventDefault();
        if(user){
            let new_customer = Object.assign({}, user);
            this.setState({new_customer}, () => this.handleShow());
        } else {
            this.handleShow();
        }
    }

    createAPI(new_customer){
        const {showConfirmDialog, showSnackBar, setLinearLoading, state: s} = this.context;
        //new_customer.code = 1234;
        let body = new_customer;
        setLinearLoading(true);
        let headers = {user_id : s.user.id };
        UserService.createCustomer(headers, body)
        .then((response) => {
            if(response.status === 409){
                showSnackBar(`Employee already exists with the same mobile no. Please check again.`, 'error');
            } else {
                this.setState({
                    new_customer: { name: "", mobile_no: ""}
                });
                this.refresh();
                showSnackBar(`Updated Customer`, 'success');
                if(this.state.show) this.handleClose();
            }
            setLinearLoading(false);
        })
        .catch((error) => {
            setLinearLoading(false);
            showConfirmDialog(`Failed to create Customer. Please try again.`, undefined, undefined);
        });
        
    }

    render() {
        let {classes} = this.props;
        let {rows, message, totalItems, numItemsPerPage, currentPageNumber} = this.state;
        return (
            <div className="Home">
                <div>
                    <Paper className={classes.root}>
                    <EnhancedTableToolbar title={<Typography variant="h5">{`Customers: ${totalItems}`}</Typography>} refresh={this.refresh} searchHint="Name, Address or mobile" onSearchTextChange={this.filterCriteria}/>
						<Table className={classes.table} size="small">
							<EnhancedTableHead
								order={this.state.order}
								orderBy={this.state.orderBy}
								onRequestSort={this.sortBy}
								rows ={this.state.columns} />
							<TableBody>
								{rows}
							</TableBody>
						</Table>
						<div className={classes.message}>
							{message}
						</div>
						<TablePagination
							rowsPerPageOptions={RowCountOptions}
							component="div"
							count={totalItems}
							rowsPerPage={numItemsPerPage}
							page={currentPageNumber}
							backIconButtonProps={{
								'aria-label': 'Previous Page',
							}}
							nextIconButtonProps={{
								'aria-label': 'Next Page',
							}}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
							classes={{ selectRoot : classes.selectRoot}}
							/>
                    </Paper>
                </div>
                <Dialog disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'
                    style={{marginTop : "5%"}}>
                    <DialogTitle id="alert-dialog-title">{this.state.show ? "Customer" : ""}</DialogTitle>
                    <DialogContent>
                        {this.userForm( "Edit Customer", this.createModifyUser, "Save")}
                    </DialogContent>
                </Dialog>
                {
                    false && 
                    <Tooltip title="Add Customer">
                        <Fab onClick={this.edit} color="primary" aria-label="Add" className={this.props.classes.fab}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
        );
    }

    userForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                defaultValue={this.state.new_customer.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <TextField
                id="mobile_no"
                label="mobile_no"
                placeholder="Mobile no"
                name="mobile_no"
                defaultValue={this.state.new_customer.mobile_no}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{10}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 10 }
                }}
                />
            <TextField
                id="address"
                label="Address"
                placeholder="Address"
                name="address"
                defaultValue={this.state.new_customer.address}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <FormControl variant="outlined" fullWidth>
                <TextField
                    id="pincode"
                    label="Pincode"
                    placeholder="Pincode"
                    name="pincode"
                    autoComplete="off"
                    defaultValue={this.state.new_customer.pincode}
                    className={this.props.classes.textField}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    InputProps={{
                        inputProps: { min: 0, maxLength: 6 }
                    }}
                />
            </FormControl>
            <TextField
                id="location"
                label="Geo Location in the format Latitude,Longitude. eg: 12.33456,9.7855"
                placeholder="Geo Location in the format Latitude,Longitude. eg: 12.33456,9.7855"
                name="location"
                defaultValue={this.state.new_customer.location}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                />
            <TextField
                id="outlined-email-input"
                label="Email"
                className={this.props.classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                defaultValue={this.state.new_customer.email}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                variant="outlined"
                />
                <Button variant="outlined" color="primary" type="submit" onClick={submitFunction} style={{marginRight: 10, marginLeft: 10}}>{buttonText}</Button>
                {this.state.show ? (<Button variant="outlined"  color="secondary" onClick={this.handleClose}>Close</Button>): ""}
        </Form>
    )

}

const detailsStyle = makeStyles(theme => ({
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        // top: 0,
        // bottom: 0,
        bottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    image: {
        position: 'relative',
        height: 250,
        [theme.breakpoints.down('xs')]: {
          width: '100% !important', // Overrides inline-style
          height: 100,
        },
        '&:hover, &$focusVisible': {
          zIndex: 1,
          '& $imageBackdrop': {
            opacity: 0.80,
          },
          '& $imageMarked': {
            opacity: 0,
          },
          '& $imageTitle': {
            border: '4px solid currentColor',
          },
        },
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.0,
        transition: theme.transitions.create('opacity'),
    },
    focusVisible: {},
}))

function CustomerDetails(props) {
    const classes = detailsStyle();
    const [showImageDialog, setShowImageDialog] = React.useState(false);
    const [dialogDetailsURL, setDialogDetailsURL] = React.useState(null);
    const [dialogDetailsTitle, setDialogDetailsTitle] = React.useState(null);
    
    const [showKYCDialog, setShowKYCDialog] = React.useState(false);
    const [showChangeKYCDialog, setShowChangeKYCDialog] = React.useState(false);
    const [showChangeKYCCustomer, setShowChangeKYCCustomer] = React.useState(null);
    const [dialogKYC, setDialogKYC] = React.useState(null);
    const [dialogKYCTitle, setDialogKYCTitle] = React.useState(null);

    const user = props.user;
    const index = props.index;
    const link = user.documents.length > 0 && user.documents[0].link;

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    const handleKYCClick = () => {
        setShowChangeKYCDialog(true);
        setShowChangeKYCCustomer(user.id);
        setShowKYCDialog(false);
        setDialogKYC(null);
        setDialogKYCTitle(null);
    }

    const changePhoto = event => {
        const fileUploaded = event.target.files[0];
        setDialogDetailsURL(null);
        setDialogDetailsTitle(null);
        setShowImageDialog(false);
        props.changePhoto(user.id, fileUploaded);
    }

    return (
        <TableRow key={user.id}>
            <StyledTableCell padding="none" scope="row">{index}</StyledTableCell>
            <StyledTableCell padding="none" component="th" scope="row">
                <div style={{verticalAlign: 'middle', textAlign: 'center'}}>
                    <BootstrapTooltip title={link ? "Click to enlarge" : ""}>
                        <div 
                            style={{marginRight: 15, maxWidth: 110, cursor: 'pointer'}} 
                            onClick={(e) => {
                                if(link){
                                    setShowImageDialog(true);
                                    setDialogDetailsTitle(user.name);
                                    setDialogDetailsURL(link);
                                }
                            }}>
                            <img src={link ? linkPreventCaching(link) : null} height="50" width="50" alt="" style={{minWidth: 50, minHeight: 50}}/>
                        </div>
                    </BootstrapTooltip>
                    <div>
                        {user.name}
                    </div>
                </div>
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">{user.cust_code}</StyledTableCell>
            <StyledTableCell padding="none" align="center">{user.mobile}</StyledTableCell>
            <StyledTableCell padding="none" align="justify">
                {user.address1}<br/>
                {user.address2 &&  user.address2}<br/>
                {user.address3 &&  user.address3}<br/>
                {user.pincode && user.pincode}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
                    {user.nominee || '-'}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">
                    {user.occupation || '-'}
            </StyledTableCell>
            <StyledTableCell padding="none" align="center">{user.email ? user.email : "-"}</StyledTableCell>
            <StyledTableCell padding="none" align="center">
                {
                    user.kyc.length > 0 ? 
                    (
                        <BootstrapTooltip title={"Click to view KYC"}>
                            <Typography color="primary" 
                                style={{cursor: 'pointer'}}
                                onClick={(e) => {
                                        setShowKYCDialog(true);
                                        setDialogKYCTitle(`${user.kyc[0].type}: ${user.kyc[0].identifier}`);
                                        setDialogKYC(user.kyc[0].link);
                                }}>
                                {user.kyc[0].type}
                            </Typography>
                        </BootstrapTooltip>
                    ) 
                    : "-"
                }
            </StyledTableCell>
            <StyledTableCell align="center">{user.active_loans || '-'}</StyledTableCell>
            {/* <StyledTableCell padding="none" align="right"> */}
                {/* <Tooltip title="Edit">
                    <IconButton size="small" aria-label="Edit" onClick={(e) => props.edit(e, user)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip> */}
                {/* <Tooltip title="Remove">
                    <IconButton size="small" aria-label="Remove" onClick={(e) => props.remove(e, user)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip> */}
            {/* </StyledTableCell> */}
            <MyDialog
                maxWidth={"xs"}
                content={
                    <ButtonBase
                        focusRipple
                        className={classes.image}
                        focusVisibleClassName={classes.focusVisible}
                    >
                        <img 
                            src={linkPreventCaching(dialogDetailsURL)} 
                            height="250" width="250" 
                            alt=""/>
                        <span className={classes.imageBackdrop} />
                        <span className={classes.imageButton} onClick={handleClick}>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                Click to Change
                            <span className={classes.imageMarked} />
                            </Typography>
                            <input accept="image/*" type="file" onChange={changePhoto} ref={hiddenFileInput} style={{display:'none'}} />
                        </span>
                    </ButtonBase>
                    }
                title={dialogDetailsTitle}
                open={showImageDialog}
                close={() => {
                    setShowImageDialog(false);
                    setDialogDetailsURL(null);
                    setDialogDetailsTitle(null);
                }}/>
            <MyDialog
                maxWidth={"xs"}
                content={
                    <div>
                        <ButtonBase
                            focusRipple
                            className={classes.image}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            <img 
                                src={linkPreventCaching(dialogKYC)} 
                                height="250" width="250" 
                                alt=""/>
                                <span className={classes.imageBackdrop} />
                            <span className={classes.imageButton} onClick={handleKYCClick}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    Click to Change
                                <span className={classes.imageMarked} />
                                </Typography>
                            </span>
                        </ButtonBase>
                        <Button href={dialogKYC} color="inherit" target="_blank">View in new Tab</Button>
                    </div>
                    }
                title={dialogKYCTitle}
                open={showKYCDialog}
                close={() => {
                    setShowKYCDialog(false);
                    setDialogKYC(null);
                    setDialogKYCTitle(null);
                }}/>
            <UploadKYCDialog
                maxWidth={"xs"}
                title={'Upload KYC'}
                open={showChangeKYCDialog}
                customerId={showChangeKYCCustomer}
                close={(refresh= false) => {
                    setShowChangeKYCDialog(false);
                    setShowChangeKYCCustomer(null);
                    if(refresh){
                        props.refresh();
                    }
                }}/>
        </TableRow>
    );
}

export default withStyles(styles)(Customer);