import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from  'clsx';
import { Grid, FormControl, Divider, Button, TextField, Icon, Card, CardHeader, CardContent, CardActions, colors } from "@material-ui/core";
import { ToolsService } from "../Services/ToolsService";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    section: {
        padding: 10,
        marginTop: 16
    },
    formControl: {
        margin: theme.spacing(3),
        width: "70%"
    },
    customToolTip: {
        fontSize: 16
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.blue[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function ManageNotifications(props) {

    const { setTitle: setAppBarTitle, showSnackBar, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const classes = useStyles();
    const [title, setTitle] = React.useState("");
    const [body, setBody] = React.useState("");

    React.useEffect(() => {
        setAppBarTitle("Manage Notifications");
    }, [true]);

    function sendBroadcast(){
        if(title.length > 0 && body.length > 0){
            showConfirmDialog("Confirm Send notification?", undefined, () => {
                ToolsService.sendBroadcastNotification(title.trim(), body.trim())
                .then(data => {
                    if(data.message_id){
                        showSnackBar(`Broadcasted message`, 'success');
                    } else {
                        showSnackBar(`Failed to add. Please try again`, 'error');
                    }
                    closeConfirmDialog();
                });
            });
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <div>
                    <Grid container direction="row" justifyContent="center" spacing={1}>
                        <Grid item xs={12} lg={6}>
                            <Card>
                                <CardHeader
                                    title="Send Broadcast Notification"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                                        <Grid item xs={12} lg={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-helperText"
                                                    label="Title"
                                                    helperText="Title of the notification"
                                                    variant="outlined"
                                                    onChange={(e) => {setTitle(e.target.value)}}
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="outlined-helperText"
                                                    label="Message"
                                                    helperText="Notification message content (max 1000 Chars)"
                                                    variant="outlined"
                                                    onChange={(e) => {setBody(e.target.value)}}
                                                    />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button endIcon={<Icon>send</Icon>} variant="contained" className={classes.button} onClick={sendBroadcast}>
                                        Send Notification
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
 