import React from "react";
import { Popover } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import { Chip, TableCell, makeStyles, withStyles, Tooltip } from "@material-ui/core";
import { STATUS } from "./Constants";
import CustomAvatar from "./components/CustomAvatar";
import { ToolsService } from "./Services/ToolsService";
import { toWords } from 'number-to-words';

const formatter = new Intl.NumberFormat('en-IN');

export function randomHex() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function popover(text) {
  return (
    <Popover id="popover-top">
      <strong>{text}</strong>
    </Popover>
  );
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_ddmmyyyyhhmm = "Do MMM YYYY HH:mm";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy_notime_report = "Do MMM, YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";

export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment.utc().clone().tz("Asia/Kolkata");
}

export function formatQty(number) {
  return formatter.format(number);
  // return parseFloat(number).toLocaleString('en-IN', {style: 'unit', minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function convertProjectStatusToString(status) {
  switch (status) {
    case STATUS.ACTIVE:
      return "Active";
    case STATUS.CANCELLED:
      return "Cancelled";
    case STATUS.CLOSED:
      return "Closed";
    default:
      return "None";
  }
}

export function getColor(status) {
  let color = "#FFFFFF";
  switch (status) {
    case STATUS.ACTIVE:
      color = "#42a5f5";
      break;
    case STATUS.CLOSED:
      color = "#bdbdbd";
      break;
    case STATUS.CANCELLED:
    case STATUS.CANCEL:
      color = "#e57373";
      break;
    case STATUS.UNSERVICEABLE:
      color = "#b39ddb";
      break;
    case STATUS.ASSIGNED:
      color = "#4db6ac";
      break;
    default:
      break;
  }
  return color;
}

export function getProjectStatusBadge(status) {
  let color = getColor(status);

  return <Chip label={convertProjectStatusToString(status)} style={{ backgroundColor: color }} />;
}

export function formatNumber(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "INR",
  });
}

export function formatNumberNoSym(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function toPercentage(portion, total) {
  return parseFloat((portion / total) * 100).toFixed(1) + "%";
}

export function isInt(n) {
  return n % 1 === 0;
}

export function TranslateLoanRequest(request) {
  return <CustomAvatar request={request} />;
}

export function TranslateStatus(status) {
  let color = getColor(status);
  let text = status;
  // switch (status) {
  //   case STATUS.OPEN:
  //     color = "primary";
  //     break;
  //   case STATUS.CLOSED:
  //     color = "default";
  //     break;
  //   case STATUS.CANCEL:
  //     color = "secondary";
  //     break;
  //   case STATUS.UNSERVICEABLE:
  //     color = "secondary";
  //     break;
  //   case STATUS.ASSIGNED:
  //     color = "primary";
  //     break;
  //   default:
  //     break;
  //
  return <Chip size="small" label={text} style={{ backgroundColor: color }} />;
}

export function StatusOptions(status) {
  let options = [];

  switch (status) {
    case STATUS.OPEN:
      options = [
        STATUS.ASSIGNED,
        STATUS.CANCEL,
        STATUS.CLOSED,
        STATUS.UNSERVICEABLE,
      ];
      break;
    case STATUS.CLOSED:
    case STATUS.CANCEL:
    case STATUS.UNSERVICEABLE:
      break;
    case STATUS.ASSIGNED:
      options = [
        STATUS.ASSIGNED,
        STATUS.CANCEL,
        STATUS.CLOSED,
        STATUS.UNSERVICEABLE,
      ];
      break;
    default:
      break;
  }

  return options;
}

export function sendNotification(
  id,
  title,
  body,
  loan_request_id = undefined,
  request_no = undefined
) {
  return ToolsService.sendNotification(
    id,
    title,
    body,
    loan_request_id,
    request_no
  );
}

export function sendSMS(
  id,
  type,
  var1,
  var2,
  var3,
  var4
) {
  let body = {
    id,
    type,
  };
  if (var1) body.var1 = var1;
  if (var2) body.var2 = var2;
  if (var3) body.var3 = var3;
  if (var4) body.var4 = var4;
  return ToolsService.sendSMS(body);
}

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: '1px solid rgba(238, 238, 238, 1)',
    fontSize: 14,
    height: 35,
  }
}))(TableCell);

export const generateID = () => {
  // // Math.random should be unique because of its seeding algorithm.
  // // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // // after the decimal.
  // return '_' + Math.random().toString(36).substr(2, 9);
  return new Date().getTime().toString();
};

export function titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export function precision(number) {
  return parseFloat(parseFloat(number).toFixed(2));
}

export function increaseby(figure = 0, percentage = 0) {
  return figure * (1 + percentage / 100);
}

export function numberToText(number) {
  return `${toWords(number).replace(/-/g, ' ')} only`;
}

/**
 * 
 * @param {object} scheme 
 * @param {int} noOfDays 
 * @param {float} amount 
 * @param {int} diffFromToday > 0: Part, 0: Normal, < 0: Advance
 */
export function calculateInterest(scheme, noOfDays, amount, diffFromToday = 0) {
  if (scheme) {
    // let interval = scheme.interval_days;
    let noOfDaysInYear = parseInt(scheme.standard) === 1 ? 360 : 360;
    let period = 0;
    // if(interval > 0){
    // period = daysRoundedInMonths(noOfDays, noOfDaysInYear) / 12;
    // } else {
    period = noOfDays / noOfDaysInYear;
    // }
    // Old Format until 21-08-2021
    // let interest = getInterestPercentage(scheme, noOfDays, diffFromToday);
    // // return amount * period * (interest / 100)
    // return Math.round(amount * period * (interest / 100))

    // New Format
    // Case 1: if Days <= 32: Interest from slab 1
    // Case 2: if Days > 32 && Days <= 272: Interest from slab 2
    // Case 3: if Days > 272: For first 270 days Interest from slab 2 and for the rest(noOfDays - 270) Interest from slab 3
    if (noOfDays <= 272) {
      let interest = getInterestPercentage(scheme, noOfDays, diffFromToday);
      return Math.round(amount * period * (interest / 100))
    } else {
      let interest1 = getInterestPercentage(scheme, 270, diffFromToday);
      let interest2 = scheme.interest_3;
      let period1 = 270 / noOfDaysInYear;
      let period2 = (noOfDays - 270) / noOfDaysInYear;
      return Math.round(amount * period1 * (interest1 / 100)) + Math.round(amount * period2 * (interest2 / 100))
    }
  }
  return 0;
}

/**
 * 
 * @param {object} scheme 
 * @param {int} noOfDays
 * @param {int} diffFromToday > 0: Part, 0: Normal, < 0: Advance
 */
export function getInterestPercentage(scheme, noOfDays, diffFromToday = 0) {
  let interest = 0;
  if (diffFromToday === 0) {
    if (noOfDays <= 45) {
      interest = scheme.interest_1;
    } else if (noOfDays > 45 && noOfDays <= 272) {
      interest = scheme.interest_2;
    } else if (noOfDays > 272) {
      interest = scheme.interest_3;
    }
  } else if (diffFromToday > 0) {
    interest = scheme.interest_1;
  } else {
    let diff = Math.abs(diffFromToday)
    if (diff <= 45) {
      interest = scheme.interest_1;
    } else if (diff > 45 && diff <= 272) {
      interest = scheme.interest_2;
    } else if (diff > 272) {
      interest = scheme.interest_3;
    }
  }
  return interest;
}

export function daysRoundedInMonths(days, totalDays) {
  totalDays /= 12;
  return Math.ceil(4 * days / totalDays) / 4
}

export function monthsRoundedInDays(months, totalDays) {
  return Math.floor(months * totalDays);
}

export function linkPreventCaching(link = undefined) {
  if (!link) {
    return undefined
  }
  return link.includes('?') ? `${link}&${(new Date()).getTime()}` : `${link}?${(new Date()).getTime()}`
}