import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, Avatar, ButtonBase, Icon, FormControl, InputAdornment, TextField, Table, TableBody, TableRow, CircularProgress, Backdrop, Card, CardContent, FormControlLabel, Checkbox } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { BootstrapTooltip, StyledTableCell, getTimeInIST, format_display_fancy_notime_withDay, formatNumber, precision, format, increaseby, linkPreventCaching } from '../Utils';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import ItemSelectionFreeSolo from '../components/ItemSelectionFreeSolo';
import { MasterService } from '../Services/MasterService';
import EnhancedTableHead from '../components/EnhancedTableHead';
import moment from 'moment';
import { LoanRequestsService } from '../Services/LoanRequestsService';
import CustomerListDialog from '../components/CustomerListDialog';
import { UserService } from '../Services/UserService';
import EditIcon from '@material-ui/icons/Edit';
import { ROLES } from '../Roles';
import MyDialog from '../components/MyDialog';
import SingleDate from '../components/SingleDate';
import { FinanceService } from '../Services/FinanceService';
import { CheckBalanceAndSave } from './utils';

let counter = 0;
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	subtitle: {
		// marginLeft: theme.spacing(2),
		flex: 1,
		fontWeight: 600
		// color: theme.palette.white
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &$focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
		// bottom: 0,
		bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateLoanRequest(props) {
	const { state, openViewLoan, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);
	const { id } = props;
	const classes = useStyles();
	const [showForm, setShowForm] = useState(false)
	const [open, setOpen] = React.useState(false);
	const [disbursementModes, setDisbursementModes] = React.useState([]);
	const [loan, setLoan] = React.useState({});
	const [photoFile, setPhotoFile] = React.useState(null);
	const [kycFile, setKYCFile] = React.useState(null);
	const [loanFile, setLoanFile] = React.useState(null);
	const [kycDocs, setKYCDocs] = React.useState([]);
	const [itemRows, setItemRows] = React.useState([]);
	const [items, setItems] = React.useState(null);
	const [schemes, setSchemes] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [historyLoading, setHistoryLoading] = React.useState(false);
	const [selectedCustomer, setSelectedCustomer] = React.useState(null);
	const [customerList, setCustomerList] = React.useState([]);
	const [referrerList, setReferrerList] = React.useState([]);
	const [openCustomerList, setOpenCustomerList] = React.useState(false);
	const [showPreviousLoans, setShowPreviousLoans] = React.useState(false);
	const [disableEdit, setDisableEdit] = React.useState(false);
	const [firstLoad, setFirstLoad] = React.useState(true);
	const [errorList, setErrorList] = React.useState({});
	const [errorItemRow, setErrorItemRow] = React.useState([]);
	const [loanHistory, setLoanHistory] = React.useState([]);
	const [bankOptions, setBankOptions] = React.useState([]);
	const [cashOptions, setCashOptions] = React.useState([]);

	const columns = [
		{ id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
		{ id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Item', sortable: false },
		{ id: 'qty', align: 'center', numeric: true, disablePadding: false, label: 'Qty', sortable: false },
		{ id: 'weight', align: 'center', numeric: true, disablePadding: false, label: 'Gross Weight', sortable: false },
		{ id: 'd_weight', align: 'center', numeric: true, disablePadding: false, label: 'Deducted Weight', sortable: false },
		{ id: 'net_weight', align: 'center', numeric: true, disablePadding: false, label: 'Net Weight', sortable: false },
		{ id: 'purity', align: 'center', numeric: false, disablePadding: false, label: 'Purity', sortable: false },
		{ id: 'action', align: 'center', numeric: false, disablePadding: false, label: '', sortable: false },
	];

	React.useEffect(() => {
		setOpen(props.open);
		if (props.open) {
			if (state.selectedBranch.id === '-1') {
				showConfirmDialog1D('Please select Entity first', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				});
			} else {
				setLoan({});
				setLoanHistory([]);
				setSelectedCustomer(null);
				showProgressDialog();
				fetchMaster();
				setBankOptions([]);
				setLoading(true);
			}
		}
		return () => {
			setLoan({});
			setOpen(false);
		}
	}, [props.open]);

	const fetchMaster = () => {
		let branchId = state.selectedBranch.id;
		setLoan(loan => ({ ...loan, branchId }));
		let reqs = [
			MasterService.getKYCDocsList([1].join(',')),
			MasterService.getItemsList([1].join(',')),
			MasterService.getSchemesList(moment(), moment()),
			LoanRequestsService.getReferrerSuggestions(),
			FinanceService.getLedgers('4', branchId, null, 0, 9999)
		];
		if (id) {
			reqs.push(LoanRequestsService.getLoanDetails(id));
		} else {
			reqs.push(LoanRequestsService.getNextPledgeNo(branchId))
		}
		reqs.push(FinanceService.getLedgers('5', null, null, 0, 9999))
		Promise.all(reqs)
			.then(datas => {
				if (datas[0]) {
					setKYCDocs(datas[0]);
				}
				if (datas[1]) {
					setItems(datas[1]);
				}
				if (datas[2]) {
					setSchemes(datas[2]);
				}
				if (datas[3]) {
					setReferrerList(datas[3]);
				}
				if (datas[4]) {
					setBankOptions(datas[4]);
				}
				if (datas[6]) {
					setCashOptions(datas[6]);
				}
				let dm = [];
				dm.push({ name: 'CASH', value: 'CASH' });
				if (datas[4] && datas[4].length > 0) {
					dm.push({ name: 'BANK', value: 'BANK' });
				}
				setDisbursementModes(dm);
				if (datas[5]) {
					if (id) {
						let data = datas[5];
						setSelectedCustomer(data.customer);
						let kyc = null;
						if (data.customer.kyc.length > 0) {
							kyc = datas[0].find(k => k.id === data.customer.kyc[0].kyc_id);
						}
						let mode = dm[0];
						let bank = null;
						if (data.disbursement_mode) {
							mode = dm.find(d => d.value === data.disbursement_mode);
							if (data.disbursement_mode === 'BANK' && datas[4] && datas[4].length > 0) {
								bank = datas[4].find(d => d.id === data.disbursement_ledger_id);
							}
						}
						setDisableEdit(!data.transactions || data.transactions.length !== 1 || !state.user || !state.user.roles.includes(ROLES.ADMIN));
						setLoan(loan => ({
							...loan,
							id: data.id,
							customer_id: data.customer.id,
							customer_name: data.customer.name,
							customer_mobile: data.customer.mobile,
							customer_alternate_no: data.customer.alternate_no,
							customer_occupation: data.customer.occupation,
							customer_nominee: data.customer.nominee,
							customer_email: data.customer.email,
							customer_address1: data.customer.address1,
							customer_address2: data.customer.address2,
							customer_address3: data.customer.address3,
							customer_pincode: data.customer.pincode,
							permanent_address1: data.customer.permanent_address1,
							permanent_address2: data.customer.permanent_address2,
							permanent_address3: data.customer.permanent_address3,
							permanent_pincode: data.customer.permanent_pincode,
							kyc,
							customer_identifier: data.customer.kyc && data.customer.kyc.length > 0 ? data.customer.kyc[0].identifier : "",
							pledge_no: data.pledge_no,
							link: data.link,
							scheme_id: data.scheme_id,
							sub_scheme_id: data.sub_scheme_id,
							scheme: datas[2].find(d => d.id === data.scheme_id),
							sub_scheme: datas[2].find(d => d.id === data.sub_scheme_id),
							total_weight: data.total_weight,
							eligible_amount: data.eligible_amount,
							loan_amount: data.loan_amount,
							rate: data.rate,
							branchId: data.branch_id,
							service_charge: data.service_charge,
							disbursement_mode: data.disbursement_mode,
							mode,
							bank,
							entry_by: data.entry_by,
							remarks: data.remarks,
							referred_by: data.referred_by,
							referrer: datas[3].find(d => d.referred_by === data.referred_by),
							start_date: getTimeInIST(data.start_date),
							end_date: getTimeInIST(data.end_date),
						})
						);
						// fetchLoanHistory(data.customer.id);
						let errRows = [], itemRows = [];
						data.items.forEach((i, index) => {
							let x = {
								id: i.id,
								rowid: counter++,
								item: datas[1].find(ii => ii.id === i.item_id),
								qty: i.qty,
								weight: i.weight,
								d_weight: i.d_weight || 0,
								purity: i.purity
							};
							itemRows.push(x);
							errRows.push({
								...x,
								item: false, qty: false, weight: false, d_weight: false
							})
						});
						setErrorItemRow(errRows);
						setItemRows(itemRows);
					} else {
						setFirstLoad(false);
						setLoan(loan => ({ ...loan, pledge_no: datas[5].no, mode: dm[0] }));
						if (datas[4] && datas[4].length > 0) {
							setLoan(loan => ({ ...loan, bank: datas[4][0] }));
						}
					}
				}
				closeProgressDialog();
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				setLoading(false);
				closeProgressDialog();
			});
	}

	const handleClose = (showConfirm = false) => {
		if (showConfirm) {
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close();
			});
		} else {
			close();
		}

	};

	const close = () => {
		setLoan({});
		setErrorList({});
		setItemRows([]);
		setErrorItemRow([]);
		setPhotoFile(null);
		setKYCFile(null);
		setLoanFile(null);
		setLoanHistory([]);
		props.onClose();
		setOpen(false);
	}

	const handleChange = field => e => {
		setFirstLoad(false);
		let value = e.target.value;
		if (field === 'loan_amount') {
			setLoan(loan => ({ ...loan, [field]: value, rate: ((value / loan.total_weight) || 0) }));
		} else {
			setLoan(loan => ({ ...loan, [field]: value }));
		}
		setErrorList(e => ({ ...e, [field]: !value }));
	}

	const handleCheckChange = field => e => {
		let checked = e.target.checked;
		setLoan(loan => ({ ...loan, [field]: checked }));

		if (field === 'sameAsCurrent') {
			copyCurrentToPer();
		}
		// else if(field.includes('option_')){
		// 	setErrorList(e => ({...e, options: !lead.option_mcx && !lead.option_ncdex && !lead.option_nse_fo && !lead.option_nse_currency && !lead.option_nse}));
		// }
	}

	const copyCurrentToPer = () => {
		setLoan(loan => ({ ...loan, permanent_address1: loan.customer_address1, permanent_address2: loan.customer_address2, permanent_address3: loan.customer_address3, permanent_pincode: loan.customer_pincode }));
		setErrorList(e => ({ ...e, permanent_address1: false, permanent_address2: false, permanent_address3: false, permanent_pincode: false }));
	}


	const handleRowChange = rowid => e => {
		setFirstLoad(false);
		let name = e.target.name;
		let value = e.target.value;
		setItemRows(itemRows.map(row =>
			row.rowid === rowid
				?
				{ ...row, [name]: value }
				:
				row
		)
		);
		setErrorItemRow(rows => rows.map(row =>
			row.rowid === rowid
				?
				{ ...row, [name]: !value }
				: row
		));
	}

	const handleRowItemChange = rowid => item => {
		setFirstLoad(false);
		setItemRows(itemRows.map(row =>
			row.rowid === rowid
				?
				{ ...row, item }
				: row
		)
		);
		setErrorItemRow(rows => rows.map(row =>
			row.rowid === rowid
				?
				{ ...row, item: !item }
				: row
		));
	}

	const handleRowRemove = rowid => e => {
		setFirstLoad(false);
		setItemRows(itemRows.filter(r => r.rowid !== rowid));
	}

	const searchForCustomer = (type) => {
		let filter = type === 'name' ? loan.customer_name : loan.customer_mobile;
		if (filter && filter.length > 0) {
			setLoading(true);
			showProgressDialog();
			UserService.getCustomerNameList(filter)
				.then(data => {
					closeProgressDialog();
					setCustomerList(data);
					setOpenCustomerList(true);
					setLoading(false);
				})
				.catch(error => {
					closeProgressDialog();
					console.log(error);
					setLoading(false);
					showSnackBar('Please try seraching again.', 'info')
				});
		} else {
			setShowForm(true)
		}
		// showSnackBar('Search Clicked', 'info');
	}

	const verifyCustomerMobile = () => {
		showProgressDialog('Verifying Customer information...')
		Promise.all([UserService.getCustomerNameList(loan.customer_mobile), UserService.getCustomerNameList(loan.customer_name)])
			.then(datas => {
				let mobileData = datas[0];
				let nameData = datas[1];
				if ((nameData.length === 0 || nameData[0].mobile === loan.customer_mobile)
					&& (mobileData.length === 0
						|| mobileData[0].name.trim().toLowerCase() === loan.customer_name.trim().toLowerCase()
						|| (mobileData[0].id === loan.customer_id && mobileData[0].name.trim().toLowerCase() === loan.customer_name.trim().toLowerCase()))
				) {
					showConfirmDialog('Confirm Save Pledge', undefined, () => {
						closeConfirmDialog();
						setLoading(true);
						checkBalanceAndSave();
					})
				} else if (nameData.length > 0) {
					if (nameData.length === 1 && nameData[0].mobile !== loan.customer_mobile) {
						showConfirmDialog(<span>There is a Customer with same name in the system with mobile no <strong>{nameData[0].mobile}</strong>. Please confirm and proceed</span>, undefined, () => {
							closeConfirmDialog();
							setLoading(true);
							checkBalanceAndSave();
						});
					} else {
						showConfirmDialog(<span>There are {nameData.length} customers with the same name in the system. Please confirm and proceed</span>, undefined, () => {
							closeConfirmDialog();
							setLoading(true);
							checkBalanceAndSave();
						});
					}
				} else {
					showConfirmDialog1D(<span>Customer already exists in the system with the name <strong>{mobileData[0].name}</strong> for the mobile no <b>{loan.customer_mobile}</b></span>, undefined, () => {
						closeConfirmDialog1D();
					});
				}
				closeProgressDialog();
			})
			.catch(error => {
				closeProgressDialog();
			});
	}

	const addRow = () => {
		let row = {
			rowid: counter++,
			item: null,
			qty: null,
			weight: null,
			d_weight: 0,
			purity: null,
		}
		setItemRows([...itemRows, row]);
		setErrorItemRow([...errorItemRow, { ...row, item: true, qty: true, weight: true, d_weight: false }]);
	}

	React.useEffect(() => {
		if (!disableEdit && !firstLoad) {
			recalulateLoan();
		}
	}, [loan.sub_scheme, loan.rate, JSON.stringify(itemRows)]);



	const recalulateLoan = () => {
		if (loan.sub_scheme) {
			let rate = loan.rate;
			let orgrate = loan.orgrate || loan.rate;
			let total_weight = itemRows.reduce((sum, next) => sum + (next.weight - next.d_weight), 0) || 0;
			setLoan(loan => ({ ...loan, total_weight: total_weight, rate: rate, eligible_amount: orgrate * total_weight, loan_amount: rate * total_weight }));
		}
	}

	const handleCustomerListClose = (customer, isNew) => {
		if (customer) {
			setLoading(true);
			UserService.getCustomerById(customer.id)
				.then(data => {
					closeProgressDialog();
					loadCustomerInfo(data);
					setLoading(false)
					setShowForm(true)
				})
				.catch(error => {
					closeProgressDialog();
					console.log(error);
					setLoading(false);
					showSnackBar('Something went wrong. Please try again.', 'info');
				});
			fetchLoanHistory(customer.id);
		} else if (isNew) {
			setShowForm(true)
		}
		setOpenCustomerList(false);
	}

	const fetchLoanHistory = (customerId) => {
		if (customerId) {
			setHistoryLoading(true);
			LoanRequestsService.getLoanHistory(customerId)
				.then(data => {
					setLoanHistory(data);
					setHistoryLoading(false);
				})
				.catch(error => {
					console.log(error);
					showSnackBar('Something went wrong while trying to fetch History', 'info');
					setHistoryLoading(false);
				});
		}
	}

	const loadCustomerInfo = (customer) => {
		setSelectedCustomer(customer);
		let kyc = null;
		let id = '';
		if (customer.kyc.length > 0) {
			kyc = kycDocs.find(k => k.id === customer.kyc[0].kyc_id);
			id = customer.kyc[0].identifier;
		}
		setLoan(loan => ({
			...loan,
			customer_id: customer.id,
			customer_name: customer.name,
			customer_mobile: customer.mobile,
			customer_alternate_no: customer.alternate_no,
			customer_occupation: customer.occupation,
			customer_nominee: customer.nominee,
			customer_email: customer.email,
			customer_address1: customer.address1,
			customer_address2: customer.address2,
			customer_address3: customer.address3,
			customer_pincode: customer.pincode,
			permanent_address1: customer.permanent_address1,
			permanent_address2: customer.permanent_address2,
			permanent_address3: customer.permanent_address3,
			permanent_pincode: customer.permanent_pincode,
			kyc,
			customer_identifier: id
		}));
	}

	const changePhoto = (field, file) => {
		if (field === 'photo_file') {
			setPhotoFile(file);
		} else if (field === 'kyc_file') {
			setKYCFile(file);
		} else if (field === 'loan_file') {
			setLoanFile(file);
		}
		setLoan(loan => ({ ...loan, [field]: file }));
	}

	const savePledge = () => {
		if (isValid()) {
			verifyCustomerMobile()
		}
	}

	const isValid = () => {
		let valid = true;
		if (!loan.customer_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_name: !loan.customer_name }))
		if (!loan.customer_mobile) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_mobile: !loan.customer_mobile }))
		if (!loan.customer_address1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_address1: !loan.customer_address1 }))
		if (!loan.customer_address3) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_address3: !loan.customer_address3 }))
		if (!loan.customer_pincode) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_pincode: !loan.customer_pincode }))
		if (!loan.permanent_address1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, permanent_address1: !loan.permanent_address1 }))
		if (!loan.permanent_address3) {
			valid = false;
		}
		setErrorList(e => ({ ...e, permanent_address3: !loan.permanent_address3 }))
		if (!loan.permanent_pincode) {
			valid = false;
		}
		setErrorList(e => ({ ...e, permanent_pincode: !loan.permanent_pincode }))
		if (!loan.kyc) {
			valid = false;
		}
		setErrorList(e => ({ ...e, kyc: !loan.kyc }));
		if (!loan.customer_identifier) {
			valid = false;
		}
		setErrorList(e => ({ ...e, customer_identifier: !loan.customer_identifier }));
		if (!photoFile && (selectedCustomer === null || (selectedCustomer !== null && selectedCustomer.documents.length === 0))) {
			valid = false;
		}
		setErrorList(e => ({ ...e, document_file: !photoFile && (selectedCustomer === null || (selectedCustomer !== null && selectedCustomer.documents.length === 0)) }))
		if (!kycFile && (selectedCustomer === null || (selectedCustomer !== null && selectedCustomer.kyc.length === 0))) {
			valid = false;
		}
		setErrorList(e => ({ ...e, kyc_file: !kycFile && (selectedCustomer === null || (selectedCustomer !== null && selectedCustomer.kyc.length === 0)) }));
		if (!loanFile && !loan.link) {
			valid = false;
		}
		setErrorList(e => ({ ...e, loan_file: !loanFile && !loan.link }))
		if (!loan.scheme) {
			valid = false;
		}
		setErrorList(e => ({ ...e, scheme: !loan.scheme }));
		if (!loan.sub_scheme) {
			valid = false;
		}
		setErrorList(e => ({ ...e, sub_scheme: !loan.sub_scheme }));
		let allowed_deviation = (loan.sub_scheme && loan.sub_scheme.allowed_deviation) || 0;
		let sAmt = (loan.scheme && loan.scheme.amount) || 0;
		if (!loan.rate || parseFloat(loan.rate) > increaseby(sAmt, allowed_deviation)
			//|| parseFloat(loan.rate) < increaseby(sAmt, -allowed_deviation)
		) {
			if (parseFloat(loan.rate) > increaseby(sAmt, allowed_deviation)) {
				showConfirmDialog(`Rate per gram of Gold cannot be greater than ${formatNumber(increaseby(sAmt, allowed_deviation))}`);
			}
			// else if(parseFloat(loan.rate) < increaseby(sAmt, -allowed_deviation)){
			// 	showConfirmDialog(`Rate per gram of Gold cannot be less than ${formatNumber(increaseby(sAmt, -allowed_deviation))}`);
			// }
			valid = false;
		}
		setErrorList(e => ({ ...e, rate: !loan.rate || parseFloat(loan.rate) > increaseby(sAmt, allowed_deviation) }))
		if (!loan.loan_amount) {
			valid = false;
		}
		setErrorList(e => ({ ...e, loan_amount: !loan.loan_amount }))
		if (!loan.entry_by) {
			valid = false;
		}
		setErrorList(e => ({ ...e, entry_by: !loan.entry_by }));
		if (itemRows.length === 0) {
			showConfirmDialog('Please add atleast 1 item');
			valid = false;
		} else {
			let i = itemRows.filter(i => !i.item || !i.qty || !i.weight || i.d_weight.length === 0);
			if (i.length > 0) {
				valid = false;
				i.forEach(i => {
					setErrorItemRow(rows => rows.map(row =>
						row.rowid === i.rowid
							?
							{ ...row, item: !i.item, qty: !i.qty, weight: !i.weight, d_weight: i.d_weight.length === 0 }
							: row
					));
				})
			}
		}

		return valid;
	}

	const checkBalanceAndSave = () => {
		showProgressDialog('Checking balance...')
		const mode = loan.disbursement_mode || 'CASH';
		const disbursementLedgerId = mode === 'BANK' && loan.bank ? loan.bank.id : (cashOptions.length > 0 ? cashOptions[0].id : null)
		const branchId = loan.branchId || null

		if (!disbursementLedgerId || !branchId) {
			closeProgressDialog()
			setLoading(false)
			showConfirmDialog1D(<span>Disbursement Ledger or branch not found</span>, undefined, () => {
				closeConfirmDialog1D()
			})
			return
		}

		CheckBalanceAndSave(disbursementLedgerId, branchId, loan.loan_amount)
			.then(data => {
				if (data.sufficientBalance) {
					save()
				} else {
					closeProgressDialog()
					setLoading(false)
					showConfirmDialog1D(data.message, undefined, () => {
						closeConfirmDialog1D()
					})
				}
			})
			.catch(error => {
				console.log(error)
				showSnackBar('Failed to Check balance. Please try again.', 'error');
				closeProgressDialog()
				setLoading(false)
			})
	}

	const save = () => {
		const formData = new FormData();
		formData.append('id', loan.id || null);
		formData.append('customer_id', loan.customer_id || null);
		formData.append('customer_name', loan.customer_name || null);
		formData.append('customer_mobile', loan.customer_mobile || null);
		formData.append('customer_alternate_no', loan.customer_alternate_no || null);
		formData.append('customer_occupation', loan.customer_occupation || '');
		formData.append('customer_nominee', loan.customer_nominee || '');
		formData.append('customer_email', loan.customer_email || '');
		formData.append('customer_address1', loan.customer_address1 || null);
		formData.append('customer_address2', loan.customer_address2 || '');
		formData.append('customer_address3', loan.customer_address3 || null);
		formData.append('customer_pincode', loan.customer_pincode || null);
		formData.append('permanent_address1', loan.permanent_address1 || null);
		formData.append('permanent_address2', loan.permanent_address2 || '');
		formData.append('permanent_address3', loan.permanent_address3 || null);
		formData.append('permanent_pincode', loan.permanent_pincode || null);

		formData.append('kyc_kyc_id', loan.kyc.id || null);
		formData.append('kyc_identifier', loan.customer_identifier || null);
		formData.append('kyc_file', kycFile || null);

		formData.append('document_type', 'PHOTO' || null);
		formData.append('document_file', photoFile || null);

		formData.append('filename', loanFile || null);
		formData.append('pledge_no', loan.pledge_no || null);
		formData.append('scheme_id', loan.scheme.id || null);
		formData.append('sub_scheme_id', loan.sub_scheme.id || null);
		formData.append('total_weight', loan.total_weight || null);
		formData.append('eligible_amount', loan.eligible_amount || null);
		formData.append('loan_amount', loan.loan_amount || null);
		formData.append('rate', loan.rate || null);
		formData.append('branch_id', loan.branchId || null);
		// formData.append('start_date', loan.start_date || moment().utc.format(format));		
		formData.append('service_charge', loan.service_charge || 0);
		const mode = loan.disbursement_mode || 'CASH';
		formData.append('disbursement_mode', mode);
		formData.append('disbursement_ledger_id', mode === 'BANK' && loan.bank ? loan.bank.id : null);
		formData.append('entry_by', loan.entry_by || '');
		formData.append('remarks', loan.remarks || '');
		formData.append('referred_by', loan.referrer ? loan.referrer.referred_by : '');
		formData.append('start_date', loan.start_date ? moment(loan.start_date).utc().format(format) : null);
		formData.append('end_date', loan.end_date ? loan.end_date : null);

		let items = itemRows.map(i => ({
			id: i.id || null,
			item_id: i.item.id,
			qty: i.qty,
			weight: i.weight,
			d_weight: i.d_weight,
			purity: i.purity,
		}));

		formData.append('items', JSON.stringify(items));

		LoanRequestsService.saveLoan(formData, { 'admin-request': state.user && state.user.roles.includes(ROLES.ADMIN) ? true : false })
			.then(data => {
				if (data.success) {
					showSnackBar('Loan Saved', 'info');
					handleClose(false);
					openViewLoan(loan.pledge_no);
				} else if (!data.success && data.cash_limit_error) {
					showConfirmDialog1D(data.message, undefined, () => {
						closeConfirmDialog1D();
					});
				} else {
					showConfirmDialog1D(data.message, undefined, () => {
						closeConfirmDialog1D();
					});
				}
				closeProgressDialog();
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showSnackBar('Failed to Save Loan. Please try again.', 'error');
				closeProgressDialog();
				setLoading(false);
			})
	}

	return (
		<Dialog
			fullScreen
			disableEscapeKeyDown
			open={open}
			onClose={(e, reason) => {
				if (reason === 'escapeKeyDown') {
					handleClose(true)
				}
			}}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{`${id ? '' : 'New '}Loan Request`}
					</Typography>
					{/* <Button autoFocus color="inherit" onClick={handleClose}>
					save
				</Button> */}
				</Toolbar>
			</AppBar>
			<Backdrop className={classes.backdrop} open={loading} onClick={() => { }}>
				<div style={{ padding: 50, backgroundColor: 'white', borderRadius: 8 }}>
					<CircularProgress
						size={40}
						thickness={4} />
				</div>
			</Backdrop>
			<Grid container className={classes.page} justifyContent="center">
				<Grid container item spacing={2} xs={12} lg={12} alignItems="flex-start" justifyContent="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								avatar={
									<Avatar aria-label="step1" className={classes.avatar}>
										1
									</Avatar>
								}
								title="Customer information"
								titleTypographyProps={{ variant: 'h4' }}
							/>
							<Divider />
							<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8 }}>
								<Grid container justifyContent="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justifyContent="flex-start" spacing={2} alignItems="center">
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Customer Name"
													id="outlined-adornment-customer-name"
													value={loan.customer_name || ''}
													required
													error={errorList.customer_name}
													margin="none"
													onChange={handleChange('customer_name')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-name',
														endAdornment: id ? '' : <InputAdornment position="start">
															<BootstrapTooltip arrow title="Click To search for Existing customer" >
																<SearchIcon style={{ cursor: 'pointer' }} onClick={() => searchForCustomer('name')} />
															</BootstrapTooltip>
														</InputAdornment>
													}}
													onKeyUp={(event) => {
														event.preventDefault();
														if (event.key === 'Enter')
															searchForCustomer('name');
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Customer Mobile"
													margin="none"
													id="outlined-adornment-customer-mobile"
													value={loan.customer_mobile || ''}
													error={errorList.customer_mobile}
													required
													disabled={id ? true : false}
													onChange={handleChange('customer_mobile')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-mobile',
														endAdornment: <InputAdornment position="start">
															<BootstrapTooltip arrow title="Click To search for Existing customer" >
																<SearchIcon style={{ cursor: 'pointer' }} onClick={() => searchForCustomer('mobile')} />
															</BootstrapTooltip>
														</InputAdornment>
													}}
													onKeyUp={(event) => {
														event.preventDefault();
														if (event.key === 'Enter')
															searchForCustomer('mobile');
														//if (event.key === 'Tab' && (event.target.value).length > 9){
														//	searchForCustomer();
														//}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											{
												loanHistory.length > 0 && (
													<Button
														variant="contained"
														color="primary"
														onClick={() => { setShowPreviousLoans(true) }}>
														{`View Previous Loans (${loanHistory.length})`}
													</Button>
												)
											}
										</Grid>
										{showForm ? (
											<>
												<Grid item xs={12} lg={3}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Occupation"
															margin="none"
															id="outlined-adornment-customer-occupation"
															value={loan.customer_occupation || ''}
															onChange={handleChange('customer_occupation')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-occupation'
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={3}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Nominee"
															margin="none"
															id="outlined-adornment-customer-nominee"
															value={loan.customer_nominee || ''}
															onChange={handleChange('customer_nominee')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-nominee'
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={3}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Alternate Contact number"
															margin="none"
															id="outlined-adornment-customer-alternate_no"
															value={loan.customer_alternate_no || ''}
															onChange={handleChange('customer_alternate_no')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer_alternate_no',
															}}
															type="number"
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={3}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Email"
															margin="none"
															id="outlined-adornment-customer-email"
															value={loan.customer_email || ''}
															onChange={handleChange('customer_email')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-email'
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={12}>
													<Divider />
												</Grid>
												<Grid item xs={12} lg={12}>
													<Typography variant="h6" className={classes.subtitle}>Current Address</Typography>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Address Line 1"
															margin="none"
															id="outlined-adornment-customer-address1"
															value={loan.customer_address1 || ''}
															required
															error={errorList.customer_address1}
															onChange={handleChange('customer_address1')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-address1',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Address Line 2"
															margin="none"
															id="outlined-adornment-customer-address2"
															value={loan.customer_address2 || ''}
															onChange={handleChange('customer_address2')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-address2',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="City / State / PO"
															margin="none"
															id="outlined-adornment-customer-address3"
															value={loan.customer_address3 || ''}
															required
															error={errorList.customer_address3}
															onChange={handleChange('customer_address3')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-address3',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={2}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Pincode"
															margin="none"
															required
															error={errorList.customer_pincode}
															id="outlined-adornment-customer-pincode"
															value={loan.customer_pincode || ''}
															onChange={handleChange('customer_pincode')}
															aria-describedby="outlined-weight-helper-text"
															inputProps={{
																'aria-label': 'customer-pincode',
																maxLength: 6
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid container item xs={12} lg={12} alignItems="center">
													<Grid item>
														<Typography variant="h6" className={classes.subtitle}>Permanent Address</Typography>
													</Grid>
													<Grid item>
														<FormControlLabel
															control={
																<Checkbox
																	checked={loan.sameAsCurrent}
																	onChange={handleCheckChange('sameAsCurrent')}
																	name="checkedB"
																	color="primary"
																/>
															}
															style={{ marginLeft: 8 }}
															label="Same as Current Address"
														/>
													</Grid>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Address Line 1"
															margin="none"
															id="outlined-adornment-permanent-address1"
															value={loan.permanent_address1 || ''}
															required
															error={errorList.permanent_address1}
															onChange={handleChange('permanent_address1')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'permanent-address1',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Address Line 2"
															margin="none"
															id="outlined-adornment-permanent-address2"
															value={loan.permanent_address2 || ''}
															onChange={handleChange('permanent_address2')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'permanent-address2',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={6}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="City / State / PO"
															margin="none"
															id="outlined-adornment-permanent_address3"
															value={loan.permanent_address3 || ''}
															required
															error={errorList.permanent_address3}
															onChange={handleChange('permanent_address3')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'permanent-address3',
																maxLength: 100
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={2}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Pincode"
															margin="none"
															required
															error={errorList.permanent_pincode}
															id="outlined-adornment-permanent_pincode"
															value={loan.permanent_pincode || ''}
															onChange={handleChange('permanent_pincode')}
															aria-describedby="outlined-weight-helper-text"
															inputProps={{
																'aria-label': 'permanent-pincode',
																maxLength: 6
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={12}>
													<Divider />
												</Grid>
												<Grid item xs={12} lg={4}>
													<ItemSelection
														required
														size="medium"
														label='KYC Type'
														value={loan.kyc || null}
														optionLabel='name'
														error={errorList.kyc}
														options={kycDocs}
														style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
														formClass={classes.formAutoClass}
														selected={(kyc) => {
															setLoan(loan => ({ ...loan, kyc }));
														}} />
												</Grid>
												<Grid item xs={12} lg={4}>
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="Identifier"
															margin="none"
															id="outlined-adornment-customer-identifier"
															value={loan.customer_identifier || ''}
															required
															error={errorList.customer_identifier}
															onChange={handleChange('customer_identifier')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-identifier'
															}}
															variant="outlined"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={4}>
													<FormControl variant="outlined" fullWidth>
														<ItemSelectionFreeSolo
															freeSolo
															clearable
															size="medium"
															label='Referred By'
															value={loan.referrer || null}
															optionLabel='referred_by'
															options={referrerList}
															style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
															formClass={classes.formAutoClass}
															selected={(referrer) => {
																setLoan(loan => ({ ...loan, referrer: referrer }));
															}} />
													</FormControl>
												</Grid>
											</>
										) : null}
									</Grid>
									{showForm ? (
										<Grid container item xs={12} lg={12}>
											<Grid item xs={12} lg={4}>
												{
													selectedCustomer && selectedCustomer.documents.length > 0 ?
														(<ImageHolder
															title="Change Photo"
															link={selectedCustomer.documents[0].link}
															change={(file) => {
																changePhoto('photo_file', file);
															}}
														/>)
														:
														(<ImagePlaceHolder
															error={errorList.document_file}
															title="Add Customer Photo"
															link={null}
															change={(file) => {
																changePhoto('photo_file', file);
															}}
														/>)
												}
											</Grid>
											<Grid item xs={12} lg={4}>
												{
													selectedCustomer && selectedCustomer.kyc.length > 0 ?
														(<ImageHolder
															title="Change KYC"
															link={selectedCustomer.kyc[0].link}
															change={(file) => {
																changePhoto('kyc_file', file);
															}}
														/>)
														:
														(<ImagePlaceHolder
															error={errorList.kyc_file}
															title="Add KYC"
															link={null}
															change={(file) => {
																changePhoto('kyc_file', file);
															}}
														/>)
												}
											</Grid>
											<Grid item xs={12} lg={4}>
												{
													loan.link ?
														(<ImageHolder
															title="Change Asset Image"
															link={loan.link}
															change={(file) => {
																changePhoto('loan_file', file);
															}}
														/>)
														:
														(<ImagePlaceHolder
															error={errorList.loan_file}
															title="Add Asset Image"
															link={null}
															change={(file) => {
																changePhoto('loan_file', file);
															}}
														/>)
												}
											</Grid>
										</Grid>
									) : null}
								</Grid>
							</div>
						</Paper>
					</Grid>
					{showForm ? (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									avatar={
										<Avatar aria-label="step1" className={classes.avatar}>
											2
										</Avatar>
									}
									title="Item information"
									titleTypographyProps={{ variant: 'h4' }}
									action={
										<IconButton disabled={disableEdit ? true : false} aria-label="add-row" onClick={addRow}>
											<AddCircleIcon />
										</IconButton>
									}
								/>
								<Divider />
								<Table>
									<EnhancedTableHead
										order={""}
										orderBy={""}
										onRequestSort={() => { }}
										rows={columns} />
									<TableBody>
										{itemRows.map((row, i) =>
											<TableRow key={i} hover>
												<StyledTableCell scope="row" align="justify" padding='none'>
													<div style={{ marginBottom: 22 }}>{i + 1}</div>
												</StyledTableCell>
												<StyledTableCell scope="row" align="justify" padding='none' style={{ minWidth: '20vw', marginBottm: 8 }}>
													<ItemSelection
														//clearable={true}
														disabled={disableEdit ? true : false}
														required
														style={{ zIndex: 500, marginTop: 4, marginBottom: 22, marginLeft: 0 }}
														label='Item'
														options={items}
														error={errorItemRow.find(i => i.rowid === row.rowid).item || false}
														value={row.item || null}
														optionLabel='name'
														fullWidth
														selected={handleRowItemChange(row.rowid)} />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none'>
													<TextField
														id="outlined-basic-qty"
														//label="Quantity" 
														disabled={disableEdit ? true : false}
														name="qty"
														variant="outlined"
														margin="dense"
														error={errorItemRow.find(i => i.rowid === row.rowid).qty || false}
														value={row.qty ? row.qty : ''}
														inputProps={{
															//startAdornment: <InputAdornment position="start">Nos</InputAdornment>,
															min: 0
														}}
														required
														style={{ marginTop: 8 }}
														type="number"
														helperText="Nos"
														onChange={handleRowChange(row.rowid)} />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none'>
													<TextField
														id="outlined-basic-wt"
														//label="Weight" 
														name="weight"
														disabled={disableEdit ? true : false}
														error={errorItemRow.find(i => i.rowid === row.rowid).weight || false}
														variant="outlined"
														margin="dense"
														required
														value={row.weight ? row.weight : ''}
														inputProps={{
															//startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
															min: 0
														}}
														style={{ marginTop: 8 }}
														type="number"
														helperText="Gram"
														onChange={handleRowChange(row.rowid)} />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none'>
													<TextField
														id="outlined-basic-dwt"
														//label="Deducted Weight" 
														name="d_weight"
														disabled={disableEdit ? true : false}
														variant="outlined"
														margin="dense"
														error={errorItemRow.find(i => i.rowid === row.rowid).d_weight || false}
														value={row.d_weight ? row.d_weight : 0}
														inputProps={{
															//startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
															min: 0
														}}
														required
														style={{ marginTop: 8 }}
														type="number"
														helperText="Gram"
														onChange={handleRowChange(row.rowid)} />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none'>
													<TextField
														id="outlined-basic-dwt"
														disabled={true}
														name="net_weight"
														variant="outlined"
														margin="dense"
														value={row.weight ? precision(row.weight - (row.d_weight || 0)) : ''}
														inputProps={{
															//startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
															min: 0
														}}
														required
														style={{ marginTop: 8 }}
														type="number"
														helperText="Gram" />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none'>
													<TextField
														id="outlined-basic-purity"
														name="purity"
														variant="outlined"
														margin="dense"
														disabled={disableEdit ? true : false}
														value={row.purity ? row.purity : ''}
														inputProps={{
															//startAdornment: <InputAdornment position="start">%</InputAdornment>,
															min: 0
														}}
														style={{ marginTop: 8, marginBottom: 26 }}
														type="number"
														helperText=""
														onChange={handleRowChange(row.rowid)} />
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='normal'>
													<Button disabled={disableEdit ? true : false}>
														<Icon style={{ marginTop: 8, marginBottom: 22 }} onClick={handleRowRemove(row.rowid)}>delete</Icon>
													</Button>
												</StyledTableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Paper>
						</Grid>
					) : null}
					{showForm ? (

						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									avatar={
										<Avatar aria-label="step1" className={classes.avatar}>
											3
										</Avatar>
									}
									title="Loan information"
									titleTypographyProps={{ variant: 'h4' }}
									action={
										<div style={{ maxWidth: 170 }}>
											<SingleDate
												disabled={disableEdit ? true : false}
												label="Loan Date"
												date={loan.start_date || moment()}
												maxDate={moment()}
												setDate={(date) => {
													setLoan(loan => ({ ...loan, start_date: date }));
												}} />
										</div>
									}
								/>
								<Divider />
								<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
									<Grid container justifyContent="flex-start" spacing={2} alignItems="center">
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Loan No"
													id="outlined-adornment-pledge-no"
													value={loan.pledge_no || ''}
													required
													disabled={true}
													margin="none"
													onChange={handleChange('pledge_no')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'pledge-no',
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<ItemSelection
												required
												size="medium"
												label='Scheme'
												disabled={disableEdit ? true : false}
												value={loan.scheme || null}
												error={errorList.scheme}
												optionLabel='name'
												options={schemes.filter(s => s.standard === 1)}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(scheme) => {
													setLoan(loan => ({ ...loan, scheme }));
												}} />
										</Grid>
										<Grid item xs={12} lg={2}>
											<ItemSelection
												required
												size="medium"
												label='Sub Scheme'
												disabled={disableEdit ? true : false}
												value={loan.sub_scheme || null}
												error={errorList.sub_scheme}
												optionLabel='name'
												options={schemes}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(scheme) => {
													setLoan(loan => ({ ...loan, sub_scheme: scheme, rate: scheme.amount, orgrate: scheme.amount }));
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<Typography align="right">Total Gross Wt: <b>{precision(itemRows.reduce((sum, next) => sum + parseFloat(next.weight || 0), 0) || 0)} gms</b></Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											<Typography align="right">Total Net Wt: <b>{precision(itemRows.reduce((sum, next) => sum + (((next.weight || 0) - (next.d_weight || 0))), 0) || 0)} gms</b></Typography>
										</Grid>
										<Grid item xs={8} lg={8}>
											<Typography variant="subtitle2" align="right">Rate<sup>*</sup></Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-i-rate"
													name="rate"
													variant="outlined"
													disabled={disableEdit ? true : false}
													value={loan.rate ? precision(loan.rate) : 0}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
														style: { textAlign: 'right' }
													}}
													error={errorList.rate}
													type="number"
													onChange={handleChange('rate')} />
											</FormControl>
										</Grid>
										<Grid item xs={8} lg={8}>
											<Typography variant="subtitle2" align="right">Eligible Amount</Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-i-eligible-amount"
													name="eligible_amount"
													variant="outlined"
													disabled={true}
													value={loan.eligible_amount ? precision(loan.eligible_amount) : ''}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
														style: { textAlign: 'right' }
													}}
													type="number"
													onChange={handleChange('eligible_amount')} />
											</FormControl>
										</Grid>
										<Grid item xs={8} lg={8}>
											<Typography variant="subtitle2" align="right">Loan Amount</Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-i-loan-amount"
													name="loan_amount"
													variant="outlined"
													required
													disabled={disableEdit ? true : false}
													error={errorList.loan_amount}
													value={loan.loan_amount ? precision(loan.loan_amount) : ''}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
														style: { textAlign: 'right' }
													}}
													type="number"
													onChange={handleChange('loan_amount')} />
											</FormControl>
										</Grid>
										<Grid item xs={8} lg={8}>
											<Typography variant="subtitle2" align="right">Service Charge</Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-i-service-charge"
													name="service_charge"
													variant="outlined"
													disabled={disableEdit ? true : false}
													value={loan.service_charge ? loan.service_charge : ''}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
														style: { textAlign: 'right' }
													}}
													type="number"
													onChange={handleChange('service_charge')} />
											</FormControl>
										</Grid>
										<Grid item xs={8} lg={8}>
											<Typography variant="subtitle2" align="right">Disbursed As</Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<ItemSelection
												required
												size="medium"
												label=''
												disabled={disableEdit ? true : (!state.user || !state.user.roles.includes(ROLES.ADMIN))}
												value={loan.mode || null}
												optionLabel='name'
												options={disbursementModes}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(mode) => {
													if (mode.value === 'BANK' && !loan.bank && bankOptions.length === 1) {
														setLoan(loan => ({ ...loan, mode, disbursement_mode: mode.value, bank: bankOptions[0] }));
													} else {
														setLoan(loan => ({ ...loan, mode, disbursement_mode: mode.value }));
													}
												}} />
										</Grid>
										{
											loan.disbursement_mode === 'BANK' && (
												<Grid item xs={8} lg={8}>
													<Typography variant="subtitle2" align="right">Bank</Typography>
												</Grid>
											)
										}
										{
											loan.disbursement_mode === 'BANK' && (
												<Grid item xs={12} lg={4}>
													<ItemSelection
														required
														size="medium"
														label=''
														disabled={disableEdit ? true : (!state.user || !state.user.roles.includes(ROLES.ADMIN) || bankOptions.length === 1)}
														value={loan.bank || (bankOptions.length > 0 ? bankOptions[0] : null)}
														optionLabel='name'
														options={bankOptions}
														style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
														formClass={classes.formAutoClass}
														selected={(bank) => {
															setLoan(loan => ({ ...loan, bank }));
														}} />
												</Grid>
											)
										}
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-entry-by"
													name="entry_by"
													variant="outlined"
													required
													error={errorList.entry_by}
													label="Attended By"
													value={loan.entry_by ? loan.entry_by : ''}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
													}}
													type="text"
													onChange={handleChange('entry_by')} />
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={8}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													id="outlined-basic-remarks"
													name="remarks"
													variant="outlined"
													label="Remarks"
													value={loan.remarks ? loan.remarks : ''}
													inputProps={{
														//startAdornment: <InputAdornment position="start">%</InputAdornment>,
														min: 0,
														maxLength: 100,
													}}
													type="text"
													onChange={handleChange('remarks')} />
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider style={{ marginTop: 8, marginBottom: 8 }} />
										</Grid>
										<Grid item xs={12} lg={12}>
											<div style={{ float: 'right' }}>
												<Button variant="contained" color="primary" onClick={savePledge}>Save Pledge</Button>
											</div>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>
					) : null}
				</Grid>
			</Grid>
			<MyDialog
				title="Previous Loans"
				maxWidth={"md"}
				content={
					<div>
						<Grid container spacing={2}>
							{
								!historyLoading &&
								loanHistory.map((hist, i) => (
									<Grid key={i} item xs={12} lg={6}>
										<Card className={classes.card}>
											<CardHeader
												title={hist.pledge_no}
												subheader={`${getTimeInIST(hist.start_date).format(format_display_fancy_notime_withDay)} - ${hist.end_date ? getTimeInIST(hist.end_date).format(format_display_fancy_notime_withDay) : 'ONGOING'}`}
												subheaderTypographyProps={{ variant: 'caption' }}
											/>
											<CardContent>
												<Grid container justifyContent="space-between">
													<Grid item xs={12} lg={6}>
														Entity
													</Grid>
													<Grid item xs={12} lg={6}>
														{hist.branch_name || '-'}
													</Grid>
													<Grid item xs={12} lg={6}>
														Total Weight
													</Grid>
													<Grid item xs={12} lg={6}>
														{hist.total_weight} gm
													</Grid>
													<Grid item xs={12} lg={6}>
														Rate
													</Grid>
													<Grid item xs={12} lg={6}>
														{formatNumber(hist.rate)}
													</Grid>
													<Grid item xs={12} lg={6}>
														Loan Amount
													</Grid>
													<Grid item xs={12} lg={6}>
														{formatNumber(hist.loan_amount)}
													</Grid>
													<Grid item xs={12} lg={6}>
														Disbursement Mode
													</Grid>
													<Grid item xs={12} lg={6}>
														{hist.disbursement_mode}
													</Grid>
													<Grid item xs={12} lg={6}>
														Referred By
													</Grid>
													<Grid item xs={12} lg={6}>
														{hist.referred_by || '-'}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								))
							}
							{historyLoading &&
								<Grid item xs={12} lg={12}>
									<div style={{ height: 200 }}>
										<CircularProgress style={{ position: 'relative', top: '30%', left: '45%' }} />
									</div>
								</Grid>
							}
						</Grid>
					</div>}
				open={showPreviousLoans}
				close={() => setShowPreviousLoans(false)} />
			<CustomerListDialog
				list={customerList}
				labelTemplate={option => `${option.name} (${option.mobile})`}
				selectedValue={selectedCustomer}
				open={openCustomerList}
				showAdd={true}
				onClose={handleCustomerListClose} />
		</Dialog>
	);
}

function ImagePlaceHolder(props) {
	let classes = useStyles();
	const { error } = props;
	const [link, setLink] = React.useState(linkPreventCaching(props.link));

	const hiddenFileInput = React.useRef(null);

	const handleClick = () => {
		hiddenFileInput.current.click();
	}

	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			{
				link && (
					<img
						src={link}
						height="200" width="200"
						alt="" />
				)
			}
			<span className={classes.imageBackdrop} style={{ backgroundColor: error || false ? 'red' : '' }} />
			<span className={classes.imageButton} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><AddCircleIcon fontSize="large" /></p>
					{props.title}
					<span className={classes.imageMarked} />
					<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} style={{ display: 'none' }} />
				</Typography>
			</span>
		</ButtonBase>
	)
}

function ImageHolder(props) {
	const hiddenFileInput = React.useRef(null);
	const [link, setLink] = React.useState(linkPreventCaching(props.link));

	const handleClick = () => {
		hiddenFileInput.current.click();
	}

	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img
				src={link}
				height="200" width="200"
				alt="" />
			<span className={classes.imageBackdrop} />
			<span className={classes.imageButtonBottom} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><EditIcon fontSize="small" /></p>
					{props.title}
					<span className={classes.imageMarked} />
				</Typography>
				<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} style={{ display: 'none' }} />
			</span>
		</ButtonBase>
	)
}

CreateLoanRequest.prototype = {
	open: PropTypes.bool.isRequired,
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired
};