import { Avatar, Button, CardHeader, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react'
import { format_display_fancy_notime, formatNumber, getProjectStatusBadge, getTimeInIST, precision } from '../Utils';
import { ROLES } from '../Roles';
import { AppContext } from '../AppContextProvider';
import { STATUS } from '../Constants';
import { red } from '@material-ui/core/colors';
import SingleDate from '../components/SingleDate';

const LoanDetails = ({
    classes,
    loan,
    postingDate,
    setPostingDate,
    print,
    showCancelConfirm,
    viewInterest,
}) => {
    const { state } = useContext(AppContext)
    return (
        <Grid item xs={12} lg={12}>
            <Paper variant="outlined" className={classes.section} elevation={3}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="step1" className={classes.avatar}>
                            3
                        </Avatar>
                    }
                    title="Loan information"
                    titleTypographyProps={{ variant: 'h4' }}
                />
                <Divider />
                <div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
                    <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Start Date</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.start_date ? getTimeInIST(loan.start_date).format(format_display_fancy_notime) : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>End Date</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.end_date ? getTimeInIST(loan.end_date).format(format_display_fancy_notime) : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Days Lapsed:</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.days >= 0 ? `${loan.days} day${loan.days > 1 ? 's' : ''}` : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Status:</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{getProjectStatusBadge(loan.status)}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Scheme</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.scheme.name}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Total Net Wt</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{precision(loan.items.reduce((sum, next) => sum + parseFloat((next.weight - (next.d_weight || 0)) || 0), 0))} gms</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Sub Scheme</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.sub_scheme.name}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Deducted Wt</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{precision(loan.items.reduce((sum, next) => sum + parseFloat(next.d_weight || 0) || 0, 0))} gms</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Rate</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{formatNumber(loan.rate)}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Eligible Amount</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{formatNumber(loan.eligible_amount)}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Loan Amount</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{formatNumber(loan.loan_amount)}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Service Charge</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{formatNumber(loan.service_charge)}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Disbursed As</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.disbursement_mode || '-'}</Typography>
                        </Grid>
                        {loan.disbursement_ledger ? (
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Ledger</Typography>
                            </Grid>
                        ) : null}
                        {loan.disbursement_ledger ? (
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{loan.disbursement_ledger.name || '-'}</Typography>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Attended By</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.entry_by || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Remarks</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{loan.remarks || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        </Grid>
                        <Grid container item xs={12} lg={12} justifyContent={'flex-end'}>
                            <Grid item xs={12} lg={2}>
                                {(state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) ? (
                                    <SingleDate
                                        label="Posting Date"
                                        date={postingDate}
                                        classes={classes.formControl}
                                        setDate={(date) => {
                                            setPostingDate(date)
                                        }} />
                                ) : null}
                            </Grid>
                            <Grid item>
                                <div style={{ float: 'right' }}>
                                    <Button variant="outlined" color="primary" onClick={() => viewInterest()} style={{ marginLeft: 8 }}>View Interest</Button>
                                    {loan.status === STATUS.ACTIVE ? (
                                        <Button variant="contained" color="primary" onClick={() => print()} style={{ marginLeft: 8 }}>print</Button>
                                    ) : null}
                                    {loan.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN) ? (
                                        <Button
                                            variant="contained"
                                            style={{ marginLeft: 8, backgroundColor: red[500], color: 'white' }}
                                            onClick={() => showCancelConfirm()}>
                                            Cancel
                                        </Button>
                                    ) : null}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Grid>
    )
}

export default LoanDetails