import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, CardHeader, Grid, Table, TableBody, TableRow } from '@material-ui/core';
import { format_display_withtime, getTimeInIST, precision, StyledTableCell } from '../Utils';
import { LoanRequestsService } from '../Services/LoanRequestsService';
import EnhancedTableHead from '../components/EnhancedTableHead';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left"
  },
  dialogContent: {
    paddingTop: 0
  },
  card: {
    margin: 8
  },
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ItemHistoryDialog(props) {
  const { loanId } = props
  const [records, setRecords] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const classes = useStyles()

  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open)
      setTitle(props.title)
      setLoading(true)
      LoanRequestsService.getItemsHistory(loanId)
        .then(data => {
          setRecords(data && data.items_history ? data.items_history : []);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      setOpen(props.open)
    }
  }, [props.open]);

  const handleClose = () => {
    props.close()
    setTitle(null)
    setOpen(false)
  }

  return (
    <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {records ? records.map((record, index) => <Record record={record} key={index} />) : null}
        </Grid>
        {loading ? <Typography align="center">Loading...</Typography> : null}
        {!loading && records.length === 0 ? <Typography align="center">No History found</Typography> : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ItemHistoryDialog.prototype = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  loanId: PropTypes.string,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool
}

const Record = ({ record }) => {
  const classes = useStyles()

  const columns = [
    { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
    { id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Item', sortable: false },
    { id: 'qty', align: 'center', numeric: false, disablePadding: false, label: 'Qty', sortable: false },
    { id: 'weight', align: 'center', numeric: false, disablePadding: false, label: 'Weight', sortable: false },
    { id: 'd_weight', align: 'center', numeric: false, disablePadding: false, label: 'Deducted Weight', sortable: false },
    { id: 'net_weight', align: 'center', numeric: false, disablePadding: false, label: 'Net Weight', sortable: false },
    { id: 'purity', align: 'center', numeric: false, disablePadding: false, label: 'Purity', sortable: false },
  ]

  return (
    <Grid item xs={12}>
      <Card className={classes.card} elevation={8}>
        <CardHeader
          avatar={
            <Avatar aria-label="step1" className={classes.avatar}>
              {record.removal_order}
            </Avatar>
          }
          title={`Removed on ${getTimeInIST(record.created_date).format(format_display_withtime)}`}
        />
        <CardContent style={{ padding: 0, paddingBottom: 24 }}>
          <Table>
            <EnhancedTableHead
              order={""}
              orderBy={""}
              onRequestSort={() => { }}
              rows={columns} />
            <TableBody>
              {record.items.map((row, i) => (
                <TableRow key={i} hover>
                  <StyledTableCell scope="row" align="justify" padding='none'>
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none' style={{ minWidth: '20vw', marginBottm: 8 }}>
                    {row.item_name}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none'>
                    {precision(row.qty)}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none'>
                    {precision(row.weight)}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none'>
                    {precision(row.d_weight)}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none'>
                    {precision(row.weight - (row.d_weight || 0))}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding='none'>
                    {row.purity}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}