import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import DialogMUI from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AppContext} from '../AppContextProvider';

//Services
import { OrganisationService } from "../Services/OrganisationService";
import { FormHelperText, Switch } from "@material-ui/core";


const styles = theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'justify',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom : '20px'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  }, 
  button: {
    margin: theme.spacing(1),
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(1)}px`,
  },
  heading: {
    color: 'black'
  }
});

class ManageOrganisationInformation extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            modal: { id: '', name: '', gst_no: '', pan_no: '', address: '', phone1: '', phone2: '', whatsappno: '', email: '', bank_details : '', invoice_prefix: '', invoice_no: '', credit_note_no: '', no_of_days: 1, no_of_requests: 1, max_requests_msg: '', cancel_msg: '', unserviceable_msg: ''},
            organisation: {},
            show: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        
    }

    componentDidMount(){
        const {setTitle} = this.context;
        setTitle("Manage Organisation Information");
        this.fetchData();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            modal: { id: '', name: '', gst_no: '', pan_no: '', address: '', phone1: '', phone2: '', whatsappno: '', email: '', bank_details : '', invoice_prefix: '', invoice_no: '', credit_note_no: '', no_of_days: 1, no_of_requests: 1, max_requests_msg: '', cancel_msg: '', unserviceable_msg: ''}
        });
    }

    handleShow() {
        let org = this.state.organisation;
        this.setState({ show: true , modal: { id: org.id, name: org.name, gst_no: org.gst_no, pan_no: org.pan_no, address: org.address, phone1: org.phone1, phone2: org.phone2, whatsappno: org.whatsappno, email: org.email, bank_details : org.bank_details, invoice_prefix: org.invoice_prefix, invoice_no: org.invoice_no, credit_note_no: org.credit_note_no, no_of_days: org.no_of_days, no_of_requests: org.no_of_requests, max_requests_msg: org.max_requests_msg, cancel_msg: org.cancel_msg, unserviceable_msg: org.unserviceable_msg }});
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.modal;
        change[e.target.name] = e.target.value;
        this.setState({modal: change});
    }

    fetchData(){
        const {isLoading, showProgressDialog, closeProgressDialog} = this.context;
        isLoading(true);
        showProgressDialog();
        OrganisationService.getOrganisation()
        .then((data) => {
            this.setState({organisation: data ? data : {}});
            localStorage.setItem('geomoney.organisation', JSON.stringify(data));
            isLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            isLoading(false);
            closeProgressDialog();
        });
    }

    updateDetails(e){
        const {isLoading, showSnackBar} = this.context;
        e.preventDefault();
        OrganisationService.updateOrganisation({}, this.state.modal)
        .then((data) => {
            isLoading(false);
            this.handleClose();
            this.fetchData();
            showSnackBar('Updated organisation information', 'success');
        })
        .catch(() => {
            showSnackBar(`Something went wrong. Please try again later.`, 'error');
            isLoading(false);
        });

    }

    handleChangeEnabled = e => {
        const {showConfirmDialog, closeConfirmDialog, showSnackBar, showProgressDialog, closeProgressDialog} = this.context;
        let enabled = e.target.checked;
        showConfirmDialog("Confirm changes?", undefined, () => {
            closeConfirmDialog()
            showProgressDialog()
            OrganisationService.enableCustomerNotification(this.state.organisation.id, enabled)
            .then(() => {
                closeProgressDialog();
                this.setState(prevState => ({
                    organisation: {...prevState.organisation, enable_customer_sms_notification: enabled ? '1' : '0'}
                }))
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
                showSnackBar(`Something went wrong. Please try again later.`, 'error');
            })
        }, "Cancel", "Yes");
    
    }

    render() {
        const {state: s} = this.context;
        let org = this.state.organisation;
        let classes = this.props.classes;
        return ( !s.loading &&
             <div className="Home">
                <div className="lander">
                    <div>
                    <br/>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={10}>
                            <Paper className={classes.paper}>
                                <div style={{float: "right"}}>
                                    <Button variant="contained" size="small" color="primary" className={classes.button}  onClick={this.handleShow}>
                                        Edit
                                        {/* <Glyphicon style={{marginRight: "10px",}} glyph="pencil" title="Edit"/>Edit */}
                                        <EditIcon className={classes.leftIcon} />
                                    </Button>
                                </div>
                                <br/>
                                <br/>
                                <Grid container className={classes.root} spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography  variant="h5" component="h2" className={classes.heading}>
                                            Organisation
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    License
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.gst_no ? org.gst_no : "-"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    PAN No
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.pan_no ? org.pan_no : "-"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant="inset"/>
                                    </Grid>
                                    {/* Contact Info */}
                                    <Grid item xs={12}>
                                        <Typography  variant="h5" component="h2" className={classes.heading}>
                                            Contact information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Address
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle2">
                                                    {org.address && org.address.split('\n').map((i,index) => {
                                                        return <span key={index}>
                                                                    {i}
                                                                    <br/>
                                                                </span>
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Contact Nos
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {`${org.phone1} ${org.phone2 ? `, ${org.phone2}` : ''}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Whatsapp No
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.whatsappno}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Email
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant="inset"/>
                                    </Grid>
                                    {/* Bank Info */}
                                    {/* <Grid item xs={12}>
                                        <Typography  variant="h5" component="h2">
                                            Bank Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1">
                                                    Bank Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <span style={{float:'left', textAlign: 'justify'}}>
                                                    {org.bank_details && org.bank_details.split('\n').map((i,index) => {
                                                        return <span key={index}>
                                                                    {i}
                                                                    <br/>
                                                                </span>
                                                    })}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant="inset"/>
                                    </Grid> */}
                                    {/* Invoice Details */}
                                    <Grid item xs={12}>
                                        <Typography  variant="h5" component="h2" className={classes.heading}>
                                            Configuration
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    How many days ahead can the customer make booking?
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.no_of_days}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Max number of concurrent requests
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2">
                                                    {org.no_of_requests}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Max number of Requests reached message for customer
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2" style={ org.max_requests_msg ? {} : {color: 'red'}}>
                                                    {org.max_requests_msg ? org.max_requests_msg : "Not defined"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Cancel Request Message
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2" style={ org.cancel_msg ? {} : {color: 'red'}}>
                                                    {org.cancel_msg ? org.cancel_msg : "Not defined"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1">
                                                    Unserviceable Message
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle2" style={ org.unserviceable_msg ? {} : {color: 'red'}}>
                                                    {org.unserviceable_msg ? org.unserviceable_msg : "Not defined"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-start" justifyContent={"space-evenly"}>
                                    <Grid container item xs={12} lg={12}>
                                        <Grid item xs={12} lg={4} data-tour="jobs">
                                            <Typography  align="left" className={classes.header} variant="subtitle2">Enable Customer SMS Notifications</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={3} data-tour="jobsenable">
                                            <Typography component="div" className={classes.subtitle}>
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item>Off</Grid>
                                                    <Grid item>
                                                        <Switch
                                                            checked={parseInt(org.enable_customer_sms_notification) === 1}
                                                            onChange={this.handleChangeEnabled}
                                                            color="primary"
                                                            name="job_status"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </Grid>
                                                    <Grid item>On</Grid>
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <DialogMUI
                        disableBackdropClick
                        open={this.state.show}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll='paper'>
                            <DialogTitle id="alert-dialog-title">Organisation Details</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" component={'span'}>
                                    <Form onSubmit={this.updateDetails}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            placeholder="Name"
                                            name="name"
                                            defaultValue={this.state.modal.name}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="gst_no"
                                            label="License No"
                                            placeholder="License No"
                                            name="gst_no"
                                            defaultValue={this.state.modal.gst_no}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="pan_no"
                                            label="PAN No"
                                            placeholder="PAN No"
                                            name="pan_no"
                                            defaultValue={this.state.modal.pan_no}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="address"
                                            label="Address"
                                            placeholder="Address"
                                            name="address"
                                            defaultValue={this.state.modal.address}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax="4" />
                                        <TextField
                                            id="phone1"
                                            label="Contact No 1"
                                            placeholder="Contact No 1"
                                            name="phone1"
                                            defaultValue={this.state.modal.phone1}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="phone2"
                                            label="Contact No 2"
                                            placeholder="Contact No 2"
                                            name="phone2"
                                            defaultValue={this.state.modal.phone2}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            fullWidth />
                                        <TextField
                                            id="whatsappno"
                                            label="Whatsapp No"
                                            placeholder="Whatsapp No"
                                            name="whatsappno"
                                            defaultValue={this.state.modal.whatsappno}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="email"
                                            label="Email"
                                            placeholder="Email"
                                            name="email"
                                            defaultValue={this.state.modal.email}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth />
                                        {/* <TextField
                                            id="bank_details"
                                            label="Bank Details"
                                            placeholder="Bank Details"
                                            name="bank_details"
                                            defaultValue={this.state.modal.bank_details}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax="4" /> */}
                                        {/* <TextField
                                            id="invoice_prefix"
                                            label="Invoice Prefix"
                                            placeholder="Invoice Prefix"
                                            name="invoice_prefix"
                                            defaultValue={this.state.modal.invoice_prefix}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth /> */}
                                        <TextField
                                            id="no_of_days"
                                            label="How many days ahead can the customer make booking?"
                                            placeholder="How many days ahead can the customer make booking?"
                                            name="no_of_days"
                                            defaultValue={this.state.modal.no_of_days}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            pattern="[0-9]*"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="no_of_requests"
                                            label="Max number of concurrent requests"
                                            placeholder="Max number of concurrent requests"
                                            name="no_of_requests"
                                            defaultValue={this.state.modal.no_of_requests}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            pattern="[0-9]*"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            required
                                            fullWidth />
                                        <TextField
                                            id="max_requests_msg"
                                            label="Max number of Requests reached message for customer"
                                            placeholder="Max number of Requests reached message for customer"
                                            name="max_requests_msg"
                                            defaultValue={this.state.modal.max_requests_msg}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax="3" />
                                        <TextField
                                            id="cancel_msg"
                                            label="Cancel Message"
                                            placeholder="Cancel Message"
                                            name="cancel_msg"
                                            defaultValue={this.state.modal.cancel_msg}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            disabled
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax="3" />
                                        <FormHelperText>{`Eg: Request {request_id} has been cancelled. (Please do not remove the request_id placeholder.)`}</FormHelperText>
                                        <TextField
                                            id="unserviceable_msg"
                                            label="Unserviceable Message"
                                            placeholder="Unserviceable Message"
                                            name="unserviceable_msg"
                                            defaultValue={this.state.modal.unserviceable_msg}
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            disabled
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax="3" />
                                        <FormHelperText>{`Eg: Unable to service the requested area, Cancelling the request {request_id}. (Please do not remove the request_id placeholder.)`}</FormHelperText>
                                    </Form>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={this.updateDetails} color="primary" autoFocus>
                                    Confirm
                                </Button>
                            </DialogActions>
                    </DialogMUI>
                </div>
            </div>
        </div>
        );
    }
}

export default withStyles(styles)(ManageOrganisationInformation);