import React, { useContext } from "react";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import { Divider, Grid, Hidden, makeStyles } from "@material-ui/core";
import {  FormControl, Button, InputLabel, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import clsx from 'clsx';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import { messaging, requestToken, messaginIsSupported } from "../init-fcm";

const useStyles = makeStyles(theme =>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
}));

export default function Login(props){

  const classes = useStyles();
  const history = useHistory();
  const {setTitle, showSnackBar, setAuthenticating, setLinearLoading, updateUser, userHasAuthenticated} = useContext(AppContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  React.useEffect(() => {
    setTitle('');
    const user = localStorage.getItem('geomoney.user') !== null ? JSON.stringify(localStorage.getItem('geomoney.user')) : null;
    const session = localStorage.getItem('geomoney.session');
    if(user !== null && session !== null) {
      history.push("/");
    } else {
      setAuthenticating(false);
    }
  }, [true])

  // const validateForm = () => {
  //   return email.length > 5 && password.length > 3;
  // }

  const handleChange = event => {
    if(event.target.name === 'password'){
      setPassword(event.target.value)
    } else if(event.target.name === 'email'){
      setEmail(event.target.value)
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
      try {
        setLinearLoading(true);
        UserService.authenticate({email, password})
        .then((data) => {
            if (data.user.id && parseInt(data.user.status) === 1 && data.user.roles.length > 0 && data.user.branches.length > 0) {
                updateUser(data.user);
                // console.log("Logged in");
                // console.log(data);
                localStorage.setItem('geomoney.user', JSON.stringify(data.user));
                localStorage.setItem('geomoney.session', data['sysbrew-x-token']);
                userHasAuthenticated(true);
                
                if(messaginIsSupported){
                  messaging.requestPermission()
                  .then( () => {
                      console.log("Have Permission");
                      requestToken(data.user);
                  })
                  .catch((err) => {
                    console.log("Unable to get permission to notify.", err);
                  });
                }
                history.push("/");
            } else if(parseInt(data.user.status) === 0 || data.user.roles.length === 0){
              showSnackBar("Your account has been deactivated. Contact Admin" , 'error');
            } else if(data.user.branches.length === 0){
              showSnackBar("There are no Branches Assigned to you. Please contact Contact Admin" , 'error');
            } else if(data.user.type === 'CUSTOMER'){
              showSnackBar("Customer cannot login" , 'error');
            }
            setLinearLoading(false);
        })
        .catch((error) => {
          showSnackBar("Failed to Login. invalid credentials" , 'error');
          setLinearLoading(false);
        });
      } catch (e) {
        alert(e.message);
        setLinearLoading(false);
      }
  }

  const handleClickShowPassword = () => {
    setShowPassword(prevState => (!prevState.showPassword));
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="Login" style={{height: '100vh'}}>
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{height: '50vh'}}>
        <Grid item xs={12} lg={6}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" height={200}/>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} lg={1} style={{height: '50vh'}}>
            <Divider orientation="vertical" variant="middle"/>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <form onSubmit={handleSubmit}>
            <FormControl className={clsx(classes.margin, classes.textField)}  variant="outlined">
              <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="standard-adornment-email"
                type={'email'}
                value={email}
                name="email"
                onChange={handleChange}
                labelWidth={40}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}  variant="outlined">
              <InputLabel htmlFor="standard-adornment-password">Code</InputLabel>
              <OutlinedInput
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                name="password"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Button variant="contained" color="primary" onClick={handleSubmit} size="large">
                Login
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}