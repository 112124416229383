import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from  'clsx';
import { Grid, FormControl, FormLabel, FormGroup, FormHelperText, FormControlLabel, Checkbox, Button, Tooltip, Slider, Card, CardHeader, CardContent, Divider, CardActions, Icon, colors } from "@material-ui/core";
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider, Day } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ToolsService } from "../Services/ToolsService";
import { format_without_time } from "../Utils";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    section: {
        padding: 10
    },
    formControl: {
    },
    customToolTip: {
        fontSize: 16
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.blue[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function Settings(props) {
    const classes = useStyles();
    const { setTitle, showSnackBar, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const [holidays, setHolidays] = React.useState([]);
    // const [workPattern, setWorkPattern] = React.useState([]);
    const [starttime, setStarttime] = React.useState(0);
    const [endtime, setEndtime] = React.useState(23);
    const [date, setDate] = React.useState(moment());

    const [workdays, setWorkDays] = React.useState({
        0 : false,
        1 : false,
        2 : false,
        3 : false,
        4 : false,
        5 : false,
        6 : false
    });

    React.useEffect(() => {
        setTitle("Manage Workdays");
        ToolsService.getWorkPattern()
        .then(data => {
            data.work_pattern.forEach(w => {
                workdays[w.id] = parseInt(w.working) === 1;
            });
            setWorkDays(workdays);
            // setWorkPattern(data.work_pattern);
            setStarttime(data.work_pattern[0].starttime);
            setEndtime(data.work_pattern[0].endtime);
        });
        monthChange(date);
    }, [true]);

    function addHoliday(holiday){
        if(holiday.isSameOrAfter(moment().startOf('d'))){
            if(holidays.includes(holiday.format(format_without_time))){
                showConfirmDialog("Confirm remove Holiday?", undefined, () => {
                    ToolsService.removeHoliday(holiday.format(format_without_time))
                    .then(data => {
                        if(data.success){
                            let index = holidays.indexOf(holiday.format(format_without_time));
                            delete holidays[index];
                            setHolidays(holidays);
                        } else {
                            showSnackBar(`Failed to add. Please try again`, 'error');
                        }
                        closeConfirmDialog();
                    });
                });
            } else {
                showConfirmDialog("Confirm Add Holiday?", undefined, () => {
                    ToolsService.addHoliday(holiday.format(format_without_time))
                    .then(data => {
                        if(data.success){
                            holidays.push(holiday.format(format_without_time));
                            setHolidays(holidays);
                        } else {
                            showSnackBar(`Failed to add. Please try again`, 'error');
                        }
                        closeConfirmDialog();
                    });
                });
            }
        }
    }

    function renderDay(day, selectedDate, isInCurrentMonth, dayComponent){
        // const date = day;
        let selected = Object.keys(workdays).filter(key => workdays[key]).map((key) => parseInt(key));

        const shouldHide = !isInCurrentMonth || !selected.includes(day.get("day"));

        const holiday = holidays.includes(day.format(format_without_time));
        
        let color = "#283593";

        if(shouldHide){
            color = "#bdbdbd";
        } else if(holiday) {
            color = "#e53935";
        }

        // You can also use our internal <Day /> component
        return <Day disabled={shouldHide}>
                { day.isBefore(moment()) ? 
                    <span style={{color: color, fontWeight: 'bold'}}>{day.get("date")}</span> 
                :
                    holiday ? 
                        <Tooltip title={"Holiday"} classes={{ tooltip: classes.customToolTip }}><span style={{color: color, fontWeight: 'bold'}}>{day.get("date")}</span></Tooltip> :
                        <Tooltip title={"Mark as Holiday?"} classes={{ tooltip: classes.customToolTip }}><span style={{color: color, fontWeight: 'bold'}}>{day.get("date")}</span></Tooltip>
                }
                </Day>
        // return <Badge badgeContent={isSelected ? "🌚" : undefined}>{dayComponent}</Badge>;
    }

    const handleChange = value => event => {
        setWorkDays({ ...workdays, [value]: event.target.checked });
    };

    const monthChange = date => {
        setDate(date);
        ToolsService.getHolidayList(date.clone().startOf('month'), date.clone().endOf('month'))
        .then(data => {
            setHolidays(data.holidays);
        });
    }

    const handleChangeTiming = (event, newValue) => {
        setStarttime(newValue[0]);
        setEndtime(newValue[1]);
    };

    const marks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(value => (
        {
            value: value,
            label: value
        }
    ));

    function valuetext(value) {
        return `${value}°C`;
    }

    const updateWorkPattern = event => {
        showConfirmDialog("Confirm save?", undefined, () => {
            let body = Object.keys(workdays).map((key) => ({
                id: key,
                working: workdays[key] ? 1 : 0,
                starttime: starttime,
                endtime: endtime
            }));
            Promise.all(body.map(b => ToolsService.saveWorkPattern(b)))
            .then(data => {
                let success = data.filter(d => !d.success).length === 0;
                if(success){
                    showSnackBar(`Update work days and timing`, 'success');
                } else {
                    showSnackBar(`Failed to add. Please try again`, 'error');
                }
                closeConfirmDialog();
            });
        });
    }

    return (
        <div className="Home">
                <div className="lander">
                    <div >
                        <div style={{display: "flex"}}>
                        </div>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={5} xs={12}>
                                <Card>
                                    <CardHeader
                                        title="Set Work Calendar"
                                        titleTypographyProps={{align: 'left'}} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container style={{paddingTop: 18, paddingLeft: 16}}>
                                            <Grid item xs={12} lg={12}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        orientation="landscape"
                                                        variant="static"
                                                        openTo="date"
                                                        value={date}
                                                        onMonthChange={monthChange}
                                                        onChange={addHoliday}
                                                        renderDay={renderDay}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={7} xs={12}>
                                <Card>
                                    <CardHeader
                                        title="Set working Days"
                                        titleTypographyProps={{align: 'left'}} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12} lg={12}>
                                                <FormControl className={classes.formControl}>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[0]} onChange={handleChange(0)} value={0} />}
                                                            label={"Sunday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[1]} onChange={handleChange(1)} value={1} />}
                                                            label={"Monday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[2]} onChange={handleChange(2)} value={2} />}
                                                            label={"Tuesday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[3]} onChange={handleChange(3)} value={3} />}
                                                            label={"Wednesday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[4]} onChange={handleChange(4)} value={4} />}
                                                            label={"Thursday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[5]} onChange={handleChange(5)} value={5} />}
                                                            label={"Friday"}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workdays[6]} onChange={handleChange(6)} value={6} />}
                                                            label={"Saturday"}
                                                        />
                                                        <FormLabel component="legend" style={{marginTop: 24, marginBottom: 24, paddingBottom: 16}}>Work Hours</FormLabel>
                                                        <Slider
                                                            value={[starttime, endtime]}
                                                            onChange={handleChangeTiming}
                                                            marks={marks}
                                                            valueLabelDisplay="on"
                                                            step={1}
                                                            min={0}
                                                            max={23}
                                                            getAriaValueText={valuetext}
                                                        />
                                                        <FormHelperText>24hour Clock</FormHelperText>
                                                        {/* <Button variant="contained" color="primary" style={{width: 100, marginTop: 24}} onClick={updateWorkPattern}>Confirm</Button> */}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardActions className={classes.actions}>
                                        <Button endIcon={<Icon>save</Icon>} variant="contained" className={classes.button} onClick={updateWorkPattern}>
                                            Confirm
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
        </div>
    );
}
 