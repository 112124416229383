import Config from '../Config';
import {RestService} from './RestService';
import { format_without_time } from '../Utils';

export const FinanceService = {
    getLedgerCount: async function(groupId = null, branch = null, filter = null, headers = {}){
        return RestService.get(`${Config.get_ledger_list}count=true${groupId ? `&group_id=${groupId}` : ''}${branch ? `&branches=${branch}` : ''}${filter ? `&filter=${filter}` : ''}`, headers)
        .then(response => response.json());
    },
    getLedgers: async function(groupId = null, branch = null, filter = null, from = 0, offset = 20, headers = {}){
        return RestService.get(`${Config.get_ledger_list}from=${from}&offset=${offset}${groupId ? `&group_id=${groupId}` : ''}${branch ? `&branches=${branch}` : ''}${filter ? `&filter=${filter}` : ''}`, headers)
        .then(response => response.json());
    },
    getLedgerById: async function(ledgerId = null, headers = {}){
        return RestService.get(Config.get_ledger_by_id.replace('{id}', ledgerId), headers)
        .then(response => response.json());
    },
    getOpeningBalance: async function(accountId = null, branchId = null, date = null, headers = {}){
        return RestService.get(Config.get_ledger_opening_balance.replace('{accountId}', accountId).replace('{branchId}', branchId).replace('{date}',  date.format(format_without_time)), headers)
        .then(response => response.json());
    },
    getLedgerGroups: async function(headers = {}){
        return RestService.get(Config.get_ledger_group_list, headers)
        .then(response => response.json());
    },
    saveLedger: async function(body = {}, headers = {}){
        return RestService.post(Config.save_ledger, headers, body)
        .then(response => response.json());
    },
    saveJournalLedgerTransaction: async function(body = {}, headers = {}){
        return RestService.post(Config.save_journal_ledger_transaction, headers, body)
        .then(response => response.json());
    },
    saveLedgerTransaction: async function(body = {}, headers = {}){
        return RestService.post(Config.save_ledger_transaction, headers, body)
        .then(response => response.json());
    },
    getLedgerTransactionCount: async function(type = null, accountId = null, fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, headers = {}){
        return RestService.get(`${Config.get_ledger_transactions.replace('{type}', type).replace('{accountId}', accountId).replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}count=true${branches ? `&branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, headers)
        .then(response => response.json());
    },
    getLedgerTransactions: async function(type = null, accountId = null, fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, from = 0, offset = 20, sort, direction, headers = {}){
        return RestService.get(`${Config.get_ledger_transactions.replace('{type}', type).replace('{accountId}', accountId).replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}${branches ? `branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}&from=${from}&offset=${offset}`, headers)
        .then(response => response.json());
    },
    getLedgerTransactionsDownload: async function(type = null, accountId = null, fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, from = 0, offset = 20, sort, direction, headers = {}){
        return RestService.get(`${Config.get_ledger_transactions.replace('{type}', type).replace('{accountId}', accountId).replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}download=true${branches ? `&branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}&from=${from}&offset=${offset}`, headers)
        .then(response => response.blob());
    },
    getTransactionDetails: async function(tId = null, headers = {}){
        return RestService.get(Config.get_transaction_details.replace('{tId}', tId), headers)
        .then(response => response.json());
    },
    getJournalTransactionDetails: async function(linkId = null, headers = {}){
        return RestService.get(Config.get_journal_transaction_details.replace('{linkId}', linkId), headers)
        .then(response => response.json());
    },
    getDayBookCount: async function(fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, headers = {}){
        return RestService.get(`${Config.get_day_book.replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}count=true${branches ? `&branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, headers)
        .then(response => response.json());
    },
    getDayBook: async function(fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, from = 0, offset = 20, sort, direction, headers = {}){
        return RestService.get(`${Config.get_day_book.replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}${branches ? `branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}&from=${from}&offset=${offset}`, headers)
        .then(response => response.json());
    },
    getDayBookDownload: async function(fromdate = null, todate = null, branches = null, filter = null, amountGTE = null, amountLTE = null, from = 0, offset = 20, sort, direction, headers = {}){
        return RestService.get(`${Config.get_day_book.replace('{fromdate}', fromdate.format(format_without_time)).replace('{todate}',  todate.format(format_without_time))}download=true${branches ? `&branches=${branches}` : ''}${filter ? `&filter=${filter}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}&from=${from}&offset=${offset}`, headers)
        .then(response => response.blob());
    },
    removeTransaction: async function(type, id, headers = {}){
        return RestService.delete(Config.remove_ledger_transaction.replace("{type}", type).replace("{id}", id), headers);
    },
    removeLedger: async function(id, headers = {}){
        return RestService.delete(Config.get_ledger_by_id.replace("{id}", id), headers)
        .then(response => response.json());
    },
};