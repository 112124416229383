import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TableRow, TableBody, Divider, Icon, Tooltip, IconButton, Button, Typography } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { StyledTableCell, formatNumber, getTimeInIST, format_display_fancy_notime, format_display_withtime } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SingleDate from "../components/SingleDate";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import XLSX from 'xlsx';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '40vw',
        [theme.breakpoints.down('md')]: {
           width: '100vw'
        },
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    cellStyle: {
        fontSize: 12,
        lineHeight: '16px'
    }
}));

export default function PledgeOutstandingDateWiseReport(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar} = useContext(AppContext);
    const classes = useStyles();
    const [selectedBranches, setSelectedBranches] = React.useState([]);
    const [branches, setBranches] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const order = 'asc';
    const orderBy = 'pledge_no';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [data, setData] = React.useState([]);
    const columns = [
        { id: 'start_date', align: 'center', numeric: false, disablePadding: true, label: 'Date', sortable: false },
        { id: 'opening_balance', align: 'right', numeric: true, disablePadding: true, label: 'Opening Balance', sortable: false },
        { id: 'payment', align: 'right', numeric: true, disablePadding: true, label: 'Payments', sortable: false },
        { id: 'receipt', align: 'right', numeric: true, disablePadding: true, label: 'Receipts', sortable: false },
        { id: 'closing_balance', align: 'right', numeric: true, disablePadding: true, label: 'Closing Balance', sortable: false },
    ];

    React.useEffect(() => {
        setTitle('Pledge Oustanding Datewise Report');
        showProgressDialog();
        // console.log(state);
        let bL = state.branches.filter(b => b.id !== '-1');
        setBranches(bL);

        if(bL.length === 1){
            setSelectedBranches(bL);
        }
        setGenerate(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setCurrentRows([]);
            setLinearLoading(true);
            showProgressDialog();
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            LoanRequestsService.reportOutstandingDateWise(startdate, enddate, selectedBranchIds)
            .then(data => {
                let rows = [];
                if(data){
                    setData(data);
                    data.forEach((element, index) => {
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover>
                                <StyledTableCell scope="row" align="center" padding='none' className={classes.cellStyle}>
                                    {getTimeInIST(element.txn_date).format(format_display_fancy_notime)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.opening_balance || 0)}
                                </StyledTableCell>                              
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.payment || 0)}
                                </StyledTableCell>                              
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle}>
                                    {formatNumber(element.receipt || 0)}
                                </StyledTableCell>                              
                                <StyledTableCell scope="row" align="right" padding='none'  className={classes.cellStyle} style={{paddingRight: 16}}>
                                    {formatNumber(element.closing_balance || 0)}
                                </StyledTableCell>                              
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate]);

    const sortBy = (event, property) => {
    }

    const exportData = () => {
        showProgressDialog('Generating Report...');
        let rows = [];
        let filename = 'Pledge Outstanding Datewise Report';
        
        rows.push([`Pledges Between` , startdate.format(format_display_fancy_notime), enddate.format(format_display_fancy_notime)])
        rows.push(columns.map(c => c.label));
        if(data){
            data.forEach((d, index) => {
                rows.push([getTimeInIST(d.txn_date).format(format_display_withtime), d.opening_balance , d.payment, d.receipt, d.closing_balance]);
            });
            const wb = XLSX.utils.book_new();
            const wsAll = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
            XLSX.writeFile(wb, filename + ".xlsx");
            closeProgressDialog();
        } else {
            showSnackBar('No data to Export', 'info');
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={8} lg={10} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                    {
                                        branches.length > 1 && (
                                            <Grid item xs={12} lg={4}>
                                                <ItemSelection 
                                                    multiple 
                                                    clearable={true} 
                                                    label='Entity' 
                                                    value={selectedBranches} 
                                                    optionLabel='name' 
                                                    options={branches} 
                                                    selected={setSelectedBranches}/>
                                            </Grid>  
                                        )
                                    }
                                <Grid item container xs={12} lg={4}>
                                    <Grid item>
                                        <div style={{maxWidth: 170}}>
                                            <SingleDate 
                                                label="From"
                                                date={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setStartDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter'){
                                                        !generate && setGenerate(true)
                                                    }
                                                }}/>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{maxWidth: 170}}>
                                            <SingleDate 
                                                label="To"
                                                date={enddate}
                                                minDate={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setEndDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter'){
                                                        !generate && setGenerate(true)
                                                    }
                                                }}/>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Tooltip title="Generate Report">
                                        <Button size="small" variant="contained" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            Generate
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={currentRows.length === 0? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={currentRows.length === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        currentRows.length === 0 && (
                                            <div style={{marginBottom: 50}}>
                                                <Typography color="error" variant="subtitle2">No Records Available</Typography>
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}