import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { formatNumber, getTimeInIST, format_display_fancy_notime_withDayAndyear } from '../Utils';
import { Grid, Fade, LinearProgress, Divider } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { TXN_TYPE, PAYMENT_TYPE } from '../Constants';
import { FinanceService } from '../Services/FinanceService';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TransactionDetails(props) {
  const [open, setOpen] = React.useState(props.id ? true : false);
  const [loading, setLoading] = React.useState(false);

  const [title, setTitle] = React.useState(undefined);
  const [details, setDetails] = React.useState([]); 

  const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.id && props.open]);

    React.useEffect(() => {
    if(open && props.id){
        setLoading(true);
        let details = [];
        FinanceService.getTransactionDetails(props.id)
        .then((data) => {
            if(data){
                setTitle(`${data.credit_debit === TXN_TYPE.C ? PAYMENT_TYPE.R : PAYMENT_TYPE.P} Transaction - Entity ${data.branch_name}`);

                details.push(
                <Grid item container xs={12} lg={12} key={1} spacing={2}  alignItems="center">
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} lg={6}>
                        <Typography className={classes.heading} align="right">
                            {getTimeInIST(data.txn_date).format(format_display_fancy_notime_withDayAndyear)}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} lg={1}>

                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <Typography className={classes.heading} style={{textAlign: 'left'}}>Particular</Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.heading} align="right">Debit</Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.heading} align="right">Credit</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{marginTop: 2, marginBottom: 2}}/>
                    </Grid>
                    <Grid item xs={1} lg={1}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}>By</Typography>
                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}><b>{data.credit_debit === TXN_TYPE.D ? data.account_name : data.contra_name}</b></Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.subheading} align='right'>
                            {formatNumber(data.amount)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.subheading} align='right'>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={1} lg={1}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}>To</Typography>
                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}><b>{data.credit_debit === TXN_TYPE.D ? data.contra_name : data.account_name}</b></Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.subheading} align='right'>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <Typography className={classes.subheading} align='right'>
                            {formatNumber(data.amount)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{marginTop: 8, marginBottom: 8}}/>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <Typography className={classes.heading} style={{textAlign: 'left'}}>Narration:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={9}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}>{data.narration || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <Typography className={classes.heading} style={{textAlign: 'left'}}>Entered By:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={9}>
                        <Typography className={classes.subheading} style={{textAlign: 'left'}}>{data.entry_by || '-'}</Typography>
                    </Grid>
                    
                </Grid>);
            }
            setLoading(false);
            setDetails(details);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }
}, [open]);

 
  const handleClose = () => {
    props.close();
    setOpen(false);
    reset();
  };

  const reset = () => {
    setDetails();
    setTitle(undefined);
  }

  return (
      <Dialog fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                <Grid item xs={12} lg={12}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '10ms' : '0ms'
                        }}
                        unmountOnExit
                        >
                        <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                    </Fade>
                </Grid>
                {details}
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
  );
}