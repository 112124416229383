import React, { useState } from 'react'
import { precision, StyledTableCell } from '../Utils'
import { Avatar, Button, CardHeader, Divider, Grid, Paper, Table, TableBody, TableRow } from '@material-ui/core'
import EnhancedTableHead from '../components/EnhancedTableHead'
import ItemHistoryDialog from './ItemHistoryDialog'

const ItemDetails = ({ items, classes, loanId, showHistory }) => {
    const [openHistory, setOpenHistory] = useState(false)

    const columns = [
        { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Item', sortable: false },
        { id: 'qty', align: 'center', numeric: false, disablePadding: false, label: 'Qty', sortable: false },
        { id: 'weight', align: 'center', numeric: false, disablePadding: false, label: 'Weight', sortable: false },
        { id: 'd_weight', align: 'center', numeric: false, disablePadding: false, label: 'Deducted Weight', sortable: false },
        { id: 'net_weight', align: 'center', numeric: false, disablePadding: false, label: 'Net Weight', sortable: false },
        { id: 'purity', align: 'center', numeric: false, disablePadding: false, label: 'Purity', sortable: false },
    ]

    return (
        <Grid item xs={12} lg={12}>
            <Paper variant="outlined" className={classes.section} elevation={3}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="step1" className={classes.avatar}>
                            2
                        </Avatar>
                    }
                    title="Item information"
                    titleTypographyProps={{ variant: 'h4' }}
                    action={showHistory && (
                        <Button variant="outlined" color="primary" onClick={() => setOpenHistory(true)} style={{ marginLeft: 8, marginTop: 8 }}>
                            View Item history
                        </Button>
                    )}
                />
                <Divider />
                <Table>
                    <EnhancedTableHead
                        order={""}
                        orderBy={""}
                        onRequestSort={() => { }}
                        rows={columns} />
                    <TableBody>
                        {items.map((row, i) => (
                            <TableRow key={i} hover>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {i + 1}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{ minWidth: '20vw', marginBottm: 8 }}>
                                    {row.item_name}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {precision(row.qty)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {precision(row.weight)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {precision(row.d_weight)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {precision(row.weight - (row.d_weight || 0))}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {row.purity}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <ItemHistoryDialog
                fullWidth={true}
                maxWidth={'lg'}
                open={openHistory}
                loanId={loanId}
                title="Item History"
                close={() => {
                    setOpenHistory(false)
                }}
            />
        </Grid>
    )
}

export default ItemDetails