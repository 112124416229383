import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { Grid, TextField } from '@material-ui/core';
import { format } from '../Utils';
import moment from 'moment';
import { LoanRequestsService } from '../Services/LoanRequestsService';
import DateTime from './DateTime';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left"
  },
  dialogContent: {
    paddingTop: 0
  }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddFollowUpDialog(props) {
  const { showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
  const { id } = props;
  const [additionalNarration, setAdditionalNarration] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [nextFollowUp, setNextFollowUp] = React.useState(null);
  const [addReminder, setAddReminder] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  const handleClose = (callback = false) => {
    props.onClose(callback);
    setAdditionalNarration(null)
    setNextFollowUp(null)
    setOpen(false);
  };

  const handleSave = () => {
    if (!additionalNarration || additionalNarration.length === 0) {
      showSnackBar(`Please enter Remark`, 'info')
    } else {
      showConfirmDialog("Confirm ?", undefined, () => {
        closeConfirmDialog();
        let body = {
          pledge_id: id,
          remarks: additionalNarration,
          next_follow_up: nextFollowUp ? moment(nextFollowUp).utc().format(format) : null
        };
        showProgressDialog();
        LoanRequestsService.createFollowup({}, body)
          .then(data => {
            if (data.success) {
              showSnackBar(data.message, 'success')
              handleClose(true)
            } else {
              showSnackBar(data.message, 'error')
            }
            closeProgressDialog();
          })
          .catch(error => {
            console.log(error);
            showSnackBar(`Something went wrong while trying to save follow up. Please try again.`, 'error');
            closeProgressDialog();
          })
      });
      //   props.save(amount, selectedMode.value, selectedLedger, additionalNarration || '', partRepayment || 0)
    }
  }

  const handleNarrationChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setAdditionalNarration(value);
  }

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        <Typography variant="h6" component="div">
          Add Follow Up Details
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <TextField
              id="remarks"
              label="Remarks"
              placeholder="Remarks"
              name="additionalNarration"
              defaultValue={''}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              rows={2}
              multiline={true}
              style={{ paddingRight: 10 }}
              onChange={handleNarrationChange}
              fullWidth
            />
          </Grid>
          {
            !addReminder && (
              <Grid item xs={12} lg={6}>
                <Button variant="contained" color="primary" onClick={() => {
                  setAddReminder(true);
                  setNextFollowUp(moment());
                }}
                >Add Reminder</Button>
              </Grid>
            )
          }
          {
            addReminder && (
              <Grid item xs={12} lg={6}>
                <DateTime
                  label="Next Follow Up"
                  date={nextFollowUp}
                  minDate={moment().startOf('day')}
                  setDate={(date) => {
                    setNextFollowUp(date);
                  }} />
              </Grid>
            )
          }
          {
            addReminder && (
              <Grid item xs={12} lg={6}>
                <Button variant="contained" color="primary" style={{ marginTop: 16 }} onClick={() => {
                  setAddReminder(false);
                  setNextFollowUp(null);
                }}
                >Remove Reminder</Button>
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddFollowUpDialog.prototype = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};