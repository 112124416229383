export const Constants = {
  EXECUTIVE: "EXECUTIVE",
  CUSTOMER: "CUSTOMER",
  LR: "LR",
  CR: "CR",
  IP: "IP",
  ER: "ER",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  OPEN: "OPEN",
  ASSIGNED: "ASSIGNED",
  CLOSED: "CLOSED",
  CANCEL: "CANCELLED",
  UNSERVICEABLE: "UNSERVICEABLE",
};

export const REQUEST_TYPE = {
  LR: "Loan Request",
  ER: "Enhancement Request",
  IP: "Interest Payment",
  CR: "Close Request",
};

export const LOAN_TYPES = [
  { key: "LR", value: "Loan Request" },
  { key: "ER", value: "Enhancement Request" },
  { key: "IP", value: "Interest Payment" },
  { key: "CR", value: "Close Request" },
];

export const SMS_TYPE = {
  C: "cancel",
  U: "unserviceable",
  A: "assigned",
};

export const TABLE_COUNTS = {
  ledger_tab_count: "geomoney.ledger_tab_count",
  loan_tab_count: "geomoney.loan_tab_count",
  report_outstanding_loan_tab_count: "geomoney.report_outstanding_loan_tab_count",
  report_due_loan_tab_count: "geomoney.report_due_loan_tab_count",
  report_release_loan_tab_count: "geomoney.report_release_loan_tab_count",
  report_risk_loan_tab_count: "geomoney.report_risk_loan_tab_count",
}

export const TXN_TYPE = {
  D: "DEBIT",
  C: "CREDIT",
  CASH: "CASH",
  BANK: "BANK",
  JOURNAL: "JOURNAL",
  GENERAL: "GENERAL",
};

export const PAYMENT_TYPE = {
  P : "Payment",
  R : "Receipt"
};