import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import queryString from 'query-string';
import jsPDF from 'jspdf';
import { getTimeInIST, format_display, formatNumberNoSym, numberToText, titleCase, precision } from "../Utils";
import { Toolbar, Typography, Button, makeStyles, Icon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function PrintLoan(props) {
    const classes = useStyles();
    const [pledgeNo, setPledgeNo] = useState(null);
    const {state, setTitle, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const [loan, setLoan] = useState(null);
    const [pdfBlob, setPdfBlob] = useState(null);

    React.useEffect(() => {
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            const pledgeNo = qString.no || null;
            setPledgeNo(pledgeNo)
            setTitle('Print Loan Details');
        }
    }, []);

    React.useEffect(() => {
        if(pledgeNo){
            refresh();
        }
    }, [pledgeNo])

    const refresh = () => {
        showProgressDialog('Fetching Loan for printing...');
        if(pledgeNo){
            LoanRequestsService.getLoanDetails(pledgeNo)
            .then(data => {
                let b = state.branches.find(b => b.id === data.branch_id);
                setLoan({...data, branch_address: `${b.address} - ${b.pincode}`});
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            })
        }
    }

    React.useEffect(()=> {
        if(loan){
            var doc = new jsPDF({
                orientation: 'portrait',
                format: 'a4',
                compress: true
            });
            doc.setProperties({
                title: pledgeNo
            });
            var yAxis = 17;
            doc.setFontStyle('bold');
            doc.setFontSize(16);
            doc.text(78, yAxis + 1, loan.branch_address);
            doc.setFontSize(12);
            doc.setFontStyle('');

            //Section 1
            doc.text(32, yAxis + 16, loan.pledge_no);
            doc.text(85, yAxis + 16, getTimeInIST(loan.start_date).format(format_display));
            doc.text(154, yAxis + 16, formatNumberNoSym(loan.loan_amount));


            //Section 2L
            let line = 55;
            loan.items.forEach((item, i) => {
                doc.text(10, line, `${i+1}`);
                doc.text(23, line, `${item.item_name}`);
                doc.text(82, line, `${item.qty}`);
                line +=7;
            })

            //Section 2R1
            doc.text(142, 49, loan.customer.name);
            doc.text(142, 54, `${loan.customer.address1 || ''}${`,\n${loan.customer.address2}` || ''},\n${loan.customer.address3 || ''},\n${loan.customer.pincode || ''}\n${`Mob: ${loan.customer.mobile}` || ''}`);
            doc.text(142, 66, loan.customer.nominee || '');

            //Section 2R2
            doc.text(140, 91, `${precision(loan.items.reduce((sum, next) => sum + parseFloat(next.weight || 0), 0))} gms`);
            doc.text(140, 97, `${precision(loan.total_weight)} gms`);
            doc.text(140, 104, `NA`); // Market Rate
            doc.text(140, 111, `NA`); // Market Value
            doc.text(140, 117, formatNumberNoSym(loan.rate));
            doc.text(140, 125, getTimeInIST(loan.start_date).add(270, 'd').format(format_display));

            //Section 3 Promisory Note
            let y3 = 153;
            doc.text(94, y3-8, loan.branch_address);
            doc.text(23, y3, formatNumberNoSym(loan.loan_amount));
            doc.text(70, y3, titleCase(numberToText(loan.loan_amount)));

            //Section 4L
            let y4l = 223;
            doc.setFontSize(9);
            doc.text(25, y4l - 20, loan.branch_address);
            doc.text(42, y4l + 1, loan.pledge_no);
            doc.text(75, y4l + 1, getTimeInIST(loan.start_date).format(format_display));
            doc.text(38, y4l+8, formatNumberNoSym(loan.loan_amount));
            doc.text(77, y4l+8, loan.customer.name);

            doc.text(29, y4l+14, `${precision(loan.items.reduce((sum, next) => sum + parseFloat(next.weight || 0), 0))} gms`);
            doc.text(58, y4l+14, `${precision(loan.total_weight)} gms`);
            doc.text(89, y4l+14, getTimeInIST(loan.start_date).add(270, 'd').format(format_display));

            //Section 4R
            let y4r = 202;
            doc.setFontSize(11);
            doc.text(138, y4r, loan.pledge_no);
            doc.text(182, y4r, getTimeInIST(loan.start_date).format(format_display));
            doc.text(132, y4r+14, loan.customer.name);
            doc.text(145, y4r+26, `${precision(loan.items.reduce((sum, next) => sum + parseFloat(next.weight || 0), 0))} gms`);
            doc.text(192, y4r+26, `${precision(loan.total_weight)} gms`);
            doc.text(136, y4r+39, formatNumberNoSym(loan.loan_amount));
            // doc.text(183, y4r+39, `${loan.items.reduce((sum, next) => sum + parseFloat((next.qty) || 0), 0)} nos`);
            doc.setFontSize(10);
            loan.items.forEach((item, i) => {
                doc.text(183, y4r+32+ i*4, `${item.item_name}-${item.qty}`);
            })
            doc.setFontSize(11);
            doc.text(140, y4r+52, loan.entry_by);

            var blobpdf = new Blob([doc.output('blob')], {'type' : 'application/pdf'});
            var blobUrl = URL.createObjectURL(blobpdf);
            setPdfBlob(blobUrl);
            // doc.output("dataurlnewwindow");
            closeProgressDialog();
        }
    }, [loan]);
 

    return (
        <div style={{width: '100vw', height: '100vh'}}>
            {
                !pledgeNo && (
                    <div style={{width: '90vw', position: 'relative', top: 300, left: 0, right: 0}}>
                        <Typography variant="h6" className={classes.title}>
                            Something went wrong while fetching loan. Please try again.
                        </Typography>
                    </div>
                )
            }
            {
                pledgeNo && (
                    <Toolbar style={{width: '95vw'}}>
                        <Typography variant="h6" className={classes.title}>
                            {`Loan: ${pledgeNo}`}
                        </Typography>
                        <Button color="inherit" onClick={refresh}>
                            <Icon>refresh</Icon>
                        </Button>
                    </Toolbar>
                )
            }
            <embed src={pdfBlob} type="application/pdf" style={{width: '90vw', height: '80vh'}} />
        </div>
    )
}