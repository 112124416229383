import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider, Switch, IconButton } from "@material-ui/core";
import { MasterService } from "../Services/MasterService";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getTimeInIST, format_fancy } from "../Utils";
import { Skeleton } from "@material-ui/lab";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    header: {
        fontSize: 16,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 14,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    divider: {
        marginTop: 3,
        marginBottom: 3
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function JobSettings(props) {
    const classes = useStyles();
    const { setTitle, showSnackBar, showConfirmDialog, closeConfirmDialog, setLinearLoading } = useContext(AppContext);
    const [jobs, setJobs] = React.useState([]);    
    const [refresh, setRefresh] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        fetchJobs();
        setTitle('Manage Background Jobs')
        return () => {
            setLinearLoading(false);
            setRefresh(false);
        }
    }, [true]);

    function Rows(props){
        const job = props.job;
        const showDivider = props.showDivider;
        const [running, setRunning] = React.useState(null);
        const [ui, setUI] = React.useState(null);

        React.useEffect(() => {
            if(job.name){
                fetchRunningStatus(job.name);
            }
        }, [true]);

        React.useEffect(() => {
            if(running){
                switch(running.type){
                    case 'INTEREST_DUE_DATE':
                    case 'LOAN_DUE_DATE':
                    default:
                        setUI(
                            <div>
                                <Typography variant="body2" align="left" display="block">
                                    Status: <b>{running.status || '-'}</b>
                                </Typography>
                                <Typography variant="body2" align="left"  display="block">
                                    Processed Count: <b>{running.processed !== undefined ? `${running.processed}/${running.actualCount}` : '-' }</b>
                                </Typography>
                                { running.comments && (
                                    <Typography variant="body2" align="left" display="block">
                                        Comments: <b>{running.comments}</b>
                                    </Typography>
                                )}
                                <Typography variant="body2" align="left" display="block">
                                    Last Started at: <b>{running.starttime ? getTimeInIST(running.starttime).format(format_fancy) : '-'}</b>
                                </Typography>
                                <Typography variant="body2" align="left" display="block">
                                    Last Finished at: <b>{running.endtime ? getTimeInIST(running.endtime).format(format_fancy) : '-'}</b>
                                </Typography>
                            </div>);
                        break;
                }
            }
        }, [running])

        const fetchRunningStatus = (jobName) => {
            setUI( <Skeleton variant="rect" width="100%" height={84} animation="wave"/>)
            MasterService.fetchBackgroundJobsStatus(jobName, {})
            .then(data => {
                // console.log(data);
                setRunning(data);
            })
            .catch((error) => {
                console.log('error fetching status');
            });
        }

        return (
                <Grid container item xs={12} lg={12} alignContent={"flex-start"} justifyContent={"center"}>
                        <Grid item xs={12} lg={4}>
                            <Typography align="left" variant="subtitle2" style={{paddingLeft: 16}}>{job.description}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography component="div" className={classes.subtitle}>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Off</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={parseInt(job.enabled) === 1}
                                            onChange={props.handleChange(job.id)}
                                            color="primary"
                                            name="job_status"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Grid>
                                    <Grid item>On</Grid>
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {ui}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={(e) => fetchRunningStatus(job.name)}>
                                <RefreshIcon/>
                            </IconButton>
                        </Grid>
                        {
                            showDivider && (
                                <Grid item xs={12} lg={12}>
                                    <Divider className={classes.divider}/>
                                </Grid>
                        )}
                        
                    </Grid>
        )
    }

    React.useEffect(() => {
        if(refresh && jobs.length > 0){
            setRefresh(false);
            let rows = [];
            jobs.forEach((job, i) => {
                rows.push(<Rows key={i} showDivider={i !== jobs.length -1} job={job} handleChange={handleChange}/>);
            });
            setRows(rows);
            setLinearLoading(false);
        }
        return () => {
            setRefresh(false);
        }
    }, [refresh])

    const fetchJobs = () => {
        setLinearLoading(true);
        // Fetch Job List
        MasterService.fetchBackgroundJobs()
        .then(data => {
            setJobs(data);
            setRefresh(true);
        })
    }

    const handleChange = jobId => e => {
        let enabled = e.target.checked;
        showConfirmDialog("Confirm change status?", undefined, () => {
            confirmChange(jobId, enabled);
        }, "Cancel", "Yes");
    
    }

    const confirmChange = (jobId, enabled) => {
        setLinearLoading(true);
        MasterService.enableJob(jobId, enabled)
        .then((data) => {
            // console.log(data);
            fetchJobs();
            showSnackBar('Changed status', "success");
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    return (
        <div >
            <Grid container direction="row" justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                    <Paper className={classes.section}>
                        <Grid container spacing={1} alignItems="flex-start" justifyContent={"space-evenly"}>
                            <Grid container item xs={12} lg={12}>
                                <Grid item xs={12} lg={4} data-tour="jobs">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Job</Typography>
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="jobsenable">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Enabled</Typography>
                                </Grid>
                                <Grid item xs={12} lg={5} data-tour="jobsstatus">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Status</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider className={classes.divider}/>
                                </Grid>
                            </Grid>
                            {rows.length === 0 ? Array.from(Array(2), (_, i) => <Skeleton key={i} width={"100%"} height={50}/>) : rows}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
 