import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { Grid, Icon, Table, TableBody, TableRow, TextField } from '@material-ui/core';
import { precision, StyledTableCell } from '../Utils';
import ItemSelection from '../components/ItemSelection';
import EnhancedTableHead from '../components/EnhancedTableHead';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left"
  },
  dialogContent: {
    paddingTop: 0
  }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let counter = 0
export const ChangeLoanItemsDialog = forwardRef((props, ref) => {
  const { showSnackBar, showConfirmDialog, closeConfirmDialog } = useContext(AppContext)
  const { subtitle, items } = props
  const [open, setOpen] = React.useState(false)
  const [enabled, setEnabled] = React.useState(true)
  const [title, setTitle] = React.useState(null)
  const [updatedItems, setUpdatedItems] = React.useState([])
  const [errorItemRow, setErrorItemRow] = React.useState([])
  const classes = useStyles()

  const columns = [
    { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
    { id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Item', sortable: false },
    { id: 'qty', align: 'center', numeric: true, disablePadding: false, label: 'Qty', sortable: false },
    { id: 'weight', align: 'center', numeric: true, disablePadding: false, label: 'Gross Weight', sortable: false },
    { id: 'd_weight', align: 'center', numeric: true, disablePadding: false, label: 'Deducted Weight', sortable: false },
    { id: 'net_weight', align: 'center', numeric: true, disablePadding: false, label: 'Net Weight', sortable: false },
    { id: 'purity', align: 'center', numeric: false, disablePadding: false, label: 'Purity', sortable: false },
    { id: 'action', align: 'center', numeric: false, disablePadding: false, label: '', sortable: false },
  ]

  useImperativeHandle(ref, () => ({
    handleClose() {
      handleClose()
    },
    enable() {
      setEnabled(true)
    }
  }));

  React.useEffect(() => {
    if (props.open) {
      setUpdatedItems([])
      setEnabled(true)
      setOpen(props.open)
      setTitle(props.title)
      reset()
    } else {
      setOpen(props.open)
    }
  }, [props.open])

  const reset = () => {
    let errRows = [], updatedItemRows = []
    items.forEach((i, index) => {
      let x = {
        ...i,
        id: i.id,
        rowid: counter++,
        qty: i.qty,
        original_qty: i.qty,
        weight: i.weight,
        original_weight: i.weight,
        d_weight: i.d_weight || 0,
        purity: i.purity
      }
      updatedItemRows.push(x);
      errRows.push({
        ...x,
        item: false, qty: false, weight: false, d_weight: false
      })
    })
    setUpdatedItems(updatedItemRows)
    setErrorItemRow(errRows)
  }

  const handleClose = () => {
    props.close()
    setTitle(null)
    setOpen(false)
  }

  const handleSave = () => {
    const qtyIsZero = updatedItems.filter(i => parseInt(i.qty) === 0).length
    const qtyIsMoreThanAvailable = updatedItems.filter(i => parseInt(i.original_qty) < parseInt(i.qty)).length
    if (updatedItems.length === 0) {
      showSnackBar(`Cannot remove all items`, 'error')
    } else if (qtyIsZero > 0) {
      showSnackBar(`Quantity cannot be zero`, 'error')
    } else if (qtyIsMoreThanAvailable > 0) {
      showSnackBar(`Quantity entered is more than available`, 'error')
    } else {
      showConfirmDialog('Save changes?', undefined, () => {
        closeConfirmDialog()
        setEnabled(false)
        props.save(updatedItems)
      }, 'Back')
    }
  }

  const handleRowRemove = rowid => e => {
    setUpdatedItems(updatedItems.filter(r => r.rowid !== rowid))
  }

  const handleRowChange = rowid => e => {
    let name = e.target.name
    let value = e.target.value
    const max = e.target.max
    setUpdatedItems(updatedItems.map(row =>
      row.rowid === rowid
        ?
        { ...row, [name]: value }
        :
        row
    ))
    setErrorItemRow(rows => rows.map(row =>
      row.rowid === rowid
        ?
        { ...row, [name]: !value || (max ? parseFloat(max) < parseFloat(value) : false) }
        : row
    ))
  }

  return (
    <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="caption" component="div">
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 0 }}>
        {updatedItems ? (
          <Grid item xs={12} lg={12}>
            <Table>
              <EnhancedTableHead
                order={""}
                orderBy={""}
                onRequestSort={() => { }}
                rows={columns} />
              <TableBody>
                {updatedItems.map((row, i) =>
                  <TableRow key={i} hover>
                    <StyledTableCell scope="row" align="justify" padding='none'>
                      <div style={{ marginBottom: 22 }}>{i + 1}</div>
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="justify" padding='none' style={{ minWidth: '20vw', marginBottm: 8 }}>
                      <ItemSelection
                        disabled={true}
                        required
                        style={{ zIndex: 500, marginTop: 4, marginBottom: 22, marginLeft: 0 }}
                        label='Item'
                        options={[row]}
                        value={row || null}
                        optionLabel='item_name'
                        fullWidth
                        selected={() => { }}
                        getOptionSelected={(option, value) => option.id === value.id}
                      />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                      <TextField
                        id="outlined-basic-qty"
                        //label="Quantity" 
                        name="qty"
                        variant="outlined"
                        margin="dense"
                        error={errorItemRow.find(i => i.rowid === row.rowid).qty || false}
                        value={row.qty ? row.qty : ''}
                        inputProps={{
                          //startAdornment: <InputAdornment position="start">Nos</InputAdornment>,
                          min: 0,
                          max: row.original_qty,
                        }}
                        required
                        style={{ marginTop: 8, width: 120 }}
                        type="number"
                        helperText={`Nos (Max: ${row.original_qty})`}
                        onChange={handleRowChange(row.rowid)}
                      />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                      <TextField
                        id="outlined-basic-wt"
                        //label="Weight" 
                        name="weight"
                        error={errorItemRow.find(i => i.rowid === row.rowid).weight || false}
                        variant="outlined"
                        margin="dense"
                        required
                        value={row.weight ? row.weight : ''}
                        inputProps={{
                          //startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
                          min: 0,
                          max: row.original_weight,
                        }}
                        style={{ marginTop: 8, width: 160 }}
                        type="number"
                        helperText={`Gram (Max: ${row.original_weight})`}
                        onChange={handleRowChange(row.rowid)} />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                      <TextField
                        id="outlined-basic-dwt"
                        //label="Deducted Weight" 
                        name="d_weight"
                        variant="outlined"
                        margin="dense"
                        error={errorItemRow.find(i => i.rowid === row.rowid).d_weight || false}
                        value={row.d_weight ? row.d_weight : 0}
                        inputProps={{
                          //startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
                          min: 0,
                          max: row.d_weight,
                        }}
                        required
                        style={{ marginTop: 8, width: 100 }}
                        type="number"
                        helperText="Gram"
                        onChange={handleRowChange(row.rowid)} />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                      <TextField
                        id="outlined-basic-dwt"
                        disabled={true}
                        name="net_weight"
                        variant="outlined"
                        margin="dense"
                        value={row.weight ? precision(row.weight - (row.d_weight || 0)) : ''}
                        inputProps={{
                          //startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
                          min: 0
                        }}
                        required
                        style={{ marginTop: 8, width: 100 }}
                        type="number"
                        helperText="Gram" />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                      <TextField
                        id="outlined-basic-purity"
                        name="purity"
                        variant="outlined"
                        margin="dense"
                        value={row.purity ? row.purity : ''}
                        inputProps={{
                          //startAdornment: <InputAdornment position="start">%</InputAdornment>,
                          min: 0
                        }}
                        style={{ marginTop: 8, marginBottom: 26 }}
                        type="number"
                        helperText=""
                        onChange={handleRowChange(row.rowid)} />
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='normal'>
                      <Button>
                        <Icon style={{ marginTop: 8, marginBottom: 22 }} onClick={handleRowRemove(row.rowid)}>delete</Icon>
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={reset} color="secondary" variant='contained'>
          RESET
        </Button>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button disabled={!enabled} autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
})

ChangeLoanItemsDialog.prototype = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  eligibleAmount: PropTypes.number.isRequired,
  bankLedgers: PropTypes.array,
  currentDisbursementLedger: PropTypes.object.isRequired,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool
};