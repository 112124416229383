import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { format_display_fancy_notime, BootstrapTooltip, toInt, getTimeInIST, format_without_time } from "../Utils";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    inner: {
        overflow: 'auto',
    },
    comments: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: 16,
        color: '#263238',
        fontWeight: 500,
        lineHeight: '1.5rem'
    },
    tableCell: {
        fontSize: '0.875rem',
        color: '#263238',
        fontWeight: 400,
        lineHeight: '1.43'
    }
}));

const LoansDue = ({count, reload, ...props}) => {
    const {state, openViewLoan}  = useContext(AppContext);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    React.useEffect(() => {
        setLoading(true);
        let bIds = state.branches.length > 1 ? null : state.selectedBranch.id;
        LoanRequestsService.reportLoanDueCount(undefined, moment().endOf('day'), '', bIds)
        .then(data => {
            setTotalCount(toInt(data.total_count));
            if(data.total_count === 0) {
                setData([]);
                setLoading(false);
            } else {
                LoanRequestsService.reportLoanDue(undefined, moment().endOf('day'), '', bIds, 'customer_name', 'asc', 0, Math.max(data.total_count, count))
                .then(data => {
                    setData(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                })
            }
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [reload]);

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card 
                className={clsx(classes.root)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={`Loans Due today (${totalCount})`}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <div className={classes.inner}>
                            <List>
                                {data.map(loan => (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        style={{paddingLeft: 10, paddingRight: 10}}
                                        divider
                                        dense
                                        key={loan.pledge_no}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <BootstrapTooltip title={`Click to view Loan Details`}>
                                                    <div style={{display: 'flex', alignItems: 'space-between'}}>
                                                        <Typography 
                                                            style={{marginRight: 8, cursor: 'pointer'}} 
                                                            align="left" 
                                                            color="primary"
                                                            component="div"
                                                            onClick={(e) => {
                                                                openViewLoan(loan.pledge_no)
                                                            }} 
                                                        >
                                                            <b>{loan.pledge_no}</b>
                                                        </Typography>
                                                        <Typography 
                                                            variant="subtitle2"
                                                            align="right" 
                                                            style={{lineHeight: '21px'}}
                                                            component="div">
                                                            {`${loan.customer_name} (${loan.customer_mobile})`}
                                                        </Typography>
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                            secondary={
                                                <div style={{display: 'flex', alignItems: 'space-between'}}>
                                                    <Typography 
                                                        variant="caption" 
                                                        style={{marginRight: 8}} 
                                                        align="left" 
                                                        component="div">
                                                        {`Branch: ${loan.branch_name}`}
                                                    </Typography>
                                                    <Typography variant="caption" align="right" component="div">
                                                        {`Start Date: ${getTimeInIST(loan.start_date).format(format_display_fancy_notime)}`}
                                                    </Typography>
                                                </div>
                                            }/>
                                    </ListItem>
                                ))}
                                {
                                    data.length === 0 && (
                                        <ListItem
                                            style={{paddingLeft: 10, paddingRight: 10}}
                                            divider
                                            dense
                                            key={'no_loans'}
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography align="left" color="secondary"><b>No loans due</b></Typography>
                                                }
                                            />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        variant="text"
                        to={`/report/pledge/due?type=ason&enddate=${moment().format(format_without_time)}`}
                    >
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default LoansDue;