import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple, green, indigo } from '@material-ui/core/colors';
import { REQUEST_TYPE } from "../Constants";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: '#fff',
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: '#fff',
      backgroundColor: deepPurple[500],
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
    indigo: {
      color: '#fff',
      backgroundColor: indigo[500],
    },
  }));
  
  export default function TranslateLoanRequest(props) {
    const classes = useStyles();
    let color = "";
    switch (props.request) {
      case "LR":
        color = classes.orange;
        break;
      case "CR":
        color = classes.purple;
        break;
      case "ER":
        color = classes.green;
        break;
      case "IP":
        color = classes.indigo;
        break;  
      default:
        break;
    }
    return  <Tooltip 
              title={<React.Fragment>
                        <Typography variant="body1">{REQUEST_TYPE[props.request]}</Typography>
                    </React.Fragment>}>
                    <Avatar className={color}>{props.request}</Avatar>
            </Tooltip>  
  }