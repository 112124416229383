import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, ListItemText, Tooltip, IconButton, debounce } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import { STATUS, TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, formatNumber, BootstrapTooltip, getTimeInIST, format_display_fancy_notime, getProjectStatusBadge } from "../Utils";
import moment from "moment";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SingleDate from "../components/SingleDate";
import { MasterService } from "../Services/MasterService";
import { LoanRequestsService } from "../Services/LoanRequestsService";
import { ROLES } from "../Roles";
import SearchBar from "../components/SearchBar";
import ItemSelection from "../components/ItemSelection";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    searchBar: {
        maxWidth: 180,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop: theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),

    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewLoans(props) {

    const { state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showSnackBar, openNewLoan, openViewLoan } = useContext(AppContext);
    const classes = useStyles();
    const [selectedBranches, setSelectedBranches] = React.useState([]);
    const [selectedSchemes, setSelectedSchemes] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [branches, setBranches] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const statuses = [{ name: 'Active', value: STATUS.ACTIVE }, { name: 'Cancelled', value: STATUS.CANCELLED }, { name: 'Closed', value: STATUS.CLOSED }]
    const [order, setOrder] = React.useState('asc');
    const [schemes, setSchemes] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('pledge_no');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'pledge_no', align: 'justify', numeric: false, disablePadding: false, label: 'Loan No', sortable: true },
        { id: 'start_date', align: 'center', numeric: false, disablePadding: true, label: 'Start Date', sortable: true },
        { id: 'end_date', align: 'center', numeric: false, disablePadding: true, label: 'End Date', sortable: true },
        { id: 'customer_name', align: 'center', numeric: false, disablePadding: true, label: 'Customer', sortable: true },
        { id: 'scheme_name', align: 'center', numeric: false, disablePadding: true, label: 'Scheme', sortable: true },
        { id: 'total_weight', align: 'center', numeric: false, disablePadding: true, label: 'Total Weight', sortable: true },
        { id: 'loan_amount', align: 'right', numeric: true, disablePadding: true, label: 'Loan Amount', sortable: false },
        { id: 'status', align: 'right', numeric: true, disablePadding: false, label: 'Status', sortable: true },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.loan_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.loan_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.loan_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if (currentPageNumber > page) {
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('View Loans');
        showProgressDialog();
        // console.log(state);
        let bL = state.branches.filter(b => b.id !== '-1');
        setBranches(bL);

        if (bL.length === 1) {
            setSelectedBranches(bL);
        }

        MasterService.getSchemesList()
            .then(data => {
                // console.log(data);
                setSchemes(data);
                closeProgressDialog();
                setLoadedMaster(true);
            });
        return () => {
            setTitle('');
        }
    }, [true]);

    // React.useEffect(() => {
    //     if(loadedMaster){
    //         closeProgressDialog();
    //     }
    // }, [loadedMaster]);

    React.useEffect(() => {
        // console.log(generate, loadedMaster)
        if (generate && loadedMaster) {
            const fetch = debounce(() => {
                setCurrentRows([]);
                setLinearLoading(true);
                setCurrentPageNumber(0);
                setTotalItems(0);
                // setToolTitle(undefined);
                if (initialLoading) {
                    showProgressDialog();
                    setInitialLoading(false);
                }

                let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
                let selectedSchemeId = selectedSchemes && selectedSchemes.length > 0 ? selectedSchemes.map(s => s.id).join(',') : null;
                let selectedStatusId = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
                let fc = filter && filter.length > 0 ? filter : '';
                LoanRequestsService.getLoanCount(startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId)
                    .then(data => {
                        setTotalItems(toInt(data.count));
                        setFetchPage(true);
                        if (toInt(data.count) === 0) {
                            setCurrentRows([]);
                        }
                        setLinearLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setLinearLoading(false);
                    });
                setGenerate(false);
            }, 700)
            fetch()
        }
    }, [generate, loadedMaster]);

    const openDialog = (pledgeNo) => {
        openViewLoan(pledgeNo, (shouldRefresh) => {
            if (shouldRefresh) {
                setGenerate(true);
            }
        });
    }

    const handleEdit = (pledgeNo) => {
        openNewLoan(pledgeNo);
    }

    const cancel = (pledgeNo) => {
        showProgressDialog('Cancelling Loan...');
        LoanRequestsService.cancelRequest(pledgeNo)
            .then(data => {
                showSnackBar('Loan Cancelled', 'info');
                closeProgressDialog();
                setGenerate(true);
            })
            .catch(error => {
                console.log(error);
                showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
                    closeConfirmDialog();
                });
                closeProgressDialog();
            })
    }

    const handleCancelLoan = (pledgeNo) => {
        showConfirmDialog(`Confirm Cancel Loan #${pledgeNo} ?`, undefined, () => {
            closeConfirmDialog();
            cancel(pledgeNo);
            // FinanceService.removeTransaction(loan, id)
            // .then(() => {
            //     showSnackBar(`Removed Tranaction`, 'success');
            //     setGenerate(true);
            // })
            // .catch((error) => {
            //     console.log(error);
            //     showSnackBar(`Failed to remove. Please try again or Contact Admin`, 'error');
            // })
        });
    }

    React.useEffect(() => {
        let rows = [];
        if (fetchPage) {
            setLinearLoading(true);
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            let selectedSchemeId = selectedSchemes && selectedSchemes.length > 0 ? selectedSchemes.map(s => s.id).join(',') : null;
            let selectedStatusId = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            LoanRequestsService.getLoans(startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
                .then((data) => {
                    if (data) {
                        data.forEach((element, index) => {
                            let days = element.end_date ? getTimeInIST(element.end_date).endOf('d').diff(getTimeInIST(element.start_date).startOf('d'), 'days') : moment().endOf('d').diff(getTimeInIST(element.start_date).startOf('d'), 'days');
                            rows.push(
                                <TableRow
                                    key={index}
                                    hover
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDialog(element.pledge_no);
                                    }}>
                                    <StyledTableCell scope="row" align="center" padding='none' style={{ width: 20 }}>
                                        {index + 1 + (currentPageNumber * numItemsPerPage)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        <ListItemText
                                            primary={element.pledge_no || ''}
                                            secondary={element.branch_name}
                                            secondaryTypographyProps={{ style: { color: 'red' } }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        {
                                            element.start_date ?
                                                <ListItemText
                                                    primary={getTimeInIST(element.start_date).format(format_display_fancy_notime)}
                                                    secondary={`${days} day${days > 1 ? 's' : ''}`}
                                                    secondaryTypographyProps={{ style: { color: grey[600] } }}
                                                />
                                                : '-'
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        {element.end_date ? getTimeInIST(element.end_date).format(format_display_fancy_notime) : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        <ListItemText
                                            primary={`${element.customer_name} (${element.cust_code})`}
                                            secondary={<span>{element.customer_address1}<br />
                                                {element.customer_address2 && (<span>{element.customer_address2}<br /></span>)}
                                                {element.customer_address3 && (<span>{element.customer_address3}<br /></span>)}
                                                {element.customer_pincode}
                                            </span>}
                                            secondaryTypographyProps={{ style: { color: grey[600] } }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        <ListItemText
                                            primary={element.scheme_name || '-'}
                                            secondary={`Sub Scheme: ${element.sub_scheme_name || '-'}`}
                                            secondaryTypographyProps={{ style: { color: grey[600] } }} />
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        <ListItemText
                                            primary={`${element.total_weight} gms`}
                                            secondary={`Items Count: ${element.item_count}`}
                                            secondaryTypographyProps={{ style: { color: grey[600] } }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
                                        {element.loan_amount && formatNumber(element.loan_amount)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
                                        {element.status ? getProjectStatusBadge(element.status) : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
                                        {
                                            (element.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN)) && (
                                                <BootstrapTooltip arrow title="Edit">
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ marginTop: 8, marginRight: 8 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEdit(element.pledge_no)
                                                        }}>edit</Icon>
                                                </BootstrapTooltip>
                                            )
                                        }
                                        {
                                            (element.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN)) && (
                                                <BootstrapTooltip arrow title="Cancel">
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ marginTop: 8, marginRight: 8 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCancelLoan(element.pledge_no)
                                                        }}>cancel</Icon>
                                                </BootstrapTooltip>
                                            )
                                        }
                                    </StyledTableCell>
                                </TableRow>
                            );
                        });
                    }
                    setCurrentRows(rows);
                    setLinearLoading(false);
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                    closeProgressDialog();
                });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportData = () => {
        showProgressDialog('Generating Report...');
        let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
        let selectedSchemeId = selectedSchemes && selectedSchemes.length > 0 ? selectedSchemes.map(s => s.id).join(',') : null;
        let selectedStatusId = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
        let fc = filter && filter.length > 0 ? filter : '';
        LoanRequestsService.getLoansExport(startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId, orderBy, order, 0, totalItems)
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'View loans.xlsx');
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Failed to export. Please try again later', 'error');
            })
            .finally(() => closeProgressDialog())
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                {
                                    branches.length > 1 && (
                                        <Grid item xs={12} lg={2}>
                                            <ItemSelection
                                                multiple
                                                clearable={true}
                                                label='Entity'
                                                value={selectedBranches}
                                                optionLabel='name'
                                                options={branches}
                                                selected={setSelectedBranches} />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        multiple
                                        clearable={true}
                                        label='Schemes'
                                        value={selectedSchemes}
                                        optionLabel='name'
                                        options={schemes}
                                        selected={setSelectedSchemes} />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        multiple
                                        clearable={true}
                                        label='Status'
                                        value={selectedStatuses}
                                        optionLabel='name'
                                        options={statuses}
                                        selected={setSelectedStatuses} />
                                </Grid>
                                <Grid item>
                                    <div style={{ maxWidth: 170 }}>
                                        <SingleDate
                                            label="From"
                                            date={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setStartDate(date);
                                            }}
                                            onKeyUp={(event) => {
                                                event.preventDefault();
                                                if (event.key === 'Enter') {
                                                    !generate && setGenerate(true)
                                                }
                                            }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ maxWidth: 170, marginLeft: 8 }}>
                                        <SingleDate
                                            label="To"
                                            date={enddate}
                                            minDate={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setEndDate(date);
                                            }}
                                            onKeyUp={(event) => {
                                                event.preventDefault();
                                                if (event.key === 'Enter') {
                                                    !generate && setGenerate(true)
                                                }
                                            }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    {
                                        loadedMaster && (
                                            <div style={{ float: 'right', marginTop: 8, marginRight: 8 }}>
                                                <SearchBar
                                                    className={classes.searchBar}
                                                    hint="Search..."
                                                    searchText={filter}
                                                    onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }} />
                                            </div>
                                        )
                                    }
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <IconButton size="medium" style={{ marginTop: 8 }} aria-label="Refresh" color="primary" onClick={() => { !generate && setGenerate(true) }}>
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={totalItems === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{ marginTop: 8 }} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 &&
                                        <Typography color="error" variant="subtitle2">No Loans</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={{ selectRoot: classes.selectRoot }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}