import * as firebase from "firebase/app";
import 'firebase/firebase-messaging';
import { FCMService } from "./Services/FCMService";
import getMAC from 'getmac'

var firebaseConfig = {
    apiKey: "AIzaSyAHzjg8suF3FkWOaV0AdA9C2pdwdfNOTkY",
    authDomain: "sysbrew-goldloan.firebaseapp.com",
    databaseURL: "https://sysbrew-goldloan.firebaseio.com",
    projectId: "sysbrew-goldloan",
    storageBucket: "sysbrew-goldloan.appspot.com",
    messagingSenderId: "296378501664",
    appId: "1:296378501664:web:241c710d74d2054fb24a45",
    measurementId: "G-HR74G5KZ51"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaginIsSupported = firebase.messaging.isSupported();

const messaging = messaginIsSupported ? firebase.messaging() : undefined;

messaging && messaging.usePublicVapidKey("BG9PPvZUOZgCALw-5amOnafpJQ-grPFiQopEDOKJk1ehI-sHEPO9ELg63CfA1iIofb5c4oDJiFDyi12sEsZwwTc");

messaginIsSupported && messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
        console.log('Token refreshed.');
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        setTokenSentToServer(false);
        // Send Instance ID token to app server.
        let user = JSON.parse(localStorage.getItem('geomoney.user'));
        if(null != user){
            sendTokenToServer(refreshedToken, user);
        }
        // ...
    }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
        // showToken('Unable to retrieve refreshed token ', err);
    });
});

// messaging.onMessage((payload) => {

//     console.log('Message received. ', payload);
// });

const requestToken = (user) => {
    if(messaginIsSupported){
        if (!isTokenSentToServer()) {
            messaging.getToken()
            .then((currentToken) => {
                if (currentToken) {
                    sendTokenToServer(currentToken, user);
                    // updateUIForPushEnabled(currentToken);
                } else {
                    // Show permission request.
                    console.log('No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    setTokenSentToServer(false);
                }
            })
            .catch((err) => {
                console.log("Unable to get permission to notify.", err);
            });
        }
    }
}

const sendTokenToServer = (token, user) => {
    if(messaginIsSupported){
        let mac = "test";
        try{
            mac = getMAC();
        } catch(err){
            console.log(err);
        }
        let header = {
            "device-id": mac,
            "user_id": user.id,
            "mobile-no": user.mobile_no
        };
        let body  = {token: token};

        FCMService.register(header, body)
        .then(data => {
            setTokenSentToServer(true);
        })
        .catch(err => console.log(err));
    }
}

const unregister = (user) => {
    if(messaginIsSupported){
        if (isTokenSentToServer()) {
            let mac = "test";
            try{
                mac = getMAC();
            } catch(err){
                console.log(err);
            }
            let header = {
                "device-id": mac,
                "user_id": user.id,
                "mobile-no": user.mobile_no
            };
            let body  = {};

            FCMService.unregister(header, body)
            .then(data => {
                setTokenSentToServer(false);
            })
            .catch(err => console.log(err)); 
        }
    }
}

function isTokenSentToServer() {
    return window.localStorage.getItem('geomoney.sentToServer') === '1';
  }

const setTokenSentToServer = (sent) => {
    window.localStorage.setItem('geomoney.sentToServer', sent ? '1' : '0');
}

export { messaging, sendTokenToServer, requestToken, unregister, messaginIsSupported };