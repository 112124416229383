import React from 'react'
import moment from "moment"
import { FinanceService } from "../Services/FinanceService"
import { formatNumber } from "../Utils"

export const CheckBalanceAndSave = (ledgerId, branchId, requestedAmount) => {
    return FinanceService.getOpeningBalance(ledgerId, branchId, moment().add(1, 'd'))
        .then(data => {
            const balance = parseFloat(data.balance || 0)
            if (balance < parseFloat(requestedAmount)) {
                return Promise.resolve({
                    sufficientBalance: false,
                    message: (
                        <div>
                            Insufficient funds to dispense. Current Balance <span style={{ fontWeight: 'bold', color: 'red' }}>{formatNumber(balance)}</span>. Please check balance before proceeding
                        </div>
                    )
                })
            } else {
                return Promise.resolve({
                    sufficientBalance: true,
                    message: (
                        <div>
                            Sufficient funds to dispense
                        </div>
                    )
                })
            }
        })
}