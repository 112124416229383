import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Fab, Tooltip, IconButton, Icon } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import { FinanceService } from "../Services/FinanceService";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import NewLedgerDialog from "./NewLedgerDialog";
import ItemSelection from "../components/ItemSelection";
import SearchBar from "../components/SearchBar";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function Ledger(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, showSnackBar, closeConfirmDialog} = useContext(AppContext);
    const classes = useStyles();
    // const theme = useTheme();
    const [filter, setFilter] = React.useState('');
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('name');
    const order = 'asc';
    const orderBy = 'name';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(null);
    const [branches, setBranches] = React.useState([]);
    const [selectedBranches, setSelectedBranches] = React.useState('');
    const [ledgerGroups, setLedgerGroups] = React.useState([]);
    const [selectedLedgerGroup, setSelectedLedgerGroup] = React.useState('');
    const [loadedMaster, setLoadedMaster] = React.useState(false); 

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'name', align: 'justify', numeric: false, disablePadding: true, label: 'Ledger Name', sortable: false },
        { id: 'group_name', align: 'justify', numeric: false, disablePadding: true, label: 'Group', sortable: false },
        { id: 'branch_name', align: 'justify', numeric: false, disablePadding: true, label: 'Entity', sortable: false },
        { id: '', numeric: false, disablePadding: true, label: '', sortable: false  }
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.ledger_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.ledger_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.ledger_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Manage Ledger');
        setBranches(state.branches);
        setSelectedBranches(state.branches[0]);
        FinanceService.getLedgerGroups()
        .then((data) => {
            if(data && data.length > 0 ){
                data.splice(0, 0, {
                    name: "All Groups",
                    id: "-1",
                    parent_id: null
                });
                setLedgerGroups(data);
                setSelectedLedgerGroup(data[0]);
                setLoadedMaster(true);
            }
            setGenerate(true);
        })
        .catch(error => {
            console.log(error);
            setGenerate(true);
        });
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setCurrentRows([]);
            setLinearLoading(true);
            let f = filter.length > 0 ? filter : null;
            if(!f){
                showProgressDialog();
            }
            setCurrentPageNumber(0);
            let branch = null;
            if(selectedBranches && selectedBranches.id !== '-1'){
                branch = selectedBranches.id;
            }
            let groupId = null;
            if(selectedLedgerGroup && selectedLedgerGroup.id !== '-1'){
                groupId = selectedLedgerGroup.id;
            }
            setTotalItems(0);
            FinanceService.getLedgerCount(groupId, branch, f)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                    closeProgressDialog();
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && totalItems > 0) {
            setLinearLoading(true);
            let branch = null;
            if(selectedBranches && selectedBranches.id !== '-1'){
                branch = selectedBranches.id;
            }
            let groupId = null;
            if(selectedLedgerGroup && selectedLedgerGroup.id !== '-1'){
                groupId = selectedLedgerGroup.id;
            }
            let f = filter.length > 0 ? filter : null;
            FinanceService.getLedgers(groupId, branch, f, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                // console.log(data);
                data.forEach((element, index) => {
                    rows.push(
                        <TableRow key={index} hover>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                {(currentPageNumber * numItemsPerPage) + index + 1}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="justify" padding='none'>
                                {element.name}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="justify" padding='none'>
                                {element.group_name}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="justify" padding='none'>
                                {element.branch_name}
                            </StyledTableCell>
                            <StyledTableCell padding="none" align="right" style={{marginRight: 10}}>
                                {
                                    !element.has_transactions && (
                                        <Tooltip title="Remove">
                                            <IconButton aria-label="Remove" onClick={removeLedger(element.id)}>
                                                <Icon fontSize="small">delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                <Tooltip title="Edit">
                                    <IconButton aria-label="Edit" onClick={newLedger(element.id)}>
                                        <Icon fontSize="small">edit</Icon>
                                    </IconButton>
                                </Tooltip>
                            </StyledTableCell>
                        </TableRow>
                    );
                });
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        
    }

    const newLedger = (id) => e => {
        setOpenDialog({open: true, id});
    }

    const removeLedger = id => e => {
        showConfirmDialog('Confirm remove ledger?', undefined, () => {
            closeConfirmDialog();
            FinanceService.removeLedger(id)
            .then(data => {
                if(data.success){
                    showSnackBar(data.message, 'info');
                    setGenerate(true);
                } else {
                    showSnackBar(data.message, 'error');
                }
            })
            .catch(error => {
                console.error(error);
                showSnackBar('Something went wrong while removing, Please try again', 'error');
            })
        })
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={6} lg={8} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid container item xs={12} lg={12}>
                                    <Grid item xs={6} lg={3}>
                                        {
                                            loadedMaster && state.branches && state.branches.length > 0 && (
                                                <ItemSelection 
                                                    required 
                                                    clearable={false} 
                                                    label='Select Entity' 
                                                    value={selectedBranches} 
                                                    optionLabel='name' 
                                                    options={branches} 
                                                    selected={(branch) => {
                                                        setSelectedBranches(branch);
                                                        setGenerate(true);
                                                    }}/>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={6} lg={3}>
                                        {
                                            loadedMaster && (
                                                <ItemSelection 
                                                    clearable={true} 
                                                    required 
                                                    label='Ledger Group' 
                                                    value={selectedLedgerGroup} 
                                                    optionLabel='name' 
                                                    options={ledgerGroups} 
                                                    selected={(group) => {
                                                        setSelectedLedgerGroup(group);
                                                        setGenerate(true);
                                                    }}/>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        {
                                            loadedMaster && (
                                                <div style={{float: 'right', marginTop: 16, marginRight: 8}}>
                                                    <SearchBar 
                                                        hint="Search..."
                                                        searchText={filter}
                                                        onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }}/>
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, maxHeight: 400, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Ledgers</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <BootstrapTooltip title="Create Ledger">
                    <Fab onClick={newLedger(null)} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </BootstrapTooltip>
                <NewLedgerDialog 
                    open={(openDialog && openDialog.open) || false} 
                    id={openDialog && openDialog.id}
                    branch_id={selectedBranches && selectedBranches.id} 
                    close={() => {
                        setOpenDialog(null);
                    }} 
                    {...props} 
                    save={() => setGenerate(true)}/>
            </div>
        </div>
    )
}