import Config from '../Config';
import { RestService } from './RestService';

export const OrganisationService = {
    getOrganisation: async function(){
        return RestService.get(Config.get_organisation_master_url, {})
        .then(response => response.json());
    },
    updateOrganisation: async function(headers, body){
        return RestService.post(Config.update_organisation_master_url, headers, body)
        .then(response => response.json());
    },
    enableCustomerNotification: async function(id, enabled, headers = {}){
        return RestService.post(Config.enable_customer_sms.replace("{id}", id).replace("{status}", enabled), headers, {})
        .then(response => response.json());
    },
};