import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import {
    IconButton,
    Tooltip,
    Icon,
    Paper,
    withStyles,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
} from "@material-ui/core";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import Config from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { ManagementService } from "../Services/ManagementService";
import {AppContext} from '../AppContextProvider';
import { LoanRequestsService } from "../Services/LoanRequestsService";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(0),
        }
    },
    message: {
        padding: 20
    }
  });

class ServiceableAreas extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_area: { pincode: "", description: ""},
        show: false,
        columns : [
            { id: 'index', numeric: false, disablePadding: true, label: 'Sl.No', sortable: false },
            { id: 'pincode', numeric: false, disablePadding: true, label: 'Pincode', sortable: false },
            { id: 'description', numeric: false, disablePadding: true, label: 'Description' , sortable: false },
            { id: '', numeric: false, disablePadding: false, label: '', sortable: false  }
        ],
        order: 'asc',
        orderBy: 'pincode',
        filter: '',
        pinError: undefined,
        error: false
      }
      
      this.createModifyArea = this.createModifyArea.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
 
    componentDidMount(){
        const {setTitle} = this.context;
        setTitle("Manage Serviceable Areas");
        this.refresh();
    }

    refresh = () => {
        this.fetchServiceablePincodes();
    }

    fetchServiceablePincodes = () => {
        const {setLinearLoading} = this.context;
        setLinearLoading(true);
        ManagementService.getServiceableAreas()
        .then((data) => {
            // console.log(data);
            this.updateRows(data);
            this.setState({data, originalData: data});
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        })

    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_area: { pincode: "", description: ""}
        });
    }

    sortBy = (event, property) => {
    }

	filterCriteria = (e) => {
        const {isLoading} = this.context;
        isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        
        let filteredList = this.state.originalData.filter(data => data.pincode.toLowerCase().includes(searchText.toLowerCase()) || data.description.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Area'
        });
        this.updateRows(filteredList);
        isLoading(false);
    }
    
    updateRows(data){
        let rows = [];
        data.forEach((area, index) => {
            rows.push(<AreaDetails index={index + 1} key={index + 1} area={area} classes={this.props.classes} remove={this.remove} edit={this.edit}/>);
        });
        this.setState({rows: rows});
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_area;
        change[e.target.name] = e.target.value;
        if(e.target.name === 'pincode'){
            if(this.state.originalData.find(d => d.pincode === e.target.value) || e.target.value === '' || !e.target.value){
                this.setState({error: true, pinError: e.target.value === '' || !e.target.value ? "Pincode cannot be empty" : "Pincode already available"});
            } else {
                this.setState({error: false, pinError: undefined});
            }
        } 
        this.setState(change);
    }

    createModifyArea(e){
        const {showConfirmDialog, closeConfirmDialog, showSnackBar} = this.context;
        e.preventDefault();
        if(this.state.new_area.pincode && this.state.new_area.pincode.length >= 6 && this.state.new_area.description.length > 5){
            showConfirmDialog("Confirm?", undefined, () => {
                this.createAPI(this.state.new_area);
                closeConfirmDialog();
            });
        } else {
            showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, area){
        const {showConfirmDialog, showProgressDialog, closeProgressDialog, closeConfirmDialog, showSnackBar} = this.context;
        e.preventDefault();
        showProgressDialog('Checking for Loan requests...');
        LoanRequestsService.getCountByPincode(area.pincode)
        .then(data => {
            closeProgressDialog();
            showConfirmDialog(`${data.count > 0 ? `There are Loan requests for this Pincode. ` : ''}Confirm removal of service area?`, undefined, () => {
                this.removeAPI(this.dialog, area.pincode);
                closeConfirmDialog();
            });
        })
        .catch(error => {
            closeProgressDialog();
            showSnackBar('Something went wrong. Please try again or reach out to your Admin');
        }) 
    }

    removeAPI(dialog, pincode){
        const {showSnackBar, showConfirmDialog} = this.context;
        ManagementService.removeServiceableArea(pincode)
        .then((data) => {
            this.refresh();
            showSnackBar(`Removed Service Area`, 'success');
        })
        .catch((error) => {
            showConfirmDialog("Could not remove Service area. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, area){
        e.preventDefault();
        if(area){
            let new_area = Object.assign({}, area);
            this.setState({new_area}, () => this.handleShow());
        } else {
            this.handleShow();
        }
    }

    createAPI(new_area){
        const {showConfirmDialog, showSnackBar, setLinearLoading, state: s} = this.context;
        //new_area.code = 1234;
        let body = new_area;
        setLinearLoading(true);
        let headers = {user_id : s.user.id };
        ManagementService.createServiceableArea(headers, body)
        .then((response) => {
            this.setState({
                new_area: { pincode: "", description: ""}
            });
            this.refresh();
            showSnackBar(`Updated Service Area`, 'success');
            if(this.state.show) this.handleClose();
            setLinearLoading(false);
        })
        .catch((error) => {
            setLinearLoading(false);
            showConfirmDialog(`Failed to create Service Area. Please try again.`, undefined, undefined);
        });
        
    }

    render() {
        let {classes} = this.props;
        let {rows, message, data} = this.state;
        return (
            <div className="Home">
                <div>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={10}>
                            <Paper className={classes.root}>
                                <EnhancedTableToolbar title={<Typography variant="h5">{`Service Areas: ${data.length}`}</Typography>} refresh={this.refresh} searchHint="Pincode or Description" onSearchTextChange={this.filterCriteria}/>
                                <Table className={classes.table} size="small">
                                    <EnhancedTableHead
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.sortBy}
                                        rows ={this.state.columns} />
                                    <TableBody>
                                        {rows}
                                    </TableBody>
                                </Table>
                                <div className={classes.message}>
                                    {message}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <Dialog disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'
                    style={{marginTop : "5%"}}>
                    <DialogTitle id="alert-dialog-title">{this.state.show ? "User" : ""}</DialogTitle>
                    <DialogContent style={{paddingBottom: 20}}>
                        {this.areaForm( "Edit Service Area", this.createModifyArea, "Save")}
                    </DialogContent>
                </Dialog>
                <Tooltip title="Add Service Area">
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={this.props.classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    areaForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="pincode"
                label="Pin Code"
                placeholder="Pin Code"
                name="pincode"
                defaultValue={this.state.new_area.pincode}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                error={this.state.error}
                helperText={this.state.pinError}
                required
                fullWidth
                pattern="[0-9]{10}"
                type="number"
                InputProps={{
                    inputProps: { min: 0 }
                }}
                />
            <TextField
                id="description"
                label="Description"
                placeholder="Description"
                name="description"
                defaultValue={this.state.new_area.description}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <Button variant="outlined" color="primary" type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
            {this.state.show ? (<Button variant="outlined"  color="secondary" onClick={this.handleClose}>Close</Button>): ""}
        </Form>
    )

}

class AreaDetails extends Component {

    openGoogle(e, pincode){
        e.preventDefault();
        window.open(Config.google_url+pincode, "_blank");
    }

    render(){
      const area = this.props.area;
      const index = this.props.index;
        return (
            <TableRow key={area.pincode}>
                <TableCell scope="row" align="center">{index}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{cursor: "pointer"}}>
                    <Typography variant="inherit" onClick={(e) => this.openGoogle(e, area.pincode)}>
                        {area.pincode} 
                    </Typography>
                </TableCell>
                <TableCell align="center">{area.description ? area.description : "-"}</TableCell>
                <TableCell align="right">
                    <Tooltip title="Edit">
                        <IconButton size="small" aria-label="Edit" onClick={(e) => this.props.edit(e, area)}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton size="small" aria-label="Remove" onClick={(e) => this.props.remove(e, area)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(ServiceableAreas);