import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import {
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Container,
  List,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import { AppContext } from './AppContextProvider';
import { ROLES } from './Roles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ListIcon from '@material-ui/icons/List';
import AssessmentIcon from '@material-ui/icons/Assessment';
import theme from './theme';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      sectionDesktop: {
        display: 'flex',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuIcon: {
        minWidth: 32
      },
      subMenuTitle: {
        // paddingLeft: 32, 
        paddingTop: 0, 
        paddingBottom: 0
      },
      subMenuItem: {
        color: 'black',
        // paddingTop: 0, 
        // paddingBottom: 0
      },
      nested: {
        paddingLeft: 48, 
        // color: 'black',
        paddingTop: 0, 
        paddingBottom: 0,
      },
      link: {
        textDecoration: 'none !important', 
        color: 'black' 
      },
}));

export default function AppDrawer(props) {

    const classes = useStyles();
    const {state, handleDrawerClose, openNewLoan} = useContext(AppContext);

    const [openDrawer, setOpenDrawer] = React.useState({
        accounts: false,
        loans: false,
        tools: false,
        finance: false,
        masters: false,
        reports: false,
    })

    const handleClick = (type) => {
        // openDrawer[type] = !openDrawer[type];
        setOpenDrawer(openDrawer => ({...openDrawer, [type]: !openDrawer[type]}))
    }

    return (
        <Drawer
            anchor="left"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: state.open,
                [classes.drawerClose]: !state.open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                }),
            }}
            open={state.open}
            onClose={handleDrawerClose}
        >
        {(state.user.roles.includes(ROLES.ADMIN) || 
            state.user.roles.includes(ROLES.EXECUTIVE) || 
            state.user.roles.includes(ROLES.EMPLOYEE) || 
            state.user.roles.includes(ROLES.MANAGER) || 
            state.user.roles.includes(ROLES.COORDINATOR))  &&
            <div style={{height: '100vh', overflowY: 'auto'}}>
                <div className={classes.toolbar}>
                    <div style={{ float: "left", width: "100%", marginLeft: 2 }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/icon.png"}
                            alt="icon"
                            height={40}
                        />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                        {state.isAuthenticated && (
                            <section className={classes.sectionDesktop}>
                                {state.user.name}
                            </section>
                        )}
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div style={{ height: "83vh", overflow: "auto" }}>
                    <Divider />
                    <Link
                        to="/"
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={handleDrawerClose}
                    >
                        <ListItem button key="Dashboard">
                            <ListItemIcon className={classes.menuIcon}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Dashboard</Typography>}
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/accounts/customer" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="Manage Customers">
                            <ListItemIcon className={classes.menuIcon}>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        Manage Customers
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    {/* <Link to="/pledge" style={{ textDecoration: "none", color: "black" }} > */}
                        <ListItem 
                            button 
                            key="New Gold Loan" 
                            onClick={(e) => {
                                handleDrawerClose();
                                openNewLoan();
                            }}>
                            <ListItemIcon className={classes.menuIcon}>
                                <AccountBalanceWalletIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        New Gold Loan
                                    </Typography>
                                }
                            />
                        </ListItem>
                    {/* </Link> */}
                    <Divider />
                    <Link to="/loans" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="View Gold Loans">
                            <ListItemIcon className={classes.menuIcon}>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        View Gold Loans
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    {
                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("reports")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <AssessmentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Reports
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["reports"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["reports"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/pledge/outstanding" className={classes.link}>
                                            <ListItem button key="Pledge Outstanding" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Pledge Outstanding</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/pledge/outstanding/datewise" className={classes.link}>
                                            <ListItem button key="Pledge Outstanding Datewise" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Pledge Outstanding Datewise</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/pledge/release" className={classes.link}>
                                            <ListItem button key="Pledge Release" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Pledge Release</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/pledge/risk" className={classes.link}>
                                            <ListItem button key="Pledge Risk" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Pledge Risk</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/pledge/due" className={classes.link}>
                                            <ListItem button key="Pledge Due" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Pledge Due</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/form/g" className={classes.link}>
                                            <ListItem button key="KML Form G" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">KML Form G</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/report/loyalty/analysis" className={classes.link}>
                                            <ListItem button key="Loyalty Cost Analysis" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Loyalty Cost Analysis</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    {
                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("finance")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <AccountBalanceIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Finance
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["finance"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["finance"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/ledger" className={classes.link}>
                                            <ListItem button key="Manage Ledgers" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Manage Ledgers</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/transaction/cash" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Create Transaction"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Create Transaction
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/report/cash/book" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Cash Book"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Cash Book
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/report/bank/book" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Bank Book"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Bank Book
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/report/journal/book" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Journal Book"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Journal Book
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/report/day/book" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Day Book"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Day Book
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    {
                                        (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) &&
                                        <Link to="/finance/accounts/ledger/report" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Ledger Report"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Ledger Report
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    {
                        (state.user.roles.includes(ROLES.ADMIN)) &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("accounts")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Users
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["accounts"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["accounts"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                    {
                                        state.user.roles.includes(ROLES.ADMIN) &&
                                        <Link to="/accounts/employee" className={classes.link}>
                                            <ListItem button key="Manage users" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">Manage users</Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    { 
                        false && 
                        <List className={classes.subMenuTitle}>
                            <ListItem button onClick={(e) => handleClick("loans")}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            className="sub-menu"
                                        >
                                            Loan Requests
                                        </Typography>
                                    }
                                />
                                {openDrawer["loans"] ? (
                                    <ExpandLess />
                                ) : (
                                        <ExpandMore />
                                    )}
                            </ListItem>
                            <Collapse
                                in={openDrawer["loans"]}
                                timeout="auto"
                                unmountOnExit
                                className={classes.subMenuItem}
                            >
                                <List
                                    component="div"
                                    disablePadding
                                    onClick={handleDrawerClose}
                                >
                                    <Link to="/loan/requests" className={classes.link}>
                                        <ListItem
                                            button
                                            key="View Loan Requests"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        View Loan Requests
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </List>
                    }
                    {
                        state.user.roles.includes(ROLES.ADMIN)  &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("masters")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Masters
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["masters"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["masters"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                    { 
                                        false &&
                                        <Link to="/tools/manage/servicearea" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Serviceable Areas"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Serviceable Areas
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                        <Link to="/tools/manage/branches" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Entities"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Entities
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/tools/manage/schemes" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Schemes"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Schemes
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/tools/manage/items" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Items"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Items
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/tools/manage/kyc" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage KYC Documents"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage KYC Documents
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    {
                        state.user.roles.includes(ROLES.ADMIN)  &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("tools")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Tools
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["tools"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["tools"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        {/* <Link to="/tools/manage/notification" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Notifications"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Notifications
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link> */}
                                        {/* <Link to="/tools/manage/workdays" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Workdays"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Workdays
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link> */}
                                        <Link to="/tools/manage/organisation" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Organisation Information"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Organisation Information
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/tools/manage/jobs" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Background Jobs"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Background Jobs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                </div>
            </div>
            }
                <div>
                    <Container maxWidth="sm" style={{ position: "absolute", bottom: 0, height: 60, paddingBottom: 10, paddingTop: 10, backgroundColor: theme.palette.primary.main, color: theme.palette.common.white}}>
                        <Typography variant="subtitle2" style={{ textAlign: "center", fontSize: 10 }} color="inherit">
                            Version: {global.appVersion}
                            <React.Fragment>
                                <br />
                                    Powered by Sysbrew
                            </React.Fragment>
                        </Typography>
                    </Container>
                </div>
        </Drawer>
    );
}
