import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { MasterService } from '../Services/MasterService';
import { UserService } from '../Services/UserService';
import { Grid, TextField } from '@material-ui/core';
import ItemSelection from './ItemSelection';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    dialogContent: {
        paddingTop: 0
    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{paddingRight: 24}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UploadKYCDialog(props) {
    const {closeProgressDialog, showProgressDialog, showSnackBar} = useContext(AppContext);
    const {subtitle, customerId} = props;
    const [open, setOpen] = React.useState(false);    
    const [submitted, setSubmitted] = React.useState(false);    
    const [title, setTitle] = React.useState(null);
    const [kycDocs, setKYCDocs] = React.useState(null);
    const [kyc, setKyc] = React.useState({kyc : null});
    const classes = useStyles();
    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    React.useEffect(() => {
        if(props.open){
            setOpen(props.open);
            setTitle(props.title);
            showProgressDialog();
            fetchKYCMaster();
            setKyc(old => ({...old, customerId: customerId}));
        } else {
          setOpen(props.open);
        }
    }, [props.open]);

    const fetchKYCMaster = () => {
      MasterService.getKYCDocsList([1].join(','))
      .then(data => {
          setKYCDocs(data);
          closeProgressDialog();
      })
      .catch(error => {
        console.log(error);
        closeProgressDialog();
      });
    }
 
    const handleClose = (refresh = false) => {
        props.close(refresh);
        setTitle(null);
        setOpen(false);
    };

    const handleSave = () => {
      setSubmitted(true);
      showProgressDialog('Uploading...');
      const formData = new FormData();
      formData.append('file', kyc.file, kyc.file.name);
      formData.append('kyc_id', kyc.kyc.id);
      formData.append('identifier', kyc.identifier);
      UserService.uploadKYC(customerId, formData)
      .then(data => {
          if(data.success){
              showSnackBar('KYC Uploaded', 'info');
              handleClose(true);
          } else {
              showSnackBar('Failed to upload. Please try again later', 'error');
          }
          closeProgressDialog();
          setSubmitted(false);
      })
      .catch(error => {
          console.log(error);
          showSnackBar('Failed to upload. Please try again later', 'error');
          closeProgressDialog();
          setSubmitted(false);
      })
    }

    const handleChange = e => {
      e.preventDefault();
      let value = e.target.value;
      setKyc(oldkyc => ({...oldkyc, identifier: value}));
    }

    return (
        <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ?  props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
              <Typography variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="overline" component="div">
                {subtitle}
              </Typography>
            </DialogTitle>
            <DialogContent dividers style={{paddingTop: 0, }}>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <ItemSelection
                      clearable={true} 
                      required 
                      label='KYC Document Type'
                      value={kyc.kyc} 
                      optionLabel='name'
                      options={kycDocs} 
                      style={{marginLeft: 0}}
                      selected={(kyc) => {
                          setKyc(oldkyc => ({...oldkyc, kyc: kyc}));
                      }}/>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                        id="identifier"
                        label="Identifier"
                        placeholder="Identifier"
                        name="identifier"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        style={{marginLeft: 8, paddingRight: 10}}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                      <Button style={{marginLeft: 8, marginRight: 8, marginTop: 8}} color="secondary" variant="outlined" size="small" onClick={handleClick}>
                        Select Image
                      </Button>
                      <input 
                        ref={hiddenFileInput} 
                        style={{display:'none'}}
                        accept="image/*" 
                        type="file" 
                        onChange={(event) => {
                          setKyc(oldkyc => ({...oldkyc, file: event.target.files[0]}));
                        }}/>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {
                      kyc.file && 
                          <Typography style={{marginLeft: 8, marginTop: 12}}>
                              1 File selected
                          </Typography>
                    }
                  </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button autoFocus disabled={submitted} onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UploadKYCDialog.prototype = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    maxWidth: PropTypes.any,
    fullWidth: PropTypes.bool
};