import Config from '../Config';
import {RestService} from './RestService';
import {format} from '../Utils';
import moment from 'moment';

export const MasterService = {
  fetchBackgroundJobs: async function(headers = {}){
      return RestService.get(Config.list_background_jobs, headers)
      .then(response => response.json());
  },
  fetchBackgroundJobsStatus: async function(type = null, headers = {}){
      return RestService.get(Config.background_job_status.replace("{type}", type), headers)
      .then(response => response.json());
  },
  enableJob: async function(jobId, enabled, headers = {}){
      return RestService.post(Config.enable_job_status.replace("{id}", jobId).replace("{status}", enabled), headers, {})
      .then(response => response.json());
  },
  getItemsList: async function(status = null){
      return RestService.get(`${Config.get_items}${status ? `status=${status}`: ''}`, {})
      .then(response => response.json());
  },
  saveItem: async function(body = {}, headers = {}){
      return RestService.post(Config.save_item, headers, body)
      .then(response => response.json());
  },
  toggleItemStatus: async function(id) {
      return RestService.get(Config.toggle_item_status.replace("{id}", id), {})
      .then(response => response.json())
  },
  removeItem: async function(id) {
      return RestService.delete(Config.remove_item.replace("{id}", id), {})
      .then((response) => {
          if (response.status === 204) {
            return {success: true};
          } else if (
            response.status === 404 ||
            response.status === 409 ||
            response.status === 403
          ) {
            return response.json();
          }
      });
  },
  getKYCDocsList: async function(status = null){
      return RestService.get(`${Config.get_kyc_docs}${status ? `status=${status}`: ''}`, {})
      .then(response => response.json());
  },
  saveKYCDoc: async function(body = {}, headers = {}){
      return RestService.post(Config.save_kyc_doc, headers, body)
      .then(response => response.json());
  },
  toggleKYCDocStatus: async function(id) {
      return RestService.get(Config.toggle_kyc_doc_status.replace("{id}", id), {})
      .then(response => response.json())
  },
  removeKYCDoc: async function(id) {
      return RestService.delete(Config.remove_kyc_doc.replace("{id}", id), {})
      .then((response) => {
          if (response.status === 204) {
            return {success: true};
          } else if (
            response.status === 404 ||
            response.status === 409 ||
            response.status === 403
          ) {
            return response.json();
          }
      });
  },
  getSchemesList: async function(startdate = null, enddate = null){
      return RestService.get(`${Config.get_schemes}${startdate ? `startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}`, {})
      .then(response => response.json());
  },
  saveScheme: async function(body = {}, headers = {}){
      return RestService.post(Config.save_scheme, headers, body)
      .then(response => response.json());
  },
  removeScheme: async function(id) {
      return RestService.delete(Config.remove_scheme.replace("{id}", id), {})
      .then((response) => {
          if (response.status === 204) {
            return {success: true};
          } else if (
            response.status === 404 ||
            response.status === 409 ||
            response.status === 403
          ) {
            return response.json();
          }
      });
  },
  updateSchemeGoldRate: async function(id, rate){
    return RestService.post(Config.update_scheme_gold_rate.replace("{id}", id).replace("{rate}", rate), {})
            .then(response => response.json());
  },
  getFYList: async function(){
      return RestService.get(Config.get_fys, {})
      .then(response => response.json());
  },
};