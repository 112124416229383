import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from  'clsx';
import { Grid, Tooltip, Icon, colors, Typography, Table, TableBody, TableRow, Fab, Paper, IconButton } from "@material-ui/core";
import { StyledTableCell, BootstrapTooltip } from "../Utils";
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import EnhancedTableHead from "../components/EnhancedTableHead";
import AddIcon from '@material-ui/icons/Add';
import SearchBar from "../components/SearchBar";
import NewItemDialog from './NewItemDialog';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    formControl: {
    },
    customToolTip: {
        fontSize: 16
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.blue[900]
        }
    },
    actions: {
        float: 'right',
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function ItemMaster(props) {
    const classes = useStyles();
    const { setTitle, showSnackBar, showConfirmDialog, closeConfirmDialog, setLinearLoading, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const [items, setItems] = React.useState([]);
    const order = 'asc';
    const orderBy = 'name';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');

    //Dialog
    const [item, setItem] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const columns = [
        { id: 'index', align: 'center', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'name', align: 'justify', numeric: false, disablePadding: true, label: 'Name', sortable: false },
        { id: 'description', align: 'justify', numeric: false, disablePadding: true, label: 'Description', sortable: false },
        { id: 'status', align: 'justify', numeric: false, disablePadding: true, label: 'Status', sortable: false },
        { id: 'action', align: 'justify', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    const statuses = [0,1];

    React.useEffect(() => {
        setTitle("Manage Items");
    }, [true]);

    React.useEffect(() => {
        if(generate){
            showProgressDialog();
            MasterService.getItemsList(statuses.join(','))
            .then(data => {
                // console.log(data);
                setItems(data);
                updateRows(data);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate]);

    const updateRows = (list) => {
        let rows = [];
        list.forEach((element, index) => {
            rows.push(
                <TableRow key={index} hover>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {index + 1}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="justify" padding='none'>
                        {element.name}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="justify" padding='none'>
                        {element.description}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="justify" padding='none'>
                        {element.status === '1' ? 'Active' : 'Deactivated'}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none'>
                        <Tooltip title="Activate/Deactivate">
                            <IconButton size="small" aria-label="Activate/Deactivate" onClick={(e) => toggleStatus(element)}>
                                <Icon style={{fontSize: 18}}>power_settings_new</Icon>
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton size="small" aria-label="Edit" onClick={(e) => edit(element)}>
                                    <Icon style={{fontSize: 18}}>edit</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove">
                                <IconButton size="small" aria-label="Remove" onClick={(e) => remove(element)}>
                                    <Icon style={{fontSize: 18}}>delete</Icon>
                                </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                </TableRow>
            );
        });
        setCurrentRows(rows);
    }

    const filterCriteria = (e) => {
        setLinearLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        
        let filteredList = items.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()) || data.description.includes(searchText));
        if(searchText.length === 0){
            filteredList = items;
        }
        setMessage(filteredList.length > 0 ? '' : 'No matching Item');
        updateRows(filteredList);
        setLinearLoading(false);
    }

    const newItem = (item) => e => {
        setItem(item);
        setDialogOpen(true);
    }

    const edit = item => {
        setItem(item);
        setDialogOpen(true);
    }

    const remove = item => {
        showConfirmDialog("Confirm removal of Item?", undefined, () => {
            closeConfirmDialog();
            MasterService.removeItem(item.id)
            .then((data) => {
                if(data.success){
                    showSnackBar(`Removed Item`, 'info');
                    setGenerate(true);
                } else {
                    showConfirmDialog(data.error, undefined, undefined);
                }
            })
            .catch((error) => {
                showConfirmDialog("Could not remove item. Please try again.", undefined, undefined);
            });
        });
    }

    const toggleStatus = item => {
        MasterService.toggleItemStatus(item.id)
        .then((data) => {
            const status = parseInt(data.status) === 0 ? "Deactivated": "Activated";
            showSnackBar(`${status} item`, 'info');
            setGenerate(true);
        })
        .catch(error => console.log(error));
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={6} lg={6} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={12}>
                                    <div style={{float: 'right', marginTop: 16, marginRight: 8}}>
                                        <SearchBar 
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                            setFilter(e.target.value);
                                            filterCriteria(e);
                                        }}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={() => {}}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        items === 0 && 
                                            <Typography color="error" variant="subtitle2">No Items</Typography>
                                    }
                                    {
                                        message.length > 0 && 
                                            <Typography color="error" variant="subtitle2">{message}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <BootstrapTooltip title="Add Item">
                                <Fab onClick={newItem(null)} color="primary" aria-label="Add" className={classes.fab}>
                                    <AddIcon />
                                </Fab>
                            </BootstrapTooltip>
                        </Paper>  
                    </Grid>
                </Grid>      
                <NewItemDialog
                    item={item}
                    open={dialogOpen} 
                    close={() => {
                        setItem(null);
                        setDialogOpen(false);
                    }}
                    save={()=> {
                        setGenerate(true);
                    }}/>
            </div>
        </div>
    );
}
 