import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Button, Divider, Icon, Tooltip, IconButton } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { TABLE_COUNTS, TXN_TYPE, PAYMENT_TYPE } from "../Constants";
import { RowCountOptions } from "../Config";
import { FinanceService } from "../Services/FinanceService";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, format_display, formatNumber, BootstrapTooltip } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SingleDate from "../components/SingleDate";
import JournalTransactionDetails from "./JournalTransactionDetails";
import TransactionDetails from "./TransactionDetails";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function DayBookReport(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showSnackBar} = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const [selectedBranches, setSelectedBranches] = React.useState([]);
    // const [toolTitle, setToolTitle] = React.useState(undefined);
    // const [filter, setFilter] = React.useState('');
    const [branches, setBranches] = React.useState([]);
    // const [amountGTE, setAmountGTE] = React.useState(undefined);
    // const [amountLTE, setAmountLTE] = React.useState(undefined);
    const [startdate, setStartDate] = React.useState(moment().startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const order = 'asc';
    const orderBy = 'name';
    // const [orderBy, setOrderBy] = React.useState('name');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const columns = [
        { id: 'txn_date', align: 'left', numeric: false, disablePadding: false, label: 'Date', sortable: false },
        { id: 'branch_name', align: 'left', numeric: false, disablePadding: false, label: 'Entity', sortable: false },
        { id: 'particulars', align: 'left', numeric: false, disablePadding: true, label: 'Particulars', sortable: false },
        { id: 'txn_type_1', align: 'left', numeric: false, disablePadding: true, label: 'Ledger', sortable: false },
        { id: 'txn_type', align: 'left', numeric: false, disablePadding: true, label: 'Type', sortable: false },
        { id: 'narration', align: 'left', numeric: false, disablePadding: true, label: 'Narration', sortable: false },
        { id: 'debit', align: 'right', numeric: true, disablePadding: false, label: 'Debit', sortable: false },
        { id: 'credit', align: 'right', numeric: true, disablePadding: false, label: 'Credit', sortable: false },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.ledger_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.ledger_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    //Dialog
    const [transactionId, setTransactionId] = React.useState(null);
    const [dialogTxnOpen, setDialogTxnOpen] = React.useState(false);
    const [dialogJournalOpen, setDialogJournalOpen] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.ledger_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Day Book');
        showProgressDialog();
        // console.log(state);
        setBranches(state.branches.filter(b => b.id !== '-1'));
        setLoadedMaster(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        if(loadedMaster){
            closeProgressDialog();
        }
    }, [loadedMaster]);

    React.useEffect(() => {
        if(generate && loadedMaster){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            showProgressDialog();
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            FinanceService.getDayBookCount(startdate, enddate, selectedBranchIds)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setGenerate(false);
    }, [generate, loadedMaster]);

    const openDialog = (type, id) => {
        setTransactionId(id);
        if(type === TXN_TYPE.JOURNAL){
            setDialogJournalOpen(true);
        } else {
            setDialogTxnOpen(true);
        }
    }

    const handleEdit = (type, id, date, branchId) => {
        if(type === TXN_TYPE.CASH){
            history.push(`/finance/accounts/transaction/cash?id=${id}&startdate=${date}&branchId=${branchId}`);
        } else if(type === TXN_TYPE.BANK){
            history.push(`/finance/accounts/transaction/bank?id=${id}&startdate=${date}&branchId=${branchId}`);
        } else if(type === TXN_TYPE.JOURNAL){
            history.push(`/finance/accounts/transaction/journal?id=${id}&startdate=${date}&branchId=${branchId}`);
        }
    }

    const handleRemove = (type, id) => {
        showConfirmDialog('Confirm Remove Transaction?', undefined, () => {
            closeConfirmDialog();
            FinanceService.removeTransaction(type, id)
            .then(() => {
                showSnackBar(`Removed Tranaction`, 'success');
                setGenerate(true);
            })
            .catch((error) => {
                console.log(error);
                showSnackBar(`Failed to remove. Please try again or Contact Admin`, 'error');
            })
        });
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
            FinanceService.getDayBook(startdate, enddate, selectedBranchIds, null, null, null, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                if(data){
                    let journalMap = [];
                    data.transactions.forEach((element, index) => {
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                style={{cursor: 'pointer'}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog(element.txn_type, element.txn_type === TXN_TYPE.JOURNAL ? element.link_id : element.id);
                                }}>
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                    {(element.txn_type !== TXN_TYPE.JOURNAL || (element.txn_type === TXN_TYPE.JOURNAL && element.credit_debit === TXN_TYPE.D && !journalMap.includes(element.link_id)))  ? moment(element.txn_date).format(format_display) : ''}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {(element.txn_type !== TXN_TYPE.JOURNAL || (element.txn_type === TXN_TYPE.JOURNAL && element.credit_debit === TXN_TYPE.D && !journalMap.includes(element.link_id)))  ? element.branch_name : ''}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {element.txn_type !== TXN_TYPE.JOURNAL ? element.account_name : (element.credit_debit === 'DEBIT' ? element.account_name : '')}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {element.txn_type !== TXN_TYPE.JOURNAL ? (element.contra_name || '') : (element.credit_debit === 'CREDIT' ? element.account_name : '')}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {element.txn_type === TXN_TYPE.JOURNAL ? "Journal" : element.credit_debit === TXN_TYPE.D ? PAYMENT_TYPE.P: PAYMENT_TYPE.R}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                {
                                    (element.txn_type !== TXN_TYPE.JOURNAL || (element.txn_type === TXN_TYPE.JOURNAL && element.credit_debit === TXN_TYPE.D && !journalMap.includes(element.link_id)))  ? 
                                        (<BootstrapTooltip arrow title={(element.narration || '').length > 80 ? element.narration : ''}>
                                            <>{(element.narration && element.narration.substring(0, 80)) || ''}</>
                                        </BootstrapTooltip>)
                                    : ''
                                }
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'>
                                    {element.credit_debit === TXN_TYPE.D ? formatNumber(element.amount) : ''}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    {element.credit_debit === TXN_TYPE.C ? formatNumber(element.amount) : ''}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    {
                                        (element.txn_type !== TXN_TYPE.JOURNAL || (element.txn_type === TXN_TYPE.JOURNAL && element.credit_debit === TXN_TYPE.D && !journalMap.includes(element.link_id))) && (
                                            <Icon 
                                                fontSize="small" 
                                                style={{marginTop: 8, marginRight: 8}} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(element.txn_type, (element.txn_type === TXN_TYPE.JOURNAL ? element.link_id : element.id), element.txn_date, element.branch_id)
                                                }}>edit</Icon>
                                        )
                                    }
                                    {
                                        (element.txn_type !== TXN_TYPE.JOURNAL || (element.txn_type === TXN_TYPE.JOURNAL && element.credit_debit === TXN_TYPE.D && !journalMap.includes(element.link_id))) && (
                                            <Icon 
                                                fontSize="small" 
                                                style={{marginTop: 8, marginRight: 16}} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemove(element.txn_type, (element.txn_type === TXN_TYPE.JOURNAL ? element.link_id : element.id))
                                                }}>delete</Icon>
                                        )
                                    }
                                </StyledTableCell>
                            </TableRow>
                        );
                        if(element.txn_type === TXN_TYPE.JOURNAL && !journalMap.includes(element.link_id)){
                            journalMap.push(element.link_id);   
                        }
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        
    }

    const exportData = () => {
        showProgressDialog('Exporting...');
        let selectedBranchIds = selectedBranches && selectedBranches.length > 0 ? selectedBranches.map(b => b.id).join(',') : null;
        FinanceService.getDayBookDownload(startdate, enddate, selectedBranchIds, null, null, null, currentPageNumber * numItemsPerPage, numItemsPerPage)
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Day Book.xlsx');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            closeProgressDialog();
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={9} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} lg={3}>
                                    <ItemSelection 
                                        multiple 
                                        clearable={true} 
                                        label='Select Entity' 
                                        value={selectedBranches} 
                                        optionLabel='name' 
                                        options={branches} 
                                        selected={setSelectedBranches}/>
                                </Grid>  
                                <Grid item>
                                    <div style={{maxWidth: 170}}>
                                        <SingleDate 
                                            label="From"
                                            date={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setStartDate(date);
                                            }}/>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{maxWidth: 170, marginLeft: 8}}>
                                        <SingleDate 
                                            label="To"
                                            date={enddate}
                                            minDate={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setEndDate(date);
                                            }}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <Tooltip arrow title={totalItems === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Transactions</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <TransactionDetails 
                    id={transactionId} 
                    open={dialogTxnOpen} 
                    close={() => {
                        setDialogTxnOpen(false); 
                        setTransactionId(null);
                }}/>
                <JournalTransactionDetails
                    id={transactionId} 
                    open={dialogJournalOpen} 
                    close={() => {
                        setDialogJournalOpen(false);
                        setTransactionId(null);
                }}/>
            </div>
        </div>
    )
}