export const RestService = {
    get: async function(url, headers) {
        headers["Content-Type"] = "application/json";
        if(!headers["device-id"]){
            headers["device-id"] = "test";
        }
        headers["sysbrew-x-token"] = localStorage.getItem('geomoney.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        return fetch(url, {
            method: "GET",
            headers: headers
        })
        .then(response => {
            if (response.status === 500) {
                localStorage.removeItem('geomoney.user');
                localStorage.removeItem('geomoney.session');
                localStorage.removeItem("geomoney.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            
            return response;
        });
    },
    delete: async function(url, headers) {
        headers["Content-Type"] = "application/json";
        if(!headers["device-id"]){
            headers["device-id"] = "test";
        }
        headers["sysbrew-x-token"] = localStorage.getItem('geomoney.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "DELETE",
             headers: headers
         });
    },
    post:  async function(url, headers, body) {
        headers["Content-Type"] = "application/json";
        if(!headers["device-id"]){
            headers["device-id"] = "test";
        }
        headers["sysbrew-x-token"] = localStorage.getItem('geomoney.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "POST",
             headers: headers,
             body: body ? JSON.stringify(body) : null
         })
         .then(response => {
            if (response.status === 500) {
                localStorage.removeItem('geomoney.user');
                localStorage.removeItem('geomoney.session');
                localStorage.removeItem("geomoney.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            return response;
        });
    },
    postFormData:  async function(url, headers, body) {
        headers["enctype"] = "multipart/form-data";
        if(!headers["device-id"]){
            headers["device-id"] = "test";
        }
        headers["sysbrew-x-token"] = localStorage.getItem('geomoney.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "POST",
             headers: headers,
             body: body
         })
         .then(response => {
            if (response.status === 500) {
                localStorage.removeItem('geomoney.user');
                localStorage.removeItem('geomoney.session');
                localStorage.removeItem("geomoney.branch");
                window.location.reload(true);
                const error = (response && response.message) || response.statusText;
                return Promise.reject(error);
            }
            return response;
        });
    }
};
