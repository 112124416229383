import { Button, CardHeader, CircularProgress, Divider, Grid, Paper, Table, TableBody, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import EnhancedTableHead from '../components/EnhancedTableHead'
import { format_display, formatNumber } from '../Utils'
import { GetApp } from '@material-ui/icons'
import XLSX from 'xlsx'
import { ROLES } from '../Roles'
import { STATUS } from '../Constants'
import { AppContext } from '../AppContextProvider'
import { red } from '@material-ui/core/colors'

const TransactionDetails = ({
    classes,
    loan,
    standardInterest,
    currentSubInterestAmount,
    currentStandardInterestAmount,
    standardTransactions,
    subTransactions,
    subInterest,
    postingDate,
    loadingTransactions,
    standardRows,
    topupLoan,
    showPostInterest,
    showCloseLoan,
    changeItems,
}) => {
    const { state } = useContext(AppContext);

    const columns = [
        { id: 'txn_date', align: 'left', numeric: false, disablePadding: true, label: 'Date', sortable: false },
        { id: 'paid_upto', align: 'left', numeric: false, disablePadding: true, label: 'Paid Upto', sortable: false },
        { id: 'narration', align: 'center', numeric: false, disablePadding: true, label: 'Narration', sortable: false },
        { id: 'debit', align: 'center', numeric: true, disablePadding: true, label: 'Debit', sortable: false },
        { id: 'credit', align: 'center', numeric: true, disablePadding: true, label: 'Credit', sortable: false },
        { id: 'balance', align: 'center', numeric: true, disablePadding: true, label: 'Balance', sortable: false },
        { id: 'no_of_days', align: 'center', numeric: true, disablePadding: true, label: 'No of Days', sortable: false },
        { id: 'no_of_months', align: 'center', numeric: true, disablePadding: true, label: 'No of Months', sortable: false },
        { id: 'interest', align: 'center', numeric: true, disablePadding: true, label: 'Interest', sortable: false },
    ]

    const exportReport = type => e => {

        let rows = [];
        let data = type === 'standard' ? standardTransactions : subTransactions;
        let filename = 'Interest Calculation';
        rows.push(['Scheme', type === 'standard' ? loan.scheme.name : loan.sub_scheme.name])
        rows.push(['Interest', `${type === 'standard' ? standardInterest : subInterest}%`])
        rows.push(type === 'standard' ? columns.filter(c => c.id !== 'no_of_months' && c.id !== 'paid_upto').map(c => c.label) : columns.filter(c => c.id !== 'no_of_months').map(c => c.label));
        data.forEach((d, index) => {
            if (type === 'standard') {
                rows.push([d.txn_date, d.narration, d.debit, d.credit, d.balance, d.no_of_days, d.interest]);
            } else {
                rows.push([d.txn_date, d.paid_upto, d.narration, d.debit, d.credit, d.balance, d.no_of_days, d.interest]);
            }
        });
        rows.push([postingDate.format(format_display)]);
        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");
    }

    return (
        <Paper variant="outlined" className={classes.section} elevation={3}>
            <CardHeader
                title={`${loan.scheme.name} - ${standardInterest}%`}
                titleTypographyProps={{ variant: 'h4' }}
            />
            <Divider />
            {loadingTransactions &&
                <div style={{ height: 200 }}>
                    <CircularProgress style={{ position: 'relative', top: '30%', left: '45%' }} />
                </div>
            }
            {
                !loadingTransactions && (
                    <div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16, }}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item xs={12} lg={12}>
                                <Table className={classes.table} stickyHeader>
                                    <EnhancedTableHead
                                        order={''}
                                        orderBy={''}
                                        onRequestSort={() => { }}
                                        rows={columns.filter(c => c.id !== 'no_of_months' && c.id !== 'paid_upto')} />
                                    <TableBody>
                                        {standardRows}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" style={{ margin: 16 }}>
                                    {`Current Interest : ${formatNumber(currentStandardInterestAmount)}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={exportReport('sub')}
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginRight: 8 }}
                                    endIcon={<GetApp />}
                                >
                                    Loyality
                                </Button>
                                <Button
                                    onClick={exportReport('standard')}
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginRight: 8 }}
                                    endIcon={<GetApp />}
                                >
                                    Normal
                                </Button>
                                {/* Need to remove admin check for Topup and Partial release */}
                                {loan.status === STATUS.ACTIVE && (state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.MANAGER)) ? (
                                    <Button onClick={() => changeItems()} color="secondary" variant="contained" style={{ marginRight: 8 }}>Partial Release Items</Button>
                                ) : null}
                                {loan.status === STATUS.ACTIVE ? (
                                    <Button onClick={() => topupLoan()} color="secondary" variant="contained" style={{ marginRight: 8 }}>Topup Loan</Button>
                                ) : null}
                                {loan.status === STATUS.ACTIVE && (currentStandardInterestAmount > 0 || currentSubInterestAmount > 0) ? (
                                    <Button onClick={showPostInterest('standard')} color="secondary" variant="contained" style={{ marginRight: 8 }}>Post Interest</Button>
                                ) : null}
                                {loan.status === STATUS.ACTIVE ? (
                                    <Button onClick={() => showCloseLoan()} style={{ marginLeft: 8, backgroundColor: red[500], color: 'white' }} variant="outlined">Close Loan</Button>
                                ) : null}
                            </Grid>
                        </Grid>
                    </div>)
            }
        </Paper>
    )
}

export default TransactionDetails