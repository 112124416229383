import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { format_display_date, getTimeInIST } from '../Utils';
import { Grid, Divider, Fade, LinearProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { UserService } from '../Services/UserService';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ManagerHistory(props) {
  const [open, setOpen] = React.useState(props.id ? true : false);
  const [loading, setLoading] = React.useState(false);

  const [title, setTitle] = React.useState(undefined);
  const [details, setDetails] = React.useState([]); 

  const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.id && props.open]);

    React.useEffect(() => {
    if(open && props.id){
        setLoading(true);
        let details = [];
        UserService.getManagerHistory(props.id)
        .then((data) => {
            if(data){
                setTitle(`${props.branch.name} - ${props.branch.code}`);

                data.forEach((element, index) => {
                    details.push(
                        <Grid item container xs={12} lg={12} key={index} spacing={2}  alignItems="center">
                            <Grid item xs={4} lg={6}>
                                <Typography className={classes.heading} style={{textAlign: 'left'}}>{element.manager_name}</Typography>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Typography className={classes.subheading} style={{textAlign: 'left'}}>{getTimeInIST(element.start_date).format(format_display_date)}</Typography>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Typography className={classes.heading} style={{textAlign: 'left'}}>{element.end_date ? getTimeInIST(element.end_date).format(format_display_date) : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider />
                            </Grid>
                        </Grid>);
                }); 
            }
            setLoading(false);
            setDetails(details);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }
}, [open]);

 
  const handleClose = () => {
    props.close();
    setOpen(false);
  };

  return (
      <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                <Grid item xs={12} lg={12}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '10ms' : '0ms'
                        }}
                        unmountOnExit
                        >
                        <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                    </Fade>
                </Grid>
                <Grid item container xs={12} lg={12} key={1} spacing={2}  alignItems="center">
                    <Grid item xs={4} lg={6}>
                        <Typography className={classes.sectionHeading} style={{textAlign: 'left'}}>Manager</Typography>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <Typography className={classes.sectionHeading} style={{textAlign: 'left'}}>Start Date</Typography>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <Typography className={classes.sectionHeading} style={{textAlign: 'left'}}>End Date</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider />
                    </Grid>
                </Grid>
                {details}
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
  );
}