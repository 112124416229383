import React, { useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, TableRow, CircularProgress, Backdrop } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { StyledTableCell, getTimeInIST, formatNumber, format_display_fancy_notime, getColor, precision, format_display, calculateInterest, getInterestPercentage, format_without_time, generateID, daysRoundedInMonths, toInt } from '../Utils';
import { AppContext } from '../AppContextProvider';
import { LoanRequestsService } from '../Services/LoanRequestsService';
import { FinanceService } from '../Services/FinanceService';
import moment from 'moment';
import { STATUS, TXN_TYPE } from '../Constants';
import { useHistory } from 'react-router-dom';
import PostAmountDialog from '../components/PostAmountDialog';
import { OrganisationService } from '../Services/OrganisationService';
import TopupLoanDialog from './TopupLoanDialog';
import ItemDetails from './ItemDetails';
import Followup from './Followup';
import CustomerDetails from './CustomerDetails';
import TransactionDetails from './TransactionDetails';
import LoanDetails from './LoanDetails';
import { ChangeLoanItemsDialog } from './ChangeLoanItemsDialog';
import { CheckBalanceAndSave } from './utils';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	header: {
		fontWeight: 700
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
		margin: 0,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewLoanDialog(props) {
	const history = useHistory()
	const { state, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext)
	const { id } = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [loan, setLoan] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [bankOptions, setBankOptions] = React.useState([]);
	const [cashOptions, setCashOptions] = React.useState([]);
	// const [ledgers, setLedgers] = React.useState([]);
	const [interestLedgers, setInterestLedgers] = React.useState([]);
	const [postingDate, setPostingDate] = React.useState(moment().startOf('day'));

	const [standardTransactions, setStandardTransactions] = React.useState([]);
	const [subTransactions, setSubTransactions] = React.useState([]);
	const [standardRows, setStandardRows] = React.useState([]);
	// const [subRows, setSubRows] = React.useState([]);
	const [loadingTransactions, setLoadingTransactions] = React.useState(false);
	const [showViewTransactions, setShowViewTransactions] = React.useState(false);
	const [standardInterest, setStandardInterest] = React.useState(0);
	const [currentStandardInterestAmount, setCurrentStandardInterestAmount] = React.useState(0);
	const [subInterest, setSubInterest] = React.useState(0);
	const [currentSubInterestAmount, setCurrentSubInterestAmount] = React.useState(0);
	const [balance, setBalance] = React.useState(0);
	const [openPostInterest, setOpenPostInterest] = React.useState(false);
	const [postInterestAmount, setPostInterestAmount] = React.useState(0);
	const [postInterestType, setPostInterestType] = React.useState('standard');
	const [openCloseLoanDialog, setOpenCloseLoanDialog] = React.useState(false);
	const [showTopupAfterInterestedPosting, setShowTopupAfterInterestedPosting] = React.useState(false);
	const [openTopupLoanDialog, setOpenTopupLoanDialog] = React.useState(false);
	const [closeureLoanAmount, setClosureLoanAmount] = React.useState(0);
	const [minDaysOfinterest, setMinDaysOfInterest] = React.useState(0);
	const [lastInterestPaidDate, setLastInterestPaidDate] = React.useState(null)

	// Change Items
	const [openChangeItems, setOpenChangeItems] = React.useState(false)
	const [showChangeItemsAfterPartialPaymentPosting, setShowChangeItemsAfterPartialPaymentPosting] = React.useState(false);
	const [lastPartialPaymentDate, setLastPartialPaymentDate] = React.useState(null)
	const changeLoanItemDialogRef = useRef(null)

	const myRef = useRef(null)
	const executeScroll = () => {
		myRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	}

	React.useEffect(() => {
		setOpen(props.open);
		if (props.open) {
			showProgressDialog()
			fetchData()
			setLoading(true)
		}
		return () => {
			setLoan({});
			setOpen(false);
			setShowViewTransactions(false)
			setPostInterestType('standard')
			setBankOptions([])
			setCashOptions([])
		}
	}, [props.open])

	const fetchData = () => {
		Promise.all([OrganisationService.getOrganisation(), LoanRequestsService.getLoanDetails(id)])
			.then(datas => {
				if (datas[0]) {
					setMinDaysOfInterest(toInt(datas[0].min_interest_days))
				}
				if (datas[1]) {
					let data = datas[1];
					let days = data.end_date ? getTimeInIST(data.end_date).endOf('d').diff(getTimeInIST(data.start_date).startOf('d'), 'days') : moment().endOf('d').diff(getTimeInIST(data.start_date).startOf('d'), 'days');
					setLoan({ ...data, days });
					FinanceService.getLedgers('4', data.branch_id, null, 0, 9999)
						.then(data => {
							setBankOptions(data);
						})
						.catch(error => {
							console.log(error)
							showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
								handleClose();
								closeConfirmDialog();
							});
							setLoading(false);
							closeProgressDialog();
						})
					FinanceService.getLedgers('5', data.branch_id, null, 0, 9999)
						.then(data => {
							setCashOptions(data);
						})
						.catch(error => {
							console.log(error)
							showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
								handleClose();
								closeConfirmDialog();
							});
							setLoading(false);
							closeProgressDialog();
						})
				}
				closeProgressDialog();
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
					handleClose();
					closeConfirmDialog();
				});
				setLoading(false);
				closeProgressDialog();
			});
	}

	const handleClose = (callback = false) => {
		props.onClose(callback);
		setOpen(false);
		setPostingDate(moment().startOf('day'))
		setShowViewTransactions(false);
	};

	const print = () => {
		handleClose()
		history.push(`/loans/print?no=${id}`)
	}

	const showCancelConfirm = () => {
		showConfirmDialog('Are you sure you want to cancel the loan?', undefined, () => {
			closeConfirmDialog();
			cancel();
		}, 'Back')
	}

	const cancel = () => {
		setLoading(true);
		LoanRequestsService.cancelRequest(id)
			.then(data => {
				showSnackBar('Loan Cancelled', 'info');
				handleClose(true);
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
					handleClose();
					closeConfirmDialog();
				});
				setLoading(false);
			})
	}

	const viewInterest = (shouldFetchLoan = false) => {
		setShowViewTransactions(true);
		setLoadingTransactions(true);
		setLastInterestPaidDate(null);
		setLastPartialPaymentDate(null);
		let groupId = loan.disbursement_mode === 'CASH' ? '5' : '6';
		const promises = [
			LoanRequestsService.getLoanTransactions(id),
			FinanceService.getLedgers(groupId, loan.branch_id, null, 0, 9999),
			FinanceService.getLedgers(8, loan.branch_id, null, 0, 9999)
		]
		if (shouldFetchLoan) {
			promises.push(LoanRequestsService.getLoanDetails(id))
		}
		Promise.all(promises)
			.then(datas => {
				if (datas[0].length > 0) {
					let data = datas[0];
					// If interest has been paid before the min days of interest will not be applicable
					let minDaysOfI = data.filter(d => d.type === 'INTEREST').length > 0 ? 0 : minDaysOfinterest;
					let sRows = [];
					// let subRows = [];
					let balance1 = 0, balance2 = 0;
					let standardTransactions = [], subTransactions = [];

					data.forEach((element, index) => {
						if (element.type === 'INTEREST') {
							setLastInterestPaidDate(moment(element.txn_date))
						}
						if (element.type === 'CAPITAL_RECEIVED') {
							setLastPartialPaymentDate(moment(element.txn_date))
						}
						balance1 += element.credit_debit === TXN_TYPE.D ? parseFloat(element.amount) : - parseFloat(element.amount);
						balance2 += element.credit_debit === TXN_TYPE.D ? parseFloat(element.paid_upto_amount || element.amount) : - parseFloat(element.paid_upto_amount || element.amount);
						let noOfDays1 = -1, noOfDays2 = -1, noOfDaysRounded = -1, lastEntrydiffFromToday = 0;
						let interest1 = 0, interest2 = 0;
						let postingDateDiffFromToday = postingDate.diff(moment().startOf('day'), 'days');
						if (index + 1 < data.length) {
							noOfDays1 = moment(data[index + 1].txn_date).diff(moment(data[index].txn_date), 'days');
							noOfDays2 = moment(data[index + 1].txn_date).diff(moment(data[index].txn_date), 'days');
						} else {
							noOfDays1 = postingDate.diff(moment(data[index].txn_date), 'days') === 0 ? 1 : postingDate.diff(moment(data[index].txn_date), 'days');
							noOfDays2 = postingDate.diff(moment(index === 0 ? data[index].txn_date : (data[index - 1].txn_date)), 'days');
							lastEntrydiffFromToday = moment(index === 0 ? data[index].txn_date : (data[index - 1].txn_date)).diff(moment().startOf('day'), 'days');
						}
						interest1 = index + 1 < data.length ?
							0 //data[index+1].amount 
							: calculateInterest(loan.scheme, noOfDays1, balance1, 0);
						if (noOfDays2 >= 0) {
							// interest1 = calculateInterest(loan.scheme, noOfDays, balance);
							// if(noOfDays2 === 31 || noOfDays2 === 271){
							// 	noOfDaysRounded = noOfDays2 - 1;
							// } else 
							if (noOfDays2 <= minDaysOfI) {
								noOfDaysRounded = minDaysOfI;
							} else {
								noOfDaysRounded = noOfDays2;
							}
							// interest2 = calculateInterest(loan.sub_scheme, noOfDays, balance);
							if (index !== 0 && index === data.length - 1 && data[index - 1].txn_date && moment(data[index - 1].txn_date).isAfter(postingDate)) {
								noOfDaysRounded = 0;
								interest2 = 0;
							} else {
								interest2 = calculateInterest(loan.sub_scheme, noOfDaysRounded, balance2, postingDateDiffFromToday === 0 ? 0 : (lastEntrydiffFromToday === 0 ? postingDateDiffFromToday : lastEntrydiffFromToday));
							}
						} else {
							noOfDays1 = 0;
							interest1 = 0;
							noOfDaysRounded = 0;
							interest2 = 0;
						}
						standardTransactions.push({
							txn_date: moment(element.txn_date).format(format_display) || '-',
							narration: element.narration || '-',
							txn_date_value: element.txn_date,
							debit: element.credit_debit === TXN_TYPE.D ? element.amount : '',
							credit: element.credit_debit === TXN_TYPE.C ? element.amount : '',
							balance: balance1,
							type: element.type,
							no_of_days: element.type !== 'INTEREST' && noOfDays1 > -1 ? noOfDays1 : '',
							no_of_months: element.type !== 'INTEREST' && noOfDays1 > -1 ? daysRoundedInMonths(noOfDays1, 360) : '',
							interest: element.type !== 'INTEREST' && interest1 > -1 ? interest1 : '',
							paid_upto: element.paid_upto ? moment(element.paid_upto).format(format_display) : '',
							paid_upto_value: element.paid_upto || undefined,
						})
						sRows.push(
							<TableRow
								key={index}
								hover
								style={{ cursor: 'pointer' }}
							>
								<StyledTableCell scope="row" align="center" padding='none' style={{ width: 130 }}>
									{moment(element.txn_date).format(format_display) || '-'}
								</StyledTableCell>
								<StyledTableCell scope="row" align="left" padding='none'>
									{element.narration || '-'}
								</StyledTableCell>
								<StyledTableCell scope="row" align="right" padding='none'>
									{element.credit_debit === TXN_TYPE.D ? formatNumber(element.amount) : ''}
								</StyledTableCell>
								<StyledTableCell scope="row" align="right" padding='none'>
									{element.credit_debit === TXN_TYPE.C ? formatNumber(element.amount) : ''}
								</StyledTableCell>
								<StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
									{formatNumber(balance1)}
								</StyledTableCell>
								<StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
									{element.type !== 'INTEREST' && noOfDays1 > -1 ? noOfDays1 : ''}
								</StyledTableCell>
								<StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
									{element.type !== 'INTEREST' && interest1 > -1 ? formatNumber(interest1) : ''}
								</StyledTableCell>
							</TableRow>
						);
						subTransactions.push({
							txn_date: moment(element.txn_date).format(format_display) || '-',
							txn_date_value: element.txn_date,
							narration: element.narration || '-',
							type: element.type,
							debit: element.credit_debit === TXN_TYPE.D ? element.paid_upto_amount || element.amount || 0 : '',
							credit: element.credit_debit === TXN_TYPE.C ? element.paid_upto_amount || element.amount || 0 : '',
							balance: balance2,
							no_of_days: element.type !== 'INTEREST' && noOfDays2 > -1 ? noOfDays2 : '',
							no_of_months: element.type !== 'INTEREST' && noOfDaysRounded > -1 ? daysRoundedInMonths(noOfDaysRounded, 360) : '',
							interest: element.type !== 'INTEREST' && interest2 > -1 ? interest2 : '',
							paid_upto: element.txn_date ? moment(element.txn_date).format(format_display) : '',
							paid_upto_value: element.txn_date || undefined,
						})
					});

					let debitEntries = data.filter(d => d.credit_debit === TXN_TYPE.D);
					if (debitEntries.length >= 1) {
						let lastDE = debitEntries[debitEntries.length - 1];
						let days = postingDate.diff(moment(lastDE.txn_date), 'days') === 0 ? 1 : postingDate.diff(moment(lastDE.txn_date), 'days');
						let lastEntrydiffFromToday = moment(lastDE.txn_date).diff(moment().startOf('day'), 'days');

						let days2 = days;
						if (days2 <= minDaysOfI) {
							days2 = minDaysOfI;
						}
						let postingDateDiffFromToday = postingDate.diff(moment().startOf('day'), 'days');
						const stdInterestAmount = calculateInterest(loan.scheme, days, balance1, 0);
						setCurrentStandardInterestAmount(stdInterestAmount < 0 ? 0 : stdInterestAmount);
						const loyaltyInterestAmount = calculateInterest(loan.sub_scheme, days2, balance2, postingDateDiffFromToday === 0 ? 0 : (lastEntrydiffFromToday === 0 ? postingDateDiffFromToday : lastEntrydiffFromToday));
						setCurrentSubInterestAmount(loyaltyInterestAmount < 0 ? 0 : loyaltyInterestAmount);
						setStandardInterest(getInterestPercentage(loan.scheme, days));
						setSubInterest(getInterestPercentage(loan.sub_scheme, days2, postingDateDiffFromToday === 0 ? 0 : (lastEntrydiffFromToday === 0 ? postingDateDiffFromToday : lastEntrydiffFromToday)));
						setBalance(balance1);
					}

					setStandardTransactions(standardTransactions);
					setSubTransactions(subTransactions);
					setStandardRows(sRows);
				}
				if (datas[2].length > 0) {
					setInterestLedgers(datas[2]);
				}
				if (datas[3]) {
					let data = datas[3];
					let days = data.end_date ? getTimeInIST(data.end_date).endOf('d').diff(getTimeInIST(data.start_date).startOf('d'), 'days') : moment().endOf('d').diff(getTimeInIST(data.start_date).startOf('d'), 'days');
					setLoan({ ...data, days });
				}
				setLoadingTransactions(false);
				if (datas[0].length === 0) {
					showConfirmDialog('Something went wrong while fetching Loan Details. Please try again.', undefined, () => {
						closeConfirmDialog();
					});
				} else if (datas[1].length === 0) {
					showConfirmDialog('There are no Ledgers defined for Cash/Bank. Please create them and try again', undefined, () => {
						closeConfirmDialog();
					});
				} else if (datas[2].length === 0) {
					showConfirmDialog('There are no Ledgers defined for Interest Account. Please create them and try again', undefined, () => {
						closeConfirmDialog();
					});
				}
				executeScroll();
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog('Something went wrong while fetching Loan Details. Please try again.', undefined, () => {
					closeConfirmDialog();
				});
				setLoadingTransactions(false);
			})
	}

	const showPostInterest = type => e => {
		let amount = 0;
		// let amount2 = currentSubInterestAmount;
		switch (type) {
			case 'standard': amount = currentStandardInterestAmount; break;
			default: amount = currentSubInterestAmount; break;
		}
		setPostInterestAmount(amount)
		setPostInterestType(type)
		setOpenPostInterest(true)
	}

	const postInterest = (amount, selectedMode, selectedLedger, additionalNarration = '', partRepayment = 0) => {
		let amount2 = currentSubInterestAmount;
		switch (postInterestType) {
			case 'standard': amount = currentStandardInterestAmount; break;
			default: amount = currentSubInterestAmount; break;
		}
		let interestTxns = [];
		switch (postInterestType) {
			case 'standard':
				interestTxns = subTransactions.filter(t => t.type === 'INTEREST' || t.type === 'LOAN');
				break;
			default:
				interestTxns = subTransactions.filter(t => t.type === 'INTEREST' || t.type === 'LOAN');
				break;
		}

		let t = interestTxns[interestTxns.length - 1]
		let paid_upto = moment(t.txn_date_value);

		let transactions = [{
			pledge_id: loan.id,
			amount: amount,
			type: 'INTEREST',
			credit_debit: TXN_TYPE.D,
			txn_date: postingDate.format(format_without_time),
			txn_type: selectedMode,
			narration: "Interest",
			entry_by: state.user.name,
			paid_upto: paid_upto.format(format_without_time),
			paid_upto_amount: amount2,
		}, {
			pledge_id: loan.id,
			amount: amount,
			type: 'INTEREST_RECEIVED',
			credit_debit: TXN_TYPE.C,
			txn_date: postingDate.format(format_without_time),
			txn_type: selectedMode,
			narration: "Cash Received",
			entry_by: state.user.name,
			paid_upto_amount: amount2,
		}];

		if (partRepayment > 0) {
			transactions.push({
				pledge_id: loan.id,
				amount: partRepayment,
				type: 'CAPITAL_RECEIVED',
				credit_debit: TXN_TYPE.C,
				txn_date: postingDate.format(format_without_time),
				txn_type: selectedMode,
				narration: "Part Repayment - Cash Received",
				entry_by: state.user.name,
			})
		}

		let link_id = generateID();
		let journalTxns = amount > 0 ? [{
			id: null,
			link_id: link_id,
			branch_id: loan.branch_id,
			account_id: loan.customer_id,
			amount: amount,
			credit_debit: TXN_TYPE.D,
			narration: `Interest for loan ${loan.pledge_no}. ${additionalNarration}`,
			txn_date: moment().format(format_without_time),
			txn_type: TXN_TYPE.JOURNAL,
			entry_by: state.user.name,
		}, {
			id: null,
			link_id: link_id,
			branch_id: loan.branch_id,
			account_id: interestLedgers[0].id,
			amount: amount,
			credit_debit: TXN_TYPE.C,
			narration: `Interest for loan ${loan.pledge_no}. ${additionalNarration}`,
			txn_date: moment().format(format_without_time),
			txn_type: TXN_TYPE.JOURNAL,
			entry_by: state.user.name,
		}] : [];
		let accountTxn = amount > 0 ? [{
			id: null,
			branch_id: loan.branch_id,
			contra_id: selectedMode === 'CASH' ? cashOptions[0].id : selectedLedger.id,
			account_id: loan.customer_id,
			amount: amount,
			credit_debit: TXN_TYPE.C,
			narration: `Interest for loan ${loan.pledge_no}. ${additionalNarration}`,
			txn_date: moment().format(format_without_time),
			txn_type: selectedMode,
			entry_by: state.user.name
		}] : [];

		if (partRepayment > 0) {
			accountTxn.push({
				id: null,
				branch_id: loan.branch_id,
				contra_id: selectedMode === 'CASH' ? cashOptions[0].id : selectedLedger.id,
				account_id: loan.customer_id,
				amount: partRepayment,
				credit_debit: TXN_TYPE.C,
				narration: `Part Repayment for loan ${loan.pledge_no}. ${additionalNarration}`,
				txn_date: moment().format(format_without_time),
				txn_type: selectedMode,
				entry_by: state.user.name
			})
		}

		showConfirmDialog(`Confirm Post Interest ${formatNumber(amount)}`, undefined, () => {
			closeConfirmDialog();
			showProgressDialog();
			setLoading(true);
			// FinanceService.saveJournalLedgerTransaction(journalTxns)
			LoanRequestsService.postInterest({ journal_txns: journalTxns, account_txns: accountTxn, loan_txns: transactions })
				.then(data => {
					// Promise.all([FinanceService.saveLedgerTransaction(accountTxn),
					// 	LoanRequestsService.addLoanTransaction(transactions)])
					// .then(datas => {
					showSnackBar('Interest Posted', 'info');
					viewInterest();
					setLoading(false);
					closeProgressDialog()
					if (showTopupAfterInterestedPosting) {
						setShowTopupAfterInterestedPosting(false)
						setOpenTopupLoanDialog(true)
					}
					if (showChangeItemsAfterPartialPaymentPosting) {
						setShowChangeItemsAfterPartialPaymentPosting(false)
						setOpenChangeItems(true)
					}
					// })
					// .catch(error => {
					// 	showSnackBar('Failed to Post Interest. Please try again', 'error');
					// 	console.log(error);
					// 	setLoading(false);
					// 	closeProgressDialog();
					// })
				})
				.catch(error => {
					showSnackBar('Failed to Post Interest. Please try again', 'error');
					console.log(error);
					setLoading(false);
					closeProgressDialog();
				})
		}, 'Cancel', 'Post');
	}

	const topupLoan = () => {
		setShowTopupAfterInterestedPosting(false)
		if (!lastInterestPaidDate || moment().startOf('day').diff(lastInterestPaidDate.startOf('day'), 'days') > 0) {
			showConfirmDialog(
				lastInterestPaidDate ? `Interest was last received on ${lastInterestPaidDate.format(format_display_fancy_notime)} and today is ${moment().format(format_display_fancy_notime)}. Please collect interest first and then continue?` : `Interest was not collected for this Loan. Please collect interest first and then continue?`,
				undefined,
				() => {
					setShowTopupAfterInterestedPosting(true)
					closeConfirmDialog()
					showPostInterest('standard')(null)
					// setOpenTopupLoanDialog(true)
				},
				'Cancel',
				'Collect Interest'
			);
		} else {
			setOpenTopupLoanDialog(true)
		}
	}

	const addTopup = (amount, selectedMode, selectedLedger, additionalNarration = '') => {
		const disbursementLedgerId = selectedMode === 'CASH' ? cashOptions[0].id : selectedLedger.id
		showProgressDialog('Checking balance...')
		CheckBalanceAndSave(disbursementLedgerId, loan.branch_id, amount)
			.then(data => {
				closeProgressDialog()
				if (data.sufficientBalance) {
					let transactions = [
						{
							pledge_id: loan.id,
							amount: amount,
							type: 'TOPUP',
							credit_debit: TXN_TYPE.D,
							txn_date: postingDate.format(format_without_time),
							txn_type: selectedMode,
							narration: "Topup Given",
							entry_by: state.user.name,
						}
					];
					let accountTxn = [
						{
							id: null,
							branch_id: loan.branch_id,
							contra_id: disbursementLedgerId,
							account_id: loan.customer_id,
							amount: amount,
							credit_debit: TXN_TYPE.D,
							narration: `Topup added for loan ${loan.pledge_no}. ${additionalNarration}`,
							txn_date: moment().format(format_without_time),
							txn_type: selectedMode,
							entry_by: state.user.name
						}
					]

					showConfirmDialog(`Confirm adding of Topup of Amount ${formatNumber(amount)}`, undefined, () => {
						closeConfirmDialog()
						showProgressDialog('Adding topup')
						setLoading(true);
						LoanRequestsService.topupLoan({ account_txns: accountTxn, loan_txns: transactions })
							.then(data => {
								if (data.success) {
									showSnackBar('Loan Topped up successfully', 'info');
									viewInterest(true);
								} else {
									showSnackBar(data.message, 'info');
								}
								setLoading(false);
								closeProgressDialog()
							})
							.catch(error => {
								showSnackBar('Failed to topup loan. Please try again', 'error');
								console.log(error);
								setLoading(false);
								closeProgressDialog();
							})
					}, 'Cancel', 'Add Topup')
				} else {
					showConfirmDialog1D(data.message, undefined, () => {
						closeConfirmDialog1D()
					})
				}
			})
			.catch(error => {
				console.log(error)
				showSnackBar('Failed to Check balance. Please try again.', 'error');
				closeProgressDialog()
				setLoading(false)
			})
	}

	const showCloseLoan = () => {
		if (!lastInterestPaidDate || moment().startOf('day').diff(lastInterestPaidDate.startOf('day'), 'days') > 0) {
			showConfirmDialog(
				lastInterestPaidDate ? `Interest was last received on ${lastInterestPaidDate.format(format_display_fancy_notime)} and today is ${moment().format(format_display_fancy_notime)}. Do you want to continue?` : `Interest was not collected for this Loan. Do you want to continue?`,
				undefined,
				() => {
					closeConfirmDialog();
					setClosureLoanAmount(precision(balance))
					setOpenCloseLoanDialog(true)
				},
				'Cancel',
				'Continue'
			);
		} else {
			setClosureLoanAmount(precision(balance))
			setOpenCloseLoanDialog(true)
		}
	}



	const closeLoan = (amount, selectedMode, selectedLedger, additionalNarration = '') => {
		showConfirmDialog(`Confirm Close loan. Received amount is ${formatNumber(amount)}`, undefined, () => {
			// if(amount < balance){
			// 	setConfirmDialogError(`Amount cannot be less than the balance ${formatNumber(balance)}`);
			// } else {
			let transactions = [{
				pledge_id: loan.id,
				type: 'CLOSE',
				amount: amount,
				credit_debit: TXN_TYPE.C,
				txn_date: moment().format(format_without_time),
				txn_type: selectedMode,
				narration: `Loan Closed ${loan.pledge_no}. ${additionalNarration}`,
				entry_by: state.user.name
			}];

			let accountTransactions = [{
				id: null,
				branch_id: loan.branch_id,
				contra_id: selectedMode === 'CASH' ? cashOptions[0].id : selectedLedger.id,
				account_id: loan.customer_id,
				amount: amount,
				credit_debit: TXN_TYPE.C,
				narration: `Gold Loan Closed. Loan No: ${loan.pledge_no}. ${additionalNarration}`,
				txn_date: moment().format(format_without_time),
				txn_type: selectedMode,
				entry_by: state.user.name
			}];
			showProgressDialog('Saving Transactions....');
			setLoading(true);
			LoanRequestsService.closeRequest(loan.pledge_no, { account_txns: accountTransactions, loan_txns: transactions })
				.then(data => {
					showSnackBar('Loan Closed', 'info');
					handleClose(true);
					setLoading(false);
					closeProgressDialog();
				})
				.catch(error => {
					console.log(error);
					showSnackBar('Something went Wrong. Please try again or contact Admin.', "warning");
					closeProgressDialog();
					setLoading(false);
				});
			closeConfirmDialog();
		}, 'Cancel', 'Confirm');
		// }
		// }, "Cancel", "Post", true, 'Cash Amount', precision(balance));
	}

	const changeItemsRequested = () => {
		setShowChangeItemsAfterPartialPaymentPosting(false)
		if (!lastPartialPaymentDate || moment().startOf('day').diff(lastPartialPaymentDate.startOf('day'), 'days') > 0) {
			showConfirmDialog(
				lastPartialPaymentDate ? `Partial payment was last received on ${lastPartialPaymentDate.format(format_display_fancy_notime)} and today is ${moment().format(format_display_fancy_notime)}. Please collect partial payment and then continue?` : `Partial Payment was not collected for this Loan. Please collect first and then continue?`,
				undefined,
				() => {
					setShowChangeItemsAfterPartialPaymentPosting(true)
					closeConfirmDialog()
					showPostInterest('standard')(null)
					// setOpenTopupLoanDialog(true)
				},
				'Cancel',
				'Release Items'
			);
		} else {
			setOpenChangeItems(true)
		}
	}

	const updateLoanItems = (items) => {
		let requestItems = items.map(i => ({
			id: i.id || null,
			item_id: i.item_id,
			qty: i.qty,
			weight: i.weight,
			d_weight: i.d_weight,
			purity: i.purity,
		}));
		showProgressDialog()
		setLoading(true)
		LoanRequestsService.updateLoanItems(loan.id, { items: requestItems })
			.then(data => {
				if (data.success) {
					showSnackBar('Loan Items updated', 'info')
					viewInterest(true)
					changeLoanItemDialogRef.current.handleClose()
				} else {
					showSnackBar(data.message, 'info');
				}
				changeLoanItemDialogRef.current.enable()
				setLoading(false);
				closeProgressDialog()
			})
			.catch(error => {
				showSnackBar('Failed to update loan items. Please try again', 'error');
				console.log(error);
				setLoading(false);
				closeProgressDialog()
				changeLoanItemDialogRef.current.enable()
			})
	}

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{id && `Loan No: ${id}`}
					</Typography>
				</Toolbar>
			</AppBar>
			<Backdrop className={classes.backdrop} open={loading} onClick={() => { }}>
				<div style={{ padding: 50, backgroundColor: 'white', borderRadius: 8 }}>
					<CircularProgress
						size={40}
						thickness={4} />
				</div>
			</Backdrop>
			<Grid container className={classes.page} style={loan.status && loan.status === STATUS.CANCELLED ? { backgroundColor: getColor(loan.status) } : {}} justifyContent="center">
				<Grid container item spacing={0} xs={12} lg={12} alignItems="flex-start" justifyContent="flex-start" direction="row">
					{loan.customer ? <CustomerDetails classes={classes} loan={loan} /> : null}
					{loan.items ? <ItemDetails items={loan.items} classes={classes} loanId={loan.id} showHistory={loan.has_items_changed === '1'} /> : null}
					{loan.scheme ? (
						<LoanDetails
							classes={classes}
							loan={loan}
							postingDate={postingDate}
							setPostingDate={setPostingDate}
							print={print}
							showCancelConfirm={showCancelConfirm}
							viewInterest={viewInterest}
						/>) : null}
					<Grid ref={myRef} item xs={12} lg={12}>
						{showViewTransactions && loan.scheme ? (
							<TransactionDetails
								classes={classes}
								loan={loan}
								standardInterest={standardInterest}
								currentSubInterestAmount={currentSubInterestAmount}
								currentStandardInterestAmount={currentStandardInterestAmount}
								standardTransactions={standardTransactions}
								subTransactions={subTransactions}
								subInterest={subInterest}
								postingDate={postingDate}
								loadingTransactions={loadingTransactions}
								standardRows={standardRows}
								showPostInterest={showPostInterest}
								showCloseLoan={showCloseLoan}
								topupLoan={topupLoan}
								changeItems={changeItemsRequested}
							/>
						) : null}
					</Grid>
					{loan.items ? (
						<Followup
							classes={classes}
							id={loan.id}
							pledgeNo={id}
							setLoading={setLoading}
							loanStartDate={loan.start_date}
						/>
					) : null}
				</Grid>
			</Grid>
			<PostAmountDialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openPostInterest}
				narration={true}
				currentDisbursementLedger={(loan.disbursement_ledger && bankOptions.find(b => b.id === loan.disbursement_ledger.id)) || null}
				title="Confirm Post Interest"
				close={() => {
					setOpenPostInterest(false)
					setPostInterestAmount(0)
				}}
				save={(amount, selectedMode, selectedLedger, additionalNarration, partRepayment = 0) => {
					postInterest(amount, selectedMode, selectedLedger, additionalNarration, partRepayment)
				}}
				showAmount={false}
				showPartRepayment={!showTopupAfterInterestedPosting}
				mode={loan.disbursement_mode}
				minAmount={postInterestAmount}
				bankLedgers={bankOptions}
				isPartialPaymentRequired={showChangeItemsAfterPartialPaymentPosting}
			/>
			<PostAmountDialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openCloseLoanDialog}
				currentDisbursementLedger={(loan.disbursement_ledger && bankOptions.find(b => b.id === loan.disbursement_ledger.id)) || null}
				title="Close Loan"
				narration={true}
				close={() => {
					setOpenCloseLoanDialog(false)
					setClosureLoanAmount(0)
				}}
				save={(amount, selectedMode, selectedLedger, additionalNarration) => {
					closeLoan(amount, selectedMode, selectedLedger, additionalNarration)
				}}
				showAmount={true}
				mode={loan.disbursement_mode}
				minAmount={closeureLoanAmount}
				bankLedgers={bankOptions}
			/>
			<TopupLoanDialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openTopupLoanDialog}
				currentDisbursementLedger={(loan.disbursement_ledger && bankOptions.find(b => b.id === loan.disbursement_ledger.id)) || null}
				title="Topup Loan"
				narration={true}
				close={() => {
					setOpenTopupLoanDialog(false)
				}}
				save={(amount, selectedMode, selectedLedger, additionalNarration) => {
					addTopup(amount, selectedMode, selectedLedger, additionalNarration)
				}}
				mode={loan.disbursement_mode}
				eligibleAmount={precision(loan.eligible_amount) - precision(loan.loan_amount)}
				bankLedgers={bankOptions}
				subtitle={`Eligible Topup Amount is ${precision(loan.eligible_amount) - precision(loan.loan_amount)}`}
			/>
			<ChangeLoanItemsDialog
				ref={changeLoanItemDialogRef}
				fullWidth={true}
				maxWidth={'lg'}
				open={openChangeItems}
				items={loan.items}
				title="Partially Release Items"
				narration={true}
				close={() => {
					setOpenChangeItems(false)
				}}
				save={(items) => {
					updateLoanItems(items)
				}}
				subtitle={`Remove items or edit the quantity of items remaining and submit`}
			/>
		</Dialog>
	);
}

ViewLoanDialog.prototype = {
	open: PropTypes.bool.isRequired,
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired
};