import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TableRow, TableBody, Fab, InputAdornment, TextField, Icon, Button } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { FinanceService } from "../Services/FinanceService";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { StyledTableCell, BootstrapTooltip, format_without_time } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import SingleDate from '../components/SingleDate';
import moment from 'moment';
import ItemSelection from "../components/ItemSelection";
import { ROLES } from "../Roles";
import { TXN_TYPE, PAYMENT_TYPE } from "../Constants";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
let counter = 0;
const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function BankTransaction(props){

    const {state, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const [txnType, setTxnType] = React.useState(PAYMENT_TYPE.P);
    const [selectedContra, setSelectedContra] = React.useState(null);
    const [accounts, setAccounts] = React.useState([]);
    const [entryBy, setEntryBy] = React.useState('');
    const [contraAccounts, setContraAccounts] = React.useState([]);
    const [branches, setBranches] = React.useState([]);
    const [selectedBranches, setSelectedBranches] = React.useState(null);
    const [startdate, setStartDate] = React.useState(moment().startOf('day'));
    const [minDate, setMinDate] = React.useState(moment().startOf('day'));
    const [currentRows, setCurrentRows] = React.useState([]);
    const groupId = '4';
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [id, setId] = React.useState(null);

    const columns = [
        { id: 'name', align: 'center', numeric: false, disablePadding: true, label: 'Ledger Name', sortable: false },
        { id: 'amount', align: 'center', numeric: true, disablePadding: false, label: 'Amount', sortable: false },
        // { id: 'credit', align: 'center', numeric: true, disablePadding: false, label: 'Credit', sortable: false },
        { id: 'narration', align: 'center', numeric: true, disablePadding: false, label: 'Narration', sortable: false },
        { id: 'actions', align: 'center', numeric: false, disablePadding: false, label: '', sortable: false },
    ];

    React.useEffect(() => {
        let bList = state.branches.filter(b => b.id !== '-1');
        setBranches(bList);
        if(bList.length === 1){
            setSelectedBranches(bList[0]);
            fetchMaster(bList[0]);
        }
        if(!state.user){
            history.push('/');
        } else {
            setMinDate(state.user.roles.includes(ROLES.ADMIN) ? moment().startOf('day').set('M', 3).startOf('M') :  moment().startOf('day'));
            if(props.location.search){
                let id = null
                let qString = queryString.parse(props.location.search);
                if(qString.id) {
                    id = qString.id;
                    setId(id);
                    if(qString.startdate)
                        setStartDate(moment(qString.startdate));
                    if(qString.branchId){
                        setSelectedBranches(state.branches.find(b => b.id === qString.branchId));
                        fetchMaster(state.branches.find(b => b.id === qString.branchId));
                    }
                }
            }
        }
    }, [true])

    React.useEffect(() => {
        if(loadedMaster){
            if(id){
                showProgressDialog();
                FinanceService.getTransactionDetails(id)
                .then((data) => {
                    if(data){
                        setSelectedContra(contraAccounts.find(c => c.id === data.contra_id));
                        setTxnType(data.credit_debit === TXN_TYPE.C ? PAYMENT_TYPE.R : PAYMENT_TYPE.P);
                        setEntryBy(data.entry_by);
                        setCurrentRows([{
                            id: id,
                            rowid : counter++,
                            account: accounts.find(a => a.id === data.account_id),
                            amount: data.amount,
                            narration: data.narration
                        }]);
                    }
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    closeProgressDialog();
                    showConfirmDialog('Failed to load Transaction', undefined, () => {
                        history.push('/');
                        closeConfirmDialog();
                    });
                });
            } else {
                closeProgressDialog();
            }
        }
    }, [loadedMaster, id])

    const handleBranchChange = (branch) => {
        if(null !== branch){
            if(selectedBranches === null){
                setSelectedBranches(branch);
                fetchMaster(branch, true);
            } else if(selectedBranches !== null && branch.id !== selectedBranches.id){
                setSelectedBranches(branch);
                fetchMaster(branch, true);
                
            }
        } else {
            setSelectedBranches(null);
            setLoadedMaster(false);
        }
    }

    const fetchMaster = (branch, clearRows = false) => {
        showProgressDialog();
        FinanceService.getLedgers(null, branch.id, null, 0, 9999)
        .then(data => {
            if(data && data.length > 0) {
                let accounts = data.filter(a => a.account_group_id !== groupId);
                let contraAccounts = data.filter(a => a.account_group_id === groupId);
                setAccounts(accounts);
                if(contraAccounts.length === 1){
                    setSelectedContra(contraAccounts[0]);
                }
                setContraAccounts(contraAccounts);
            }
            newEntry(clearRows);
            setLoadedMaster(true);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
        });
    }

    const newEntry = (clearRows = false) => {
        let row = {
            rowid : counter++,
            account: null,
            amount: null,
            narration: null
        }
        if(clearRows){
            setCurrentRows([row]);
        } else {
            setCurrentRows([...currentRows, row]);
        }
    }

    const handleAccountChange = rowid => account => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, account : account} 
                : row 
        ))
    }

    const handleAmountChange = rowid => event => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, amount : event.target.value} 
                : row 
        ))
    }
    
    const handleNarrationChange = rowid => event => {
        setCurrentRows(
            currentRows.map(row => 
                row.rowid === rowid 
                ? {...row, narration : event.target.value} 
                : row 
        ))
    }

    const handleRemove = rowid => event => {
        setCurrentRows(currentRows.filter(r => r.rowid !== rowid));
    }

    const saveTransaction = () => {
        if(currentRows.length > 0 && selectedBranches && selectedContra && txnType && startdate){
            setSubmitted(true);
            let txnRows = currentRows.map(row => ({
                id: id || null,
                branch_id : selectedBranches.id,
                contra_id : selectedContra.id,
                account_id : row.account.id,
                amount : row.amount,
                credit_debit: txnType === PAYMENT_TYPE.P ? TXN_TYPE.D : TXN_TYPE.C,
                narration : row.narration,
                txn_date : startdate.format(format_without_time),
                txn_type : "BANK",
                entry_by: entryBy,
            }));
            // let p = txnRows.map(row => FinanceService.saveLedgerTransaction(row));
            showProgressDialog('Saving Transactions....');
            FinanceService.saveLedgerTransaction(txnRows)
            .then(data => {
                if(data){
                    showSnackBar('Saved Transaction', "info");
                    setCurrentRows([]);
                    resetForm();
                } else {
                    showSnackBar('Something went Wrong. Please try again or contact Admin.', "warning");
                }
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setSubmitted(false);
                closeProgressDialog();
            })
        }
    }

    const resetForm = () => {
        setSelectedContra(null);
        setSelectedBranches(null);
        setStartDate(moment().startOf('day'));
        setSubmitted(false);
        setEntryBy('');
    };

    const handleOptionChange = (event, newValue) => {
        if(newValue){
            setTxnType(newValue);
        }
    }

    return (
        <>
            <Grid container direction="row" spacing={1} justifyContent="center">
                {/* <Grid item xl={12} lg={12} xs={12}>
                    <div style={{float: 'left'}}>
                        
                    </div>
                </Grid> */}
                <Grid item xl={12} lg={12} xs={12}>
                    <Paper className={classes.section}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} lg={4}>
                                <ToggleButtonGroup
                                    value={txnType}
                                    exclusive
                                    onChange={handleOptionChange}
                                    aria-label="transaction type"
                                    style={{height: 38, marginTop: 8}}
                                    >
                                    <ToggleButton value={PAYMENT_TYPE.P} aria-label="Payment" style={{width: 150}}>
                                        Payment
                                    </ToggleButton>
                                    <ToggleButton value={PAYMENT_TYPE.R} aria-label="Receipt"  style={{width: 150}}>
                                        Receipt
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <ItemSelection 
                                    required 
                                    clearable={true} 
                                    label='Select Entity' 
                                    value={selectedBranches} 
                                    optionLabel='name' 
                                    options={branches} 
                                    selected={handleBranchChange}/>
                            </Grid>
                            { contraAccounts.length > 1 && (
                                <Grid item xs={12} lg={3}>
                                    <ItemSelection 
                                        clearable={true} 
                                        required 
                                        label='Select Bank Account' 
                                        value={selectedContra} 
                                        optionLabel='name' 
                                        options={contraAccounts} 
                                        selected={setSelectedContra}/>
                                </Grid>
                            )}
                            <Grid item>
                                <div style={{maxWidth: 170}}>
                                    <SingleDate 
                                        date={startdate}
                                        minDate={minDate}
                                        maxDate={moment()}
                                        setDate={(date) => {
                                            setStartDate(date);
                                        }}/>
                                    </div>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div style={{minHeight: 300, maxHeight: 400, overflowY: 'auto', display: 'block'}}>
                                    <Table className={classes.table} stickyHeader>
                                        <EnhancedTableHead
                                            order={""}
                                            orderBy={""}
                                            onRequestSort={() => {}}
                                            rows={columns}  />
                                        <TableBody>
                                            {currentRows.map((row, i) => 
                                                <TableRow key={i} hover>
                                                    <StyledTableCell scope="row" align="justify" padding='none' style={{minWidth: '20vw'}}>
                                                        <ItemSelection 
                                                            clearable={true} 
                                                            required 
                                                            label='Particular' 
                                                            value={row.account} 
                                                            optionLabel='name' 
                                                            fullWidth
                                                            options={accounts.sort((a, b) => a.group_name.localeCompare(b.group_name))} 
                                                            selected={handleAccountChange(row.rowid)}
                                                            groupBy={(option) => option.group_name}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='none'>
                                                        <TextField 
                                                            id="outlined-basic" 
                                                            label="Amount" 
                                                            name="amount"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={row.amount ? row.amount : ''} 
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                                min: 0
                                                            }}
                                                            style={{marginTop: 8}}
                                                            type="number"
                                                            onChange={handleAmountChange(row.rowid)}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='none'>
                                                        <TextField 
                                                            id="outlined-basic" 
                                                            label="Narration" 
                                                            name="narration" 
                                                            multiline 
                                                            rowsMax="2" 
                                                            variant="outlined" 
                                                            value={row.narration ? row.narration : ''} 
                                                            fullWidth 
                                                            margin="dense"
                                                            onChange={handleNarrationChange(row.rowid)}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row" align="right" padding='normal'>
                                                        <Icon style={{marginTop: 8}} onClick={handleRemove(row.rowid)}>delete</Icon>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} lg={12}>
                                <Divider style={{marginTop: 10, marginBottom: 10}}/>
                            </Grid> */}
                            <Grid container item xs={12} lg={12} justifyContent={"space-between"} alignItems="center" style={{marginTop: 8}}>
                                <Grid item xs={12} lg={4}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Entered By" 
                                        name="entry_by"
                                        required 
                                        multiline 
                                        row={2}
                                        rowsMax="2" 
                                        variant="outlined" 
                                        value={entryBy ? entryBy : ''} 
                                        fullWidth 
                                        margin="dense"
                                        style={{marginLeft: 16}}
                                        onChange={(e) => setEntryBy(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button 
                                        disabled={(entryBy.length === 0 || currentRows.length === 0 || !selectedBranches || !txnType) || submitted} 
                                        variant="contained"
                                        color="primary" 
                                        onClick={saveTransaction}>Save Transaction</Button> 
                                </Grid>
                                {/* <div style={{float: 'right', marginRight: 20, marginTop: 20}}>
                                       
                                </div> */}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <BootstrapTooltip title="Click to add new Entry">
                <Fab onClick={newEntry} color="primary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip>
        </>
    )
}