import Config from '../Config';
import { RestService } from './RestService';

export const FCMService = {
    register: async function(header = {}, body = {}){
        return RestService.post(Config.register_fcm_url, header, body)
        .then(response => response.json())
    },
    unregister: async function(header = {}, body = {}){
        return RestService.post(Config.unregister_fcm_url, header, body)
        .then(response => response.json())
    },
};