import Config from '../Config';
import { RestService } from './RestService';
import { format, format_without_time } from '../Utils';
import moment from 'moment';

export const LoanRequestsService = {
    //v2
    getNextPledgeNo: async function (branchId) {
        return RestService.get(Config.get_next_pledge_no.replace("{branchId}", branchId), {})
            .then(response => response.json());
    },
    getReferrerSuggestions: async function () {
        return RestService.get(Config.get_ref_suggestions, {})
            .then(response => response.json());
    },
    getLoanHistory: async function (customerId = null) {
        return RestService.get(Config.get_loan_history.replace("{customerId}", customerId), {})
            .then(response => response.json());
    },
    getLoanCount: async function (startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId) {
        return RestService.get(`${Config.get_loans}count=true&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${selectedSchemeId && selectedSchemeId.length > 1 ? `&schemes=${selectedSchemeId}` : ''}${selectedStatusId && selectedStatusId.length > 1 ? `&statuses=${selectedStatusId}` : ''}`, {})
            .then(response => response.json());
    },
    getLoans: async function (startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId, sort, direction, start, offset) {
        return RestService.get(`${Config.get_loans}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${selectedSchemeId && selectedSchemeId.length > 1 ? `&schemes=${selectedSchemeId}` : ''}${selectedStatusId && selectedStatusId.length > 1 ? `&statuses=${selectedStatusId}` : ''}`, {})
            .then(response => response.json());
    },
    getLoansExport: async function (startdate, enddate, fc, selectedBranchIds, selectedSchemeId, selectedStatusId, sort, direction, start, offset) {
        return RestService.get(`${Config.get_loans}export=true&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${selectedSchemeId && selectedSchemeId.length > 1 ? `&schemes=${selectedSchemeId}` : ''}${selectedStatusId && selectedStatusId.length > 1 ? `&statuses=${selectedStatusId}` : ''}`, {})
            .then(response => response.blob());
    },
    reportOutstandingLoanCount: async function (startdate, enddate, fc, selectedBranchIds, schemeType, amountLTE = null, amountGTE = null) {
        return RestService.get(`${Config.report_loans_outstanding}count=true${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${schemeType ? `&scheme_type=${schemeType}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, {})
            .then(response => response.json());
    },
    reportLoanDueCount: async function (startdate, enddate, fc, selectedBranchIds) {
        return RestService.get(`${Config.report_loans_due}count=true${startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : ''}&todate=${moment(enddate).endOf('day').format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    reportOutstandingLoan: async function (startdate, enddate, fc, selectedBranchIds, schemeType, amountLTE = null, amountGTE = null, sort, direction, start, offset) {
        return RestService.get(`${Config.report_loans_outstanding}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${schemeType ? `&scheme_type=${schemeType}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, {})
            .then(response => response.json());
    },
    reportLoanDue: async function (startdate, enddate, fc, selectedBranchIds, sort, direction, start, offset) {
        return RestService.get(`${Config.report_loans_due}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    reportReleasedLoanCount: async function (startdate, enddate, fc, selectedBranchIds, schemes, amountLTE = null, amountGTE = null) {
        return RestService.get(`${Config.report_loans_released}count=true${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${schemes && schemes.length > 1 ? `&schemes=${schemes}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, {})
            .then(response => response.json());
    },
    reportReleasedLoan: async function (startdate, enddate, fc, selectedBranchIds, schemes, amountLTE = null, amountGTE = null, sort, direction, start, offset) {
        return RestService.get(`${Config.report_loans_released}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${schemes && schemes.length > 1 ? `&schemes=${schemes}` : ''}${amountLTE ? `&amountLTE=${amountLTE}` : ''}${amountGTE ? `&amountGTE=${amountGTE}` : ''}`, {})
            .then(response => response.json());
    },
    reportOutstandingDateWise: async function (startdate, enddate, selectedBranchIds) {
        return RestService.get(`${Config.report_loans_outstanding_datewise}${startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : ''}&todate=${moment(enddate).startOf('day').format(format)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    reportLoyaltyCostAnalysis: async function (startdate, enddate, selectedBranchIds, selectedCriteria) {
        return RestService.get(`${Config.report_loyalty_cost_analysis}${startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : ''}&todate=${moment(enddate).startOf('day').format(format)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${selectedCriteria ? `&criteria=${selectedCriteria}` : ''}`, {})
            .then(response => response.json());
    },
    reportFormG: async function (startdate, enddate, selectedBranchId) {
        return RestService.get(`${Config.report_form_g}${startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : ''}&todate=${moment(enddate).endOf('day').utc().format(format)}${selectedBranchId && selectedBranchId.length > 1 ? `&branch=${selectedBranchId}` : ''}`, {})
            .then(response => response.json());
    },
    getLoanDetails: async function (pledgeNo = null) {
        return RestService.get(`${Config.get_loan_details}pledgeNo=${pledgeNo}`, {})
            .then(response => response.json());
    },
    getLoanTransactions: async function (pledgeNo = null) {
        return RestService.get(`${Config.get_loan_transactions}pledgeNo=${pledgeNo}`, {})
            .then(response => response.json());
    },
    cancelRequest: async function (pledgeNo = null) {
        return RestService.delete(`${Config.get_loan_url}pledgeNo=${pledgeNo}`, {});
    },
    closeRequest: async function (pledgeNo = null, body) {
        return RestService.post(`${Config.get_loan_close_url}pledgeNo=${pledgeNo}`, {}, body);
    },
    saveLoan: async function (body, headers = {}) {
        return RestService.postFormData(Config.get_loan_url, headers, body)
            .then(response => response.json());
    },
    getLoansSearch: async function (filter, selectedBranchIds, start, offset) {
        return RestService.get(`${Config.get_loans_search_url}from=${start}&offset=${offset}&filter=${filter}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    addLoanTransaction: async function (body, headers = {}) {
        return RestService.post(Config.get_loan_transaction_url, headers, body)
            .then(response => response.json());
    },
    getLoansDashboardCount: async function (selectedStatusId, selectedBranchIds, startdate, enddate) {
        return RestService.get(`${Config.get_loans_count_dashboard}fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${selectedStatusId && selectedStatusId.length > 1 ? `&statuses=${selectedStatusId}` : ''}`, {})
            .then(response => response.json());
    },
    getLoansDashboardAmount: async function (selectedBranchIds, disbursed = false, startdate, enddate) {
        return RestService.get(`${Config.get_loans_amount_dashboard}disbursed=${disbursed}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    getLoansDashboardAmountGraph: async function (selectedBranchIds, type = null, startdate, enddate) {
        return RestService.get(`${Config.get_loans_amount_dashboard_graph}${type && type.length > 1 ? `type=${type}` : ''}&fromdate=${moment(startdate).format(format_without_time)}&todate=${moment(enddate).format(format_without_time)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    getLoansDashboardInterest: async function (selectedBranchIds, startdate, enddate) {
        return RestService.get(`${Config.get_loans_interest_dashboard}fromdate=${moment(startdate).format(format_without_time)}&todate=${moment(enddate).format(format_without_time)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}`, {})
            .then(response => response.json());
    },
    //v1
    getLoanRequests: async function (status, sort, direction, start, offset, filter, startdate, enddate, executive_ids, branch_ids) {
        return RestService.get(`${Config.get_loan_requests_url.replace("{status}", status)}&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&startdate=${moment(startdate).startOf('day').format(format)}&enddate=${enddate.startOf('day').format(format)}&filter=${filter}${executive_ids && executive_ids.length > 1 ? `&executive_id=${executive_ids}` : ''}${branch_ids && branch_ids.length > 1 ? `&branch_id=${branch_ids}` : ''}`, {})
            .then(response => response.json());
    },
    getTotalCount: async function (status, filter, startdate, enddate, executive_ids, branch_ids) {
        return RestService.get(`${Config.get_count_loan_requests_url.replace("{status}", status)}&startdate=${moment(startdate).startOf('day').format(format)}&enddate=${enddate.startOf('day').format(format)}&filter=${filter}${executive_ids && executive_ids.length > 1 ? `&executive_id=${executive_ids}` : ''}${branch_ids && branch_ids.length > 1 ? `&branch_id=${branch_ids}` : ''}`, {})
            .then(response => response.json());
    },
    getCountByPincode: async function (pincode) {
        return RestService.get(Config.get_count_loan_requests_by_pincode_url.replace("{pincode}", pincode), {})
            .then(response => response.json());
    },
    updateStatus: async function (header, body) {
        return RestService.post(Config.update_loan_status, header, body)
            .then(response => response.json());
    },
    getLoanRequestById(id) {
        return RestService.get(`${Config.get_loan_request_by_id_url.replace("{id}", id)}`, {})
            .then(response => response.json());
    },
    updateLoanRequest: async function (header, body) {
        return RestService.post(Config.update_loan_requests_url.replace("{id}", body.id), header, body)
            .then(response => response.json());
    },
    createLoanRequest: async function (header, body) {
        return RestService.post(Config.create_loan_requests_url.replace("{type}", body.request_type), header, body)
            .then(response => response.json());
    },
    postInterest: async function (body, header = {}) {
        return RestService.post(Config.post_loan_interest_requests_url, header, body)
            .then(response => response.json());
    },
    topupLoan: async function (body, header = {}) {
        return RestService.post(Config.topup_loan_requests_url, header, body)
            .then(response => response.json());
    },
    createFollowup: async function (header, body) {
        return RestService.post(Config.loan_followup, header, body)
            .then(response => response.json());
    },
    removeFollowup: async function (id, header = {}) {
        return RestService.delete(Config.remove_loan_followup.replace("{id}", id), header);
    },
    getFollowUps: async function (startdate, enddate, selectedBranchIds, pledgeNo) {
        return RestService.get(`${Config.loan_followup_list}?${startdate ? `fromdate=${moment(startdate).utc().format(format)}` : ''}&todate=${moment(enddate).utc().format(format)}${selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : ''}${pledgeNo ? `&pledge_no=${pledgeNo}` : ''}`, {})
            .then(response => response.json());
    },
    getItemsHistory: async function (loanId) {
        return RestService.get(Config.loan_items_history_list.replace('{id}', loanId), {})
            .then(response => response.json());
    },
    updateLoanItems: async function (loanId, body, header = {}) {
        return RestService.post(Config.update_loan_items_url.replace('{id}', loanId), header, body)
            .then(response => response.json());
    },
};