import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, Fade, LinearProgress, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from '../AppContextProvider';
import { FinanceService } from '../Services/FinanceService';
import ItemSelection from '../components/ItemSelection';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    autoCompleteSelectionTextField: {

    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewLedgerDialog(props) {
    const [open, setOpen] = React.useState(false);
    const {showConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D, showSnackBar, closeConfirmDialog, state} = useContext(AppContext);
    const [loading, setLoading] = React.useState(false);

    const title = 'Ledger';
    const [ledger, setLedger] = React.useState({});
    const [branches, setBranches] = React.useState([]);
    const [selectedBranches, setSelectedBranches] = React.useState(null);
    const [ledgerGroups, setLedgerGroups] = React.useState([]);
    const [selectedLedgerGroup, setSelectedLedgerGroup] = React.useState(null);
    
    const classes = useStyles();

    React.useEffect(() => {
        setBranches(state.branches);
        if(props.branch_id){
            setSelectedBranches(state.branches.find(b => b.id === props.branch_id));
        }
        setOpen(props.open)
    }, [props.open]);

    // const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        setLedger(ledger => ({...ledger, [field]: value}));
    }

    React.useEffect(() => {
        if(open){
            setLoading(true);
            let reqs = [FinanceService.getLedgerGroups()];
            if(props.id){
                reqs.push(FinanceService.getLedgerById(props.id))
            }
            Promise.all(reqs)
            .then(datas => {
                if(datas[0]){
                    setLedgerGroups(datas[0]);
                }
                if(datas[1]){
                    setLedger(datas[1]);
                    let lg = datas[0].find(d => d.id === datas[1].account_group_id);
                    setSelectedLedgerGroup(lg);
                    setSelectedBranches(state.branches.find(b => b.id === (datas[1].branch_id || '-1')));
                } else {
                    setSelectedLedgerGroup(datas[0][0]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
                    handleClose(false);
                    closeConfirmDialog1D();
                }, 'OK');
                setLoading(false);
                handleClose();
            });
        }
    }, [open]);

    const handleBranchChange = (branch) => {
        setSelectedBranches(branch);
    }

    const handleSave = () => {
        if(ledger.name && selectedBranches && selectedLedgerGroup){
            showConfirmDialog("Confirm save Ledger?", undefined, () => {
                save();
                closeConfirmDialog();
            });
        } else {
            showSnackBar('All fields are Mandatory', 'error');
        }
    }

    const save = () => {
        let header = {user_id : state.user.id };
        let body = ledger;
        body.opening_balance = 0;
        body.branch_id = selectedBranches.id !== '-1' ? selectedBranches.id : null;
        body.group_id = selectedLedgerGroup.id;
        FinanceService.saveLedger(body, header)
        .then((data) => {
            showSnackBar(`Ledger Saved.`, 'success');
            props.save();
            handleClose();
        })
        .catch((err) => {
            console.log(err);
            showSnackBar(`Failed to Save Ledger. Please check again or contact Admin.`, 'error');
        })
    }
    
    const handleClose = () => {
        props.close();
        setLedger({});
        setOpen(false);
        setSelectedLedgerGroup(null)
    };

  return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                <Grid item xs={12} lg={12}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '10ms' : '0ms'
                        }}
                        unmountOnExit
                        >
                        <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                    </Fade>
                </Grid>
                <Grid item container xs={12} lg={12} key={1} spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={12}>
                        <TextField 
                            id="outlined-basic" 
                            label="Name" 
                            name="name"
                            variant="outlined" 
                            value={ledger.name || ''} 
                            fullWidth 
                            margin="dense"
                            type="text"
                            onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <ItemSelection 
                            required 
                            clearable={true} 
                            disabled={ledger.id ? true: false}
                            label='Select Entity' 
                            value={selectedBranches} 
                            optionLabel='name' 
                            options={branches} 
                            selected={handleBranchChange}/>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography className={classes.heading} align="left" style={{marginTop: 12}}>Under</Typography>
                    </Grid>
                    <Grid item xs={6} lg={10}>
                        <ItemSelection 
                            clearable={true} 
                            required 
                            label='Ledger Group' 
                            value={selectedLedgerGroup} 
                            optionLabel='name' 
                            options={ledgerGroups} 
                            selected={setSelectedLedgerGroup}/>
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
  );
}