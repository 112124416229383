// let organisation = localStorage.getItem('geomoney.organisation') === null ? "sysbrew-test" : localStorage.getItem('geomoney.organisation');

const host = process.env.NODE_ENV === 'production' ? "https://geo-service.sysbrew.in/" : "http://localhost:8082/goldloan/";
// const host = process.env.NODE_ENV === 'production' ? "http://goldloan.sysbrew.in/" : "http://localhost:6001/";

const Config = {

    authenticate_url: `${host}authenticate`,
    authenticate_token_url: `${host}authenticate/token`,
    gold_rate_url: `${host}gold/rate/current`,
    update_gold_rate_url: `${host}gold/rate/{rate}`,
    get_summary_url: `${host}report/summary/{startdate}/{enddate}`,
    get_loan_requests_url: `${host}loanRequests/list/{status}?`,
    get_loan_request_by_id_url: `${host}loanRequests/id/{id}`,
    get_count_loan_requests_url: `${host}loanRequests/count/{status}?`,
    get_count_loan_requests_by_pincode_url: `${host}loanRequests/count/pincode/{pincode}?`,
    update_loan_requests_url: `${host}loanRequests/update/{id}`,
    create_loan_requests_url: `${host}loanRequests/add/{type}`,
    update_loan_status: `${host}loanRequests/change/status`,
    get_roles: `${host}roles/list`,
    get_items: `${host}items/list?`,
    get_schemes: `${host}schemes/list?`,
    get_fys: `${host}fy/list?`,
    get_loans: `${host}loan/list?`,
    report_loans_outstanding: `${host}report/loan/outstanding?`,
    report_loans_due: `${host}report/loan/due?`,
    report_loans_released: `${host}report/loan/release?`,
    report_loans_outstanding_datewise: `${host}report/loan/outstanding/datewise?`,
    report_loyalty_cost_analysis: `${host}report/loyalty/cost/analysis?`,
    report_form_g: `${host}report/form/g?`,
    get_loans_count_dashboard: `${host}dashboard/loan/count?`,
    get_loans_amount_dashboard: `${host}dashboard/loan/amount?`,
    get_loans_amount_dashboard_graph: `${host}dashboard/loan/amount/graph?`,
    get_loans_interest_dashboard: `${host}dashboard/loan/interest?`,
    get_kyc_docs: `${host}kyc/document/list?`,
    get_users_internal_url: `${host}users/list`,
    get_users_url: `${host}users/list/{type}`,
    get_branches_url: `${host}branch/list?`,
    toggle_user_status: `${host}users/togglestatus/{id}`,
    toggle_item_status: `${host}items/togglestatus/{id}`,
    toggle_kyc_doc_status: `${host}kyc/document/togglestatus/{id}`,
    remove_user: `${host}users/{id}`,
    remove_item: `${host}items/{id}`,
    remove_scheme: `${host}scheme/{id}`,
    update_scheme_gold_rate: `${host}scheme/rate/{id}/{rate}`,
    remove_kyc_doc: `${host}kyc/document/{id}`,
    save_item: `${host}items`,
    save_scheme: `${host}scheme`,
    save_kyc_doc: `${host}kyc/document?`,
    remove_branch: `${host}branch/delete`,
    create_branch: `${host}branch`,
    toggle_branch_status: `${host}branch/togglestatus/{id}`,
    get_branch_manager_history: `${host}branch/history/{id}`,
    create_user: `${host}users/add/{type}`,
    create_user_internal: `${host}users`,
    get_customer_list: `${host}customers/list?`,
    get_customer_name_list: `${host}customers/list/mini?`,
    get_customer_by_id: `${host}customer/{id}?`,
    change_customer_documentation: `${host}customer/document/{id}`,
    upload_customer_kyc: `${host}customer/kyc/{id}`,
    get_serviceareas_url: `${host}serviceareas/list`,
    google_url: "http://maps.google.com?q=",
    remove_servicearea: `${host}serviceareas/delete`,
    create_servicearea: `${host}serviceareas/add`,
    work_pattern: `${host}available/pattern`,
    holiday_list: `${host}available/holidays`,
    holiday_url: `${host}available/holidays/{holiday}`,
    broadcast_notification_url: `${host}available/broadcast/notification`,
    send_notification_url: `${host}available/individual/notification`,
    send_sms_url: `${host}available/sms/notification`,
    get_organisation_master_url: `${host}organisation`,
    update_organisation_master_url: `${host}organisation`,
    list_background_jobs: `${host}jobs?`,
    background_job_status: `${host}jobs/status/{type}?`,
    enable_job_status: `${host}jobs/enable/{id}/{status}?`,
    enable_customer_sms: `${host}customer/sms/enable/{id}/{status}?`,

    //Loan
    get_next_pledge_no: `${host}loan/pledge/no/{branchId}`,
    get_ref_suggestions: `${host}loan/referrer/suggestions`,
    get_loan_history: `${host}loan/history/{customerId}`,
    get_loan_details: `${host}loan/details?`,
    get_loan_transactions: `${host}loan/transactions?`,
    get_loan_transaction_url: `${host}loan/transaction?`,
    get_loan_url: `${host}loan?`,
    get_loan_close_url: `${host}loan/close?`,
    get_loans_search_url: `${host}loan/search?`,
    post_loan_interest_requests_url: `${host}loan/post/interest`,
    loan_followup: `${host}loan/followup`,
    remove_loan_followup: `${host}loan/followup/{id}`,
    loan_followup_list: `${host}loan/followup/list`,
    topup_loan_requests_url: `${host}loan/add/topup`,
    loan_items_history_list: `${host}loan/item/history/{id}`,
    update_loan_items_url: `${host}loan/change/items/{id}`,


    save_ledger: `${host}account/ledger`,
    get_ledger_by_id: `${host}account/ledger/id/{id}`,
    get_ledger_list: `${host}account/list/ledger?`,
    get_ledger_opening_balance: `${host}account/ledger/opening/balance/{accountId}/{branchId}/{date}?`,
    get_ledger_group_list: `${host}account/list/ledger/groups?`,
    get_ledger_transactions: `${host}account/ledger/transaction/{type}/{accountId}/{fromdate}/{todate}?`,
    get_day_book: `${host}account/ledger/day/book/{fromdate}/{todate}?`,
    get_transaction_details: `${host}account/transaction/detail/{tId}?`,
    get_journal_transaction_details: `${host}account/journal/transaction/detail/{linkId}?`,
    save_ledger_transaction: `${host}account/transaction`,
    save_journal_ledger_transaction: `${host}account/transaction/journal`,
    remove_ledger_transaction: `${host}account/transaction/{type}/{id}`,


    register_fcm_url: `${host}users/fcm/add`,
    unregister_fcm_url: `${host}users/fcm/delete`,
    
    REFRESH_INTERVAL: 300000
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];