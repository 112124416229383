import { Avatar, CardHeader, Divider, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import ImageHolder from './ImageHolder'

const CustomerDetails = ({ classes, loan }) => {
    return (
        <Grid item xs={12} lg={12}>
            <Paper variant="outlined" className={classes.section} elevation={3}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="step1" className={classes.avatar}>
                            1
                        </Avatar>
                    }
                    title="Customer information"
                    titleTypographyProps={{ variant: 'h4' }}
                />
                <Divider />
                <div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8 }}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                        <Grid item xs={12} lg={12} container justifyContent="center" spacing={2} alignItems="center">
                            <Grid item xs={12} lg={1}>
                                <Typography variant="h5" className={classes.header}>Name</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Typography variant="subtitle1">{loan.customer.name || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <Typography variant="h5" className={classes.header}>Code</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Typography variant="subtitle1">{loan.customer.cust_code || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <Typography variant="h5" className={classes.header}>Mobile</Typography>
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <Typography variant="subtitle1">{loan.customer.mobile || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Typography variant="h5" className={classes.header}>Alternate number</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Typography variant="subtitle1">{loan.customer.alternate_no || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Occupation</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{loan.customer.occupation || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Nominee</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{loan.customer.nominee || '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Email</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{loan.customer.email || '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Referred By</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{loan.referred_by || '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Current Address</Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Typography variant="subtitle1">{loan.customer.address1 || ''}{`, ${loan.customer.address2}` || ''}, {loan.customer.address3 || ''}, {loan.customer.pincode || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>Permanent Address</Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Typography variant="subtitle1">{loan.customer.permanent_address1 || ''}{`, ${loan.customer.permanent_address2}` || ''}, {loan.customer.permanent_address3 || ''}, {loan.customer.permanent_pincode || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>KYC Type</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{(loan.customer.kyc && loan.customer.kyc.length > 0 && loan.customer.kyc[0].type) || '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="h5" className={classes.header}>KYC Identifier</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="subtitle1">{(loan.customer.kyc && loan.customer.kyc.length > 0 && loan.customer.kyc[0].identifier) || '-'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={12}>
                            <Grid item xs={12} lg={4}>
                                {loan.customer.documents && loan.customer.documents.length > 0 ? (
                                    <ImageHolder
                                        title="Photo"
                                        link={loan.customer.documents[0].link}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {loan.customer.kyc && loan.customer.kyc.length > 0 ? (
                                    <ImageHolder
                                        title="KYC"
                                        link={loan.customer.kyc[0].link}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {loan.link ? (
                                    <ImageHolder
                                        title="Asset"
                                        link={loan.link}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Grid>
    )
}

export default CustomerDetails