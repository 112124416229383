import Config from '../Config';
import { RestService } from './RestService';
import { format_without_time } from '../Utils';

export const ToolsService = {
    getWorkPattern: async function(){
        return RestService.get(`${Config.work_pattern}`, {})
        .then(response => response.json());
    },
    getHolidayList: async function(startdate, enddate){
        return RestService.get(`${Config.holiday_list}/${startdate.format(format_without_time)}/${enddate.format(format_without_time)}`, {})
        .then(response => response.json());
    },
    addHoliday: async function(holiday){
        return RestService.post(Config.holiday_url.replace("{holiday}", holiday), {}, { })
        .then(response => response.json());
    },
    removeHoliday: async function(holiday){
        return RestService.delete(Config.holiday_url.replace("{holiday}", holiday), {})
        .then(response => response.json());
    },
    saveWorkPattern: async function(body){
        return RestService.post(Config.work_pattern, {}, body)
        .then(response => response.json());
    },
    sendBroadcastNotification: async function(title, body){
        return RestService.get(`${Config.broadcast_notification_url}?title=${title}&body=${body}`, {})
        .then(response => response.json());
    },
    sendNotification: async function(id, title, body, loan_request_id, request_no = undefined){
        return RestService.get(`${Config.send_notification_url}?title=${title}&body=${body}&id=${id}${loan_request_id ? `&loan_request_id=${loan_request_id}` : ''}${request_no ? `&request_no=${request_no}` : ''}`, {});
    },
    sendSMS: async function(body = {}, headers = {}){
        return RestService.post(Config.send_sms_url, headers, body);
    },
};