import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { Grid, TextField } from '@material-ui/core';
import ItemSelection from './ItemSelection';
import { formatNumber, precision } from '../Utils';
import { ROLES } from '../Roles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left"
  },
  dialogContent: {
    paddingTop: 0
  }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PostAmountDialog(props) {
  const { showSnackBar, state } = useContext(AppContext);
  const {
    subtitle,
    mode,
    minAmount,
    bankLedgers,
    currentDisbursementLedger,
    showAmount,
    narration,
    showPartRepayment,
    isPartialPaymentRequired = false
  } = props;
  const [disbursementModes, setDisbursementModes] = React.useState([]);
  const [selectedMode, setSelectedMode] = React.useState(null);
  const [selectedLedger, setSelectedLedger] = React.useState(null);
  const [additionalNarration, setAdditionalNarration] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [requested, setRequested] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [partRepayment, setPartRepayment] = React.useState(0);
  const disableEdit = !state.user || !state.user.roles.includes(ROLES.ADMIN);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open);
      setTitle(props.title);
      let dm = []
      dm.push({ name: 'CASH', value: 'CASH' });
      if (bankLedgers && bankLedgers.length > 0) {
        dm.push({ name: 'BANK', value: 'BANK' });
      }
      setDisbursementModes(dm)
      setAmount(minAmount || 0)
      setSelectedMode(mode === 'CASH' ? dm[0] : dm[1])
      setSelectedLedger(currentDisbursementLedger)
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  const handleClose = () => {
    props.close();
    setTitle(null);
    setSelectedLedger(null)
    setAdditionalNarration(null)
    setSelectedMode(null)
    setAmount(0)
    setPartRepayment(0)
    setOpen(false);
    setRequested(false)
  };

  const handleSave = () => {
    if (isPartialPaymentRequired && partRepayment === 0) {
      showSnackBar(`Partial payment is mandatory to proceed`, 'info')
    } else if (showAmount && amount < minAmount) {
      showSnackBar(`Amount cannot be less than the balance ${formatNumber(minAmount)}`, 'info')
    } else {
      setRequested(true)
      props.save(amount, selectedMode.value, selectedLedger, additionalNarration || '', partRepayment || 0)
      handleClose()
    }
  }

  const handleChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setAmount(value);
  }

  const handlePartRepaymentChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setPartRepayment(value);
  }

  const handleNarrationChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setAdditionalNarration(value);
  }

  return (
    <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="overline" component="div">
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="amount"
              label="Amount"
              placeholder="Amount"
              disabled={!showAmount}
              name="amount"
              value={precision(amount)}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              style={{ marginLeft: 8, paddingRight: 10 }}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          {
            showPartRepayment && (
              <Grid item xs={12} lg={6}>
                <TextField
                  id="partRepayment"
                  label="Part Repayment"
                  placeholder="Part Repayment"
                  name="partRepayment"
                  value={precision(partRepayment || 0)}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  style={{ marginLeft: 8, paddingRight: 10 }}
                  onChange={handlePartRepaymentChange}
                  required={isPartialPaymentRequired}
                  fullWidth
                />
              </Grid>
            )
          }
          {
            narration && (
              <Grid item xs={12} lg={12}>
                <TextField
                  id="remarks"
                  label="Remarks"
                  placeholder="Remarks"
                  name="additionalNarration"
                  defaultValue={''}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  style={{ marginLeft: 8, paddingRight: 10 }}
                  onChange={handleNarrationChange}
                  fullWidth
                />
              </Grid>
            )
          }
          {
            selectedMode && (
              <Grid item xs={12} lg={3}>
                <ItemSelection
                  required
                  label=''
                  disabled={disableEdit}
                  value={selectedMode || null}
                  optionLabel='name'
                  options={disbursementModes}
                  style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
                  formClass={classes.formAutoClass}
                  selected={(mode) => {
                    setSelectedMode(mode);
                  }} />
              </Grid>
            )
          }
          {/* {
                    selectedMode && selectedMode.value === 'BANK' && (
                      <Grid item xs={8} lg={2}>
                        <Typography variant="subtitle2" align="right">Bank</Typography>
                      </Grid>
                    )
                  } */}
          {
            selectedMode && selectedMode.value === 'BANK' && (
              <Grid item xs={12} lg={9}>
                <ItemSelection
                  required
                  label=''
                  disabled={disableEdit}
                  value={selectedLedger || null}
                  optionLabel='name'
                  options={bankLedgers}
                  style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
                  formClass={classes.formAutoClass}
                  selected={(ledger) => {
                    setSelectedLedger(ledger);
                  }} />
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button disabled={requested} autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PostAmountDialog.prototype = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  showAmount: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  minAmount: PropTypes.number.isRequired,
  bankLedgers: PropTypes.array,
  currentDisbursementLedger: PropTypes.object.isRequired,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool,
  isPartialPaymentRequired: PropTypes.bool,
};