import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from  'clsx';
import { Grid, Tooltip, Icon, colors, Typography, Table, TableBody, TableRow, Fab, Paper, IconButton } from "@material-ui/core";
import { StyledTableCell, BootstrapTooltip, formatNumber, getTimeInIST, format_fancy } from "../Utils";
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import EnhancedTableHead from "../components/EnhancedTableHead";
import AddIcon from '@material-ui/icons/Add';
import SearchBar from "../components/SearchBar";
import NewSchemeDialog from "./NewSchemeDialog";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    formControl: {
    },
    customToolTip: {
        fontSize: 16
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.blue[900]
        }
    },
    actions: {
        float: 'right',
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function SchemeMaster(props) {
    const classes = useStyles();
    const { setTitle, showSnackBar, showConfirmDialog, closeConfirmDialog, setLinearLoading, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const [schemes, setSchemes] = React.useState([]);
    const order = 'asc';
    const orderBy = 'name';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');

    //Dialog
    const [scheme, setScheme] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const columns = [
        { id: 'index', align: 'center', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'name', align: 'justify', numeric: false, disablePadding: true, label: 'Name', sortable: false },
        { id: 'amount', align: 'center', numeric: false, disablePadding: true, label: 'Amount', sortable: false },
        { id: 'standard', align: 'center', numeric: false, disablePadding: true, label: 'Standard', sortable: false },
        { id: 'allowed_deviation', align: 'center', numeric: false, disablePadding: true, label: 'Allowed Deviation', sortable: false },
        // { id: 'interval_days', align: 'center', numeric: false, disablePadding: true, label: 'No of Days calculation', sortable: false },
        { id: 'interest_1', align: 'center', numeric: false, disablePadding: true, label: 'Interest Slab 1', sortable: false, tooltip: 'Upto 30 days' },
        { id: 'interest_2', align: 'center', numeric: false, disablePadding: true, label: 'Interest Slab 2', sortable: false, tooltip: 'Between 30 - 270 days' },
        { id: 'interest_3', align: 'center', numeric: false, disablePadding: true, label: 'Interest Slab 3', sortable: false, tooltip: 'Beyond 270 days' },
        { id: 'startdate', align: 'center', numeric: false, disablePadding: true, label: 'Start Date', sortable: false },
        { id: 'enddate', align: 'center', numeric: false, disablePadding: true, label: 'End Date', sortable: false },
        { id: 'action', align: 'justify', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    React.useEffect(() => {
        setTitle("Manage Schemes");
    }, [true]);

    React.useEffect(() => {
        if(generate){
            showProgressDialog();
            MasterService.getSchemesList()
            .then(data => {
                // console.log(data);
                setSchemes(data);
                updateRows(data);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate]);

    const updateRows = (list) => {
        let rows = [];
        list.forEach((element, index) => {
            rows.push(
                <TableRow key={index} hover>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {index + 1}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="justify" padding='none'>
                        {element.name}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {formatNumber(element.amount)}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {
                            element.standard && (element.standard) ?  
                            <CheckIcon color="primary"/>
                            : ''
                        }
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {parseFloat(element.allowed_deviation).toFixed(2)}%
                    </StyledTableCell>
                    {/* <StyledTableCell scope="row" align="center" padding='none'>
                        {element.interval_days || 0}
                    </StyledTableCell> */}
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {element.interest_1 || 0}%
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {element.interest_2 || 0}%
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {element.interest_3 || 0}%
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {element.startdate ? getTimeInIST(element.startdate).format(format_fancy) : '-'}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="center" padding='none'>
                        {element.enddate ? getTimeInIST(element.enddate).format(format_fancy) : '-'}
                    </StyledTableCell>
                    <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                        <Tooltip title="Remove">
                            <IconButton size="small" aria-label="Remove" onClick={(e) => remove(element)}>
                                <Icon style={{fontSize: 18}}>delete</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton size="small" aria-label="Edit" onClick={newItem(element)}>
                                <Icon style={{fontSize: 18}}>edit</Icon>
                            </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                </TableRow>
            );
        });
        setCurrentRows(rows);
    }

    const filterCriteria = (e) => {
        setLinearLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        
        let filteredList = schemes.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = schemes;
        }
        setMessage(filteredList.length > 0 ? '' : 'No matching Scheme');
        updateRows(filteredList);
        setLinearLoading(false);
    }

    const newItem = (item) => e => {
        setScheme(item);
        setDialogOpen(true);
    }

    const remove = item => {
        showConfirmDialog("Confirm removal of Scheme?", undefined, () => {
            closeConfirmDialog();
            MasterService.removeScheme(item.id)
            .then((data) => {
                if(data.success){
                    showSnackBar(`Removed Scheme`, 'info');
                    setGenerate(true);
                } else {
                    showConfirmDialog(data.error, undefined, undefined);
                }
            })
            .catch((error) => {
                showConfirmDialog("Could not remove Scheme. Please try again.", undefined, undefined);
            });
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={8} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={12}>
                                    <div style={{float: 'right', marginTop: 16, marginRight: 8}}>
                                        <SearchBar 
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                            setFilter(e.target.value);
                                            filterCriteria(e);
                                        }}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={() => {}}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        schemes === 0 && 
                                            <Typography color="error" variant="subtitle2">No Schemes Defined</Typography>
                                    }
                                    {
                                        message.length > 0 && 
                                            <Typography color="error" variant="subtitle2">{message}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <BootstrapTooltip title="Add Item">
                                <Fab onClick={newItem(null)} color="primary" aria-label="Add" className={classes.fab}>
                                    <AddIcon />
                                </Fab>
                            </BootstrapTooltip>
                        </Paper>  
                    </Grid>
                </Grid>      
                <NewSchemeDialog
                    scheme={scheme}
                    open={dialogOpen} 
                    close={() => {
                        setScheme(null);
                        setDialogOpen(false);
                    }}
                    save={()=> {
                        setGenerate(true);
                    }}/>
            </div>
        </div>
    );
}
 