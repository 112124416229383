import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { BootstrapTooltip } from './Utils';
import { AppContext } from './AppContextProvider';
//Tour
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SearchBar from './components/SearchBar';
import SearchDialog from './components/SearchDialog';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
        }
    },
    hide: {
        display: 'none',
    },
    title: {
        [theme.breakpoints.down('md')]: {
          flexGrow: 1,
          alignSelf: 'flex-end',
          marginTop: 8,
          fontSize: 14,
          textAlign: 'left'
        }
    },
    networkBadge: {
        position: "absolute", 
        right: 120,
        [theme.breakpoints.down('md')]: {
          right: 120,
        }
    },
    onlyDesktop: {
        display: 'inherit',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }
}));

export default function MyAppBar(props){

    const classes = useStyles();
    const {state, handleDrawerOpen, handleLogout, tourAvailable, userWantsToTour, changeBranch} = useContext(AppContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchText, setSearchText] = React.useState('');
    const [openSearchDialog, setOpenSearchDialog] = React.useState(false);

    const showTour = e => {
        userWantsToTour(true);
    }

    const cancelTour = () => {
        userWantsToTour(false);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (branch) => e => {
        if(branch && state.selectedBranch.id !== branch.id){
            changeBranch(branch, true);
        }
        setAnchorEl(null);
    };

    const handleCloseSearch = () => {
        setSearchText('');
        setOpenSearchDialog(false);
    }

    const search = () => {
        setOpenSearchDialog(true);
    }

    return (
        <AppBar position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: state.open,
            })}
            >
            <Toolbar disableGutters={!state.open}>
                { state.isAuthenticated  &&
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={classNames(classes.menuButton, {
                            [classes.hide]: state.open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                }
                { state.isAuthenticated && !state.open && 
                    <Typography variant="h4" color="inherit" className={classes.title} noWrap>
                        {state.title}
                    </Typography>
                }
                { state.isAuthenticated && state.branches.length > 0 && (
                        state.branches.length === 1 ?
                        (
                            <BootstrapTooltip title={state.branches[0].licence_no}>
                                <Typography variant="h4" color="inherit" style={{position: "absolute", right: 550}} noWrap>
                                    {state.branches[0].name}
                                </Typography>
                            </BootstrapTooltip>
                        ) :
                        (
                            <div style={{position: "absolute", right: 550}}>
                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    variant="text"
                                    onClick={handleClick}
                                    style={{color: 'white'}}
                                    endIcon={<ArrowDropDownIcon/>}
                                >
                                    {state.selectedBranch.name}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose(null)}
                                >
                                {
                                    state.branches.map((branch, index) => (
                                        <MenuItem key={index} onClick={handleClose(branch)}>{branch.name}</MenuItem>
                                    ))
                                }
                                </Menu>
                            </div>
                        )
                    )
                }
                {
                    state.isAuthenticated && (
                        <div className={classes.onlyDesktop} style={{position: "absolute", right: 250}}>
                            <SearchBar 
                                searchText={searchText} 
                                searchIconColor="white" 
                                hint="Search for Loan" 
                                onTextChange={(e) => {setSearchText(e.target.value)}}
                                onKeyUp={(event) => {
                                    event.preventDefault();
                                    if (event.key === 'Enter' || event.key === 'Tab'){
                                        search();
                                    }
                                }}/>
                        </div>
                    )
                }
                { state.isDisconnected &&
                    <BootstrapTooltip title={'No internet connection available. App offline.'}>
                        <IconButton className={classes.networkBadge} color="inherit"><WifiOffIcon/></IconButton>
                    </BootstrapTooltip>
                }
                {/* { state.isAuthenticated &&
                <Button color="inherit" className={classes.sectionMobile} style={{position: "absolute", right: 130}}>{state.user.name ? state.user.name : ''}</Button>
                } */}
                {state.isAuthenticated && tourAvailable && (
                    <Tooltip title="Help">
                        <IconButton color="inherit" style={{position: "absolute", right: 70}} onClick={showTour}>
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                    )
                }
                {!state.isAuthenticating && (state.isAuthenticated ? 
                    <Tooltip title="Logout">
                        <IconButton color="inherit" style={{position: "absolute", right: 20}}  onClick={handleLogout}><ExitToAppOutlinedIcon/></IconButton>
                    </Tooltip>
                :
                    <Button color="inherit" style={{position: "absolute", right: 20, color: "white"}}><Link to="/login" style={{color: "inherit", textDecoration: "none"}}>Login</Link></Button>
                )}
                <Tour
                    steps={state.tourSteps}
                    showNumber={false}
                    rounded={5}
                    isOpen={state.userIsTouring}
                    onRequestClose={cancelTour}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody} />
            </Toolbar>
            <SearchDialog 
                text={searchText}
				open={openSearchDialog}
				onClose={handleCloseSearch}/>
        </AppBar>
    );
}