import React, { Component } from "react";
import "../css/employee.css";
import { Form } from 'react-bootstrap';
import {Button, Chip, ListItemText, Grid} from '@material-ui/core';
import { UserService } from "../Services/UserService";
import { RoleService } from "../Services/RoleService";
import AddIcon from '@material-ui/icons/Add';
import {
    IconButton,
    Tooltip,
    Icon,
    Paper,
    withStyles,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import {AppContext} from '../AppContextProvider';
import { HtmlTooltip, getTimeInIST, format_fancy } from "../Utils";
import ItemSelection from "../components/ItemSelection";
import { green, red } from "@material-ui/core/colors";
// import { ROLES } from "../Roles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(2), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: 50
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
  });

class Employee extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_employee: { name: "", mobile: "", code: "", email: "", password: "", ip_address: "", roles: []},
        show: false,
        master_roles: new Map(),
        master_branches: [],
      }

      this.toggleStatus = this.toggleStatus.bind(this);
      this.createModifyEmployee = this.createModifyEmployee.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.hasAdmin = this.hasAdmin.bind(this);
    }
 
    componentDidMount(){
        // const {setTitle} = this.context;
        // setTitle(`Manage Users (${this.state.data.length || 0})`);
        this.fetchRoles();
        const {state} = this.context;
        this.setState({master_branches : state.branches.filter(b => b.id !== '-1')});
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_employee: { name: "", mobile: "", code: "", email: "", password: "", ip_address: "", roles: []}
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    fetchData(){
        const {showProgressDialog, closeProgressDialog, setLinearLoading, setTitle} = this.context;
        setLinearLoading(true);
        showProgressDialog();
        UserService.getEmployeesList()
        .then((data) => {
            this.setState({data: data,  originalData: data});
            this.updateRows(data);
            setTitle(`Manage Users (${data.length || 0})`);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
            closeProgressDialog();
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((user) => {
            rows.push(<UserDetails classes={this.props.classes} key={user.id} user={user} toggleStatus={this.toggleStatus} edit={this.edit} remove={this.remove} roles={this.state.master_roles}/>);
        });
        this.setState({rows: rows});
    }

    toggleStatus(e,user){
        const {showSnackBar} = this.context;
        e.preventDefault();
        UserService.toggleStatus(user.id)
        .then((data) => {
            const status = parseInt(data.status) === 0 ? "Deactivated": "Activated";
            showSnackBar(`${status} employee ${user.name} Account`, 'info');
            this.fetchData();
        })
        .catch(error => console.log(error));
    }

    fetchRoles(){
        const {setLinearLoading} = this.context;
        setLinearLoading(true);
        RoleService.getRoleList()
        .then((data) => {
            let roleDetailMap = new Map();
            data.forEach(role => {
                roleDetailMap.set(role.id, role);
            });
            this.setState({master_roles: roleDetailMap},  this.fetchData());
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        });
    }

    filterCriteria = (e) => {
        const {setLinearLoading} = this.context;
        setLinearLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()) || data.mobile.includes(searchText) || data.address.includes(searchText) || (data.ip_address || '').includes(searchText));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Employee'
        });
        this.updateRows(filteredList);
        setLinearLoading(false);
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_employee;
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    handleChangeCheckBox(e) {
        let change = this.state.new_employee;
        if(e.target.checked && !change['roles'].includes(e.target.name)){
            change['roles'].push(e.target.name);
        } else {
            var index = change['roles'].indexOf(e.target.name);
            change['roles'].splice(index, 1);
        }
        if(change.roles.includes('ADMIN')){
            change.selectedBranches = this.state.master_branches;
        }
        this.setState({new_employee: change});
    }

    hasAdmin() {
        let hasAdminRole = false;
        this.state.new_employee.roles.forEach((roleId) => {
            if(this.state.master_roles.get(roleId).code === "ADMIN"){
                hasAdminRole = true;
            }
        });
        return hasAdminRole;
    }

    createModifyEmployee(e){
        e.preventDefault();
        const {showConfirmDialog, closeConfirmDialog, showSnackBar} = this.context;
        let ds = this.state.data.filter(d => d.id !== this.state.new_employee.id);
        let emailTaken = this.state.new_employee.email ? ds.map(u => u.email).includes(this.state.new_employee.email) : false;
        let mobileTaken = this.state.new_employee.mobile ? ds.map(u => u.mobile).includes(this.state.new_employee.mobile) : false;
        if(this.state.new_employee.email && !emailTaken && !mobileTaken && this.state.new_employee.name && this.state.new_employee.mobile && (this.state.new_employee.ip_address || '').length >= 0 
            && (this.state.new_employee.id || (!this.state.new_employee.id && this.state.new_employee.password.length >= 4)) 
            //&& (this.state.new_employee.roles.includes(ROLES.ADMIN) || (!this.state.new_employee.roles.includes(ROLES.ADMIN) && (this.state.new_employee.ip_address || '').length >= 7)) 
            && this.state.new_employee.roles.length > 0){
            if(this.hasAdmin() || (!this.hasAdmin() && (!this.state.new_employee.id || (this.state.new_employee.id && this.state.new_employee.id !== this.context.state.user.id)))){
                showConfirmDialog("Confirm?", undefined, () => {
                    this.createEmployeeAPI(this.state.new_employee);
                    closeConfirmDialog();
                });
            } else {
                showConfirmDialog("Cannot remove Admin role for currently logged in user", undefined, undefined);
            }
        } else if(this.state.new_employee.name && this.state.new_employee.mobile && this.state.new_employee.roles.length === 0){
            showSnackBar(`Please select atleast one role`, 'error');
        } else if(this.state.new_employee.code.length < 4){
            showSnackBar(`Code should be atleast 4 characters`, 'error');
        } else if(emailTaken){
            showSnackBar(`Email has already been used. Please choose another one.`, 'error');
        } else if(mobileTaken){
            showSnackBar(`Mobile has already been used. Please choose another one.`, 'error');
        } else {
            showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, user){
        const {showConfirmDialog, closeConfirmDialog, state : s} = this.context;
        e.preventDefault();
        if(s.user.id !== user.id){
            showConfirmDialog("Confirm removal of Account?", undefined, () => {
                this.removeEmployeeAPI(this.dialog, user.id);
                closeConfirmDialog();
            });
        } else {
            showConfirmDialog("Cannot remove yourself", undefined, undefined);
        }
    }

    removeEmployeeAPI(dialog, id){
        const {showConfirmDialog, showSnackBar} = this.context;
        UserService.removeUser(id)
        .then((data) => {
            this.fetchData();
            showSnackBar(`Removed Employee Account`, 'info');
        })
        .catch((error) => {
            showConfirmDialog("Could not remove Account. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, user){
        e.preventDefault();
        if(user){
            let new_employee = Object.assign({}, user);
            delete new_employee.password;
            new_employee.selectedBranches = new_employee.branches.map(b => this.state.master_branches.find(c => c.id === b.id));
            this.setState({new_employee}, () => this.handleShow());
        } else {
            this.handleShow();
        }
    }

    createEmployeeAPI(new_employee){
        const {showConfirmDialog, showSnackBar, setLinearLoading, state: s} = this.context;
        //new_employee.code = 1234;
        let body = new_employee;
        body.branches = body.roles.includes('ADMIN') ? [] : body.selectedBranches ? body.selectedBranches.map(b => b.id) : [];
        delete body.selectedBranches;
        setLinearLoading(true);
        let headers = {user_id : s.user.id };
        UserService.createEmployee(headers, body)
        .then((response) => {
            if(response.status === 409){
                showSnackBar(`Employee already exists with the same mobile no. Please check again.`, 'error');
            } else {
                this.setState({
                    new_employee: { name: "", mobile: "", code: "", email: "", password: "", ip_address: "", roles: []}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
            }
            setLinearLoading(false);
        })
        .catch((error) => {
            setLinearLoading(false);
            showConfirmDialog(`Failed to create Employee. Please try again.`, undefined, undefined);
        });
        
    }

    render() {
        let message = this.state.message;
        let {classes} = this.props;
        return (
            <div className="Home">
                {/* <h3>Accounts <Badge>{this.state.data.length}</Badge></h3> */}
                <div >
                    <Paper className={classes.root}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar onSearchTextChange={this.filterCriteria} searchHint="Name, Address or mobile"/>
                        <Table size="small" className={classes.table}>
                            <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Mobile</Typography></TableCell>
                                {/* <TableCell align="center"><Typography variant="subtitle1">Roles</Typography></TableCell> */}
                                <TableCell align="center"><Typography variant="subtitle1">Branches</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle1">Email</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">IP Address</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Last Used</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Status</Typography></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                        {
                            message && <p style={{color:"red", paddingTop: 16, fontSize: "14pt", paddingLeft:"20px", width: "100%", textAlign: "center"}}>{message}</p>
                        }
                    </Paper>
                </div>
                <Dialog disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'
                    style={{marginTop : "5%"}}>
                    <DialogTitle id="alert-dialog-title">{this.state.show ? "Employee" : ""}</DialogTitle>
                    <DialogContent>
                        {this.userForm( "User", this.createModifyEmployee, "Save")}
                    </DialogContent>
                </Dialog>
                <Tooltip title="Add User">
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    userForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="name"
                        label="Name"
                        placeholder="Name"
                        name="name"
                        defaultValue={this.state.new_employee.name}
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="mobile"
                        label="mobile"
                        placeholder="Mobile"
                        name="mobile"
                        defaultValue={this.state.new_employee.mobile}
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        pattern="[0-9]{10}"
                        type="text"
                        InputProps={{
                            inputProps: { min: 0, maxLength: 10 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="outlined-email-input"
                        label="Email"
                        className={this.props.classes.textField}
                        type="email"
                        required
                        name="email"
                        autoComplete="off"
                        defaultValue={this.state.new_employee.email}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="code"
                        label="code"
                        placeholder="Code"
                        name="code"
                        autoComplete="off"
                        defaultValue={this.state.new_employee.code}
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        fullWidth
                        pattern="[0-9]{4}"
                        type="text"
                        InputProps={{
                            inputProps: { min: 0, maxLength: 4 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="password"
                        label="Password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        className={this.props.classes.textField}
                        margin="normal"
                        type="password"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        InputProps={{
                            inputProps: { min: 0, maxLength: 20 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="ip_address"
                        label="IP Address"
                        placeholder="IP Address"
                        name="ip_address"
                        autoComplete="off"
                        defaultValue={this.state.new_employee.ip_address}
                        className={this.props.classes.textField}
                        margin="normal"
                        type="text"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        InputProps={{
                            inputProps: { min: 0, maxLength: 15 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="address"
                        label="Address"
                        placeholder="Address"
                        name="address"
                        autoComplete="off"
                        defaultValue={this.state.new_employee.address}
                        className={this.props.classes.textField}
                        margin="normal"
                        type="text"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        InputProps={{
                            inputProps: { min: 0, maxLength: 200 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="pincode"
                        label="Pincode"
                        placeholder="Pincode"
                        name="pincode"
                        autoComplete="off"
                        defaultValue={this.state.new_employee.pincode}
                        className={this.props.classes.textField}
                        margin="normal"
                        type="text"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        InputProps={{
                            inputProps: { min: 0, maxLength: 6 }
                        }}
                        />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <ItemSelection
                        label='Entity' 
                        multiple
                        disabled={this.state.new_employee.roles.includes('ADMIN')}
                        value={this.state.new_employee.selectedBranches || []} 
                        optionLabel='name' 
                        options={this.state.master_branches} 
                        selected={(branches) => this.setState(prevState => ({new_employee: {...prevState.new_employee, selectedBranches: branches}}))}/>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FormGroup row>
                        {
                            [...this.state.master_roles.keys()].map((key) => {
                                let role = this.state.master_roles.get(key);
                                return (
                                    <Tooltip title={role.description} key={key}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.new_employee.roles.includes(role.id) || false}
                                                    onChange={this.handleChangeCheckBox}
                                                    color="primary"
                                                    name={role.id}
                                                    />
                                            }
                                            label={role.name}
                                        />
                                    </Tooltip>
                                )
                            })
                        }
                    </FormGroup>
                </Grid>
                <Grid item>
                    <Button type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
                </Grid>
                <Grid item>
                    {this.state.show ? (<Button variant="text" style={{color: 'red'}} onClick={this.handleClose}>Close</Button>): ""}
                </Grid>
            </Grid>
        </Form>
    )

}

class UserDetails extends Component {

    render(){
      const user = this.props.user;
      const roles = this.props.roles;
      const classes = this.props.classes;
      //<TableRow key={user.id} style={{backgroundColor : user.status === 0 ?  "#ff8a80" : "", color: "white"}}>
        return (
            <TableRow key={user.id} style={{color: "white"}}>
                <TableCell component="th" scope="row">
                    <ListItemText 
                        primary={user.name}
                        secondary={user.address || ''} />
                    {
                        
                        user.roles.map((u, i) => <Chip size="small" variant="outlined" key={i} style={{margin: 3}} label={roles.get(u).name}/>)
                    }
                </TableCell>
                <TableCell align="center">{user.mobile || '-'}</TableCell>
                {/* <TableCell align="center">
                    {
                        
                        user.roles.map((u, i) => <Chip size="small" key={i} style={{margin: 3}} color="primary" label={roles.get(u).name}/>)
                    }
                </TableCell> */}
                <TableCell align="center" style={{maxWidth: 200}}>
                    {
                        
                        user.branches.map((u, i) => <Chip size="small" key={i} style={{margin: 3}} color="primary" label={u.name}/>)
                    }
                </TableCell>
                <TableCell align="left">{user.email ? user.email : "-"}</TableCell>
                <TableCell align="center">{user.ip_address ? user.ip_address : "-"}</TableCell>
                <TableCell align="center">{user.last_login ? getTimeInIST(user.last_login).format(format_fancy) : "-"}</TableCell>
                {/* <TableCell align="center">{user.status === 0 ? "Deactive" : "Active"}</TableCell> */}
                <TableCell align="center">
                    {
                        parseInt(user.status) === 0 ? 
                            <HtmlTooltip arrow title={ parseInt(user.status) === 0 ? "Deactive": ""}>
                                <IconButton className={classes.red} component="span">
                                    <FiberManualRecordIcon />
                                </IconButton>
                            </HtmlTooltip> 
                        : 
                            <HtmlTooltip arrow title={ parseInt(user.status) === 0 ? "Deactive": ""}>
                                <IconButton className={classes.green} component="span">
                                    <FiberManualRecordIcon />
                                </IconButton>
                            </HtmlTooltip>
                    }
                </TableCell>
                <TableCell align="right" style={{width: 120}}>
                    <Tooltip title="Activate/Deactivate">
                        <IconButton size="small" aria-label="Activate/Deactivate" onClick={(e) => this.props.toggleStatus(e, user)}>
                            <Icon fontSize="small">power_settings_new</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton size="small" aria-label="Edit" onClick={(e) => this.props.edit(e, user)}>
                            <Icon fontSize="small">edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton size="small" aria-label="Remove" onClick={(e) => this.props.remove(e, user)}>
                            <Icon fontSize="small">delete</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(Employee);