import React, { Component } from "react";
import "../css/employee.css";
import { Form } from 'react-bootstrap';
import { UserService } from "../Services/UserService";
import AddIcon from '@material-ui/icons/Add';
import {
    IconButton,
    Tooltip,
    Icon,
    Paper,
    withStyles,
    Fab,
    Table,
    TableBody,
    TableRow,
    Typography,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    FormControl,
    Button,
    Fade,
    LinearProgress,
    Grid,
    ListItemText,
} from "@material-ui/core";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import Config, { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { ROLES } from "../Roles";
import ManagerHistory from "../components/ManagerHistory";
import {AppContext} from '../AppContextProvider';
import { HtmlTooltip, StyledTableCell } from "../Utils";
import ItemSelection from "../components/ItemSelection";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(0),
        }
    }
  });

class Branch extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_branch: { name: "", mobile: "", address: "", pincode: "", location: "", code: "", email: "", manager_id: "", kml_filer: '', kml_filer_father: ''},
        show: false,
        columns : [
            { id: 'index', numeric: false, disablePadding: true, label: 'Sl.No', sortable: false },
            { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
            { id: 'licence_no', numeric: false, disablePadding: true, label: 'License No', sortable: true },
            { id: 'code', numeric: false, disablePadding: true, label: 'Code', sortable: true },
            { id: 'manager_name', numeric: false, disablePadding: true, label: 'Manager', sortable: false },
            { id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile' , sortable: false },
            // { id: 'address', numeric: false, disablePadding: true, label: 'Address', sortable: true },
            { id: 'pincode', numeric: false, disablePadding: true, label: 'Pin Code', sortable: true  },
            { id: 'location', numeric: false, disablePadding: true, label: 'Geo Location', sortable: false  },
            { id: 'email', numeric: false, disablePadding: true, label: 'Email', sortable: false  },
            { id: 'prefix', numeric: false, disablePadding: true, label: 'Prefix', sortable: false  },
            { id: 'current_sequence', numeric: false, disablePadding: true, label: 'Next Seq No', sortable: false  },
            { id: 'kml_filer', numeric: false, disablePadding: true, label: 'KML Filer', sortable: false  },
            { id: '', numeric: true, disablePadding: false, label: '', sortable: false  }
        ],
        order: 'asc',
        orderBy: 'name',
        numItemsPerPage: 10,
        currentPageNumber: 0,
        totalItems: 0,
        totalPages: 0,
        filter: '',
        manager_options: [],
        managers: [],
        branch: undefined,
        dialog_id: undefined,
        dialog_open: false,
        loading: false,
      }

      this.handleChange = this.handleChange.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
 
    componentDidMount(){
        const {setTitle} = this.context;
        setTitle("Manage Entities");
        this.refresh();
    }

    refresh = () => {
        this.fetchExecutives();
		this.fetchTotalPages();
    }

    fetchExecutives = () => {
        UserService.getEmployeesList()
        .then((data) => {
            let users = data.filter(e => e.roles.includes(ROLES.MANAGER));
            let manager_options = users.map(u => ({
                id: u.id,
                value: u.id, 
                label: `${u.name} - ${u.mobile}`
            }))
            this.setState({managers: users, manager_options});
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    fetchTotalPages(){
        const {setLinearLoading} = this.context;
        setLinearLoading(true);
        let {filter} = this.state;
        UserService.getBranchCount(filter)
        .then((data) => {
            this.setState({
                totalItems: data.count, 
                totalPages: Math.ceil(data.count / this.state.numItemsPerPage) - 1
            });
            if(data.count > 0) {
                this.fetchBranches(0, this.state.numItemsPerPage);
            } else {
                this.setState({rows: [], message: "No Entities", showSpinner: false });
                setLinearLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        });
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_branch: { name: "", licence_no: "", mobile: "", address: "", pincode: "", location: "", code: "", email: "", manager_id: "", prefix: '', current_sequence: 0, kml_filer: '', kml_filer_father: ''},
        });
    }

    fetchBranches = (start, numberItems) => {
        const {setLinearLoading} = this.context;
		let {order, orderBy, filter} = this.state;
        let rows = [];
		let sort = orderBy ? orderBy : '', direction = order ? order : '';
		
		UserService.getBranchList(start, numberItems, filter, sort, direction)
        .then((data) => {
            if(data){
                data.forEach((branch, index) => {
                    rows.push(<BranchDetails index={index + 1  + start} key={index + 1  + start} branch={branch} classes={this.props.classes} remove={this.remove} edit={this.edit} toggleStatus={this.toggleStatus} managerHistory={this.managerHistory}/>);
                });
            }
			this.setState({rows, message: data.length > 0 ? "" : "No Entities"});
			setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        });
	}

	handleSelect = (eventKey, e) => {
        if(eventKey === 'prev'){
            if((this.state.currentPageNumber) > 0){
                this.fetchRow(this.state.currentPageNumber - 1);
            }
        } else if(eventKey === 'next'){
            if((this.state.currentPageNumber) < this.state.totalPages){
                this.fetchRow(this.state.currentPageNumber + 1);
            }   
        }
    }

    fetchRow = (newCurrentPage) => {
        this.fetchBranches(newCurrentPage * this.state.numItemsPerPage, this.state.numItemsPerPage);
        this.setState({ currentPageNumber: newCurrentPage });
    }

	handleChangeRowsPerPage = event => {
        this.setState({ numItemsPerPage : event.target.value, currentPageNumber: 0 }, () => this.fetchBranches(0, this.state.numItemsPerPage));
    }

    handleChangePage = (event, page) => {
        if(this.state.currentPageNumber > page){
            this.fetchRow(this.state.currentPageNumber - 1);
        } else {
            this.fetchRow(this.state.currentPageNumber + 1);
        }
    };

    sortBy = (event, property) => {
        const orderBy = property;
        let order = 'desc';
    
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }
    
        this.setState({ order, orderBy }, () => this.fetchBranches(0, this.state.numItemsPerPage));
    }

	filterCriteria = (e) => {
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value || "";
        }
        this.setState({filter : searchText}, () => this.fetchBranches(0, this.state.numItemsPerPage));
	}

    handleShow() {
        this.setState({ show: true });
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_branch;
        change[e.target.name] = e.target.value;
        this.setState(change);
    }
    
    handleManagerSelection = (id) => {
        let new_branch = this.state.new_branch;
        new_branch.manager_id = id;
        this.setState({new_branch});
    }

    createModifyBranch = (e) => {
        const {showConfirmDialog, showSnackBar, closeConfirmDialog} = this.context;
        e.preventDefault();
        if(this.state.new_branch.name && this.state.new_branch.code && this.state.new_branch.manager_id 
            && this.state.new_branch.mobile && this.state.new_branch.pincode 
            && this.state.new_branch.address.length > 5){
            showConfirmDialog("Confirm?", undefined, () => {
                this.createAPI(this.state.new_branch);
                closeConfirmDialog();
            });
        } else {
            showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, branch){
        const {showConfirmDialog, closeConfirmDialog} = this.context;
        e.preventDefault();
        showConfirmDialog("Confirm removal of Entity?", undefined, () => {
            this.removeAPI(this.dialog, branch.id);
            closeConfirmDialog();
        });
    }

    removeAPI(dialog, id){
        const {showConfirmDialog, showSnackBar} = this.context;
        UserService.removeBranch(id)
        .then((data) => {
            this.refresh();
            showSnackBar(`Removed Entity`, 'success');
        })
        .catch((error) => {
            showConfirmDialog("Could not remove Entity. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, branch){
        e.preventDefault();
        if(branch){
            let new_branch = Object.assign({}, branch);
            this.setState({new_branch}, () => this.handleShow());
        } else {
            this.handleShow();
        }
    }

    managerHistory = (e, branch) => {
        this.setState({dialog_id: branch.id, branch: branch, dialog_open: true});
    }

    createAPI(new_branch){
        const {showConfirmDialog, showSnackBar, setLinearLoading, state: s} = this.context;
        //new_branch.code = 1234;
        let body = new_branch;
        setLinearLoading(true);
        let headers = {user_id : s.user.id };
        this.setState({loading: true});
        UserService.createBranch(headers, body)
        .then((response) => {
            if(response.status === 409){
                showSnackBar(`Entity already exists associated with same Entity code. Please check again.`, 'error');
            } else {
                this.setState({
                    new_branch: { name: "", licence_no: "", mobile: "", address: "", pincode: "", location: "", code: "", email: "", manager_id: "", prefix: '', current_sequence: 0, kml_filer: '', kml_filer_father: ''},
                });
                this.refresh();
                showSnackBar(`Updated Entity`, 'success');
                if(this.state.show) this.handleClose();
            }
            this.setState({loading: false});
            setLinearLoading(false);
        })
        .catch((error) => {
            setLinearLoading(false);
            this.setState({loading: false});
            showConfirmDialog(`Failed to create Entity. Please try again.`, undefined, undefined);
        });
    }

    toggleStatus = (e, branch) => {
        const {showSnackBar} = this.context;
        e.preventDefault();
        UserService.toggleBranchStatus(branch.id)
        .then((data) => {
            const status = data.status === 0 ? "Deactivated": "Activated";
            showSnackBar(`${status} Entity ${branch.name}`, 'info');
            this.refresh();
        })
        .catch(error => console.log(error));
    }

    render() {
        let {classes} = this.props;
        let {dialog_id, branch, dialog_open, rows, message, totalItems, numItemsPerPage, currentPageNumber} = this.state;
        return (
            <div className="Home">
                <div>
                    <Paper className={classes.root}>
                    <EnhancedTableToolbar 
                        title={<Typography varient="h5">{`Entities: ${totalItems}`}</Typography>}
                        refresh={this.refresh} 
                        searchHint="Name, Address, mobile, Pin, Code" 
                        onSearchTextChange={this.filterCriteria}/>
						<Table className={classes.table} size="small">
							<EnhancedTableHead
								order={this.state.order}
								orderBy={this.state.orderBy}
								onRequestSort={this.sortBy}
								rows ={this.state.columns} />
							<TableBody>
								{rows}
							</TableBody>
						</Table>
						<div className={classes.message}>
							{message}
						</div>
						<TablePagination
							rowsPerPageOptions={RowCountOptions}
							component="div"
							count={totalItems}
							rowsPerPage={numItemsPerPage}
							page={currentPageNumber}
							backIconButtonProps={{
								'aria-label': 'Previous Page',
							}}
							nextIconButtonProps={{
								'aria-label': 'Next Page',
							}}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
							classes={{ selectRoot : classes.selectRoot}}
							/>
                    </Paper>
                </div>
                <Dialog 
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'
                    style={{marginTop : "5%"}}>
                    <DialogTitle id="alert-dialog-title">{this.state.show ? "Entity" : ""}</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Fade
                                    in={this.state.loading}
                                    style={{
                                        transitionDelay: this.state.loading ? '10ms' : '0ms'
                                    }}
                                    unmountOnExit
                                    >
                                    <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {this.userForm( "Edit Entity", this.createModifyBranch, "Save")}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Tooltip title="Add Entity">
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={this.props.classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <ManagerHistory id={dialog_id} branch={branch} open={dialog_open} close={() => this.setState({dialog_open: false})}/>
            </div>
        );
    }

    userForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                defaultValue={this.state.new_branch.name}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
            />
            <TextField
                id="code"
                label="Code"
                placeholder="Code"
                name="code"
                autoComplete="off"
                defaultValue={this.state.new_branch.code}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
            />
            <TextField
                id="licence_no"
                label="License No"
                placeholder="License No"
                name="licence_no"
                defaultValue={this.state.new_branch.licence_no}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth />
            <FormControl variant="outlined" fullWidth>
                <ItemSelection 
                    clearable={true} 
                    required 
                    label='Select Manager'
                    value={this.state.new_branch.manager_id ? this.state.manager_options.find((manager) => manager.value === this.state.new_branch.manager_id) : null} 
                    optionLabel='label'
                    //labelTemplate={option => `${option.name} (Mobile: ${option.mobile})`}
                    options={this.state.manager_options} 
                    selected={(manager) => {
                        this.handleManagerSelection(manager.value);
                    }}/>
            </FormControl>
            <TextField
                id="mobile"
                label="Contact Number"
                placeholder="Contact Number"
                name="mobile"
                defaultValue={this.state.new_branch.mobile}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{10}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 10 }
                }}
                />
            <TextField
                id="address"
                label="Address"
                placeholder="Address"
                name="address"
                defaultValue={this.state.new_branch.address}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <FormControl variant="outlined" fullWidth>
                <TextField
                    id="pincode"
                    label="PinCode"
                    placeholder="PinCode"
                    name="pincode"
                    defaultValue={this.state.new_branch.pincode}
                    className={this.props.classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    pattern="[0-9]{10}"
                    type="text"
                    InputProps={{
                        inputProps: { min: 0, maxLength: 6 }
                    }}
                    />
            </FormControl>
            <TextField
                id="location"
                label="Geo Location in the format Latitude,Longitude. eg: 12.33456,9.7855"
                placeholder="Geo Location in the format Latitude,Longitude. eg: 12.33456,9.7855"
                name="location"
                defaultValue={this.state.new_branch.location}
                className={this.props.classes.textField}
                margin="dense"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                />
            <TextField
                id="outlined-email-input"
                label="Email"
                className={this.props.classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                defaultValue={this.state.new_branch.email}
                onChange={this.handleChange}
                margin="dense"
                fullWidth
                variant="outlined"
                />
                <TextField
                    id="prefix"
                    label="Loan No Prefix"
                    placeholder="Loan No Prefix"
                    name="prefix"
                    defaultValue={this.state.new_branch.prefix}
                    className={this.props.classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    type="text"
                    InputProps={{
                        inputProps: { maxLength: 10 }
                    }}
                />
                <TextField
                    id="current_sequence"
                    label="Next Sequence No"
                    placeholder="Next Sequence No"
                    name="current_sequence"
                    defaultValue={this.state.new_branch.current_sequence}
                    className={this.props.classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    pattern="[0-9]{10}"
                    type="text"
                    InputProps={{
                        inputProps: { min: 0, maxLength: 10 }
                    }}
                    />
                <TextField
                    id="kml_filer"
                    label="KML Filer"
                    placeholder="KML Filer"
                    name="kml_filer"
                    defaultValue={this.state.new_branch.kml_filer}
                    className={this.props.classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    type="text"
                    InputProps={{
                        inputProps: { maxLength: 30 }
                    }}
                />
                <TextField
                    id="kml_filer_father"
                    label="Filer Father Name"
                    placeholder="Filer Father Name"
                    name="kml_filer_father"
                    defaultValue={this.state.new_branch.kml_filer_father}
                    className={this.props.classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleChange}
                    required
                    fullWidth
                    type="text"
                    InputProps={{
                        inputProps: { maxLength: 30 }
                    }}
                />
                <Button 
                    variant="outlined" 
                    color="primary" 
                    disabled={this.state.loading}
                    type="submit" 
                    onClick={submitFunction} 
                    style={{marginRight: 10, marginLeft: 10}}>
                    {buttonText}
                </Button>
                {this.state.show ? (<Button disabled={this.state.loading} variant="outlined"  color="secondary" onClick={this.handleClose}>Close</Button>): ""}
        </Form>
    )

}

class BranchDetails extends Component {

    openGoogle(e, current_location){
        let location = current_location.split(",");
        e.preventDefault();
        window.open(Config.google_url+location[0]+","+location[1], "_blank");
    }

    render(){
      const branch = this.props.branch;
      const index = this.props.index;
        return (
            <HtmlTooltip arrow title={branch.status === 0 ?  "Deactive" : ""}>
                <TableRow hover key={branch.id} style={{backgroundColor : branch.status === 0 ?  "#ff8a80" : "", color: "white"}}>
                    <StyledTableCell align="center" scope="row">{index}</StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">
                        <ListItemText
                            primary={branch.name}
                            secondary={branch.address}/>
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">{branch.licence_no || '-'}</StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">{branch.code}</StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">{branch.manager_name}</StyledTableCell>
                    <StyledTableCell align="center">{branch.mobile}</StyledTableCell>
                    <StyledTableCell align="center">
                            {branch.pincode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        {branch.location  ? 
                            <Typography variant="inherit" style={{cursor: 'pointer'}} onClick={(e) => this.openGoogle(e, branch.location)}>
                                {branch.location} 
                            </Typography>
                            : "-"
                        }
                    </StyledTableCell>
                    <StyledTableCell align="center">{branch.email ? branch.email : "-"}</StyledTableCell>
                    <StyledTableCell align="center">{branch.prefix ? branch.prefix : "-"}</StyledTableCell>
                    <StyledTableCell align="center">{branch.current_sequence ? branch.current_sequence : "-"}</StyledTableCell>
                    <StyledTableCell align="center">
                        <ListItemText
                            primary={branch.kml_filer || '-'}
                            secondary={`Father: ${branch.kml_filer_father || ''}`}/>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        <Tooltip title="Edit">
                            <IconButton size="small" aria-label="Edit" onClick={(e) => this.props.edit(e, branch)}>
                                <Icon style={{fontSize:18}}>edit</Icon>
                            </IconButton>
                        </Tooltip>
                        {
                            false 
                            && 
                            <Tooltip title="Remove">
                                <IconButton size="small" aria-label="Remove" onClick={(e) => this.props.remove(e, branch)}>
                                    <Icon style={{fontSize:18}}>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            false 
                            && 
                            <Tooltip title="Activate/Deactivate">
                                <IconButton size="small" aria-label="Activate/Deactivate" onClick={(e) => this.props.toggleStatus(e, branch)}>
                                    <Icon style={{fontSize:18}}>power_settings_new</Icon>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Manager History">
                            <IconButton size="small" aria-label="Manager History" onClick={(e) => this.props.managerHistory(e, branch)}>
                                <Icon style={{fontSize:18}}>history</Icon>
                            </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                </TableRow>
            </HtmlTooltip>
        );
    }
}

export default withStyles(styles)(Branch);