import React from 'react';
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";

export default function OpenNewRequest(props){
    const {openNewLoan} = useContext(AppContext);

    React.useEffect(() =>{
        openNewLoan();
    }, [true])
  
    return (
      <>
        NEW REQUEST
      </>
    )
  }