import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import Employee from "./EmployeePage/Employee";
import Customer from "./CustomerPage/Customer";
import ServiceableAreas from "./ServiceableAreasPage/ServiceableAreas";
import NotFound from "./NotFound/NotFound";

// Components
import Branch from "./BranchPage/Branch";
import Settings from "./Tools/Settings";
import ManageNotification from "./Tools/ManageNotification";
import ManageOrganisationInformation from "./Tools/OrganisationMaster";
import JobSettings from "./Tools/JobSettings";
import Ledger from "./FinancePage/Ledger";
import LedgerReport from "./FinancePage/LedgerReport";
import LedgerTransaction from "./FinancePage/LedgerTransaction";
import CashBookReport from "./FinancePage/CashBookReport";
import BankBookReport from "./FinancePage/BankBookReport";
import JournalBookReport from "./FinancePage/JournalBookReport";
import DayBookReport from "./FinancePage/DayBookReport";
import ItemMaster from "./Masters/ItemMaster";
import KYCMaster from "./Masters/KYCMaster";
import SchemeMaster from "./Masters/SchemeMaster";
import OpenNewRequest from "./LoanRequestPage/NewLoanRequest";
import ViewLoans from "./LoanRequestPage/ViewLoans";
import PrintLoan from "./LoanRequestPage/PrintLoan";
import PledgeOutstandingReport from "./Reports/PledgeOutstandingReport";
import PledgeReleaseReport from "./Reports/PledgeReleaseReport";
import PledgeRiskReport from "./Reports/PledgeRiskReport";
import FormGReport from "./Reports/FormGReport";
import PledgeOutstandingDateWiseReport from "./Reports/PledgeOutstandingDateWiseReport";
import LoyaltyCostAnalysis from "./Reports/LoyaltyCostAnalysis";
import PledgeDueReport from "./Reports/PledgeDueReport";
// import CashTransaction from "./FinancePage/CashTransaction";

export default () =>
  <Switch>
    <Route path={`/login`} exact component={Login} />
    <Route path={`/`} exact component={Home} />
    <Route path={`/accounts/employee`} exact component={Employee} />
    <Route path={`/accounts/customer`} exact component={Customer} />
    <Route path={`/tools/manage/servicearea`} exact component={ServiceableAreas} />
    <Route path={`/tools/manage/branches`} exact component={Branch} />
    <Route path={`/loan/requests`} exact component={OpenNewRequest} />
    <Route path={`/loans`} exact component={ViewLoans} />
    <Route path={`/loans/print`} exact component={PrintLoan} />
    
    {/* Finance */}
    <Route path={`/finance/accounts/ledger`} exact component={Ledger} />
    {/* <Route path={`/finance/accounts/transaction`} exact component={LedgerTransaction} /> */}
    <Route path={`/finance/accounts/transaction/:tab`} exact component={LedgerTransaction} />
    <Route path={`/finance/accounts/ledger/report`} exact component={LedgerReport} />
    <Route path={`/finance/accounts/report/cash/book`} exact component={CashBookReport} />
    <Route path={`/finance/accounts/report/bank/book`} exact component={BankBookReport} />
    <Route path={`/finance/accounts/report/journal/book`} exact component={JournalBookReport} />
    <Route path={`/finance/accounts/report/day/book`} exact component={DayBookReport} />
    
    {/* Tools */}
    <Route path={`/tools/manage/workdays`} exact component={Settings} />
    <Route path={`/tools/manage/notification`} exact component={ManageNotification} />
    <Route path={`/tools/manage/organisation`} exact component={ManageOrganisationInformation} />
    <Route path={`/tools/manage/jobs`} exact component={JobSettings} />
    <Route path={`/tools/manage/items`} exact component={ItemMaster} />
    <Route path={`/tools/manage/kyc`} exact component={KYCMaster} />
    <Route path={`/tools/manage/schemes`} exact component={SchemeMaster} />

    {/* Reports */}
    <Route path={`/report/pledge/outstanding`} exact component={PledgeOutstandingReport} />
    <Route path={`/report/pledge/due`} exact component={PledgeDueReport} />
    <Route path={`/report/pledge/outstanding/datewise`} exact component={PledgeOutstandingDateWiseReport} />
    <Route path={`/report/pledge/release`} exact component={PledgeReleaseReport} />
    <Route path={`/report/loyalty/analysis`} exact component={LoyaltyCostAnalysis} />
    <Route path={`/report/pledge/risk`} exact component={PledgeRiskReport} />
    <Route path={`/report/form/g`} exact component={FormGReport} />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;