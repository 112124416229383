import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { Grid, TextField } from '@material-ui/core';
import { formatNumber, precision } from '../Utils';
import { ROLES } from '../Roles';
import ItemSelection from '../components/ItemSelection';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left"
  },
  dialogContent: {
    paddingTop: 0
  }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TopupLoanDialog(props) {
  const { showSnackBar, state } = useContext(AppContext);
  const { subtitle, mode, eligibleAmount, bankLedgers, currentDisbursementLedger, narration } = props;
  const [disbursementModes, setDisbursementModes] = React.useState([]);
  const [selectedMode, setSelectedMode] = React.useState(null);
  const [selectedLedger, setSelectedLedger] = React.useState(null);
  const [additionalNarration, setAdditionalNarration] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(true);
  const [title, setTitle] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const isAdmin = state.user && state.user.roles.includes(ROLES.ADMIN);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.open) {
      setEnabled(true)
      setOpen(props.open);
      setTitle(props.title);
      let dm = []
      dm.push({ name: 'CASH', value: 'CASH' });
      if (bankLedgers && bankLedgers.length > 0) {
        dm.push({ name: 'BANK', value: 'BANK' });
      }
      setDisbursementModes(dm)
      setAmount(eligibleAmount || 0)
      setSelectedMode(mode === 'CASH' ? dm[0] : dm[1])
      setSelectedLedger(currentDisbursementLedger)
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  const handleClose = () => {
    props.close();
    setTitle(null);
    setSelectedLedger(null)
    setAdditionalNarration(null)
    setSelectedMode(null)
    setAmount(0)
    setOpen(false);
  };

  const handleSave = () => {
    const isAmountCorrect = amount > 0 && isAdmin ? true : (amount <= eligibleAmount)
    if (!isAmountCorrect) {
      showSnackBar(`Amount cannot be greater than ${formatNumber(eligibleAmount)}`, 'info')
    } else {
      setEnabled(false)
      props.save(amount, selectedMode.value, selectedLedger, additionalNarration || '')
      handleClose()
    }
  }

  const handleChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setAmount(value || 0);
  }

  const handleNarrationChange = e => {
    e.preventDefault();
    let value = e.target.value;
    setAdditionalNarration(value);
  }

  return (
    <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ? props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="overline" component="div">
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="amount"
              label="Topup Amount"
              placeholder="Topup Amount"
              name="amount"
              value={precision(amount)}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              style={{ marginLeft: 8, paddingRight: 10 }}
              onChange={handleChange}
              InputProps={{ inputProps: { max: isAdmin ? null : eligibleAmount, min: 1 } }}
              required
              fullWidth
            />
          </Grid>
          {
            narration && (
              <Grid item xs={12} lg={12}>
                <TextField
                  id="remarks"
                  label="Remarks"
                  placeholder="Remarks"
                  name="additionalNarration"
                  defaultValue={''}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  style={{ marginLeft: 8, paddingRight: 10 }}
                  onChange={handleNarrationChange}
                  fullWidth
                />
              </Grid>
            )
          }
          {
            selectedMode && (
              <Grid item xs={12} lg={3}>
                <ItemSelection
                  required
                  label=''
                  disabled={isAdmin}
                  value={selectedMode || null}
                  optionLabel='name'
                  options={disbursementModes}
                  style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
                  formClass={classes.formAutoClass}
                  selected={(mode) => {
                    setSelectedMode(mode);
                  }} />
              </Grid>
            )
          }
          {
            selectedMode && selectedMode.value === 'BANK' && (
              <Grid item xs={12} lg={9}>
                <ItemSelection
                  required
                  label=''
                  disabled={isAdmin}
                  value={selectedLedger || null}
                  optionLabel='name'
                  options={bankLedgers}
                  style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
                  formClass={classes.formAutoClass}
                  selected={(ledger) => {
                    setSelectedLedger(ledger);
                  }} />
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button disabled={!enabled} autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TopupLoanDialog.prototype = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  eligibleAmount: PropTypes.number.isRequired,
  bankLedgers: PropTypes.array,
  currentDisbursementLedger: PropTypes.object.isRequired,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool
};