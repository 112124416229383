import React, { useMemo } from 'react';
import { Toolbar, Typography, Tooltip, IconButton, Icon } from "@material-ui/core";
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
	parent: {
		minWidth: 200
	},
	root: {
		flex: '0 0 25%'
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: "black"
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 180,
			'&:focus': {
				width: 220,
			},
		},
	},
}));

const EnhancedTableToolbar = props => {

	const classes = useStyles();

	const { exportReport, title, subtitle, refresh, onSearchTextChange, searchHint } = props

	const onChange = useMemo(
		() =>
			debounce((value) => {
				onSearchTextChange(value)
			}, 300),
		[onSearchTextChange]
	)

	return (
		<Toolbar >
			{
				title &&
				<div className={classes.root}>
					{title}
					<Typography variant="h6" id="tableTitle">
						{subtitle ? subtitle : ""}
					</Typography>
				</div>
			}
			<div style={{ flex: '1 2 100%' }} />
			{onSearchTextChange &&
				<div className={classes.search}>
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>
					<InputBase
						placeholder={searchHint ? searchHint : "Search…"}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						inputProps={{ 'aria-label': 'search' }}
						onChange={(e) => onChange(e.target.value)}
					/>
				</div>
			}
			{exportReport &&
				<div style={{ color: 'red' }}>
					<Tooltip title="Export">
						<IconButton aria-label="Export" onClick={exportReport}>
							<Icon>save_alt</Icon>
						</IconButton>
					</Tooltip>
				</div>
			}
			{
				refresh &&
				<div style={{ color: 'red' }}>
					<Tooltip title="Refresh">
						<IconButton aria-label="Refresh" onClick={refresh}>
							<Icon>refresh</Icon>
						</IconButton>
					</Tooltip>
				</div>
			}
		</Toolbar>
	);
};

export default EnhancedTableToolbar;