import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { LoanRequestsService } from "../Services/LoanRequestsService";
// import queryString from 'query-string';
import jsPDF from 'jspdf';
import { format_display, format_without_time, format_display_fancy_notime_report } from "../Utils";
import { Button, makeStyles, Grid, Paper, FormControl, RadioGroup, FormControlLabel, Radio, Box } from "@material-ui/core";
import ItemSelection from "../components/ItemSelection";
import moment from "moment";
import 'jspdf-autotable';
import { MasterService } from "../Services/MasterService";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
}));

export default function FormGReport(props) {
    const classes = useStyles();
    const {state, setTitle, showProgressDialog, closeProgressDialog, showSnackBar} = useContext(AppContext);
    const [data, setData] = useState(null);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [selectedBranches, setSelectedBranches] = useState(null);
    const [branches, setBranches] = useState([]);
    const [startdate, setStartDate] = useState(moment().startOf('month').startOf('day'));
    const [enddate, setEndDate] = useState(moment().endOf('month').endOf('day'));
    const [fys, setFYList] = useState([]);
    const [selectedFY, setSelectedFY] = useState(null);
    const [selectedReportType, setSelectedReportType] = React.useState('fy');

    const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
        compress: true
    });

    React.useEffect(() => {
        // console.log(props.location.search);
        // if(props.location.search){
        //     let qString = queryString.parse(props.location.search);
        //     const pledgeNo = qString.no || null;
        //     setPledgeNo(pledgeNo)

        MasterService.getFYList()
        .then(data => {
            setFYList(data);
            if(data.length > 0){
                setSelectedFY(data[0]);
            }
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to fetch FY List. Please reload Page', 'error');
        })

        setTitle('Form G');
        let bL = state.branches.filter(b => b.id !== '-1');
        setBranches(bL);
        if(bL.length === 1){
            setSelectedBranches(bL[0]);
        }
    }, []);

    const refresh = () => {
        if(selectedBranches && selectedFY && selectedReportType){
            showProgressDialog('Fetching Form G...');
            LoanRequestsService.reportFormG(startdate, enddate, selectedBranches.id)
            .then(data => {
                setData({organisation_name: 'GEO Finance', owner: selectedBranches ? selectedBranches.kml_filer || '' : '', father: selectedBranches ? selectedBranches.kml_filer_father || '' : '', ...data});
            })
        } else {
            showSnackBar('Select an Entity First', 'info');
        }
        // if(pledgeNo){
        //     LoanRequestsService.getLoanDetails(pledgeNo)
        //     .then(data => {
        //         let b = state.branches.find(b => b.id === data.branch_id);
        //         setLoan({...data, branch_address: `${b.address} - ${b.pincode}`});
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         closeProgressDialog();
        //     })
        // }
    }

    React.useEffect(()=> {
        if(data){
            var width = doc.internal.pageSize.getWidth();
            // console.log(doc.getFontList());
            var centerOfPage = width / 2;
            var endOfPage = width - 10;
            var startOfPage = 10;
            var offset = 5;
            // doc.setProperties({
            //     title: pledgeNo
            // });
            doc.setFontStyle('bold');
            doc.setFontSize(16);
            doc.text('FORM G', centerOfPage, 8, {align: 'center'});
            doc.setFontSize(11);
            doc.text('(See rule 15(3))', centerOfPage + 2, 14, {align: 'center'});
            doc.text(`Statement of Account for the ${selectedReportType === 'fy' ? 'Year' : 'Quarter'} ended ${enddate.format(format_display_fancy_notime_report)}`, centerOfPage, 20, {align: 'center'});
            doc.setFontStyle('');
            let section1 = 35;
            doc.text(`KML No ${selectedBranches.licence_no || '               '}`, endOfPage - 40, section1, {align: 'left'});
            doc.text(`___________________`, endOfPage - 40, section1, {align: 'left'});
            // doc.text(`_____________________`, endOfPage, 30, {align: 'right'});

            //From
            doc.text(`From     ${data.organisation_name}`, startOfPage, section1, {align: 'left'});
            section1 += offset;
            selectedBranches.address.split(',')
                .forEach((line) => {
                    doc.text(`${line.trim()}`, startOfPage + 14, section1, {align: 'left'});
                    section1 += offset;
                });

            //To
            section1 += offset;
            doc.text(`To`, startOfPage, section1, {align: 'left'});
            section1 += offset;

            doc.text(`The Inspector / The Inspg. Commissioner,`, startOfPage + 14, section1, {align: 'left'});
            section1 += offset;
            doc.text(`AIT & ST ....................................`, startOfPage + 14, section1, {align: 'left'});
            section1 += offset;

            //Salutation
            doc.text(`Sir`, startOfPage, section1, {align: 'left'});
            section1 += offset;

            //Body
            doc.text(`I ${data.owner.padEnd(20, ' ')} son of ${data.father.padEnd(20, ' ')} on behalf of the money-lender whose particulars are given below`,startOfPage + 10, section1, {align: 'left'});
            doc.text(`${"".padEnd(27, '.')}`, startOfPage + 17, section1+1, {align: 'left'});
            doc.text(`${"".padEnd(25, '.')}`, startOfPage + 55, section1+1, {align: 'left'});
            section1 += offset;
            doc.text(`furnish herewith the statement of accounts of the money lender for the ${selectedReportType === 'fy' ? 'year' : 'quarter'} ended ${enddate.format(format_display_fancy_notime_report)}.`, startOfPage, section1, {align: 'left'});
            section1 += offset * 2;

            //Points
            var pointOffset = 0;
            doc.text(`1. Name and full postal address of the money-lender:`, startOfPage + pointOffset, section1, {align: 'left'});
            section1 += offset + 3;
            doc.setFontStyle('bold');
            doc.text(`${data.owner}`, startOfPage + 10, section1, {align: 'left'});
            section1 += offset;
            selectedBranches.address.split(',')
                .forEach((line) => {
                    doc.text(`${line.trim()}`, startOfPage + 10, section1, {align: 'left'});
                    section1 += offset;
                });
            doc.setFontStyle('');

            section1 += 3;
            doc.text(`2. Particulars of branch if any with postal address of each such branch:`, startOfPage + pointOffset, section1, {align: 'left'});

            // Section 3
            section1 += offset*2;
            doc.text(`3. Particulars of loans/advances:`, startOfPage + pointOffset, section1, {align: 'left'});
            section1 += offset;

            // let active = 0;
            // let closed = 0;
            // if(data.count){
            //     // let x = data.count.find(d => d.status === 'ACTIVE');
            //     // active = x ? x.total : 0;
            //     let x = data.count.find(d => d.status === 'CLOSED');
            //     closed = x ? x.total : 0;
            // }

            let rowData = [
                [
                    { content: 'Against security of gold or gold ornaments', styles: { halign: 'left' } }, 
                    { content: `${data.pledge_opened_count || 0}`, styles: { halign: 'right' } },
                    { content: `${data.active || 0}`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against promissory notes', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against fixed assets', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against other forms of security', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
            ];
            doc.autoTable({
                body: rowData,
                head: [[
                    'Name of loan','No. of persons to whom loan was advanced', 'Amount Rs.'
                  ], ['(1)', '(2)','(3)']],
                theme: 'plain',
                startY: section1,
                // tableLineColor: [0, 0, 0],
                // tableLineWidth: 0.5,
                bodyStyles: { halign: 'left', lineWidth: 0.1, lineColor: [0, 0, 0], },
                headStyles: { halign: 'center', lineWidth: 0.1, lineColor: [0, 0, 0], }
            });
            
            // Section 4
            section1 += 60;
            doc.text(`4. Particulars of loans redeemed:`, startOfPage + pointOffset, section1, {align: 'left'});
            section1 += offset;
            rowData = [
                [
                    { content: 'Against security of gold or gold ornaments', styles: { halign: 'left' } }, 
                    { content: `${data.pledge_closed_count || 0}`, styles: { halign: 'right' } },
                    { content: `${data.closed || 0}`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against promissory notes', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against fixed assets', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Against other forms of security', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
            ];

            doc.autoTable({
                body: rowData,
                head: [[
                    'Name of loan','No. of persons from whom loan was received back', 'Amount Rs.'
                  ], ['(1)', '(2)','(3)']],
                theme: 'plain',
                startY: section1 ,
                // tableLineColor: [0, 0, 0],
                // tableLineWidth: 0.5,
                bodyStyles: { halign: 'left', lineWidth: 0.1, lineColor: [0, 0, 0], },
                headStyles: { halign: 'center', lineWidth: 0.1, lineColor: [0, 0, 0], }
            });
            
            // Section 5
            doc.addPage();
            section1 = 10;
            doc.text(`5. Particulars of deposits received:`, startOfPage + pointOffset, section1, {align: 'left'});
            section1 += offset;
            rowData = [
                [
                    { content: 'No. of depositors/persons** from whom deposit was received at the beginning of the year/Quarter*.', styles: { halign: 'left' } }, 
                    { content: ``, styles: { halign: 'right' } },
                    { content: ``, styles: { halign: 'right' } },
                ],
                [
                    { content: 'No. of depositors ** from whom deposit was received during the year/quarter*', styles: { halign: 'left' } }, 
                    { content: ``, styles: { halign: 'right' } },
                    { content: ``, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Total:', styles: { halign: 'left' } }, 
                    { content: `0`, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
                [
                    { content: 'No. of depositors ** to whom deposit was repaid during the year/quarter*', styles: { halign: 'left' } }, 
                    { content: ``, styles: { halign: 'right' } },
                    { content: ``, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Balance number of deposits outstanding at the end ofthe year/Quarter.*', styles: { halign: 'left' } }, 
                    { content: ``, styles: { halign: 'right' } },
                    { content: ``, styles: { halign: 'right' } },
                ],
                [
                    { content: 'Balance no. of depositors to whom deposit is to be repaid at the end of the year/quarter', styles: { halign: 'left' } }, 
                    { content: ``, styles: { halign: 'right' } },
                    { content: `0`, styles: { halign: 'right' } },
                ],
            ];

            doc.autoTable({
                body: rowData,
                head: [[
                    'Number', 'Amount Rs.', 'Form of Security given'
                  ], ['(1)', '(2)','(3)']],
                theme: 'plain',
                startY: section1 ,
                // tableLineColor: [0, 0, 0],
                // tableLineWidth: 0.5,
                bodyStyles: { halign: 'left', lineWidth: 0.1, lineColor: [0, 0, 0], },
                headStyles: { halign: 'center', lineWidth: 0.1, lineColor: [0, 0, 0], }
            });
            
            // Section 6
            section1 += 90;

            let max_outstanding_amount = data.max_outstanding ? data.max_outstanding.amount : 0;
            let max_outstanding_date = data.max_outstanding ? data.max_outstanding.date : '-';

            doc.text(`6. (a) Maximum amount of money outstanding to be redeemed on any day of the quarter/year:`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`${max_outstanding_amount || 0}`, endOfPage, section1, {align: 'right'});
            section1 += offset + 2;
            doc.text(`    (b) The date on which the maximum amount of money was outstanding to be redeemed:`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`${max_outstanding_date ? moment(max_outstanding_date).format(format_display) : '-'}`, endOfPage, section1, {align: 'right'});
            section1 += offset + 2;

            // Section 7
            doc.text(`7. Value of assets at the end of the year/quarter*:`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`-`, endOfPage, section1, {align: 'right'});
            section1 += offset + 2;

            // Section 8
            doc.text(`8. Total liabilities outstanding at the end of the year/quarter:`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`-`, endOfPage, section1, {align: 'right'});
            section1 += offset + 2;
            
            // Section 9
            doc.text(`9. Excess of assets over liabilities or`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`-`, endOfPage, section1, {align: 'right'});
            section1 += offset + 2;

            doc.text(`10. Excess of liabilities over assets.`, startOfPage + pointOffset, section1, {align: 'left'});
            doc.text(`-`, endOfPage, section1, {align: 'right'});
            section1 += offset;
            doc.setLineWidth(0.5)
            doc.setDrawColor(0, 0, 0)
            doc.line(0, section1, width, section1);
            section1 += offset * 2;

            //Declaration
            doc.setFontStyle('bold');
            doc.setFontSize(12);
            doc.text(`DECLARATION`, centerOfPage, section1, {align: 'center'});
            section1 += offset * 2;
            doc.setFontStyle('');

            doc.text(`I ${data.owner.padEnd(20, ' ')} son of ${data.father.padEnd(20, ' ')} hereby declare that to the best of my knowledge `, startOfPage + 10, section1, {align: 'justify'});
            doc.text(`${"".padEnd(27, '.')}`, startOfPage + 14, section1+1, {align: 'left'});
            doc.text(`${"".padEnd(25, '.')}`, startOfPage + 55, section1+1, {align: 'left'});
            section1 += offset;
            doc.text(`and belief the information furnished in the above statement is true and correct.`, startOfPage + pointOffset, section1, {align: 'justify'});
            section1 += offset * 2;

            //Place and Name
            doc.text(`Place: ${selectedBranches.name}`, startOfPage, section1, {align: 'left'});
            doc.text(`Name: ${data.owner}`, endOfPage - 50, section1, {align: 'left'});
            section1 += offset * 2;

            //Date and Sign
            doc.text(`Date: ${moment().format(format_display)}`, startOfPage, section1, {align: 'left'});
            doc.text(`Signature:`, endOfPage - 50, section1, {align: 'left'});
            section1 += offset * 2;
            doc.text(`Status`, endOfPage - 50, section1, {align: 'left'});

            var blobpdf = new Blob([doc.output('blob')], {'type' : 'application/pdf'});
            var blobUrl = URL.createObjectURL(blobpdf);
            setPdfBlob(blobUrl);
            // doc.output("dataurlnewwindow");
            closeProgressDialog();
        }
    }, [data]);

    // const save = () => {
        // const el = document.getElementById('content');
        // html2canvas(el)
        // .then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     const pdf = new jsPDF({
        //         orientation: 'portrait',
        //         format: 'a4',
        //         compress: true
        //     });
        //     pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getHeight(), pdf.internal.pageSize.getWidth());
        //     pdf.save("download.pdf");
        // })
        // // doc.addHTML(el, () => {
        // //     doc.save('test.pdf');
        // // })
    // }

    React.useEffect(() => {
        if(selectedFY && selectedReportType){
            let start = moment(selectedFY.start, format_without_time);
            let end = moment(selectedFY.end, format_without_time);
            switch (selectedReportType) {
                case 'fy':
                    setStartDate(moment(start).startOf('d'));
                    setEndDate(moment(end).endOf('d'));
                    break;
                case 'q1':
                    setStartDate(moment(start).set('quarter', 2).startOf('quarter'));
                    setEndDate(moment(start).set('quarter', 2).endOf('quarter'));
                    break;
                case 'q2':
                    setStartDate(moment(start).set('quarter', 3).startOf('quarter'));
                    setEndDate(moment(start).set('quarter', 3).endOf('quarter'));
                    break;
                case 'q3':
                    setStartDate(moment(start).set('quarter', 4).startOf('quarter'));
                    setEndDate(moment(start).set('quarter', 4).endOf('quarter'));
                    break;
                case 'q4':
                    setStartDate(moment(end).set('quarter', 1).startOf('quarter'));
                    setEndDate(moment(end).set('quarter', 1).endOf('quarter'));
                    break;
                default:
                    break;
            }
        }
    }, [selectedFY, selectedReportType])


    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item xl={12} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                    {
                                        branches.length > 1 && (
                                            <Grid item xs={12} lg={2}>
                                                <ItemSelection
                                                    label='Entity' 
                                                    value={selectedBranches} 
                                                    optionLabel='name' 
                                                    options={branches} 
                                                    selected={setSelectedBranches}/>
                                            </Grid>  
                                        )
                                    }
                                <Grid item xs={6} lg={2}>
                                    <ItemSelection
                                        required 
                                        label='Select FY'
                                        value={selectedFY} 
                                        optionLabel='description'
                                        //labelTemplate={option => `${option.name} (Mobile: ${option.mobile})`}
                                        options={fys} 
                                        selected={(fy) => {
                                            setSelectedFY(fy);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormControl component="fieldset" style={{marginLeft: 8, marginTop: 8}}>
                                        <RadioGroup 
                                            row 
                                            aria-label="report type" 
                                            name="selectedReportType" 
                                            value={selectedReportType} 
                                            onChange={(e) => setSelectedReportType(e.target.value)}>
                                            <FormControlLabel value="fy" labelPlacement="bottom" style={{marginLeft: 0}} control={<Radio />} label={<Box component="div" fontSize={12}>FY</Box>} />
                                            <FormControlLabel value="q1" labelPlacement="bottom" style={{marginLeft: 0}} control={<Radio />} label={<Box component="div" fontSize={12}>Q1</Box>} />
                                            <FormControlLabel value="q2" labelPlacement="bottom" style={{marginLeft: 0}} control={<Radio />} label={<Box component="div" fontSize={12}>Q2</Box>} />
                                            <FormControlLabel value="q3" labelPlacement="bottom" style={{marginLeft: 0}} control={<Radio />} label={<Box component="div" fontSize={12}>Q3</Box>} />
                                            <FormControlLabel value="q4" labelPlacement="bottom" style={{marginLeft: 0}} control={<Radio />} label={<Box component="div" fontSize={12}>Q4</Box>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        style={{marginTop: 8}} 
                                        onClick={refresh}
                                    >
                                        Generate
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12} lg={1}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        style={{marginTop: 8}} 
                                        onClick={save}
                                    >
                                        Save
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xl={12} lg={12} xs={12}>
                        <embed src={pdfBlob} type="application/pdf" style={{marginTop: 16, width: '96vw', height: '80vh'}} />
                    </Grid>
                    {/* <Grid item xl={12} lg={12} xs={12}>
                        <div id="content">
                            <div id="disptext">
                                <div style={{textDecoration: 'underline'}}>Hello</div>
                            </div>
                        </div>
                    </Grid> */}
                </Grid>
            </div>
        </div>
    )
}