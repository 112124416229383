import React, { useContext, useEffect } from 'react'
import { CardHeader, Divider, Grid, IconButton, Paper, Table, TableBody, TableRow } from '@material-ui/core'
import { SpeakerNotes } from '@material-ui/icons'
import EnhancedTableHead from '../components/EnhancedTableHead'
import { format_fancy, getTimeInIST, StyledTableCell } from '../Utils'
import { AppContext } from '../AppContextProvider'
import { LoanRequestsService } from '../Services/LoanRequestsService'
import moment from 'moment'

const Followup = ({ classes, id, pledgeNo, setLoading, loanStartDate }) => {
    const { openAddFollowUp } = useContext(AppContext)
    const [followUpList, setFollowUpList] = React.useState([])

    const followUpColumns = [
        { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'remarks', align: 'left', numeric: false, disablePadding: true, label: 'Remarks', sortable: false },
        { id: 'entered_by', align: 'center', numeric: false, disablePadding: false, label: 'Entered By', sortable: false },
        { id: 'next_follow_up', align: 'center', numeric: false, disablePadding: false, label: 'Next Follow Up Scheduled', sortable: false },
        { id: 'created_date', align: 'center', numeric: false, disablePadding: false, label: 'Followed On', sortable: false },
    ]

    useEffect(() => {
        fetchFollowUps(loanStartDate)
    }, [loanStartDate])

    const newFollowUp = () => {
        openAddFollowUp(id, () => fetchFollowUps())
    }

    const fetchFollowUps = (st = loanStartDate) => {
        setLoading(true);
        LoanRequestsService.getFollowUps(moment(st), moment().endOf('day'), '', pledgeNo)
            .then(data => {
                setFollowUpList(data || []);
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            });
    }

    return (
        <Grid item xs={10} lg={10}>
            <Paper variant="outlined" className={classes.section} elevation={3}>
                <CardHeader
                    title="Follow Ups"
                    titleTypographyProps={{ variant: 'h4' }}
                    action={
                        <IconButton disabled={false} aria-label="add-new-follow-up" onClick={newFollowUp}>
                            <SpeakerNotes />
                        </IconButton>
                    }
                />
                <Divider />
                <Table>
                    <EnhancedTableHead
                        order={""}
                        orderBy={""}
                        onRequestSort={() => { }}
                        rows={followUpColumns} />
                    <TableBody>
                        {followUpList.map((row, i) =>
                            <TableRow key={i} hover>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {i + 1}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none' style={{ minWidth: '20vw', marginBottm: 8 }}>
                                    {row.remarks}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {row.entered_by}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {row.next_follow_up ? getTimeInIST(row.next_follow_up).format(format_fancy) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {getTimeInIST(row.created_date).format(format_fancy)}
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    )
}

export default Followup