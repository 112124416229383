import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function CustomerListDialog(props) {
  const classes = useStyles();
  const { onClose, list, label, labelTemplate, selectedValue, open, showAdd } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value, isNew = false) => {
    onClose(value, isNew)
  };

  const getOptionLabelTemplate = (option) => {
    if (labelTemplate) {
      return labelTemplate(option);
    }
    return option[label];
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Customer</DialogTitle>
      <List>
        {list.map((item, index) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={index}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={getOptionLabelTemplate(item)} />
          </ListItem>
        ))}

        {
          showAdd && (
            <ListItem autoFocus button onClick={() => handleListItemClick(null, true)}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" />
            </ListItem>
          )
        }
      </List>
      <Button color="secondary" onClick={handleClose}>CANCEL</Button>
    </Dialog>
  );
}

CustomerListDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object,
  list: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelTemplate: PropTypes.func,
  showAdd: PropTypes.bool
};