import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { ListSubheader, ListItemSecondaryAction, Icon, Fade, CircularProgress, Typography, DialogContent } from '@material-ui/core';
import { getTimeInIST, format_display_fancy_notime, BootstrapTooltip, formatNumber } from '../Utils';
import { ROLES } from '../Roles';
import { AppContext } from '../AppContextProvider';
import { LoanRequestsService } from '../Services/LoanRequestsService';
import { STATUS } from '../Constants';
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SearchDialog(props) {
  const classes = useStyles();
  const { state, openViewLoan, showSnackBar, openNewLoan } = useContext(AppContext);
  const { onClose, text, open } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    if (open && text.length > 0) {
      let bIds = state.branches.length > 0 ? null : state.selectedBranch.id;
      LoanRequestsService.getLoansSearch(text, bIds, 0, 20)
        .then(data => {
          setItems(data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          showSnackBar('Something weng wrong. Please try Again');
          setLoading(false);
          handleClose();
        })
    }
    return () => {
      setItems([]);
    }
  }, [open, text])

  const handleClose = () => {
    setItems([]);
    onClose();
  };

  const viewClick = (value) => {
    openViewLoan(value.pledge_no);
    handleClose();
  };

  const editClick = (value) => {
    openNewLoan(value.pledge_no);
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {loading ? `Searching for ${text} ` : `Search result for ${text}`}
      </DialogTitle>
      <DialogContent>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '10ms' : '0ms'
          }}
          unmountOnExit
        >
          <div style={{ height: 200 }}>
            <CircularProgress style={{ position: 'relative', top: '30%', left: '45%' }} />
          </div>
        </Fade>
        {
          !loading ? (
            items.length > 0 ? (
              <List subheader={<ListSubheader>Loans</ListSubheader>}>
                {items.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Loan No: ${item.pledge_no}, Customer: ${item.customer_name}, Code: ${item.cust_code}, Mobile: ${item.customer_mobile}, Status: ${item.status}`}
                      secondary={`Amount ${formatNumber(item.loan_amount)} Start: ${getTimeInIST(item.start_date).format(format_display_fancy_notime)}`}
                    />
                    <ListItemSecondaryAction>
                      <BootstrapTooltip arrow title="View">
                        <Icon
                          fontSize="small"
                          style={{ marginTop: 8, marginRight: 8 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            viewClick(item)
                          }}>library_books</Icon>
                      </BootstrapTooltip>
                      {
                        (item.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN)) && (
                          <BootstrapTooltip arrow title="Edit">
                            <Icon
                              fontSize="small"
                              style={{ marginTop: 8, marginRight: 8 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                editClick(item)
                              }}>edit</Icon>
                          </BootstrapTooltip>
                        )
                      }
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="subtitle1" style={{ marginLeft: 8, marginBottom: 16 }}>
                No Results found
              </Typography>
            )
          ) : null}
        {
          items.length >= 20 &&
          <Typography variant="h5">Showing first 20 matching results...</Typography>
        }
      </DialogContent>
    </Dialog>
  );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};