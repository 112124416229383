import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, colors, Tabs, Tab, Divider, Card, CardHeader, CardContent } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import CashTransaction from "./CashTransaction";
import BankTransaction from "./BankTransaction";
import JournalTransaction from "./JournalTransaction";

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3)
    },
    tabs: {
      marginTop: theme.spacing(3)
    },
    divider: {
      backgroundColor: colors.grey[300]
    }
  }));
  

export default function LedgerTransaction(props){

    const { match, history } = props;
    const classes = useStyles();
    const tab = match.params.tab || 'cash';

    const {setTitle} = useContext(AppContext);
    
    React.useEffect(() => {
        setTitle('Create Transactions');
    }, [true]);

    const handleTabsChange = (event, value) => {
        history.push(value);
      };
    
    const tabs = [
        { value: 'cash', label: 'Cash Transaction' },
        { value: 'bank', label: 'Bank Transaction' },
        { value: 'journal', label: 'Journal' },
    ];

    if (!tab) {
        return <Redirect to="/finance/accounts/transaction/cash" />;
    }

    if (!tabs.find(t => t.value === tab)) {
        return <Redirect to="/errors/error-404" />;
    }

    return (
        <div className="Home">
            <div className="lander">
                {/* <Header /> */}
                <Grid container justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} lg={10} xl={10}>
                        <Card>
                            <CardHeader
                                style={{paddingBottom: 0}}
                                title={
                                    <Tabs
                                        className={classes.tabs}
                                        onChange={handleTabsChange}
                                        scrollButtons="auto"
                                        value={tab}
                                        variant="fullWidth"
                                    >
                                        {tabs.map(tab => (
                                        <Tab
                                            key={tab.value}
                                            label={tab.label}
                                            value={tab.value}
                                        />
                                        ))}
                                    </Tabs>
                                } />
                            <Divider className={classes.divider} />
                            <CardContent>
                                <div>
                                    {tab === 'cash' && <CashTransaction {...props}/>}
                                    {tab === 'bank' && <BankTransaction {...props}/>}
                                    {tab === 'journal' && <JournalTransaction {...props}/>}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

LedgerTransaction.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};